import { createReducer, createSlice } from '@reduxjs/toolkit';
import { Building } from '../../../models/claim/building';
import { GetBuildings } from '../../actions/buildingactions';


export interface BuildingListState {
    buildinglist: Building[];
    buildingListLoading: boolean;
}

const initialBuildingState: BuildingListState = {
    buildingListLoading: true,
    buildinglist: [],
};

const buildinglistSlice = createSlice({
    name: 'buildinglist',
    initialState: initialBuildingState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetBuildings.pending, (state: BuildingListState, action) => {
            state.buildingListLoading = true;
            state.buildinglist = undefined;
        });
        builder.addCase(GetBuildings.fulfilled, (state: BuildingListState, action) => {
            state.buildingListLoading = false;
            let data = { ...(action.payload as Building[]) };
          
            state.buildinglist = data;
        });
        builder.addCase(GetBuildings.rejected, (state: BuildingListState, action) => {
            state.buildingListLoading = false;
            state.buildinglist = undefined;
          //  alert('data fetch error');
        });
    },
});

export default buildinglistSlice.reducer;
