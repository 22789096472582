
import * as React from 'react';

import DatePicker from 'react-datepicker';
import { useEffect, useRef, useState } from 'react';
import { formatDate, parseDate } from '@progress/kendo-intl';
import { Grid, GridColumn, GridColumnMenuFilter, GridColumnMenuProps, GridColumnMenuSort, GridDataStateChangeEvent, GridExpandChangeEvent, GridNoRecords, GridPageChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { useLoadData } from '../../hooks/use-scheduler-load-data';
import { DataResult, process, State } from '@progress/kendo-data-query';
import { LoadingIndicator } from '../../components/loading-indicator.component';

import dayjs from 'dayjs';
import {
    setExpandedState,
    setGroupIds,
} from "@progress/kendo-react-data-tools";
import { CLAIM_STATUS_CODE, formatPhoneNumber } from './utils';
import { isEmpty } from 'lodash';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { convertToTimezone, convertToTimezoneWithDateString } from '../../utils';
import SingleDropdownReport from './components/dropdown-report';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useAgingParameters } from '../../api/dashboard/useAgingParameters';
import { useStatusParameters } from '../../api/dashboard/useStatusParameters';

///Interface and Models
interface IAgingReportProps {

}



const AgingReport: React.FunctionComponent<IAgingReportProps> = (props) => {
    ///state variables
    const initialDataState: State = {
        take: 50,
        skip: 0,
        group: [],
    };

    ///States
    const chkRef = useRef<Scheduler>(null);
    const refAging = useRef<string>("");
    const refStatus = useRef<string>("");
    const [gridData, setGridData] = useState<DataResult>({ data: [], total: 0 });


    const [fromDate, setFromDate] = useState<Date>(new Date(dayjs().add(-6, "months").format("MM/DD/YYYY")))
    const [toDate, setToDate] = useState<Date>(new Date())
    const fromDateRef = useRef<Date>(new Date(dayjs().add(-6, "months").format("MM/DD/YYYY")));
    const toDateRef = useRef<Date>(new Date());
    let maxDate = new Date();

    const [agingList, setAgingList] = useState<any>([]);
    const [statusList, setStatusList] = useState<any>([]);
    //const refStatusList = useRef<any>([]);

    const [resultData, setResultData] = useState<DataResult>();
    const [dataState, setDataState] = useState<State>(initialDataState);


    const [cancelFilter, setCancelFilter] = useState(false);
    const [collapsedState, setCollapsedState] = React.useState([]);
    const filteredDataRef = useRef<Scheduler[]>([]);
    const filteredDataMainRef = useRef<Scheduler[]>([]);

    const refPendingCount = useRef<number>(0);
    const refReadyToScheduleCount = useRef<number>(0);
    const refScheduledCount = useRef<number>(0);

    const url = `SchedulerAssignments/GetMetricsReport?sDate=${encodeURIComponent(formatDate(fromDateRef.current, "MM/dd/yyyy"))}&fDate=${encodeURIComponent(formatDate(toDateRef.current, "MM/dd/yyyy"))}`;
    const { isLoading, isError, reload: reloadClaimScheduler, result } = useLoadData<Scheduler[]>(url);

    /// const urlAging = "SchedulerAssignments/GetMetricsRptAgingList";
    //const { isLoading: IsLoadingAging, isError: isErrorAging, reload: reloadAgingList, result: resultAging } = useLoadData<IAgingColor[]>(urlAging);
    //const { isLoading: IsLoadingStatus, isError: isErrorStatus, reload: reloadStatusList, result: resultStatus } = useLoadData<IClaimStatus[]>(urlStatus);


    const { data: resultAging, isLoading: isLoadingAging, isError: isErrorAging, refetch: refetchAgingList } = useAgingParameters({
    })
    const { data: resultStatus, isLoading: IsLoadingStatus, isError: isErrorStatus, refetch: refetchStatusList } = useStatusParameters({
    })



    const exportExcel = useRef<ExcelExport | null>(null);
    const [lastFilterStatus, setLastFilterStatus] = useState(String);
    const [filterActive, setFilterActive] = useState<string>("ALL");

    const [isValid, setValid] = useState<boolean>(false);
    const [isSearchClick, setSearchClick] = useState<boolean>(false);


    const DEFAULT_DATES = {
        start: new Date(dayjs().add(-6, "months").format("MM/DD/YYYY")),
        end: new Date()
    }

    useEffect(() => {
        if (result !== undefined) {

            result.forEach((p) => {
                if (p?.aging_Colour?.toLowerCase() === "yellow") {
                    p.aging_status = "Risk"
                } else if (p?.aging_Colour?.toLowerCase() === "red") {
                    p.aging_status = "Breach"
                } else if (p?.aging_Colour?.toLowerCase() === "green") {
                    p.aging_status = "On Time"
                }
            });

            const DataRes = cancelFilter === true ? process(filteredDataRef.current, dataState) : process(result, dataState);
            setGridData(DataRes);
            cancelFilter === true ? setResultData(processWithGroups(filteredDataRef.current, dataState)) : setResultData(processWithGroups(result, dataState));

            if (resultAging) {
                const agingParameters: any = [];
                resultAging?.forEach(e => {
                    agingParameters.push({ text: e?.status, value: e.status?.toLowerCase() });
                })
                setAgingList(agingParameters);
                localStorage.setItem("aginglist", JSON.stringify(agingParameters));
            } else {
                if (localStorage.getItem("aginglist") != null) {
                    const agingParameters = JSON.parse(localStorage.getItem("aginglist"));
                    setAgingList(agingParameters);
                }
            }
            if (resultStatus) {
                const statusParameters: any = [];
                resultStatus.forEach(e => {
                    statusParameters.push({ text: e?.status, value: e?.statusCode });
                });
                setStatusList(statusParameters);
                localStorage.setItem("statusList", JSON.stringify(statusParameters));
            } else {
                if (localStorage.getItem("statusList") != null) {
                    const statusParameters = JSON.parse(localStorage.getItem("statusList"));
                    setStatusList(statusParameters);
                }
            }

            if (!isSearchClick) {
                filteredDataMainRef.current = result;
                filteredCountsMain();
            }
        }

    }, [result, dataState]);


    //-------------------------------------------------------
    ///Local Functions
    //------------------------------------------------------
    const processWithGroups = (data: Scheduler[], dataState: State) => {
        const groups = dataState.group;
        dataState.group = groups;
        const newDataState = process(data, dataState);
        setGroupIds({ data: newDataState.data, group: dataState.group });
        return newDataState;
    };
    const filteredCountsMain = () => {

        const pendingCount = result?.filter(
            (p) =>
                p.statusCode?.toLowerCase() === (CLAIM_STATUS_CODE.RECEIVED.toLowerCase())
                || p.statusCode?.toLowerCase().includes(CLAIM_STATUS_CODE.AWATINGAVAILABILITY.toLowerCase())
                || p.statusCode?.toLowerCase().includes(CLAIM_STATUS_CODE.RECEIVED_RESCHEDULE.toLowerCase())
        );
        refPendingCount.current = pendingCount.length;

        const readyToScheduledCount = result?.filter(
            (p) =>
                p.statusCode?.toLowerCase() === (CLAIM_STATUS_CODE.READY_TO_SCHEDULE.toLowerCase())
        ).length;

        refReadyToScheduleCount.current = readyToScheduledCount;

        const scheduledCount = result?.filter(
            (p) =>
                p.statusCode?.toLowerCase() === (CLAIM_STATUS_CODE.SCHEDULED.toLowerCase())
        ).length;

        refScheduledCount.current = scheduledCount;


    };


    const filteredCounts = () => {

        const pendingCount = filteredDataMainRef.current?.filter(
            (p) =>
                p.statusCode?.toLowerCase() === (CLAIM_STATUS_CODE.RECEIVED.toLowerCase())
                || p.statusCode?.toLowerCase().includes(CLAIM_STATUS_CODE.AWATINGAVAILABILITY.toLowerCase())
                || p.statusCode?.toLowerCase().includes(CLAIM_STATUS_CODE.RECEIVED_RESCHEDULE.toLowerCase())
        );
        refPendingCount.current = pendingCount.length;

        const readyToScheduledCount = filteredDataMainRef.current?.filter(
            (p) =>
                p.statusCode?.toLowerCase() === (CLAIM_STATUS_CODE.READY_TO_SCHEDULE.toLowerCase())
        ).length;
        refReadyToScheduleCount.current = readyToScheduledCount;

        const scheduledCount = filteredDataMainRef.current?.filter(
            (p) =>
                p.statusCode?.toLowerCase() === (CLAIM_STATUS_CODE.SCHEDULED.toLowerCase())
        ).length;

        refScheduledCount.current = scheduledCount;


    };

    useEffect(() => {
        if (cancelFilter === true) {
            setDataState(initialDataState)
        }
        //filteredCounts(filteredDataRef.current)
        //filteredDataMainRef.current = result;
        //filteredCounts;
    }, [filteredDataRef.current])


    const columnProps = (field: string) => {
        return {
            field: field,
            columnMenu: ColumnMenu,
            headerClassName: isColumnActive(field, dataState) ? "active" : "",
        };
    };
    const isColumnActive = (field: string, dataState: State) => {
        return (
            GridColumnMenuFilter.active(field, dataState.filter) ||
            GridColumnMenuSort.active(field, dataState.sort)
        );
    };
    // useEffect(() => {
    //     console.log(refScheduledCount.current)
    // }, [refScheduledCount.current])

    const handleSearchClick = () => {

        const fromDateCheck = new Date(fromDate);
        const toDateCheck = new Date(toDate);
        setSearchClick(true);
        setFilterActive("");
        if (fromDateCheck > toDateCheck) {
            setValid(true);
        } else {
            fromDateRef.current = fromDate
            toDateRef.current = toDate;
            reloadClaimScheduler();
        }
        if (result) {

            const agingCondition = isEmpty(refAging?.current) || refAging?.current === "all" ? "all" : refAging?.current?.toLowerCase() === "on time" ? "green" : refAging?.current?.toLowerCase() !== "risk" ? "red" : "yellow";
            const statusCondition = isEmpty(refStatus?.current) || refStatus?.current === "-1" ? "all" : refStatus?.current.toLowerCase();
            if (agingCondition !== "all" && statusCondition !== "all") {
                let filteredData: any = []
                filteredData = result?.filter(
                    (p) =>
                        p.statusCode?.toLowerCase() === (statusCondition) && p.aging_Colour?.toLowerCase().includes(agingCondition)
                );
                setGridData(process(filteredData, dataState));
                setResultData(process(filteredData, dataState));
                setCancelFilter(true);
                filteredDataMainRef.current = filteredData;
                filteredDataRef.current = filteredData;
            }
            else if (agingCondition !== "all" && statusCondition === "all") {
                const filteredData = result?.filter(
                    (p) =>
                        p.aging_Colour?.toLowerCase() === (agingCondition)
                );
                setGridData(process(filteredData, dataState));
                setResultData(process(filteredData, dataState));
                setCancelFilter(true);
                filteredDataRef.current = filteredData;
                filteredDataMainRef.current = filteredData;
            }
            else if (agingCondition === "all" && statusCondition !== "all") {
                let filteredData: any = [];
                filteredData = result?.filter(
                    (p) =>
                        p.statusCode?.toLowerCase() === (statusCondition)
                );
                setGridData(process(filteredData, dataState));
                setResultData(process(filteredData, dataState));
                setCancelFilter(true);
                filteredDataRef.current = filteredData;
                filteredDataMainRef.current = filteredData;

            } else {
                setGridData(process(result, dataState));
                setResultData(process(result, dataState));
                setCancelFilter(false);
                filteredDataRef.current = result;
                filteredDataMainRef.current = result;
            }
            filteredCounts();
        }
    }
    const onStatusFilterToggle = (status: string) => {
        console.log(filteredDataMainRef.current.length)
        if (!cancelFilter || lastFilterStatus !== status) {
            const filteredData = status?.toLowerCase() === "pending" ?
                filteredDataMainRef.current?.filter((p) => p.statusCode?.toLowerCase() === CLAIM_STATUS_CODE.RECEIVED?.toLocaleLowerCase() || p.statusCode?.toLowerCase() === CLAIM_STATUS_CODE.AWATINGAVAILABILITY.toLowerCase()?.toLocaleLowerCase()
                    || p.statusCode?.toLowerCase() === CLAIM_STATUS_CODE.RECEIVED_RESCHEDULE.toLowerCase()?.toLocaleLowerCase())
                :
                filteredDataMainRef.current?.filter((p) => p.statusCode?.toLowerCase() === status?.toLowerCase());
            setGridData(process(filteredData, dataState));
            setResultData(process(filteredData, dataState));
            setCancelFilter(true);
            filteredDataRef.current = filteredData;
            console.log('load', filteredData)
        } else {
            setGridData(process(filteredDataMainRef.current, dataState));
            setResultData(process(filteredDataMainRef.current, dataState));
            setCancelFilter(false);
            filteredDataRef.current = [];
        }
        setLastFilterStatus(status);
    };

    useEffect(() => {
        if (cancelFilter === true) {
            setDataState(initialDataState)
        }
        //filteredCounts(filteredDataRef.current)
    }, [filteredDataRef.current])

    const expandChange = (event: GridExpandChangeEvent) => {
        const item = event.dataItem;

        if (item.groupId) {
            const newCollapsedIds = !event.value
                ? [...collapsedState, item.groupId]
                : collapsedState.filter((groupId) => groupId !== item.groupId);
            setCollapsedState(newCollapsedIds);
        }
    };

    let newData: any
    if (resultData) {
        newData = setExpandedState({
            data: resultData.data,
            collapsedIds: collapsedState,
        });
    }

    //-----------------------
    //Grid Events
    //-------------------------
    const ColumnMenu = (props: GridColumnMenuProps) => {
        return (
            <div>
                <GridColumnMenuFilter {...props} expanded={true} />
            </div>
        );
    };
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        const newDataState = process(result, dataState);
        setResultData(newDataState);
        setDataState(event.dataState);
    };
    const handleDataStateChange = (e: GridPageChangeEvent) => {
        const newDataState = { skip: e.page.skip, take: e.page.take };
        setDataState(newDataState);
    };

    const excelExport = () => {
        if (exportExcel.current !== null) {
            exportExcel.current.save();
        }
    };

    const aginColumnCell = (props: any) => {
        return (
            <td
                {...props.tdProps}
                colSpan={1}
                style={{
                    color: "black",
                }}
            >
                <div>
                    <td style={{ textAlign: 'center' }}>
                        {props.dataItem?.aging_Colour?.toLowerCase() === 'yellow' && (
                            <div style={{ width: "15px", height: "15px" }} className="scheduler-aging-yellow">
                            </div>)
                        }
                        {props.dataItem?.aging_Colour?.toLowerCase() === "red" &&
                            (<div style={{ width: "15px", height: "15px" }} className="scheduler-aging-red">
                            </div>)
                        }
                        {props.dataItem?.aging_Colour?.toLowerCase() === "green" &&
                            (<div style={{ width: "15px", height: "15px" }} className="scheduler-aging-green">
                            </div>)
                        }
                    </td>
                </div>
            </td>
        );
    };
    const scheduledOnColumnCell = (props: any) => {
        return (
            <td
                {...props.tdProps}
                colSpan={1}
                style={{
                    color: "black",
                }}
            >

                <div>
                    {
                        formatDate(!isEmpty(props?.dataItem?.scheduleOn) && new Date(props?.dataItem?.scheduleOn), "MM/dd/yyyy")
                    }
                </div>

            </td>
        );
    };
    const createdOnColumnCell = (props: any) => {
        return (
            <td
                {...props.tdProps}
                colSpan={1}
                style={{
                    color: "black",
                }}
            >
                <div>
                    {
                        formatDate(!isEmpty(props?.dataItem?.createdOn) && new Date(props?.dataItem?.createdOn), "MM/dd/yyyy")
                    }
                </div>

            </td>
        );
    };

    const phoneColumnCell = (props: any) => {
        return (
            <td
                {...props.tdProps}
                colSpan={1}
                style={{
                    color: "black",
                }}
            >
                <div>
                    <td className={props.className}>{!!props.dataItem?.[props.field] && formatPhoneNumber(props.dataItem[props.field])}</td>
                </div>
            </td>
        );
    };


    const toggleDialog = () => {
        setValid(false);
    };

    let grid = null;
    if (newData) {
        grid = (<Grid
            data={newData}
            pageable={{ pageSizes: [50, 100, 200, 500] }}
            skip={dataState.skip}
            take={dataState.take}
            total={gridData.total}
            groupable={true}
            onExpandChange={expandChange}
            sortable={true}
            onDataStateChange={dataStateChange}
            {...dataState}
            onPageChange={(e) => handleDataStateChange(e)}
            resizable={true}
            columnVirtualization={false}
        >   <GridToolbar>
                <button
                    title="Export Excel"
                    className="k-button k-primary"
                    onClick={excelExport}
                >
                    Export to Excel
                </button>
            </GridToolbar>
            <GridColumn field="aging_Colour" title="AGING" width={"70px"} cells={{ data: aginColumnCell }}
            />
            <GridColumn
                width={"100px"}
                field="claimNo"
                columnMenu={ColumnMenu}
                title="CLAIM#"
                {...columnProps("claimNo")}
            />
            <GridColumn
                width={"120px"}
                field="policyNo"
                columnMenu={ColumnMenu}
                {...columnProps("policyNo")}
                title="POLICY#" />
            <GridColumn
                field="keyStoneNo"
                width={"120px"}
                title="KEYSTONE#"
                columnMenu={ColumnMenu}
                {...columnProps("keyStoneNo")}
            />
            <GridColumn  {...columnProps("homeOwner")} field="homeOwner" title="HOMEOWNER" columnMenu={ColumnMenu} />
            <GridColumn width={"150px"} {...columnProps("homeOwner_Phone")} field="homeOwner_Phone" columnMenu={ColumnMenu} title="HOMEOWNER PHONE" cells={{ data: phoneColumnCell }} />
            <GridColumn width={"200px"}  {...columnProps("address")} columnMenu={ColumnMenu} className='text-nowrap' field="address" title="ADDRESS" />
            <GridColumn width={"140px"} {...columnProps("scheduleOn")} field='scheduleOn' columnMenu={ColumnMenu} title="SCHEDULED ON" filter={'date'}
                cells={{ data: scheduledOnColumnCell }}
            />
            <GridColumn
                columnMenu={ColumnMenu}
                field="inspector"
                {...columnProps("inspector")}
                title="INSPECTOR"
            />
            <GridColumn
                columnMenu={ColumnMenu}
                {...columnProps("homeowneravailability")}
                field="homeowneravailability"
                title="HOMEOWNER AVAILABILITY"
            />
            <GridColumn
                {...columnProps("status")}
                columnMenu={ColumnMenu} field="status" title="STATUS" />
            <GridColumn
                {...columnProps("createdOn")}
                field='createdOn' columnMenu={ColumnMenu} title="CREATED ON" filter='date'
                cells={{ data: createdOnColumnCell }}
            />
            <GridNoRecords>
                <div className='mt-2' style={{ width: "100%", height: "500px" }}>
                    <center><h5>No data found</h5></center>
                </div>
            </GridNoRecords>
        </Grid>)
    };
    return (<>
        {isValid &&
            <Dialog title={"Validation"} onClose={toggleDialog} style={{ borderRadius: "10px" }} className="delete-dialog">
                <div>
                    <div className="row">
                        <div className="d-flex justify-content-between align-items-center mt-2 mb-2'">
                            <p style={{ fontSize: "16px", fontWeight: "bold" }}>From Date should be less than To Date</p>
                        </div>
                    </div>
                </div>
            </Dialog>
        }
        <div className='m-lg-6 mt-lg-3 m-4'>
            <div className="top-sec-align"><h5 className="text-normal title-web mb-0">REPORT</h5> </div>
            <div className='agingColumn'>
                <div className='boxcount colmn-3'>
                    <div onClick={() => { setFilterActive(filterActive === "pending" ? "" : "pending"); onStatusFilterToggle("pending") }} style={{ cursor: "pointer" }} className={filterActive === "pending" ? ('gredientBg card p-4 border-0 sw-shadow radius-15') : ("card p-4 border-0 sw-shadow radius-15")}>
                        <h6 className='text-uppercase fw-bold'>Pending </h6>
                        <h2 className='indication2'>{refPendingCount.current}</h2>
                    </div>
                    <div onClick={() => { setFilterActive(filterActive === "ready" ? "" : "ready"); onStatusFilterToggle(CLAIM_STATUS_CODE.READY_TO_SCHEDULE) }} style={{ cursor: "pointer" }} className={filterActive === "ready" ? ('gredientBg card p-4 border-0 sw-shadow radius-15') : ("card p-4 border-0 sw-shadow radius-15")}>
                        <h6 className='text-uppercase fw-bold'>Ready to Schedule</h6>
                        <h2 className='indication2'>{refReadyToScheduleCount.current}</h2>
                    </div>
                    <div onClick={() => { setFilterActive(filterActive === "scheduled" ? "" : "scheduled"); onStatusFilterToggle(CLAIM_STATUS_CODE.SCHEDULED) }} style={{ cursor: "pointer" }} className={filterActive === "scheduled" ? ('gredientBg card p-4 border-0 sw-shadow radius-15') : ("card p-4 border-0 sw-shadow radius-15")}>
                        <h6 className='text-uppercase fw-bold'>Scheduled</h6>
                        <h2 className='indication2'>{refScheduledCount.current}</h2>
                    </div>
                </div>

                <div className='dateFitersec'>
                    <div className='colm-3sec'>
                        <div>
                            <label className='mb-1'>Date From</label>

                            <DatePicker
                                openToDate={fromDate}
                                maxDate={toDate}
                                className="form-control  ms-auto calendarBg"
                                value={formatDate(fromDate, "MM/dd/yyyy")} placeholderText='To' dateFormat={"MM/dd/yyyy"} onChange={(date) => {
                                    setFromDate(date);
                                }} />
                        </div>
                        <div >
                            <label className='mb-1'>Date To</label>
                            <DatePicker
                                openToDate={toDate}
                                minDate={DEFAULT_DATES.start}
                                maxDate={maxDate}
                                className="form-control ms-auto calendarBg"
                                value={formatDate(toDate, "MM/dd/yyyy")} placeholderText='To' dateFormat={"MM/dd/yyyy"} onChange={(date) => {
                                    setToDate(date);
                                }} />
                        </div>
                    </div>
                    <div className='colm-4sec'>
                        <div >
                            <label className='mb-1'>Status</label>
                            < SingleDropdownReport
                                height={"35px"}
                                className="form-control dropDownList search-right-align"
                                isDef={{ text: "All", value: "all" }}
                                dataitems={statusList}
                                onchange={(value: string) => {
                                    refStatus.current = value;
                                }}
                                id={'status'} />
                        </div>
                        <div >
                            <label className='mb-1'>Aging</label>
                            <SingleDropdownReport
                                height={"35px"}
                                isDef={{ text: "All", value: "all" }}
                                dataitems={agingList}
                                onchange={(value: string) => {
                                    refAging.current = value;
                                }}
                                className="form-control dropDownList search-right-align"
                                id={'aging'} />
                        </div>
                        <div >


                            <button className="btn-primary btn search-btn mt-1" onClick={() => {

                                handleSearchClick();
                            }} type="submit" >
                                <svg width="17" height="17" viewBox="0 0 17.5 17.5">
                                    <path id="Path_magnify" data-name="Path / magnify" d="M9.5,3a6.5,6.5,0,0,1,4.94,10.73l.27.27h.79l5,5L19,20.5l-5-5v-.79l-.27-.27A6.5,6.5,0,1,1,9.5,3m0,2A4.5,4.5,0,1,0,14,9.5,4.481,4.481,0,0,0,9.5,5Z" transform="translate(-3 -3)" fill="#fff" />
                                </svg>

                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card p-4 border-0 sw-shadow radius-15 border-0 '>
                {isError && (<div>Error</div>)}
                {isLoading && (<LoadingIndicator isLoading />)}
                {!isLoading && <>
                    {grid}
                    <ExcelExport fileName={"Aging_Report_" + convertToTimezone(new Date).format("MMDDYYYHHMMS")} data={filteredDataRef.current?.length > 0 ? filteredDataRef.current : newData} ref={exportExcel}>
                        <ExcelExportColumn width={100} field="aging_status" title="AGING" />
                        <ExcelExportColumn width={100} field="claimNo" title="CLAIM #" />
                        <ExcelExportColumn width={100} field="policyNo" title="Policy #" />
                        <ExcelExportColumn width={150} field="keyStoneNo" title="KEYSTONE #" />
                        <ExcelExportColumn width={170} field="homeOwner" title="HOME OWNER" />
                        <ExcelExportColumn width={150} field="homeOwner_Phone" title="HOME OWNER PHONE" />
                        <ExcelExportColumn width={280} field="address" title="ADDRESS" />
                        <ExcelExportColumn width={170} field='scheduleOn' title="SCHEDULED ON" />
                        <ExcelExportColumn width={150} field="inspector" title="INSPECTOR" />
                        <ExcelExportColumn width={170} field="homeowneravailability" title="HOMEOWNER AVAILABILITY" />
                        <ExcelExportColumn width={170} field="status" title="STATUS" />
                        <ExcelExportColumn width={170} field="createdOn" title="CREATED ON" />
                    </ExcelExport>

                    <div className='d-flex p-2 m-1'>
                        <div style={{ display: "flex", flexDirection: "row", textAlign: "justify" }}>
                            <div style={{ width: "15px", height: "15px" }} className="mt-1 scheduler-aging-green">
                            </div>
                            <div className="ms-2">On Time</div>
                        </div>
                        <div className="ms-2" style={{ display: "flex", flexDirection: "row", textAlign: "left" }}>
                            <div style={{ width: "15px", height: "15px" }} className="mt-1 scheduler-aging-yellow">
                            </div>
                            <div className="ms-1" style={{ height: "15px" }}>Risk</div>
                        </div>
                        <div className="ms-2" style={{ display: "flex", flexDirection: "row", textAlign: "left" }}>
                            <div style={{ width: "15px", height: "15px" }} className="mt-1 scheduler-aging-red">
                            </div>
                            <div className="ms-1" style={{ width: "100px", height: "15px" }}>Breach</div>
                        </div>
                    </div>
                </>
                }
            </div >
        </div >
    </>)
}
export default AgingReport