import type { AxiosError } from 'axios';
import axios from 'axios';
import { createMutation } from 'react-query-kit';

type Response = {
    message: 'Slot created successfully';
    data: null;
};

type Payload = { UserID?: number; SchID?: number; ClaimId?: number; SlotID?: number; FromDate: string; ToDate: string; SlotStatus?: string; inspectorID?: number; homeownerID?: number };

export const useScheduleHomeownerSlot = createMutation<Response, Payload, AxiosError>({
    mutationFn: async payload =>
        axios({
            url: 'SchedulerAssignments/SaveHomeOwnerSlotsForCalendar',
            method: 'POST',
            data: payload
        }).then(response => response.data),
});
