import { ClaimObservation, Photo } from '../../../models/claim/claim-observation';
import videoimg from '../../../images/file-video';
import imageimg from '../../../images/file-image';
import noteimg from '../../../images/note.svg';
import editImg from '../../../images/edit-gray';
import DeleteGreyIcon from '../../../images/delete-gray';
import { EntityType } from '../../../models/claim/entity-type';
import { ObservationOptionsComponent } from './observation-options.component';
import { GuardedComponent } from '../../../components/guarded.componemt';
import { PermissionName } from '../../../models/user-security/permission.name';
import FileImage from '../../../images/file-image';
import FileVideoIcon from '../../../images/file-video';
import EditGrayIcon from '../../../images/edit-gray';
import { ObservationType } from '../../../models/masters/observation-type';
import { removeDuplicates } from '../../../utils/array.util';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip';
import EditIcon from '../../../images/edit';
import DeleteIcon from '../../../images/delete';
interface ObservationComponentProps {
    observation: ClaimObservation;
    onEditObservation?: any;
    onDeleteObservation?: any;
    onOpenGallery?: any;
    onselect: Function;
    onCopyObservation?: (observation: ClaimObservation) => any;
    onShowObservationPhotos?: any;
    className: string;
}

export const ObservationComponent = ({
    observation,
    onEditObservation,
    onDeleteObservation,
    onOpenGallery,
    onselect,
    onCopyObservation,
    className,
    onShowObservationPhotos,
}: ObservationComponentProps) => {
    observation.options = removeDuplicates(observation.options, 'id');

    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
                onShowObservationPhotos(observation);
                onselect(e);
            }}
            className={className}
        >
            {observation.observation != 'Other' ? (
                <div>
                    {observation.observation}
                </div>
            ) : null}
            <div>
                <ObservationOptionsComponent options={observation.options} observationType={observation.entityType} />
            </div>
            <div>{observation.note}</div>
            <div className="d-none">
                <FileVideoIcon></FileVideoIcon> {/* <img src={videoimg} className="d-none" /> */}
                <span className="tagtop d-none">0</span>
            </div>
            {/*  {(onOpenGallery && observation.entityType === ObservationType.Door) ||
            observation.entityType === ObservationType.Railing ||
            observation.entityType === ObservationType.Window ? (
                <div className="span-2-col">
                    <button
                        className="tag-buttons"
                        title={'Edit Observation'}
                        onClick={(e) => {
                            e.stopPropagation();
                            onOpenGallery({
                                entityType: observation.entityType,
                                entityId: observation.observationID,
                            });
                        }}
                    >
                        <FileImage></FileImage>

                        <span className="tagtop" title={'Edit'}>
                            {observation.images}
                        </span>
                    </button>
                </div>
            ) : null} */}
            {onEditObservation && (
                <div>
                    <GuardedComponent permission={PermissionName.EditObservation}>
                        <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                                <Tooltip {...props}>
                                    Edit
                                </Tooltip>
                            )}
                            placement="bottom"
                        >

                            <button
                                className="tag-buttons"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onEditObservation(observation);
                                }}
                            >
                                <EditIcon />{/* <img src={editImg} /> */}
                            </button>
                        </OverlayTrigger>
                    </GuardedComponent>
                </div>
            )}
            {onDeleteObservation && (
                <div>
                    <GuardedComponent permission={PermissionName.DeleteObservation}>
                        <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                                <Tooltip {...props}>
                                    Delete
                                </Tooltip>
                            )}
                            placement="bottom"
                        >
                            <button
                                className="tag-buttons"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDeleteObservation(observation);
                                }}

                            >
                                <DeleteIcon /> {/* <img src={deleteImg} /> */}
                            </button>
                        </OverlayTrigger>
                    </GuardedComponent>
                </div>
            )}
            {onCopyObservation && (
                <div>
                    <GuardedComponent permission={PermissionName.DeleteObservation}>
                        <button
                            className="tag-buttons"
                            onClick={(e) => {
                                e.stopPropagation();
                                onCopyObservation(observation);
                            }}
                        >
                            <FileImage></FileImage> {/* <img src={imageimg} /> */}
                        </button>
                    </GuardedComponent>
                </div>
            )}
        </div>
    );
};
