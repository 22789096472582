import { cloneDeep, forEach, isNil, map, remove } from 'lodash';
import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { getCanvasMousePosition } from './utils/canvas.util';
import {
    applyTransform,
    getAdjustedRectanglePoints,
    getDistance,
    getSelectedShapeAtPosition,
    inverseTransform,
    getImageBoundingPoint,
    getResizeTranformation,
    checkPointInsideRectangle,
} from './utils/geometry.util';
import { ColorInfo, ImageConfig } from './image-draw.config';
import {
    Point,
    Shape,
    Transform,
    Mode,
    ShapeType,
    GhostMode,
} from '../../../models/building-image/image-draw.types';
import { ShapeInfoComponent } from './shape-info.component';
import { getShapeCreator } from './utils/shape-creator';
import { addOrUpdateArrayItem, deleteArrayItem } from '../../../utils/array.util';
import { getCurrentGhostMode, moveGhost, resizeGhost } from './utils/ghost.util';
import { getImageCursor } from './utils/cursor.util';
import { EntityImageData } from '../../../models/building-image/entity-image-data';
import { ShapeEditButtonsComponent } from './shape-edit-buttons.component';
import { DrawingToolsComponent } from './drawing-tools.component';

export interface CanvasConfig {
    maxCanvasWidth: number;
    maxCanvasHeight: number;
}

export interface ImageDrawingComponentProps {
    imageUrl: string;
    initialShapes?: Shape[];
    disableDraw: boolean;
    customButtons?: ReactNode;
    onShapeSelected?: (shape: Shape) => any;
    onShapeSave?: (shape: Shape) => Promise<{ success: boolean; tagData?: EntityImageData }>;
    onShapeDelete?: (shape: Shape) => Promise<boolean>;
    onViewDetails?: (entityData: EntityImageData) => any;
}

export const ImageDrawingComponent = ({
    imageUrl,
    initialShapes,
    disableDraw,
    customButtons,
    onShapeSelected,
    onShapeSave,
    onShapeDelete,
    onViewDetails,
}: ImageDrawingComponentProps) => {
    const [mode, setMode] = useState(disableDraw ? Mode.Select : Mode.Draw);
    const [image, setImageToDisplay] = useState<HTMLImageElement>();
    const [canvasSize, setCanvasSize] = useState<{ height: number; width: number }>({
        width: 100,
        height: 100,
    });
    const [editorColor, setEditorColor] = useState<ColorInfo>({
        defaultColor: 'green',
        ghostColor: 'blue',
        highlightColor: 'yellow',
        selectionColor: 'red',
    });

    const [shapes, setShapes] = useState<Shape[]>([]);
    const [selectedShape, setSelectedShape] = useState<Shape>();
    const [ghostShape, setGhostShape] = useState<Shape>();
    const [currentGhostMode, setCurrentGhostMode] = useState<GhostMode>();
    const [fullScreen, setFullScreen] = useState(false);

    const [mouseActive, setMouseActive] = useState(false);
    const [dragStartPoint, setDragStartPoint] = useState<Point>();
    const [previousMousePoint, setPreviousMousePoint] = useState<Point>();
    const [tooltipCanvasPoint, setTooltipCanvasPoint] = useState<Point>();

    const [isBusy, setIsBusy] = useState<boolean>(false);

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const rootDivRef = useRef<HTMLDivElement>(null);

    const SCALE_FACTOR = 0.1;
    const ORIGINAL_SCALE = 1;

    useEffect(() => {
        if (imageUrl) {
            const imageHtml = new Image();
            imageHtml.onload = () => {
                if (rootDivRef.current) {
                    setCanvasSize({
                        width: rootDivRef.current.clientWidth,
                        height: rootDivRef.current.clientHeight,
                    });
                }
                setImageToDisplay(imageHtml);
            };
            imageHtml.src = imageUrl;
        }
    }, [imageUrl]);

    useEffect(() => {
        setGhostShape(null);
        setSelectedShape(null);
    }, [mode]);

    useEffect(() => {
        refreshTooltipPoint();
    }, [selectedShape, ghostShape]);

    useEffect(() => {
        if (disableDraw) {
            setMode(Mode.Select);
        } else {
            setMode(Mode.Draw);
        }
    }, [disableDraw]);

    useEffect(() => {
        if (!image) {
            return;
        }
        setSelectedShape(null);
        setGhostShape(null);
        const context = getDrawingContext();
        context.resetTransform();
        redrawCanvas([getResizeTranformation(canvasRef.current, image)]);
    }, [image]);

    useEffect(() => {
        setShapes(
            map(initialShapes, (s, i) => {
                return {
                    ...s,
                    shapeKey: i,
                    ...(!s.shapeType && { shapeType: ShapeType.Rectangle }),
                    ...(!s.thickness && { thickness: ImageConfig.LINE_THICKNESS }),
                };
            }),
        );
        setSelectedShape(null);
        setGhostShape(null);
    }, [initialShapes]);

    useLayoutEffect(() => {
        redrawCanvas();
    }, [shapes, ghostShape, selectedShape, editorColor]);

    const getDrawingContext = () => {
        return canvasRef.current.getContext('2d');
    };

    const getCurrentTransofm = () => {
        const context = getDrawingContext();
        return context.getTransform();
    };

    const redrawCanvas = (transforms: Transform[] = []) => {
        if (!image) {
            return;
        }

        const context = getDrawingContext();
        const clearWidth = Math.max(image.width, canvasSize.width) + 1000;
        const clearHeight = Math.max(image.height, canvasSize.height) + 1000;
        context.clearRect(0, 0, clearWidth, clearHeight);
        forEach(transforms, (transform) => {
            context.transform(
                transform.zoom,
                0,
                0,
                transform.zoom,
                transform.translate.x,
                transform.translate.y,
            );
        });
        if (image) context.drawImage(image, 0, 0, image.width, image.height);
        const allShapes = cloneDeep(shapes);
        if (ghostShape) {
            remove(allShapes, (s) => s.shapeKey === ghostShape.shapeKey);
            allShapes.push(ghostShape);
        }
        drawShapes(context, allShapes);
        refreshTooltipPoint();
    };

    const drawShapes = (context: CanvasRenderingContext2D, shapes: Shape[]) => {
        forEach(shapes, (shape) => {
            if (!shape.isHidden) {
                context.beginPath();
                const shapeCreator = getShapeCreator(shape.shapeType);
                const shape1 = cloneDeep(shape);
                if (selectedShape && selectedShape.shapeKey === shape1.shapeKey) {
                    shape1.isSelected = true;
                }
                shapeCreator(context, shape1, editorColor);
            }
        });
    };

    const handleMouseWheel = (mouseEvent: any) => {
        const point = getImageMousePosition(mouseEvent);
        handleApplyZoom(
            mouseEvent.deltaY > 0 ? ORIGINAL_SCALE - SCALE_FACTOR : ORIGINAL_SCALE + SCALE_FACTOR,
            point,
        );
    };

    const handleMouseDown = (mouseEvent: any) => {
        mouseEvent.preventDefault();
        setMouseActive(true);
        const point = getImageMousePosition(mouseEvent);
        setPreviousMousePoint(point);
        setDragStartPoint(point);

        const currentTranform = getCurrentTransofm();
        const ghostMode: GhostMode =
            ghostShape && getCurrentGhostMode(point, ghostShape, currentTranform.a);
        if (ghostMode) {
            setCurrentGhostMode(ghostMode);
            return;
        }

        switch (mode) {
            case Mode.Draw:
                const selectedShape = getSelectedShapeAtPosition(point, shapes);
                if (selectedShape) {
                    setGhostShape(null);
                    handleSelectShape(selectedShape);
                } else {
                    setSelectedShape(null);
                    const newShape = getNewShape(point);
                    newShape.isGhost = true;
                    setGhostShape(newShape);
                }
                break;
            case Mode.ZoomIn:
                handleApplyZoom(ORIGINAL_SCALE + SCALE_FACTOR, point);
                break;
            case Mode.ZoomOut:
                handleApplyZoom(ORIGINAL_SCALE - SCALE_FACTOR, point);
                break;
            case Mode.Pan:
                setDragStartPoint(point);
                break;
            case Mode.Select:
                handleSelectShape(getSelectedShapeAtPosition(point, shapes));
                break;
        }
    };

    const handleMouseMove = (mouseEvent: any) => {
        mouseEvent.preventDefault();
        const point = getImageMousePosition(mouseEvent);
        const currentTranform = getCurrentTransofm();
        setMousePointer(mouseEvent, point, currentTranform.a);

        if (!mouseActive) return;

        if (ghostShape && currentGhostMode) {
            handleGhostMouseMove(point);
        } else {
            handleNormalMouseMove(mouseEvent, point, currentTranform.a);
        }

        setPreviousMousePoint(point);
    };

    const handleGhostMouseMove = (point: Point) => {
        let updatedGhost;
        if (currentGhostMode === GhostMode.Moving) {
            updatedGhost = moveGhost(
                { x: point.x - previousMousePoint.x, y: point.y - previousMousePoint.y },
                ghostShape,
                image.width,
                image.height,
            );
        } else {
            updatedGhost = resizeGhost(point, ghostShape, currentGhostMode);
        }

        if (updatedGhost) setGhostShape(updatedGhost);
    };

    const handleNormalMouseMove = (mouseEvent: any, point: Point, sclaeFactor: number) => {
        switch (mode) {
            case Mode.Draw:
                if (ghostShape && !currentGhostMode) {
                    setGhostShape({ ...ghostShape, point2: point });
                }
                break;
            case Mode.Pan:
                setMousePointer(mouseEvent, point, sclaeFactor, true);
                handleApplyPan(point.x - dragStartPoint.x, point.y - dragStartPoint.y);
                break;
        }
    };

    const handleMouseUp = (mouseEvent: any) => {
        mouseEvent.preventDefault();
        setMouseActive(false);

        if (currentGhostMode) {
            setCurrentGhostMode(null);
        }

        switch (mode) {
            case Mode.Draw:
                if (ghostShape && getDistance(ghostShape.point1, ghostShape.point2) > 5) {
                    const { point1, point2 } = getAdjustedRectanglePoints(
                        ghostShape.point1,
                        ghostShape.point2,
                    );
                    const updatedShape = {
                        ...ghostShape,
                        point1: point1,
                        point2: point2,
                    };
                    setGhostShape(updatedShape);
                } else {
                    setGhostShape(null);
                }
                break;
        }
    };

    const setMousePointer = (
        mouseEvent: any,
        point: Point,
        sclaeFactor: number,
        inprogress = false,
    ) => {
        mouseEvent.target.style.cursor = getImageCursor(
            mode,
            point,
            inprogress,
            ghostShape,
            sclaeFactor,
        );
    };

    const handleApplyZoom = (zoom: number, point: Point) => {
        redrawCanvas([
            { zoom: ORIGINAL_SCALE, translate: { x: point.x, y: point.y } },
            { zoom: zoom, translate: { x: 0, y: 0 } },
            { zoom: ORIGINAL_SCALE, translate: { x: -point.x, y: -point.y } },
        ]);
    };

    const handleApplyPan = (x: number, y: number) => {
        redrawCanvas([{ zoom: ORIGINAL_SCALE, translate: { x: x, y: y } }]);
    };

    const handleSelectShape = (shape: Shape) => {
        setSelectedShape(shape);
        if (onShapeSelected) {
            onShapeSelected(shape);
        }
    };

    const getNewShape = (point: Point) => {
        const lasyMaxKey = shapes.reduce(
            (acc, shot) => (acc = acc > shot.shapeKey ? acc : shot.shapeKey),
            0,
        );
        return getShapeObject(point, point, lasyMaxKey + 1);
    };

    const getShapeObject = (
        point1: Point,
        point2: Point,
        shapeKey: number,
        shapeType = ShapeType.Rectangle,
    ): Shape => {
        return {
            shapeType,
            point1,
            point2,
            shapeKey,
            thickness: ImageConfig.LINE_THICKNESS,
        };
    };

    const getImageMousePosition = (event: any): Point => {
        const point = getCanvasMousePosition(event, canvasRef.current);
        return convertToImagePosition(point);
    };

    const convertToImagePosition = (canvasPoint: Point): Point => {
        const transform = getCurrentTransofm();
        const inverseTransformPoint = inverseTransform(transform, canvasPoint);
        return getImageBoundingPoint(
            inverseTransformPoint,
            image?.width,
            image?.height,
            ImageConfig.LINE_THICKNESS,
        );
    };

    const handleEditSelectedClick = () => {
        setGhostShape({ ...selectedShape, isGhost: true });
        setSelectedShape(null);
    };

    const handleDeleteSelectedClick = async () => {
        setIsBusy(true);
        if (!onShapeDelete || (await onShapeDelete(selectedShape))) {
            const clonedShapes = deleteArrayItem([...shapes], selectedShape, 'shapeKey');
            setSelectedShape(null);
            setShapes(clonedShapes);
        }
        setIsBusy(false);
    };

    const handleSaveChangesClick = async () => {
        setIsBusy(true);
        if (onShapeSave) {
            const result = await onShapeSave({ ...ghostShape, isSelected: false, isGhost: false });
            if (result.success) {
                const clonedShapes = addOrUpdateArrayItem(
                    [...shapes],
                    { ...ghostShape, tagData: result.tagData },
                    'shapeKey',
                );
                forEach(clonedShapes, (s) => {
                    s.isSelected = false;
                    s.isGhost = false;
                });
                setShapes(clonedShapes);
            }
        } else {
            const clonedShapes = addOrUpdateArrayItem([...shapes], ghostShape, 'shapeKey');
            forEach(clonedShapes, (s) => {
                s.isSelected = false;
                s.isGhost = false;
            });
            setShapes(clonedShapes);
        }
        setGhostShape(null);
        setIsBusy(false);
    };

    const handleCancelChangesClick = () => {
        setGhostShape(null);
    };

    const refreshTooltipPoint = () => {
        if (!selectedShape && !ghostShape) {
            return;
        }
        let currentPoint = { x: 0, y: 0 };
        if (selectedShape) {
            currentPoint = selectedShape.point1;
        }
        if (ghostShape) {
            currentPoint = ghostShape.point1;
        }

        const transform = getCurrentTransofm();
        const transformedPoint = applyTransform(transform, currentPoint);
        if (
            !checkPointInsideRectangle(
                transformedPoint,
                { x: 0, y: 0 },
                { x: canvasRef.current.width, y: canvasRef.current.height },
            )
        ) {
            setTooltipCanvasPoint(null);
        } else {
            setTooltipCanvasPoint(transformedPoint);
        }
    };

    const toolButtonClick = (currentMode: Mode) => {
        if (currentMode !== mode) {
            setMode(currentMode);
        }

        switch (currentMode) {
            case Mode.ZoomIn:
                handleApplyZoom(ORIGINAL_SCALE + SCALE_FACTOR, {
                    x: canvasSize.width / 2 - 100,
                    y: canvasSize.height / 2,
                });
                break;
            case Mode.ZoomOut:
                handleApplyZoom(ORIGINAL_SCALE - SCALE_FACTOR, {
                    x: canvasSize.width / 2,
                    y: canvasSize.height / 2,
                });
                break;
        }
    };

    const handleCloseColorPicker = (color: ColorInfo) => {
        if (color) {
            setEditorColor(color);
        }
    };

    const toggleFullScreen = () => {
        setFullScreen(!fullScreen);
    };

    const getInfoCanvasStyle = (marginTop = 0): any => {
        return {
            position: 'absolute',
            zIndex: 1,
            left: tooltipCanvasPoint.x,
            top: tooltipCanvasPoint.y + marginTop,
        };
    };

    if (!imageUrl) {
        return <>No Image</>;
    }

    return (
        <div className={fullScreen ? 'image-toolwrap full-screen' : 'image-toolwrap'}>
            <DrawingToolsComponent
                disableDraw={disableDraw}
                currentMode={mode}
                toolButtonClick={toolButtonClick}
                editorColor={editorColor}
                handleCloseColorPicker={handleCloseColorPicker}
                customButtons={customButtons}
                toggleFullScreen={toggleFullScreen}
            />
            <div className="canvas-wrap" ref={rootDivRef}>
                <canvas
                    id="canvas"
                    ref={canvasRef}
                    width={canvasSize.width}
                    height={canvasSize.height}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                    onWheel={handleMouseWheel}
                    style={{
                        margin: 'auto',
                    }}
                >
                    Canvas
                </canvas>

                {selectedShape && tooltipCanvasPoint && (
                    <div style={getInfoCanvasStyle(-82)}>
                        <ShapeInfoComponent
                            shape={selectedShape}
                            onViewDetails={onViewDetails}
                            oEditSelectedClick={handleEditSelectedClick}
                            onDeleteClick={handleDeleteSelectedClick}
                        />
                    </div>
                )}
                {ghostShape && !mouseActive && !isBusy && tooltipCanvasPoint && (
                    <div style={getInfoCanvasStyle(-60)}>
                        <ShapeEditButtonsComponent
                            handleCancelChangesClick={handleCancelChangesClick}
                            handleSaveChangesClick={handleSaveChangesClick}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
