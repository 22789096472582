
import type { AxiosError } from 'axios';
import axios from 'axios';
import { createMutation } from 'react-query-kit';

type Response = {
    message: 'Slot created successfully';
    data: null;
};




type AddAvailabilityPayload = { homeOwnerID?: number; schedulerID?: number; inspectorID?: number; claimID?: number; slotID?: number; fromDateTime: string; toDateTime: string; slotStatusID?: number; };
type Params = { claimNumber: string }

export const useAddAvailabilitySlots = createMutation<Response, { params: Params; payload: AddAvailabilityPayload[] }, AxiosError>({
    mutationFn: async variables =>
        axios({
            url: 'SchedulerAssignments/SaveMultipleInspSlotsForCalendar',
            method: 'POST',
            data: variables.payload,
            params: variables.params
        }).then(response => response.data),
});
