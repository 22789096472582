export const toCapitalize = (word: string) => {
    return word.substring(0, 1).toUpperCase() + word.substring(1);
};
export const uniqueFiles = (files: File[]) => {
    const uniqueFileNames = new Set();
    return files.filter(file => {
        if (!uniqueFileNames.has(file.name)) {
            uniqueFileNames.add(file.name);
            return true;
        }
        return false;
    });
};
export function truncateString(str: string) {
    if (str.length > 50) {
        return str.slice(0, 50 - 3) + '...';
    }
    return str;
}


export function genNumericArray(startval: number, endval: number): string[] {
    let array: string[] = [];
    for (let i = startval; i <= endval; i++) {
        array.push((i).toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false }));
    }
    return array;
}