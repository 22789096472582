import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import * as React from 'react';
import SingleDropdown from '../../../components/single-select-dropdown';
import TimeSlot, { ISlot } from './timeslot-component';
import ManualTimeSelect from './manual-time-select.component';
import { IDateSlots, ISaveTimeSlot } from '../../../models/scheduler/save-time-slot';
import { usePostData } from '../../../hooks/use-post-data.hook';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { IInspectorAvailability } from '../../../models/scheduler/inspector-availability';
import { formatDate, parseDate } from '@progress/kendo-intl';
import { isEmpty } from 'lodash';
import { IHomeOwnerAvailability } from '../../../models/scheduler/home-owner-availability';
import moment from 'moment';
import { parseAsDateFromString } from '../../../utils';
import { CLAIM_STATUS_CODE } from '../utils';
import { useLoadData } from '../../../hooks/use-scheduler-load-data';
import { number } from 'yup';
import dayjs from 'dayjs';
import ClockedIcon from '../../../images/clocked';
import ClockedIconRight from '../../../images/clock-right';


export interface IAddAvailabilityProps2 {
    data: ISaveTimeSlot;
    onSuccess?: () => void;
    unAvailableDaySlots?: ISlot[];
    unAvailableTimeSlots?: ISlot[];
    showCancelButton?: boolean;
    onCancel?: () => void;
    claimId?: number
    userType?: string
}

interface Props extends IAddAvailabilityProps2 {

    closeDialog: () => void;
}

const AddAvailability: React.FunctionComponent<Props> = (props) => {
    const {
        closeDialog,
        data,
        onSuccess = () => { },
        unAvailableDaySlots = [],
        unAvailableTimeSlots = [],
        userType
    } = props;
    const [err, seterr] = React.useState<string>('');
    const [timeValidation, setTimeValidation] = React.useState<string>('');
    const [isAvailable, setIsAvailable] = React.useState<number>(1);
    const [isManualTimeSlot, setIsManualTimeSlot] = React.useState<boolean>(false);
    const { response, submitData } = usePostData('SchedulerAssignments/SaveTimeSlotMultiple');

    const requestType = userType === "inspector" ? "InspectorResponseTime" : "HomeownerResponseTime";
    const { isLoading: IsLoadingSLA, isError: isErrorAging, reload: reloadSLA, result: resultSLA } = useLoadData<ISLACondition[]>(`SchedulerAssignments/GetSLA?key=${requestType}`);
    const { isLoading: IsLoadingSLATime, isError: isErrorTime, reload: reloadSLATime, result: resultSLATime } = useLoadData<ISLACondition[]>(`SchedulerAssignments/GetSLA?key=TimeSlotRange`);


    const { isSubmitting, error, result } = response;
    const [dayFrom, setDayFrom] = React.useState<number>(0);
    const [dayTo, setDayTo] = React.useState<number>(6);
    const timeIntervelRef = React.useRef<number>(1);
    const selectedSlots = React.useRef<ISlot[]>([]);
    const selectedTimeSlots = React.useRef<ISlot[]>([]);
    const noOfDaysPerslot = React.useRef<number>(6);
    const [currentPage, setCurrentPage] = React.useState<number>(1);



    React.useEffect(() => {
        data.timeSlot = '';
        // data.sDate = '';
        selectedTimeSlots.current = [];
        if (resultSLA) {
            if (resultSLA?.length > 0 && resultSLA[0] && (data?.inspectorId > 0 || data?.homeOwnerId > 0)) {
                console.log("SLA:", resultSLA)
            }
        }
        if (resultSLATime) {
            if (resultSLATime?.length > 0 && resultSLATime[0]) {
                if (resultSLATime[0].sValue != "") {
                    timeIntervelRef.current = parseInt(resultSLATime[0].sValue);
                }
            }
        }

    }, [isManualTimeSlot, resultSLA]);

    const ontoggle = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        setIsManualTimeSlot(!isManualTimeSlot);
        setTimeValidation("");
    };

    const onNextClick = (e: any) => {
        if (parseInt(resultSLA[0]?.sValue) > 0) {
            // Define the maximum limit for days, which is `dayTo`
            const maxDays = parseInt(resultSLA[0]?.sValue);

            // If this is the first call, start `dayFrom` at 0 and set `dayTo` to 6
            if (dayFrom === 0 && dayTo === maxDays) {
                setDayFrom(0);
                setDayTo(Math.min(6, maxDays)); // Ensure `dayTo` does not exceed `maxDays`
                return;
            }

            else {
                // Move to the next interval by incrementing `dayFrom` and `dayTo`
                let newDayFrom = dayFrom + 6;
                let newDayTo = Math.min(newDayFrom + 6, maxDays); // Ensure `newDayTo` does not exceed `maxDays`

                if (newDayTo <= maxDays && newDayFrom <= maxDays

                ) {
                    // Update the state with the new interval
                    setDayFrom(newDayFrom);
                    setDayTo(newDayTo);
                }
            }
        }
    };

    const onBeforeClick = (e: any) => {
        if (dayFrom > 0) {
            // Calculate the new `dayFrom` by going back 6 days, with a minimum of 0
            let newDayFrom = Math.max(dayFrom - 6, 0);

            // Calculate `dayTo` as 6 days after the new `dayFrom`, or the maximum allowed `dayTo`
            let newDayTo = Math.min(newDayFrom + 6, dayTo);

            // Update the state with the new interval
            setDayFrom(newDayFrom);
            setDayTo(newDayTo);


            // Decrement the current page if applicable
            setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
        }
    };


    const getUnAvailableDaySlots = (days: string[]) => {
        let slots: ISlot[] = [];
        let sData = days?.map((e) => formatDate(parseDate(e), 'EE,MM/dd/yyyy'));
        sData.forEach((e) => slots.push({ label: e, value: e }));
    };

    const getUnAvailableTimeSlots = (time: string[]) => {
        let slots: ISlot[] = [];

        time?.forEach((e) => slots.push({ label: e, value: e }));
    };
    React.useMemo(() => {
        if (result) {
            closeDialog();
        }
    }, [result]);

    return (
        <>
            <Dialog closeIcon onClose={closeDialog} className="poupnewDesign">
                <div>
                    <center>
                        <h5 style={{ fontWeight: 'bold' }}>Add Time Slot</h5>
                    </center>
                    <div className="gredientBg90 outerPadding-fix">
                        <center>
                            <div style={{ color: 'red', fontSize: "15px" }}>{err}</div>
                        </center>
                        <br></br>
                        <div
                            style={{ position: 'relative', top: '-15px' }}
                            className="d-flex justify-content-center"
                        >
                            <div className="text-dark" style={{ fontWeight: 'bold' }}>
                                Select Days
                            </div>
                            <div className="fixedButton">
                                <div
                                    className="arrow-back"
                                    onClick={onBeforeClick}
                                    style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                                ></div>
                                <div
                                    className="arrow-next"
                                    onClick={onNextClick}
                                    style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                                ></div>
                            </div>
                        </div>
                        <TimeSlot
                            unavailableSlots={unAvailableDaySlots}
                            previousSelectedSlots={selectedSlots.current}
                            formatType="days"
                            diffIn="day"
                            factor={1}
                            from={dayjs().add(dayFrom, 'day')}
                            manipulateType="day"
                            to={dayjs().add(dayTo, 'day')}
                            timeFormat="EE MM/dd/yyyy"
                            onTimeSelected={(slots) => {
                                let slotvalues = slots.map((e) => e.value);
                                data.sDate = slotvalues.join(',');
                                console.log(data.sDate);
                                selectedSlots.current = slots;
                                err !== '' ? seterr('') : null;
                            }}
                        ></TimeSlot>
                    </div>

                    <div className="d-flex justify-content-center curvedBg">
                        <div className="text-dark" style={{ fontWeight: 'bold' }}>
                            Select Time Slots
                        </div>
                        <div className="fixedButton">
                            {isManualTimeSlot && (
                                <button
                                    type="submit"
                                    className="border-0 toggleBtn-style"
                                    onClick={ontoggle}
                                >
                                    {/* <svg width="38.323" height="24" viewBox="0 0 38.323 24">
                                        <g
                                            id="Group_748"
                                            data-name="Group 748"
                                            transform="translate(15639.868 14282)"
                                        >
                                            <g
                                                id="Group_743"
                                                data-name="Group 743"
                                                transform="translate(-16030.938 -14674)"
                                            >
                                                <g
                                                    id="MDI_clock-time-three-outline"
                                                    data-name="MDI / clock-time-three-outline"
                                                    transform="translate(405.393 392)"
                                                    opacity="0.6"
                                                >
                                                    <g
                                                        id="Boundary"
                                                        stroke="rgba(0,0,0,0)"
                                                        stroke-width="1"
                                                        opacity="0"
                                                    >
                                                        <rect
                                                            width="24"
                                                            height="24"
                                                            stroke="none"
                                                        />
                                                        <rect
                                                            x="0.5"
                                                            y="0.5"
                                                            width="23"
                                                            height="23"
                                                            fill="none"
                                                        />
                                                    </g>
                                                    <path
                                                        id="Path_clock-time-three-outline"
                                                        data-name="Path / clock-time-three-outline"
                                                        d="M12,20a8,8,0,1,0-8-8,8.024,8.024,0,0,0,8,8M12,2A10,10,0,1,1,2,12,10.029,10.029,0,0,1,12,2m5,9.5V13H11V7h1.5v4.5Z"
                                                    />
                                                </g>
                                                <path
                                                    id="Path_335"
                                                    data-name="Path 335"
                                                    d="M-15549-14507.333l7.517,7.517-7.517,7.517"
                                                    transform="translate(-15149 -14095.8) rotate(180)"
                                                    fill="none"
                                                    stroke="#707070"
                                                    stroke-width="2"
                                                />
                                            </g>
                                        </g>
                                    </svg> */}
                                    <ClockedIcon></ClockedIcon>
                                </button>
                            )}
                            {!isManualTimeSlot && (
                                <button
                                    type="submit"
                                    className="border-0 toggleBtn-style"
                                    onClick={ontoggle}
                                >
                                    {/* <svg width="39.021" height="24" viewBox="0 0 39.021 24">
                                        <g
                                            id="Group_743"
                                            data-name="Group 743"
                                            transform="translate(-392.393 -392)"
                                        >
                                            <g
                                                id="MDI_clock-time-three-outline"
                                                data-name="MDI / clock-time-three-outline"
                                                transform="translate(392.393 392)"
                                                opacity="0.6"
                                            >
                                                <g
                                                    id="Boundary"
                                                    stroke="rgba(0,0,0,0)"
                                                    stroke-width="1"
                                                    opacity="0"
                                                >
                                                    <rect width="24" height="24" stroke="none" />
                                                    <rect
                                                        x="0.5"
                                                        y="0.5"
                                                        width="23"
                                                        height="23"
                                                        fill="none"
                                                    />
                                                </g>
                                                <path
                                                    id="Path_clock-time-three-outline"
                                                    data-name="Path / clock-time-three-outline"
                                                    d="M12,20a8,8,0,1,0-8-8,8.024,8.024,0,0,0,8,8M12,2A10,10,0,1,1,2,12,10.029,10.029,0,0,1,12,2m5,9.5V13H11V7h1.5v4.5Z"
                                                />
                                            </g>
                                            <path
                                                id="Path_335"
                                                data-name="Path 335"
                                                d="M-15549-14507.333l7.517,7.517-7.517,7.517"
                                                transform="translate(15971.483 14903.833)"
                                                fill="none"
                                                stroke="#707070"
                                                stroke-width="2"
                                            />
                                        </g>
                                    </svg> */}
                                    <ClockedIconRight></ClockedIconRight>
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="outerPadding-time">
                        {!isManualTimeSlot ? (
                            <TimeSlot
                                unavailableSlots={unAvailableTimeSlots}
                                previousSelectedSlots={selectedTimeSlots.current}
                                formatType="time"
                                diffIn="hours"
                                factor={1}
                                from={dayjs().utc().startOf('date').add(10, 'hours')}
                                manipulateType="hours"
                                to={dayjs().utc().startOf('date').add(19, 'hours')}
                                timeFormat="h a"
                                onTimeSelected={(slots) => {
                                    let slotsvalues = slots.map((e) => e.value);
                                    selectedTimeSlots.current = slots;
                                    data.timeSlot = slotsvalues.join(',');

                                    // Update the data.sDate
                                    //let slotValues = slots.map(e => e.value);
                                    //data.sDate = slotValues.join(',');
                                    //console.log(data.sDate);
                                }}
                            ></TimeSlot>
                        ) : (
                            <>
                                {!isEmpty(timeValidation) ?
                                    (<div><center>
                                        <div style={{ color: 'red', fontSize: "15px" }}>{timeValidation}</div>
                                    </center>
                                        <br></br></div>) : (null)
                                }
                                <ManualTimeSelect
                                    onchange={(timeRange) => {
                                        data.timeSlot = `${timeRange.fromTime}-${timeRange.toTime}`;
                                        //data.timeSlot = `${timeRange.fromTime}, ${timeRange.toTime}`;
                                    }}
                                />
                            </>
                        )}
                    </div>
                </div>

                <div style={{ padding: '10px 0px' }}>

                    <SingleDropdown

                        dataitems={[
                            { text: 'Available', value: '1' },
                            { text: 'Unavailable', value: '0' },
                        ]}

                        value={isAvailable?.toString()}

                        showDefaultItem={false}
                        placeholder='Select availability'
                        onchange={(value: string) => {
                            data.isAvailable = value === '1';
                            data.slotStatus = parseInt(value) === 1 ? CLAIM_STATUS_CODE.AVAILABLE : CLAIM_STATUS_CODE.UNAVAILABLE;
                            setIsAvailable(parseInt(value));
                            console.log(data)
                        }}
                        id={'isAvailable'}
                    />
                </div>

                <DialogActionsBar>
                    <div className="row">
                        <div className="d-flex justify-content-between">
                            <button
                                disabled={isSubmitting}
                                className="btn btn-secondary m-3"
                                onClick={closeDialog}
                            >
                                Cancel
                            </button>
                            <button
                                disabled={isSubmitting}
                                className="btn btn-primary m-3"
                                onClick={async () => {
                                    if (isManualTimeSlot && data.timeSlot === '') {
                                        data.timeSlot = 'T08:00:00.000Z-T09:00:00.000Z';
                                    }
                                    if (data.timeSlot === '' || data.sDate === '') {
                                        seterr('Please select a date to continue');
                                        // closeDialog();
                                    } else {
                                        seterr('');
                                        setTimeValidation("");

                                        let TimeSlots: IDateSlots[] = [];

                                        data.sDate.split(',').forEach((e) => {

                                            data.timeSlot.split(',').forEach((f) => {
                                                TimeSlots.push({
                                                    FromDateTime: parseAsDateFromString(e, f.split('-')[0]),
                                                    ToDateTime: parseAsDateFromString(e, f.split('-')[1]),
                                                });

                                            });
                                        });
                                        if (isManualTimeSlot) {
                                            if (data.timeSlot === '' || data.sDate === '') {
                                                seterr('Please select a date to continue');
                                                setTimeValidation("");
                                            } else {
                                                const FromDt = dayjs(TimeSlots[0].FromDateTime).format('YYYY-MM-DDTHH:mm');
                                                const ToDt = dayjs(TimeSlots[0].ToDateTime).format('YYYY-MM-DDTHH:mm');
                                                const difHr = dayjs(ToDt).diff(FromDt, "hours");
                                                if (difHr < 0) {
                                                    setTimeValidation("End time should be greater than Start time")
                                                } else {
                                                    if (difHr !== timeIntervelRef.current) {
                                                        setTimeValidation(`Time interval between two times should be ${timeIntervelRef.current} hour`)
                                                    } else {
                                                        data.slotStatus = data.isAvailable ? CLAIM_STATUS_CODE.AVAILABLE : CLAIM_STATUS_CODE.UNAVAILABLE;
                                                        data.dateSlots = TimeSlots;
                                                        await submitData({ data: data });
                                                        if (response) {
                                                            closeDialog();
                                                            onSuccess();
                                                        }
                                                    }
                                                }
                                            }
                                        } else {

                                            data.slotStatus = data.isAvailable ? CLAIM_STATUS_CODE.AVAILABLE : CLAIM_STATUS_CODE.UNAVAILABLE;
                                            data.dateSlots = TimeSlots;
                                            await submitData({ data: data });
                                            if (response) {
                                                closeDialog();
                                                onSuccess();
                                            }
                                        }
                                    }
                                }}
                            >
                                Update
                            </button>
                        </div>
                    </div>
                </DialogActionsBar>
            </Dialog >
        </>
    );
};

export default AddAvailability;