
import * as React from 'react';
import { useLoadData } from '../../hooks/use-scheduler-load-data';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { useLocation, useNavigate } from 'react-router-dom';
import EditIcon from '../../images/edit';
import CloseIcon from '../../images/close-icon';
import { boolean } from 'yup';
import { useEffect, useRef, useState } from 'react';
import AddAvailability from './components/add-inspector-availability';
import { IHomeOwnerAvailability } from "../../models/scheduler/home-owner-availability"
import { ISaveTimeSlot } from "../../models/scheduler/save-time-slot"
import { isEmpty } from 'lodash';
import { formatDate, parseDate } from '@progress/kendo-intl';
import TimeSlot, { ISlot } from './components/timeslot-component';
import { DeleteModelDialog } from '../../components/delete-model-dialog.component';
import { usePostData } from '../../hooks/use-post-data.hook';
import axios from 'axios';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import dayjs from 'dayjs';
import { convertToTimezoneWithDateString } from '../../utils';
import { stat } from 'fs';
import { CLAIM_STATUS_CODE } from './utils';
import { ScheduledSlot } from '../../types/ScheduledSlot';
import { GroupedSlot } from '../../types/GroupedSlot';
import { AvailabilityList } from './components/availability-list';

interface IHomeOwnerAvailabilityProps {
}

const HomeOwnerAvailability: React.FunctionComponent<IHomeOwnerAvailabilityProps> = (props) => {
    const nav = useNavigate();
    const loc = useLocation();
    const { state } = loc;
    const unAvailableDaySlots = useRef<ISlot[]>([])
    const unAvailableTimeSlots = useRef<ISlot[]>([])
    const { schUsers, Claim } = state;
    const { userGuid, userId, userName } = schUsers;
    const { claimNo, claimID, homeOwner_Name,
        homeowner_Email,
        homeOwner_Phone,
        homeOwner_Address } = Claim;

    const [del, setDel] = useState<{ isDel: boolean, deleteData?: ScheduledSlot }>({ deleteData: undefined, isDel: false })
    const initialSlotData = useRef<ISaveTimeSlot>({
        claimNo: claimNo, isAvailable: true, schID: 0, sDate: "", timeSlot: "", userGUID: userGuid, claimId: claimID, userId: userId, homeOwnerId: userId, statusCode: "", dateSlots: []
    });


    const { isError, isLoading, reload, result } = useLoadData<ScheduledSlot[]>(`SchedulerAssignments/GetHomeOwnerAvailability?HomeownerID=${userId}&claimID=${claimID}`)
    const [addNew, setAddNew] = useState<boolean>(false);





    const groupedSlots = React.useMemo(() => {

        if (result) {
            const groupedSlots: GroupedSlot[] = Object.values(
                result?.reduce<Record<string, GroupedSlot>>((acc, slot) => {
                    const date = convertToTimezoneWithDateString(slot.dateFrom).format('MM/DD/YYYY'); // Format date as DD/MM/YYYY
                    if (!acc[date]) {
                        acc[date] = { date, slots: [] };
                    }
                    acc[date].slots.push(slot);
                    return acc;
                }, {})
            ).map(group => {
                group?.slots.sort((a, b) => convertToTimezoneWithDateString(a.dateFrom).valueOf() - convertToTimezoneWithDateString(b.dateFrom).valueOf());// Sort slots by dateFrom
                return group;
            });
            return groupedSlots
        }
        return []

    }, [result])

    const handleDelete = (data: ScheduledSlot) => {
        setDel({ isDel: true, deleteData: data })
    }


    return (<>
        {isLoading && (<LoadingIndicator isLoading />)}
        {isError && (<div>
            Error Loading Content...
        </div>)}
        {claimNo && !isError && (<div >

            <div className='p-4'>
                <div className="grid-userdetails">
                    <div>
                        <div className='indication'>{!isEmpty(homeOwner_Name) && (homeOwner_Name?.toUpperCase())}</div>
                        <div>{homeOwner_Address}</div>
                        <div>{homeOwner_Phone}</div>
                    </div>
                    <div>
                        {!isEmpty(claimNo) ? (
                            <><div className='indication'>{claimNo}</div>
                                <div>Claim#</div></>) : (null)}
                    </div>
                </div>
            </div>
            <div className='m-3 p-2'>
                <div className="p-3 card border-0 mb-1 mb-lg-4 mt-1 sw-shadow radius-15" >
                    <div>
                        <h5>
                            INSTRUCTIONS
                        </h5>
                        <div>

                            <p>
                                Please select the dates and time slots that work best for your
                                inspection by clicking on the 'Add new time slot' button
                                and choosing your preferred time slots. You can select multiple dates
                                and times if you have flexibility. Thank you!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card p-4 p-4  p-lg-5 pt-lg-3 border-0 radius-page-15 sw-shadow ' style={{ minHeight: 'Calc(100vh - 170px)' }}>

                <div className="d-flex">
                    <button style={{ display: "none" }} className="backbtn" onClick={() => { nav('/homeowner/homeowner-landing', { state: schUsers }) }}>
                        Back
                    </button>
                    <button onClick={() => {
                        let days = result.map(e => e.dateFrom);
                        let dayslots: ISlot[] = days.map(e => { return { label: formatDate(parseDate(e), "EE,MM/dd/yyyy"), value: formatDate(parseDate(e), "MM/dd/yyyy") } })
                        unAvailableDaySlots.current = dayslots;
                        let time: ISlot[] = result.map(e => { return { label: e.timeSlot, value: e.timeSlot } })
                        unAvailableTimeSlots.current = time;

                        setAddNew(true);
                    }} className=" d-flex justify-content-end btn-primary btn-sm ms-auto mb-3">
                        Add New Time Slot
                    </button></div>
                <div>
                    <AvailabilityList groupedSlots={groupedSlots} handleDelete={handleDelete} />
                </div>
            </div>
        </div >
        )}
        {
            addNew && (<AddAvailability userType={"homeowner"} data={initialSlotData.current} closeDialog={() => {
                return setAddNew(false);
            }} onSuccess={() => {
                reload();
            }}
                unAvailableDaySlots={unAvailableDaySlots.current}
                unAvailableTimeSlots={unAvailableTimeSlots.current}

                claimId={claimID}
            />)
        }


        {
            del.isDel && (<Dialog title='Delete Slot' width={"30%"} onClose={() => { setDel({ isDel: false, deleteData: undefined }) }} key={1}>
                <center>
                    <h5>Are you sure you want to delete ?</h5>
                </center>
                <DialogActionsBar>
                    <div className='row'>
                        <div className='d-flex justify-content-between' >
                            <button className='btn btn-sm btn-secondary' onClick={() => {
                                setDel({ isDel: false, deleteData: undefined })
                            }}>No</button>
                            <button className='btn btn-primary btn-sm' onClick={async () => {
                                const response = await axios.post(`SchedulerAssignments/DeleteHomeOwnerSlotsForCalendar?homeownerId=${userId}&slotId=${del.deleteData.slotId}&userId=${userId}&userName=${userName}&claimNumber=${claimNo}`);
                                if (response.data.result === -1) {
                                    setDel({ isDel: false, deleteData: undefined });
                                    reload();
                                }
                            }}>Yes</button>

                        </div>
                    </div>
                    {/* <button className='btn btn-secondary btn-sm ms-auto me-1' onClick={async () => {
                        setDel({ isDel: false, deleteData: undefined })


                    }}

                    >
                        Cancel
                    </button>
                    <button className='btn btn-primary btn-sm me-1' onClick={async () => {
                        const response = await axios.post(`SchedulerAssignments/DeleteInspSlotsForCalendar?inspectorId=${userId}&slotId=${del.deleteData.slotId}&userId=${userId}&userName=${userName}&claimNumber=${del.deleteData.claimNo}`);
                        if (response.data.result === -1) {
                            setDel({ isDel: false, deleteData: undefined });
                            reload();
                        }

                    }}>OK</button> */}
                </DialogActionsBar>




            </Dialog>)
        }


    </>);
};

export default HomeOwnerAvailability;
