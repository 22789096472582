import { ReactNode } from 'react';
import { Mode, Shape } from '../../../models/building-image/image-draw.types';
import { ColorPickerComponent } from './color-picker.component';
import { ColorInfo } from './image-draw.config';

export interface DrawingToolsComponentProps {
    disableDraw: boolean;
    currentMode: Mode;
    toolButtonClick: any;
    handleCloseColorPicker: any;
    editorColor: ColorInfo;
    customButtons: ReactNode;
    toggleFullScreen: any;
}

export const DrawingToolsComponent = ({
    disableDraw,
    currentMode,
    toolButtonClick,
    handleCloseColorPicker,
    editorColor,
    customButtons,
    toggleFullScreen,
}: DrawingToolsComponentProps) => {
    const zoomIn = () => {
        toolButtonClick(Mode.ZoomIn);
    };
    const zoomOut = () => {
        toolButtonClick(Mode.ZoomOut);
    };

    const select = () => {
        toolButtonClick(Mode.Select);
    };

    const draw = () => {
        toolButtonClick(Mode.Draw);
    };

    const togglePan = () => {
        toolButtonClick(Mode.Pan);
    };

    return (
        <div className="toolImg">
            {disableDraw && (
                <button
                    data-cypress-id="reset-btn"
                    className={currentMode === Mode.Select ? 'active icon-select' : 'icon-select'}
                    onClick={select}
                ></button>
            )}
            {!disableDraw && (
                <button
                    data-cypress-id="zoom-out-btn"
                    className={currentMode === Mode.Draw ? 'active icon-draw' : 'icon-draw'}
                    onClick={draw}
                ></button>
            )}
            <button
                data-cypress-id="reset-btn"
                className={currentMode === Mode.Pan ? 'active icon-pan' : 'icon-pan'}
                onClick={togglePan}
            ></button>
            <button
                data-cypress-id="zoom-in-btn"
                className={currentMode === Mode.ZoomIn ? 'active icon-zoom' : 'icon-zoom'}
                onClick={zoomIn}
            ></button>
            <button
                data-cypress-id="zoom-out-btn"
                className={currentMode === Mode.ZoomOut ? 'active icon-zoom-out' : 'icon-zoom-out'}
                onClick={zoomOut}
            ></button>
            <ColorPickerComponent currentColorInfo={editorColor} onClose={handleCloseColorPicker} />
            <button onClick={toggleFullScreen} className="expand-btn"></button>
            <div>{customButtons}</div>
        </div>
    );
};
