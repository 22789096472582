import PropTypes from "prop-types";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export const queryClient = new QueryClient();


export default function APIProvider({ children }: { children: React.ReactNode }) {
    return (
        <QueryClientProvider client={queryClient} >
            {children}
            <ReactQueryDevtools />
        </QueryClientProvider>
    );
}

APIProvider.propTypes = {
    children: PropTypes.element.isRequired,
};
