import { Link } from 'react-router-dom';
import Logo from '../images/logo-keystone';
import { useCurrentUser } from '../hooks/user-current-user.hook';
import './header.scss';
import { LogoutComponent } from '../features/login/logout.component';
import { SchedulerMenuComponent } from './scheduler.menu.component ';

export const SchedulerHeaderComponent = ({ showMenu = true }: { showMenu?: boolean }) => {
    const { fullName } = useCurrentUser();
    return (
        <div className="header">
            <nav className="navbar navbar-expand-sm navbar-toggleable-sm  bg-white border-bottom box-shadow">
                <div className="container-fluid d-flex">
                    <Link to="/" className="navbar-brand">
                        <Logo></Logo> {/* <img src={Logo} alt='logo' className="logo" /> */}
                    </Link>
                    {showMenu && <div className='ms-auto d-flex'>
                        <SchedulerMenuComponent />
                        <div className="navbar-collapse collapse me-auto">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item user-name text-nowrap">{fullName}</li>
                                <li className="nav-item pt-1">
                                    <LogoutComponent />
                                </li>
                            </ul>
                        </div>
                    </div>}
                </div>



            </nav>
        </div>
    );
};
