export const ImageConfig = {
    LINE_THICKNESS: 2,
};

export interface ColorInfo {
    ghostColor: string;
    defaultColor: string;
    selectionColor: string;
    highlightColor: string;
}
