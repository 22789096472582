import type { AxiosError } from 'axios';
import axios from 'axios';
import { createMutation } from 'react-query-kit';

type Response = {
    message: 'Slot deleted successfully';
    data: null;
};



type Params = { homeownerId: number; slotId: number; userId: number; userName: string; claimNumber: string; inspectorId: number };

export const useScheduleDeleteHomeownerSlot = createMutation<Response, Params, AxiosError>({
    mutationFn: async params =>
        axios({
            url: 'SchedulerAssignments/DeleteHomeOwnerSlotsForCalendar',
            method: 'POST',
            params

        }).then(response => response.data),
});
