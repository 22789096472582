import { useRef, useState } from 'react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import './login.style.scss';
import { SendOtpPayload, SendOtpResponse } from '../../api/inspector';
import { InspectorVerifyOtp } from './inspector-verify-otp';



interface Props {
    onSendOtp: (payload: SendOtpPayload, onSuccess: (response: SendOtpResponse) => void, onError: () => void) => void;
    isPendingSendOtp: boolean;
    sendOtpPayload: SendOtpPayload;
    authType: 'login' | 'verifyOtp';
    setAuthType: (authType: 'login' | 'verifyOtp') => void
}

export const EmailLogin = ({ onSendOtp, isPendingSendOtp, sendOtpPayload, authType, setAuthType }: Props) => {

    const [error, setError] = useState('');

    const userGuidRef = useRef<string>()

    const handleSendOtp = (values: { email: string, userType: string }) => {
        onSendOtp({ ...values, userType: 'Inspector' }, (response) => {
            if (response.success) {
                userGuidRef.current = response?.result
                setAuthType('verifyOtp')
            } else {
                setError(response.errorMessage || 'Failed to send One Time Code');
            }
        }, () => setError('Failed to send One Time Code')
        );
    }

    const initialLoginData = {
        email: '',
        userType: ''
    };

    const loginSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
    });

    return (
        authType === 'verifyOtp' ?
            <InspectorVerifyOtp channel='email' sendOtpPayload={sendOtpPayload} userGuid={userGuidRef.current} resendOtp={onSendOtp} isPendingResendOtp={isPendingSendOtp} /> :
            <>
                {error && <div className="alert alert-danger">{error}</div>}
                <LoadingIndicator isLoading={isPendingSendOtp} />
                <Formik
                    initialValues={initialLoginData}
                    validationSchema={loginSchema}
                    onSubmit={handleSendOtp}
                >
                    {() => (
                        <Form className="form-content">

                            <div style={{ marginTop: '25px', marginBottom: '35px' }}>
                                <label htmlFor="email">Email</label>
                                <Field
                                    className="form-control form-control-md"
                                    name="email"
                                    placeholder="Enter email address"
                                    type="email"
                                />
                                <div className="mt-4">
                                    <small className="errorMsg">
                                        <ErrorMessage name="email" />
                                    </small>
                                </div>
                            </div>


                            <button
                                id="btnSignIn"
                                className="btn btn-normal btn-block w-100 mt-2 text-uppercase"
                                type="submit"
                                disabled={isPendingSendOtp}
                            >
                                Send one time code
                            </button>
                        </Form>
                    )}
                </Formik >
            </>
    );
};

export default EmailLogin;
