import { ErrorMessage, Field } from 'formik';

interface InputFieldProps {
    label?: string;
    name: string;
    type?: string;
    disabled?: boolean;
}

export const InputField = ({ name, type, label, disabled }: InputFieldProps): any => {
    return (
        <div>
            {label && <label className="label">{label}</label>}
            <Field disabled={disabled} type={type ? type : 'input'} className="form-control  mb-3" name={name} />
            <small className="errorMsg">
                <ErrorMessage name={name} />
            </small>
        </div>
    );
};
