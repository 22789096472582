import { useEffect } from 'react';
import * as Yup from 'yup';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { usePostData } from '../../hooks/use-post-data.hook';
import { LoginReponse } from '../../models/login-response';
import Logo from '../../images/logo-keystone';
import './login.style.scss';
import { useStorage } from '../../hooks/use-storage';
import { StorageKey } from '../../constants';
import { Form, Formik } from 'formik';
import { InputField } from '../../components/form/input.feild.component';
import { setAxiosAuthHeaders } from '../../api-client.ts/axios';

interface LoginWithKeyPageProps {
    loginKey: string;
}

export const LoginWithKeyPage = ({ loginKey }: LoginWithKeyPageProps) => {
    const [currentAuthState, setAuthState] = useStorage(StorageKey.AuthState, null);
    console.log(currentAuthState);
    const {
        submitData,
        response: { isSubmitting, result, error },
    } = usePostData<LoginReponse>(`login/key/${loginKey}`, 'POST');

    useEffect(() => {
        if (!isSubmitting && result && result.isSuccess) {
            setAuthState(result.token);
            setAxiosAuthHeaders()

        }
    }, [result, isSubmitting]);

    const handleSubmit = async (values: any) => {
        submitData({
            data: values,
        });
    };

    const initialLoginData = {
        email: '',
        name: '',
        company: '',
    };
    const signupSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
        name: Yup.string().required('Name is required'),
        company: Yup.string().required('Company is required'),
    });

    return (
        <div className="mt100">
            <h3 className="mt-5 mb-5 card-title text-center">
                <Logo></Logo> {/* <img src={Logo} className="logo" /> */}
            </h3>
            <div className="col-md-5 mx-auto">
                <div className="login-container global-container loginWrapper">
                    <div className="card login-form p-4">
                        {error && (
                            <div>
                                Failed to login with shared key. Please contact you administrator
                            </div>
                        )}
                        <LoadingIndicator isLoading={isSubmitting} />
                        <Formik
                            initialValues={initialLoginData}
                            validationSchema={signupSchema}
                            onSubmit={handleSubmit}
                        >
                            {() => (
                                <Form className="p-2">
                                    <InputField label="Email Address" name="email" />
                                    <InputField label="Name" name="name" />
                                    <InputField label="Company" name="company" />
                                    <button
                                        id="btnSignIn"
                                        className="btn btn-normal btn-sm btn-block"
                                        type="submit"
                                    >
                                        GO
                                    </button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};
