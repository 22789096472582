import { useEffect, useRef, useState } from 'react';
import { PanelBar, PanelBarItem, PanelBarItemProps } from '@progress/kendo-react-layout';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { useLoadData } from '../../../hooks/use-load-data.hook';
import { Room } from '../../../models/claim/room';
import { UnitWindow } from '../../../models/claim/unit-window';
import EyeIcon from '../../../images/eye';
import { ObservationListComponent } from '../observation/observation-list.component';
import { EditObservation } from '../observation/edit-observation.component';
import { ObservationType } from '../../../models/masters/observation-type';
import { ClaimObservation } from '../../../models/claim/claim-observation';
import { SubmitStatus } from '../../../components/formik-model-dialog.component';
import { EditWindow } from './edit-window.component';
import { Building } from '../../../models/claim/building';
import { EntityType } from '../../../models/claim/entity-type';
import { addOrUpdateArrayItem, deleteArrayItem } from '../../../utils/array.util';
import {
    DeleteModelDialog,
    DeletePostDataInfo,
    DeleteStatus,
} from '../../../components/delete-model-dialog.component';
import { ClaimObservationOption } from '../../../models/claim/claim-observation-option';
import { ImageListModelDialog } from '../../image/image-list-model-dialog.component';
import DeleteGreyIcon from '../../../images/delete-gray';
import { GuardedComponent } from '../../../components/guarded.componemt';
import { PermissionName } from '../../../models/user-security/permission.name';
import { LinkImageModelDialog } from '../../building-image/link-image-model-dialog.component';
import ImageLink from '../../../images/image-link';
import FileImage from '../../../images/file-image';
import FileVideoIcon from '../../../images/file-video';
import EditGrayIcon from '../../../images/edit-gray';
import ImageUploader from '../../../components/add-images.component';
import { UploadedFile } from '../../../redux/reducers/filereducer';
import {
    ImgFile,
    deleteFile,
    editComments,
    fetchFile,
    uploadImages,
} from '../../../utils/uploadfile.util';
import { fetchFiles } from '../../../api-client.ts/fileapi';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { Image } from 'react-grid-gallery';
import EditIcon from '../../../images/edit';
import DeleteIcon from '../../../images/delete';

interface WindowsComponentProps {
    building: Building;
    room: Room;
}

export const WindowsComponent = ({ building, room }: WindowsComponentProps) => {
    const { isLoading, result, isError, reload } = useLoadData<Room>(`rooms?roomid=${room?.roomID}`);
    const [imgpreview, showImgPreview] = useState<{
        imgpreview: boolean;
        src: File;
        comment?: string;
    }>({
        imgpreview: false,
        src: null,
        comment: '',
    });
    const [imageListData, setImageListData] = useState<{
        entityType: EntityType;
        entityId: number;
    }>();
    const [linkImageData, setLinkImageData] = useState<{
        entityType: EntityType;
        entityId: number;
        entityName: string;
        direction: string;
    }>();
    const [data, setEditWindow] = useState<{
        building: Building;
        window: UnitWindow;
        copyFrom: boolean;
    }>();
    const [newObservation, setNewObservation] = useState<{
        newObservation: ClaimObservation;
        copyFrom?: boolean;
        updateOption: ClaimObservationOption;
    }>();
    const [photos, setPhoto] = useState<Image[]>();
    const [roomData, setRoomData] = useState<Room>();
    const [deleteWinowData, setDeleteWindowData] = useState<DeletePostDataInfo>();
    const [deleteObservation, setDeleteObservation] = useState<DeletePostDataInfo>();
    // const [isobsView, setisObsView] = useState<boolean>(false);

    const [imgList, setImgList] = useState<ImgFile[]>([]);
    useEffect(() => {
        if (result) {
            const roomData = { ...result };
            setRoomData(roomData);
        }
    }, [result]);
    const dispatch = useDispatch<AppDispatch>();

    const openGallery = (data: { entityType: EntityType; entityId: number }) => {
        setImageListData(data);
    };
    const uploadedfileList = useRef<UploadedFile[]>([]);
    const observationtype = useRef<{ entityType: ObservationType; entityID: number }>({
        entityType: ObservationType.Window,
        entityID: 0,
    });
    const handleAddFileToImgList = async (file: File) => {
        await uploadImages([file], {
            claimGuID: building.claimGuid,
            EntityID: observationtype.current.entityID.toString(),
            EntityType: observationtype.current.entityType,
            IsInterior: false,
            Comments: imgpreview.comment.toString(),
        }).then((value: UploadedFile) => {
            loadImages();
            imgList.push({
                comment: imgpreview.comment!,
                file: file,
                isbaseImg: value.isBase,
                baseID: 0,
                entityID: value.entityID,
                entityType: value.entityType,
                fileID: value.fileID.toString(),
                userGuid: '',
            });
            //  alert('upload success');

            showImgPreview({
                imgpreview: false,
                src: null,
            });
        });
    };

    useEffect(() => {
        loadImages();
    }, []);

    const loadImages = async () => {
        try {
            //setPhoto([]);
            const action = await dispatch(
                fetchFiles({
                    FileType: 'image',
                    UserID: 0,
                    EntityType: observationtype.current.entityType,
                    EntityID: observationtype.current.entityID,
                }),
            );

            if (action.payload) {
                let uploadedFileArray = action.payload as UploadedFile[];

                uploadedfileList.current = uploadedFileArray;

                let files = await Promise.all(
                    uploadedFileArray.map(async (e) => {
                        return fetchFile(
                            e.fileCDN,
                            e.comments,
                            e.entityID.toString(),
                            observationtype.current.entityType,
                            e.isBasePhoto,
                            e.fileID.toString(),
                            e.basePhotoID
                        );
                    }),
                );

                setImgList(files);
            } else {
                setImgList([]);
            }
        } catch (error) {
            console.error('Error fetching files:', error);
            setImgList([]);
        }
    };

    const linkWindowImage = (data: {
        entityType: EntityType;
        entityId: number;
        entityName: string;
        direction: string;
    }) => {
        setLinkImageData(data);
    };

    const closGallery = () => {
        setImageListData(null);
    };

    const onAddWindowClick = (copyFrom: boolean) => {
        const selectedWindow: UnitWindow = {
            roomID: room.roomID,
            windowID: 0,
            windowName: '',
            direction: '',
            notes: '',
            images: 0,
            attributes: [],
            observations: [],
        };
        setEditWindow({ building: building, window: selectedWindow, copyFrom });
    };

    const onEditWindowClick = (selectedWindow: UnitWindow) => {
        setEditWindow({ building: building, window: selectedWindow, copyFrom: false });
    };
    const onDeleteWindow = (selectedWindow: UnitWindow) => {
        setDeleteWindowData({
            data: selectedWindow,
            route: `windows/delete/${selectedWindow.windowID}`,
        });
    };
    const onDeleteObservation = (claimObservation: ClaimObservation) => {
        setDeleteObservation({
            data: claimObservation,
            route: `ClaimObservation/${claimObservation.observationID}`,
        });
    };

    const onAddNewObservationClick = (window: UnitWindow, copyFrom: boolean) => {
        const observation = new ClaimObservation();
        observation.observationID = 0;
        observation.entityType = ObservationType.Window;
        observation.entityID = window.windowID;
        setNewObservation({
            newObservation: observation,
            copyFrom: copyFrom,
            updateOption: null,
        });
    };

    const onEditObservationClick = (
        observation: ClaimObservation,
        updateOption: ClaimObservationOption,
    ) => {
        setNewObservation({
            newObservation: observation,
            updateOption: updateOption,
        });
    };

    const handleDeleteWindowComplete = (status: DeleteStatus, data: UnitWindow) => {
        if (status == DeleteStatus.Completed) {
            roomData.windows = deleteArrayItem(roomData.windows, data, 'windowID');
            setRoomData({ ...roomData });
            setImgList([]);
            setPhoto([]);
        }
        setDeleteWindowData(null);
    };

    const handleDeleteObservationComplete = (status: DeleteStatus, data: ClaimObservation) => {
        if (status == DeleteStatus.Completed) {
            const window = roomData.windows.find((w) => w.windowID === data.entityID);
            window.observations = deleteArrayItem(window.observations, data, 'observationID');
            setRoomData({ ...roomData });
        }
        setDeleteObservation(null);
        setPhoto([]);
    };

    /* function to show picture of selected observation*/
    const onShowObservationPhotos = (observation: ClaimObservation) => {
        setPhoto([
            ...observation.photos.map(
                (image, index): Image => ({
                    src: image.fileCDN,
                    height: 200,
                    width: 200,
                    key: image.observationID,
                    orientation: 1,
                    caption: image.comments,
                    /*   customOverlay: (
                        <div className="bg-dark text-light">
                            <div>{image.comments}</div>
                        </div>
                    ), */
                }),
            ),
        ]);
    };

    const handleSaveWindowComplete = (status: SubmitStatus, data: UnitWindow) => {
        if (status === SubmitStatus.Completed) {
            // const window = roomData.windows.find((w) => w.windowID === data.windowID);
            // roomData.windows = addOrUpdateArrayItem(
            //     roomData.windows,
            //     { ...window, ...data },
            //     'windowID',
            // );
            reload();
            setRoomData({ ...roomData });
        }
        setEditWindow(null);
        setPhoto([]);
        setImgList([]);
        loadImages();
    };
    const handleLinkWinodwComplete = () => {
        setLinkImageData(null);
    };
    const handleSaveObservationComplete = (status: SubmitStatus, data: ClaimObservation) => {
        if (status === SubmitStatus.Completed) {
            const currentWindow = roomData.windows.find((p) => {
                return p.windowID === data.entityID;
            });
            currentWindow.observations = addOrUpdateArrayItem(
                currentWindow.observations,
                data,
                'observationID',
            );
            setRoomData({ ...roomData });
        }
        setNewObservation(null);

        setPhoto([]);

    };

    const windowData = () => {
        if (roomData) {
            const panelItems: PanelBarItemProps[] = roomData.windows.map<PanelBarItemProps>(
                (item): PanelBarItemProps => {
                    return {
                        id: item.windowID.toString(),
                        title: (
                            <div
                                className="structureList-grid"
                                style={{
                                    padding: '10px',
                                    borderRadius: '10px',
                                }}
                                key={`window_${item.windowID}`}
                            >
                                <div>
                                    <h5 className="text-normal">{item.windowName}</h5>
                                </div>
                                <div>
                                    <h6 className="text-normal">{item.direction}</h6>
                                </div>
                                <div className="options-list">
                                    {item.attributes?.map<any>((attribute) => (
                                        <div>{attribute?.attributeValue?.toString()}</div>
                                    ))}
                                </div>
                                <div className="d-none">
                                    <FileVideoIcon></FileVideoIcon> {/* <img src={videoimg} /> */}
                                    <span className="tagtop">0</span>
                                </div>
                                <GuardedComponent permission={PermissionName.EditBuilding}>
                                    <div>
                                        <button
                                            className="tag-buttons"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                linkWindowImage({
                                                    entityType: EntityType.Window,
                                                    entityId: item.windowID,
                                                    entityName: item.windowName,
                                                    direction: item.direction,
                                                });
                                            }}
                                        >
                                            {item.imageCordinateID > 0 ? (
                                                <EyeIcon></EyeIcon>
                                            ) : (
                                                <ImageLink />
                                            )}{' '}
                                        </button>
                                    </div>
                                </GuardedComponent>
                                <div>
                                    <button
                                        className="tag-buttons"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            // setisObsView(true);
                                            /*  openGallery({
                                                entityType: EntityType.Window,
                                                entityId: item.windowID,
                                            }); */
                                        }}
                                    >
                                        <FileImage></FileImage> {/* <img src={imageimg} /> */}
                                        <span className="tagtop">{item.images}</span>
                                    </button>
                                </div>
                                <div>
                                    <GuardedComponent permission={PermissionName.EditWindow}>
                                        <button
                                            className="tag-buttons"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onEditWindowClick(item);
                                            }}
                                        >
                                            <EditIcon />{' '}
                                            {/* EditGreyIcon<img src={editImg} /> */}
                                        </button>
                                    </GuardedComponent>
                                </div>
                                <div>
                                    <GuardedComponent permission={PermissionName.DeleteWindow}>
                                        <button
                                            className="tag-buttons"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onDeleteWindow(item);
                                            }}
                                        >
                                            <DeleteIcon />{' '}
                                            {/* <img src={deleteImg} /> */}
                                        </button>
                                    </GuardedComponent>
                                </div>
                            </div>
                        ),
                        children: [
                            /*  {
                                title: ( */
                            /*  isobsView ? (
                                <Dialog minWidth={'90vw'}
                                height={'90vh'}
                                    onClose={() => {
                                        setisObsView(false);
                                    }}
                                > */
                            <div className="me-3">
                                <GuardedComponent permission={PermissionName.ViewObservation}>
                                    {imgpreview.imgpreview ? (
                                        <ImageUploader
                                            onAddImg={(comment: string) => {
                                                imgpreview.comment = comment;
                                                handleAddFileToImgList(imgpreview.src);
                                            }}
                                            onclose={() => {
                                                showImgPreview({
                                                    imgpreview: false,
                                                    src: null,
                                                });
                                            }}
                                            showModal={imgpreview.imgpreview}
                                            img={imgpreview.src}
                                        />
                                    ) : null}
                                    <ObservationListComponent
                                        handleCancel={() => { loadImages(); }}
                                        handleDelete={async (img: ImgFile) => {
                                            const isdeleted: boolean = await deleteFile(img);
                                            if (isdeleted) {
                                                setPhoto([]);
                                                await loadImages().then(() => {
                                                    if (result.windows.length > 0) {
                                                        result.windows.forEach((w) => {
                                                            if (w.observations.length > 0) {
                                                                w.observations.forEach((o) => {
                                                                    console.log(o);

                                                                    if (o.photos.length > 0) {
                                                                        let photo = o.photos.find(
                                                                            (p) =>
                                                                                p.fileId ===
                                                                                parseInt(
                                                                                    img.fileID,
                                                                                ),
                                                                        );
                                                                        if (photo) {
                                                                            let filteredPhotos =
                                                                                o.photos.filter(
                                                                                    (p) =>
                                                                                        p.fileId !==
                                                                                        parseInt(
                                                                                            img.fileID,
                                                                                        ),
                                                                                );
                                                                            filteredPhotos.length >
                                                                                0
                                                                                ? (o.photos = [
                                                                                    ...filteredPhotos,
                                                                                ])
                                                                                : (o.photos = []);
                                                                        }
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        }}
                                        handleSave={async (file: ImgFile) => {
                                            const isedited = await editComments({
                                                fileId: file.fileID!,
                                                comments: file.comment,
                                                isBaseImg: file.isbaseImg,
                                                baseID: file.baseID,
                                                entityID: file.entityID,
                                                entityType: file.entityType,
                                                fileID: file.fileID,
                                                userGuid: '',
                                            });
                                            if (isedited) {
                                                setPhoto([]);
                                                await loadImages().then((value) => {
                                                    if (result.windows.length > 0) {
                                                        result.windows.forEach((w) => {
                                                            if (w.observations.length > 0) {
                                                                w.observations.forEach((o) => {
                                                                    if (o.photos.length > 0) {
                                                                        let photo = o.photos.find(
                                                                            (p) =>
                                                                                p.fileId ===
                                                                                parseInt(
                                                                                    file.fileID,
                                                                                ),
                                                                        );
                                                                        if (photo) {
                                                                            o.photos.find(
                                                                                (p) =>
                                                                                    p.fileId ===
                                                                                    parseInt(
                                                                                        file.fileID,
                                                                                    ),
                                                                            ).comments =
                                                                                file.comment;
                                                                        }
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        }}
                                        addphoto={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            showImgPreview({
                                                imgpreview: true,
                                                src: e.target.files[0],
                                            });
                                            observationtype.current = {
                                                entityID: item.windowID,
                                                entityType: ObservationType.Window,
                                            };
                                        }}
                                        onAddNewObservationClick={() =>
                                            onAddNewObservationClick(item, false)
                                        }
                                        oncopyobservation={() =>
                                            onAddNewObservationClick(item, true)
                                        }
                                        onShowObservationPhotos={onShowObservationPhotos}
                                        imgList={imgList}
                                        photos={photos}
                                        observations={item?.observations}
                                        onEditObservation={onEditObservationClick}
                                        onDeleteObservation={onDeleteObservation}
                                        onOpenGallery={openGallery}
                                    />
                                </GuardedComponent>
                            </div>,
                            /*   </Dialog>
                            ) : null, */
                            /*  ),
                            }, */
                        ],
                    };
                },
            );
            return panelItems;
        }
    };

    if (isError) {
        return <div>Error loading windows</div>;
    }

    return (
        <div className="windowsListing">
            <GuardedComponent permission={PermissionName.AddWindow}>
                <div className='stack_title1 mb-2 mt-2 d-flex justify-content-end'>
                    {/* <button
                        className="ms-auto btn btn-outline-primary btn-sm"
                        onClick={() => onAddWindowClick(true)}
                    >
                        Copy From Other Window
                    </button> */}
                    {roomData?.windows?.length > 0 && (<button
                        className="btn btn-outline-primary btn-sm ms-2"
                        onClick={() => onAddWindowClick(false)}
                    >
                        Add Window
                    </button>)}
                </div>
            </GuardedComponent>
            <LoadingIndicator isLoading={isLoading} />
            {roomData && (
                <PanelBar
                    expandMode="single"
                    onSelect={(e) => {
                        console.log(e.target.key);
                        let windowID: string =
                            e.target.key.split('$')[e.target.key.split('$').length - 1];
                        console.log(windowID);

                        observationtype.current = {
                            entityID: parseInt(windowID),
                            entityType: ObservationType.Window,
                        };
                        setImgList([]);

                        setPhoto([]);
                        loadImages();
                    }}
                >




                    {windowData().length > 0 ? windowData().map((e) => {
                        return (
                            <PanelBarItem key={e.id} title={e.title}>
                                {e.children}
                            </PanelBarItem>
                        );
                    }) :
                        <div className='alertDesign'>
                            <div>
                                <div className='images'></div>

                                <div>
                                    <b>
                                        Windows are Empty. Please add a window by clicking on
                                    </b>
                                    <br />
                                    <div style={{ textAlign: 'center' }}> <button
                                        className="btn btn-outline-primary btn-sm ms-2 m-1"
                                        onClick={() => onAddWindowClick(false)}
                                    >
                                        Add Window
                                    </button></div>
                                </div>
                            </div>
                        </div>
                    }
                </PanelBar>
            )}
            {imageListData && <ImageListModelDialog data={imageListData} onCancel={closGallery} />}
            {newObservation && (
                <EditObservation
                    isphotoUploaded={(res: number) => {
                        if (res === 1) {
                            loadImages();
                        }
                    }}
                    observationType={ObservationType.Window}
                    building={building}
                    data={newObservation}
                    onClose={handleSaveObservationComplete}
                    imgList={imgList}
                    uploadedFiles={uploadedfileList.current}
                />
            )}
            {data && <EditWindow data={data} onClose={handleSaveWindowComplete} />}
            {deleteWinowData && (
                <DeleteModelDialog
                    title="Delete Window"
                    deleteData={deleteWinowData}
                    onClose={handleDeleteWindowComplete}
                >
                    <div>
                        Are you sure you want to delete Window - {deleteWinowData.data.windowName}?
                    </div>
                </DeleteModelDialog>
            )}
            {deleteObservation && (
                <DeleteModelDialog
                    title="Delete Observation"
                    deleteData={deleteObservation}
                    onClose={handleDeleteObservationComplete}
                >
                    <div>
                        Are you sure you want to delete Observation -{' '}
                        {deleteObservation.data.observation}?
                    </div>
                </DeleteModelDialog>
            )}
            <LinkImageModelDialog
                buildingId={building.buildingID}
                linkImageData={linkImageData}
                title={'Link Window'}
                onClose={handleLinkWinodwComplete}
            />
        </div>
    );
};
