import { Checkbox } from '@progress/kendo-react-inputs';
import * as React from 'react';
import ExclamationIcon from '../images/exclamation';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { SLOT_TYPES } from '../constants';

interface ICheckboxGroupProps {
    items: ICheckboxItemProps[];
    onchange: (selected: ICheckboxItemProps[]) => void;
}
export interface ICheckboxItemProps {
    label: string;
    value: any;
    isUnAvailable?: number;
    isAccept?: number;
    b_Status: string;
    b_StatusCode: string;
}



const TOOL_TIP_COLOR = {
    [SLOT_TYPES.scheduled]: '#ff9e97',
    [SLOT_TYPES.unavailable]: '#ff9e97',
    [SLOT_TYPES.availabilityReceived]: '#6ebe4a'
}

const CheckboxGroup: React.FunctionComponent<ICheckboxGroupProps> = (props) => {
    const { items, onchange } = props;
    const [selectedItems, setSelectedItems] = React.useState<ICheckboxItemProps[]>([])






    return (<>
        {items.map((item, i) => {
            return (<>
                <div style={{ marginTop: "5px" }}>
                    {item?.isAccept > 0 ? (
                        <Checkbox
                            disabled
                            checked
                            style={{ border: "2px solid #f58a1f" }} value={item.value} onChange={(e) => {
                                if (e.target.value === false) {
                                    if (selectedItems?.some(e => parseInt(e.value) === parseInt(item.value))) {
                                        let sItems = selectedItems.filter(e => parseInt(e.value) != parseInt(item.value));
                                        setSelectedItems(sItems);
                                    }
                                } else {

                                    selectedItems.push(item);
                                    //setSelectedItems((prev) => [...prev, item]);

                                }
                                onchange(selectedItems);
                            }}>

                            <span style={{ fontWeight: "bolder", marginLeft: "5px", width: 226 }}> {item.label}</span>
                            {
                                (!!item?.b_Status && !!item.b_StatusCode) ?

                                    (<OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={(props) => <Tooltip {...props} placement='top' >
                                            {`Already marked for another claim (${item?.b_Status})`}
                                        </Tooltip>}
                                    >
                                        <div style={{ cursor: 'pointer' }} className='ms-3 pointer'>
                                            <ExclamationIcon subColor={TOOL_TIP_COLOR[item.b_StatusCode] || '#6ebe4a'} color={TOOL_TIP_COLOR[item.b_StatusCode] || '#6ebe4a'} />
                                        </div></OverlayTrigger>) :
                                    (null)

                            }
                        </Checkbox >) : (
                        <Checkbox
                            disabled={item.b_StatusCode === SLOT_TYPES.scheduled}
                            style={{ border: "2px solid #f58a1f" }} value={item.value} onChange={(e) => {
                                if (e.target.value === false) {
                                    if (selectedItems?.some(e => parseInt(e.value) === parseInt(item.value))) {
                                        let sItems = selectedItems.filter(e => parseInt(e.value) != parseInt(item.value));
                                        setSelectedItems(sItems);
                                    }
                                } else {

                                    selectedItems.push(item);
                                    //setSelectedItems((prev) => [...prev, item]);

                                }
                                onchange(selectedItems);
                            }}>

                            <span style={{ fontWeight: "bolder", marginLeft: "5px", width: 226 }}> {item.label}</span>
                            {
                                (!!item?.b_Status && !!item.b_StatusCode) ?

                                    (<OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={(props) => <Tooltip {...props} placement='top'>
                                            {`Already marked for another claim (${item?.b_Status})`}
                                        </Tooltip>}
                                    >
                                        <div style={{ cursor: 'pointer' }} className='ms-3 pointer'>
                                            <ExclamationIcon subColor={TOOL_TIP_COLOR[item.b_StatusCode] || '#6ebe4a'} color={TOOL_TIP_COLOR[item.b_StatusCode] || '#6ebe4a'} />
                                        </div></OverlayTrigger>) :
                                    (null)

                            }
                        </Checkbox >

                    )}
                </div >
            </>)
        })}

    </>);
};

export default CheckboxGroup;
