import { StepperProps, TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import * as React from 'react';
import HomeOwnerCard from './components/homeowner-card';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import RequestStatus from './components/claim-request-status';
import Stepper, { Step } from '../../components/stepper';
import ProfileMap from './components/profile-map';
import ClaimHistory from './components/claim-history';
import { ScheduleClaimHandler, ScheduleClaimProfile } from './components/schedule-claim-profile';
import { isEmpty } from 'lodash';
import { SLOT_TYPES, StorageKey, WINDOWS_DOORS_API_URL } from '../../constants';
import { useCurrentUser } from '../../hooks/user-current-user.hook';
import { Dialog } from '@progress/kendo-react-dialogs';
import mobileImage from '../../images/mobile-image.png';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { Claim } from '../../models/claim/claim';
import CheckAvailability from './check-availability';
import { useLoadData } from '../../hooks/use-scheduler-load-data';
import { IClaimProfile } from '../../models/scheduler/claim-profile';
import { profile } from 'console';
import { IClaimRequest } from '../../models/scheduler/claim-request';
import { ScheduleHomeowner } from './components/schedule-homeowner';
import { CLAIM_STATUS_CODE } from './utils';

interface IProfileProps {
}


const STATUS_CODES_TO_HIDE_REQUEST_BUTTONS = [SLOT_TYPES.cancelled, SLOT_TYPES.scheduled]


const STEPPER_OPTIONS = [
    { label: 'Received', statusCode: [SLOT_TYPES.received] },
    { label: 'Awaiting Availability', statusCode: [SLOT_TYPES.awaitingAvailability] },
    { label: 'Ready to Schedule', statusCode: [SLOT_TYPES.readyToSchedule, SLOT_TYPES.rescheduled] },
    { label: 'Scheduled', statusCode: [SLOT_TYPES.scheduled] },
    { label: 'Canceled', statusCode: [SLOT_TYPES.cancelled] },
]

const Profile: React.FunctionComponent<IProfileProps> = (props) => {
    // Steps array
    const { userName, fullName, userGUID, userID } = useCurrentUser();
    const refStatusID = React.useRef<number>(0);

    const loc = useLocation();
    const { state } = loc;

    const [isLoadingRequest, setloadingRequest] = useState<boolean>(false);
    const [confirmMessage, setConfirmMessage] = useState<string>("Availability request has been sent to the Homeowner");
    const [showRequestAvailabilityStatus, setShowRequestAvailabilityStatus] = useState(false);
    const [availability, setChkAvailability] = useState<boolean>(false)
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [showHomeownerScheduler, setShowHomeownerScheduler] = useState(false)


    const { isError: isErrorClaimDetails, isLoading: isLoadingProfileDetail, reload: reloadProfileDetail, result: profileDetailList } = useLoadData<IClaimProfile[]>(`SchedulerAssignments/GetClaimDetailsForProfile?claimID=${state.claimID}`);
    const { isError: isErrorRequestStatus, isLoading: isLoadingRequestStatus, result: requestStatus, reload: reloadRequestStatus } = useLoadData<IClaimRequest[]>(`SchedulerAssignments/GetClaimRequestsForProfile?claimID=${state.claimID}`);

    const profileDetail = profileDetailList?.[0]



    const statusCode = profileDetail?.currentStatusCode || state?.statusCode






    const toggleDialog = () => {
        setShowRequestAvailabilityStatus(false);
    };


    const schedulerRef = React.useRef<ScheduleClaimHandler>()

    return (<>
        {isLoadingRequest && (<LoadingIndicator isLoading={true} />)}
        {availability ? <CheckAvailability onClose={() => { setChkAvailability(false); }} claimInfo={state as Scheduler} show={availability} onAddOrDeleteSchedule={() => {
            reloadProfileDetail();
            reloadRequestStatus();
            schedulerRef?.current.reloadCalendar()
        }} />
            :
            null}
        {showRequestAvailabilityStatus &&
            <Dialog width="430px" title={" "} onClose={toggleDialog} style={{ borderRadius: "10px" }} className="delete-dialog">
                <div className='messageGroup'>

                    <div >
                        <img src={mobileImage} />
                    </div>
                    <div >
                        <h6 className="justify-content-center" style={{ textAlign: "center" }} >{confirmMessage}</h6>
                    </div>

                </div>
            </Dialog>
        }
        <div className="p-4"  >
            <center>
                <div className="w-75">
                    <Stepper options={STEPPER_OPTIONS} activeStatusCode={statusCode} />
                </div>
            </center>

            <div className="grid-row mt-3">
                <HomeOwnerCard isErrorClaimDetails={isErrorClaimDetails} isLoadingClaimDetails={isLoadingProfileDetail} claimDetails={profileDetail} />
                <ProfileMap claimDetails={profileDetail}></ProfileMap>
                <RequestStatus isErrorRequestStatus={isErrorRequestStatus} isLoadingRequestStatus={isLoadingRequestStatus} requestStatus={requestStatus}
                />
            </div>
            <div>
            </div>


            <div className="card border-0 sw-shadow radius-15 tabsdesign" >
                {!STATUS_CODES_TO_HIDE_REQUEST_BUTTONS.includes(statusCode) && <div className="tab-btn">
                    <button style={refStatusID.current !== 7 ? { display: "visible" } : { display: "none" }}
                        onClick={() => setShowHomeownerScheduler(true)}
                        className="btn btn-sm btn-outline-purpple me-2">
                        Request Homeowner Availability
                    </button>
                    <button style={refStatusID.current !== 7 ? { display: "visible" } : { display: "none" }} className="btn btn-sm btn-primary" onClick={() => {
                        setChkAvailability(true);
                    }}>
                        Check Inspector Availability
                    </button>
                </div>}
                <TabStrip selected={selectedTab} onSelect={(e) => { setSelectedTab(e.selected) }} className='mx-2 mt-3 mb-3 bg-light' style={{ minHeight: "35vh", minWidth: "92vw" }}>
                    <TabStripTab contentClassName="structureTab text-capitalize reportpagetab" title="AVAILABILITY CALENDAR">
                        <ScheduleClaimProfile ref={schedulerRef} claim={state as Scheduler} onAddOrDeleteSchedule={() => {
                            reloadProfileDetail();
                            reloadRequestStatus();
                        }} claimStatus={statusCode} />
                    </TabStripTab>
                    <TabStripTab contentClassName="structureTab text-capitalize reportpagetab" title="CLAIM HISTORY">
                        <ClaimHistory claimID={state.claimID} claimNumber={state?.claimNo} />
                    </TabStripTab>
                </TabStrip>
            </div>


        </div >
        {showHomeownerScheduler && <ScheduleHomeowner
            onAddOrDeleteSchedule={() => {
                reloadProfileDetail();
                reloadRequestStatus();
                schedulerRef?.current.reloadCalendar();
            }}
            show={showHomeownerScheduler}
            claim={state as Scheduler}
            onClose={() => setShowHomeownerScheduler(false)}
            setShowRequestAvailabilityStatus={setShowRequestAvailabilityStatus} />}
    </>);
};
export default Profile;


