import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ReactNode, useEffect } from 'react';
import { usePostData } from '../hooks/use-post-data.hook';
import { LoadingIndicator } from './loading-indicator.component';

export interface DeletePostDataInfo {
    data: any;
    route: string;
}

export enum DeleteStatus {
    Completed,
    Cancel,
}

interface DeleteModalDialogProps {
    children: ReactNode;
    deleteData?: DeletePostDataInfo;
    title: string;
    onClose?: (status: DeleteStatus, data: any) => any;
}

export const DeleteModelDialog = ({
    children,
    deleteData,
    title,
    onClose,
}: DeleteModalDialogProps) => {
    const {
        submitData,
        response: { isSubmitting, result, error },
    } = usePostData<any>(deleteData?.route, 'DELETE');

    useEffect(() => {
        if (!isSubmitting && result && !error && onClose) {
            onClose(DeleteStatus.Completed, deleteData.data);
        }
    }, [result, isSubmitting]);

    const submitDelete = () => {
        submitData({ data: deleteData.data });
    };

    const onCancel = () => {
        onClose(DeleteStatus.Cancel, null);
    };

    return (
        <>

            {deleteData && (
                <Dialog title={title} onClose={onCancel} className="delete-dialog">
                    <LoadingIndicator isLoading={isSubmitting} />
                    {error && <div>Error</div>}
                    {children}
                    {!isSubmitting && (
                        <DialogActionsBar>
                            <div className="d-flex justify-content-between">
                                <button className="btn btn-secondary btn-sm" onClick={onCancel}>
                                    Cancel
                                </button>
                                <button className="btn btn-primary btn-sm" onClick={submitDelete}>
                                    OK
                                </button>
                            </div>
                        </DialogActionsBar>
                    )}
                </Dialog>
            )}

        </>
    );
};
