export interface ShapeEditButtonsComponentProps {
    handleCancelChangesClick: any;
    handleSaveChangesClick: any;
}

export const ShapeEditButtonsComponent = ({
    handleCancelChangesClick,
    handleSaveChangesClick,
}: ShapeEditButtonsComponentProps) => {
    return (
        <div className="pop-tool">
            <button className="btn btn-sm btn-secondary me-2" onClick={handleCancelChangesClick}>
                Cancel
            </button>
            <button className="btn btn-sm btn-primary" onClick={handleSaveChangesClick}>
                Save
            </button>
        </div>
    );
};
