import { useState } from 'react';
import { SubmitActionType } from '../components/formik-buttons.component';
import { postData } from '../utils/network.client';

interface PostDataHookResponse<T> {
    isSubmitting: boolean;
    result?: T;
    error?: any;
    submitActionType?: SubmitActionType;
}

export const usePostData = <T>(
    route: string,
    method: string = 'POST',
    contentType: string = 'application/json',
) => {
    const [response, setResponse] = useState<PostDataHookResponse<T>>({ isSubmitting: false });

    const submitData = async (variables: {
        data?: T;
        overrideRoute?: string;
        submitActionType?: SubmitActionType;
    }) => {
        let resultData: any;
        try {
            setResponse({ isSubmitting: true, submitActionType: variables.submitActionType });
            const result = await postData<T>(
                variables.overrideRoute ? variables.overrideRoute : route,
                method,
                contentType,
                variables.data,
            );
            if (result.status === 200) {
                resultData = result.data;
                setResponse({
                    isSubmitting: false,
                    submitActionType: variables.submitActionType,
                    result: result.data,
                });
            } else {
                resultData = { message: 'Failed' };
                setResponse({
                    isSubmitting: false,
                    submitActionType: variables.submitActionType,
                    result: result.data,
                    error: { message: 'Failed' },
                });
            }
        } catch (ex) {
            setResponse({
                isSubmitting: false,
                submitActionType: variables.submitActionType,
                error: ex,
            });
        }
        return resultData;
    };

    return { submitData, response };
};
