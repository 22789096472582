import { PanelBar, PanelBarItem, PanelBarItemProps } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ClaimSetupBreadCrumb } from '../../components/claim-setup-breadcrumb';
import {
    DeleteModelDialog,
    DeletePostDataInfo,
    DeleteStatus,
} from '../../components/delete-model-dialog.component';
import { SubmitStatus } from '../../components/formik-model-dialog.component';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { StorageKey, WINDOWS_DOORS_API_URL } from '../../constants';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { useStorage } from '../../hooks/use-storage';
import { Building } from '../../models/claim/building';
import { Claim } from '../../models/claim/claim';
import { Floor } from '../../models/claim/floor';
import { Unit } from '../../models/claim/unit';
import { addOrUpdateArrayItem, deleteArrayItem } from '../../utils/array.util';
import './building.style.scss';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { ClaimInfoPopover } from './claim-info-popover.component';
import { EditBuilding } from './edit-building.component';
import { EditFloor } from './floor/edit-floor.component';
import { EditRoom } from './room/edit-room.component';
import { EditUnit } from './unit/edit-unit.component';
import DeleteIcon from '../../images/delete';
import EditIcon from '../../images/edit';
import EyeIcon from '../../images/eye';
import { Room } from '../../models/claim/room';
import { EditClaim } from '../claim/edit-claim.component';
import { GuardedComponent } from '../../components/guarded.componemt';
import { PermissionName } from '../../models/user-security/permission.name';
import { find } from 'lodash';
import { ShareLinkButton } from '../../components/share-link-button.component';
import { ShareType } from '../../components/share-link-dialog.component';
import { Button, Table } from 'react-bootstrap';
import { usePermission } from '../../hooks/use-permission.hook';
import { useParams } from 'react-router-dom';
import React from 'react';
import DownloadIcon from '../../images/download-icon';
import { ImageDownloaddialog } from '../../components/download-images-component';
import { DownloadFiles, IDownloadFileProps, IDownloadFileResponse } from '../../utils/downloadfileutil';
import { ObservationType } from '../../models/masters/observation-type';
import { ListBox } from '@progress/kendo-react-listbox';
import { fetchFile } from '../../utils/uploadfile.util';
import FileTable from '../../components/downloadfile-grid.component';
import axios from 'axios';
import { InspectionReportIcon } from '../../images/report-icon.component';

/* 
export const BuildingsPage = (props: {  }) => { */
export const BuildingsPage: React.FC = () => {
    const { claimGuid } = useParams();
    const navigate = useNavigate();
    const [editBuildingData, setEditBuildingData] = useState<Building>();
    const [editFloorData, setEditFloorData] = useState<{
        currentBuilding: Building;
        floor: Floor;
    }>();
    const nav = useNavigate();
    const [editUnitData, setEditUnitData] = useState<{ building: Building; unit: Unit }>();
    const [editRoomData, setEditRoomData] = useState<{ building: Building; room: Room }>();
    const [deleteBuildingData, setDeleteBuildingData] = useState<DeletePostDataInfo>();
    const [buildings, setBuildings] = useState<Building[]>();
    const [downloadFiles, setDownloadFiles] = useState<IDownloadFileResponse[]>([]);
    const [currentClaim, setCurrentClaim] = useStorage(StorageKey.CurrentUser, new Claim());
    const [editClaimData, setEditClaimData] = useState<Claim>();
    const { hasPermission: hasViewClaimPermission } = usePermission(PermissionName.ViewClaim);


    const {
        isLoading: isLoadingClaimData,
        result: claimDataResult,
        isError: isClaimDataError,
    } = useLoadData<Claim>(`claims/${/* props.match.params.claimGuid */ claimGuid}`);
    const { isLoading, result, isError } = useLoadData<Building[]>(
        /*  `building/claims/${props.match.params.claimGuid}` */ `building/claims/${claimGuid}`,
    );
    const [isdownload, setIsDownload] = useState<boolean>(false);
    const [isprocessing, setIsProcessing] = useState<{ process: boolean, props: IDownloadFileProps, buildingData: Building }>({ process: true, props: undefined, buildingData: undefined });

    useEffect(() => {
        if (claimDataResult) {
            setCurrentClaim(claimDataResult);
        }
    }, [claimDataResult]);

    useEffect(() => {
        if (result) {
            setBuildings(result);
        }
    }, [result]);

    const onViewReport = () => {
        navigate(`/reports/observation/${/* props.match.params.claimGuid */ claimGuid}`);
    };

    const onEditClaimClick = () => {
        setEditClaimData({ ...currentClaim });
    };

    const handleSaveClaimComplete = (status: SubmitStatus, data: Claim) => {
        if (status === SubmitStatus.Completed) {
            setCurrentClaim(data);
        }
        setEditClaimData(null);
    };

    const handleSaveBuildingComplete = (status: SubmitStatus, data: Building) => {
        if (status === SubmitStatus.Completed) {
            const building = find(buildings, (b) => b.buildingID === data.buildingID);
            data.numberOfFloors = building !== undefined ? building?.numberOfFloors : 0;
            data.numberOfUnits = building !== undefined ? building?.numberOfUnits : 0;
            data.numberOfRooms = building !== undefined ? building?.numberOfRooms : 0;
            const newData = addOrUpdateArrayItem(buildings, data, 'buildingID');
            setBuildings(newData);
        }
        setEditBuildingData(null);
    };

    const handleDeleteBuildingComplete = (status: DeleteStatus, data: Building) => {
        if (status === DeleteStatus.Completed) {
            const newData = deleteArrayItem(buildings, data, 'buildingID');
            setBuildings(newData);
        }
        setDeleteBuildingData(null);
    };

    /*  const closeEditModal = (status: SubmitStatus, data: Building) => {
        setEditFloorData(null);
        setEditUnitData(null);
        setEditRoomData(null);
    };
 */
    const handleSaveFloorComplete = (status: SubmitStatus, data: Floor) => {
        if (status === SubmitStatus.Completed) {
            const building = find(buildings, (b) => b.buildingID === data.buildingID);
            building.numberOfFloors = building?.numberOfFloors + 1;
        }
        setEditFloorData(null);
    };

    const handleSaveUnitComplete = (status: SubmitStatus, data: Unit) => {
        if (status === SubmitStatus.Completed) {
            const building = find(
                buildings,
                (b) => b.buildingID === editUnitData.building.buildingID,
            );
            building.numberOfUnits = building?.numberOfUnits + 1;
        }
        setEditUnitData(null);
    };

    const handleSaveRoomComplete = (status: SubmitStatus, data: Room) => {
        if (status === SubmitStatus.Completed) {
            const building = find(
                buildings,
                (b) => b.buildingID === editRoomData.building.buildingID,
            );
            building.numberOfRooms = building?.numberOfRooms + 1;
        }
        setEditRoomData(null);
    };

    const onViewClick = (buildingID: number) => {
        navigate(`/buildingdetails/${buildingID}`);
    };
    const onDownloadClick = async (building: Building, isrefresh?: boolean): Promise<boolean> => {
        { !isrefresh ? setIsDownload(true) : null };
        setIsProcessing({ process: true, props: undefined, buildingData: undefined });

        const response = await axios.get(`${WINDOWS_DOORS_API_URL}/api/file/GetFileDownloadLogs?entityID=${building.buildingID}&entityType=photodownload`);
        if (response) {
            setIsProcessing({ buildingData: building, process: false, props: { claimGUID: building.claimGuid, claimID: 0, entityID: building.buildingID, building: building, entityType: 'photodownload', userID: 0, } });
            setDownloadFiles(response.data as IDownloadFileResponse[]);
            return true;

        }
        return false;
    };

    const onEditClick = (building: Building) => {
        setEditBuildingData(building);
    };

    const onAddNewBuildingClick = () => {
        const building: Building = {
            buildingID: 0,
            claimGuid: /* props.match.params.claimGuid */ claimGuid,
        };
        setEditBuildingData(building);
    };

    const onAddFloorClick = (building: Building) => {
        const floor: Floor = {
            floorID: 0,
            buildingID: building.buildingID,
        };
        setEditFloorData({
            currentBuilding: building,
            floor: floor,
        });
    };

    const onAddUnitClick = (building: Building) => {
        const unit: Unit = {
            unitID: 0,
            floorID: 0,
            unitName: '',
            unitNumber: '',
        };
        setEditUnitData({
            building: building,
            unit,
        });
    };

    const onAddRoomClick = (building: Building) => {
        const room: Room = {
            unitID: 0,
            roomID: 0,
        };
        setEditRoomData({
            building,
            room,
        });
    };

    const onDeleteBuildingClick = (data: Building) => {
        const { buildingID } = data;
        setDeleteBuildingData({
            data,
            route: `building/delete/${buildingID}`,
        });
    };

    const onSearch = (e: any) => {
        if (e.target.value.length > 0) {
            const searchKey: string = e.target.value;
            setBuildings(
                result.filter((p) =>
                    p.buildingName.toLowerCase().includes(searchKey.toLowerCase()),
                ),
            );
        } else {
            setBuildings(result);
        }
    };

    const panelData = () => {
        if (buildings) {
            const panelItems: PanelBarItemProps[] = buildings.map<PanelBarItemProps>(
                (item): PanelBarItemProps => {
                    return {
                        id: item.buildingID.toString(),
                        title: (
                            <div className="buildings-container">
                                <div className="span-2-col">
                                    <div>Building Name</div>
                                    <h5 className="text-normal">{item.buildingName}</h5>
                                </div>

                                <div className="border-start">
                                    <div>Floors</div>
                                    <h4>{item.numberOfFloors}</h4>
                                </div>
                                <div className="border-start">
                                    <div>Units</div>
                                    <h4>{item.numberOfUnits}</h4>
                                </div>
                                <div className="border-start">
                                    <div>Rooms</div>
                                    <h4>{item.numberOfRooms}</h4>
                                </div>
                                <div className="border-start d-flex align-items-center">
                                    <GuardedComponent permission={PermissionName.ViewBuilding}>
                                        <OverlayTrigger

                                            overlay={(props) => (
                                                <Tooltip {...props}>
                                                    Download
                                                </Tooltip>
                                            )}
                                            placement="bottom"
                                        >
                                            <button
                                                className="action-btns k-grid-download-command"
                                                onClick={() => onDownloadClick(item)}
                                            >
                                                <DownloadIcon />
                                            </button>
                                        </OverlayTrigger>
                                    </GuardedComponent>
                                    <GuardedComponent permission={PermissionName.ViewBuilding}>
                                        <OverlayTrigger

                                            overlay={(props) => (
                                                <Tooltip {...props}>
                                                    View
                                                </Tooltip>
                                            )}
                                            placement="bottom"
                                        >
                                            <button
                                                className="action-btns k-grid-edit-command"
                                                onClick={() => onViewClick(item.buildingID)}
                                            >
                                                <EyeIcon></EyeIcon>{' '}
                                            </button>
                                        </OverlayTrigger>
                                    </GuardedComponent>
                                    <GuardedComponent permission={PermissionName.EditBuilding}>
                                        <OverlayTrigger

                                            overlay={(props) => (
                                                <Tooltip {...props}>
                                                    Edit
                                                </Tooltip>
                                            )}
                                            placement="bottom"
                                        >
                                            <button
                                                className="action-btns k-grid-edit-command"
                                                onClick={() => onEditClick(item)}
                                            >
                                                <EditIcon></EditIcon>{' '}
                                            </button>
                                        </OverlayTrigger>
                                    </GuardedComponent>
                                </div>
                            </div>
                        ),
                        children: [
                            /*                         {
                            title: ( */
                            <div className="buildings-container  align-items-center ps-2">
                                <div className="span-2-col">
                                    <div>Assigned Inspector</div>
                                    <h6>{item.buildingNumber}</h6>
                                </div>
                                <div>
                                    <GuardedComponent permission={PermissionName.AddFloor}>
                                        <button
                                            className="k-primary k-button k-grid-edit-command"
                                            onClick={() => onAddFloorClick(item)}
                                        >
                                            Add Floor
                                        </button>
                                    </GuardedComponent>
                                </div>
                                <div>
                                    <GuardedComponent permission={PermissionName.AddUnit}>
                                        {item.numberOfFloors > 0 && (
                                            <button
                                                className="k-primary k-button k-grid-edit-command"
                                                onClick={() => onAddUnitClick(item)}
                                            >
                                                Add Unit
                                            </button>
                                        )}
                                    </GuardedComponent>
                                </div>
                                <div>
                                    <GuardedComponent permission={PermissionName.AddRoom}>
                                        {item.numberOfUnits > 0 && (
                                            <button
                                                className="k-primary k-button k-grid-edit-command"
                                                onClick={() => onAddRoomClick(item)}
                                            >
                                                Add Room
                                            </button>
                                        )}
                                    </GuardedComponent>
                                </div>
                                <GuardedComponent permission={PermissionName.DeleteBuilding}>
                                    <div className="text-end">
                                        <button
                                            className="action-btns del-btn-position k-grid-edit-command"
                                            onClick={() => onDeleteBuildingClick(item)}
                                        >
                                            <DeleteIcon />{' '}
                                        </button>
                                    </div>
                                </GuardedComponent>
                            </div>,
                        ],
                    };
                },
            );
            return panelItems;
        }
    };

    if (isError) {
        return <div>Error</div>;
    }

    return (
        <div className="container-fluid">
            <div className="top-sec-align">
                <div>
                    <h5 className='title-web text-uppercase mb-0'>Buildings</h5>
                    {hasViewClaimPermission && <ClaimSetupBreadCrumb currentClaim={currentClaim} />}
                    {!hasViewClaimPermission && (
                        <span>KeyStone#: {currentClaim?.keystoneNumber}</span>
                    )}

                </div>

                <div className="ms-auto">
                    {buildings && currentClaim && (
                        <div className="d-flex mb-2 search-container align-items-center">
                            <div className="ms-auto d-flex align-items-end me-4">
                                <input
                                    type="text"
                                    className="form-control searchBg search-right-align me-3 "
                                    onChange={onSearch}
                                />
                                <GuardedComponent permission={PermissionName.AddBuilding}>
                                    <button
                                        className="btn btn-primary w-auto text-nowrap btn-sm"
                                        onClick={onAddNewBuildingClick}
                                    >
                                        Add New Building
                                    </button>
                                </GuardedComponent>

                                <GuardedComponent permission={PermissionName.ViewClaim}>
                                    <div className="ms-2">
                                        <ShareLinkButton
                                            title="Share Claim"
                                            shareUrl={window.location.href}
                                            type={ShareType.Claim}
                                            typeId={Number(currentClaim?.claimID)}
                                        />
                                    </div>
                                </GuardedComponent>

                                <Button
                                    onClick={onViewReport}
                                    className="btn btn-primary w-auto text-nowrap btn-sm"
                                >
                                    Report
                                </Button>
                            </div>
                            <button style={{ marginLeft: '-15px' }}
                                className='action-btns k-grid-edit-command'
                                onClick={async () => {
                                    nav("/inspectionReport", { state: currentClaim });

                                }}
                            >
                                <InspectionReportIcon />
                            </button>
                            <ClaimInfoPopover
                                claim={currentClaim}
                                onEditClaimClick={onEditClaimClick}
                            />
                        </div>
                    )}



                </div>
            </div>
            {(!buildings || buildings?.length <= 0) && (!isLoading) ? (<div className='alertDesign'>
                <div style={{ width: '100%' }}>
                    <div className='images'></div>

                    <div>
                        <center>  <b>
                            There are no buildings associated with this project. Please add a building to proceed.
                        </b>
                        </center>
                        <br />
                        {/* <div style={{ textAlign: 'center' }}> <b>Button:Add Observation</b></div> */}
                    </div>
                </div>
            </div>) : null}
            <LoadingIndicator isLoading={isLoading} />
            {buildings && currentClaim && (
                <div>
                    <PanelBar>
                        {panelData().map((e) => {
                            return (
                                <PanelBarItem key={e.id} title={e.title}>
                                    {e.children}
                                </PanelBarItem>
                            );
                        })}
                    </PanelBar>
                    {editFloorData && (
                        <EditFloor data={editFloorData} onClose={handleSaveFloorComplete} />
                    )}
                    {editUnitData && (
                        <EditUnit data={editUnitData} onClose={handleSaveUnitComplete} />
                    )}
                    {editRoomData && (
                        <EditRoom data={editRoomData} onClose={handleSaveRoomComplete} />
                    )}
                    {editBuildingData && (
                        <EditBuilding
                            data={editBuildingData}
                            onClose={handleSaveBuildingComplete}
                        />
                    )}
                    {deleteBuildingData && (
                        <DeleteModelDialog
                            title="Delete Claim"
                            deleteData={deleteBuildingData}
                            onClose={handleDeleteBuildingComplete}
                        >
                            <div>
                                Are you sure you want to delete building -{' '}
                                {deleteBuildingData.data.buildingName}?
                            </div>
                        </DeleteModelDialog>
                    )}
                    {editClaimData && (
                        <EditClaim data={editClaimData} onClose={handleSaveClaimComplete} />
                    )}
                </div>
            )}
            {isdownload && (
                <ImageDownloaddialog
                    onClose={() => {
                        setIsDownload(false);
                    }}
                    show={isdownload}
                    children={
                        <>
                            <center>
                                <LoadingIndicator isLoading={isprocessing.process} />
                                {isprocessing.process ? (
                                    <div>
                                        {' '}
                                        Loading file(s)..
                                    </div>
                                ) : (
                                    <div style={{ justifyContent: 'space-evenly', padding: '5px', }}>
                                        {
                                            <FileTable downloadFiles={downloadFiles} downloadFileProps={isprocessing.props} onRefresh={async (building: Building) => {
                                                return await onDownloadClick(building);
                                            }} />
                                        }
                                    </div>
                                )}
                            </center>
                        </>
                    }
                />
            )}
        </div>
    );
};
