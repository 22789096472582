import * as React from 'react';
import { useLoadData } from '../../../hooks/use-scheduler-load-data';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { getFormSubmissionInfo } from 'react-router-dom/dist/dom';
import { string } from 'yup';
import { useState } from 'react';
import { CLAIM_STATUS_CODE } from '../utils';
import { useSchedulerDashboardCounts } from '../../../api/dashboard';
import { convertToTimezone } from '../../../utils';

interface IDashboardCountProps {
    onCancelFilterToggle: (status: string) => void;
    onCancelDateToggle: (status: string) => void;
    sDate: string;
    fDate: string;
}

const DashboardCount: React.FunctionComponent<IDashboardCountProps> = (props) => {
    const { onCancelFilterToggle, onCancelDateToggle, sDate, fDate } = props;

    const { data: dashboardCounts, isLoading: isLoadingDashboardCounts, isError: isErrorDashboardCounts } = useSchedulerDashboardCounts({
        variables: {
            SDate: sDate,
            FDate: fDate,
        }
    })

    const [filter, setFilter] = useState<string>("");
    const [filterIndicator, setFilterIndicator] = useState<string>("");
    const active = "gredientBg card p-4 border-0 sw-shadow radius-15 border-0";
    const inActive = "card p-4 border-0 sw-shadow radius-15 border-0";

    return (<>
        {isLoadingDashboardCounts && (<LoadingIndicator isLoading />)}
        {!isLoadingDashboardCounts && dashboardCounts && <>
            <div className='boxcount mb-4'>
                <div className={filter === "upcomming" ? (active) : (inActive)}  >
                    <h5 className='text-uppercase  '>Upcoming <span className='fw-bold text-primary'> Inspections</span></h5>
                    <div className='gridTd' >
                        <div style={{ cursor: "pointer" }} className={filterIndicator === "today" ? ('active-indicator') : ("")} onClick={() => {
                            // setFilter("today")
                            setFilter(filterIndicator !== "today" ? "upcomming" : "");
                            setFilterIndicator(filterIndicator === "today" ? "" : "today");
                            onCancelDateToggle("today")
                        }}>
                            <h3 className='indication'>  {dashboardCounts[0].upinS_Count_Today}</h3>
                            <p className='mb-0 text-nowrap' >Today</p>
                        </div>
                        <div style={{ cursor: "pointer" }} onClick={() => {
                            setFilter(filterIndicator !== "tomorrow" ? "upcomming" : "");
                            setFilterIndicator(filterIndicator === "tomorrow" ? "" : "tomorrow");
                            onCancelDateToggle("tomorrow")
                        }} className={filterIndicator === "tomorrow" ? ('active-indicator') : ("")}>
                            <h3 className='indication'> {dashboardCounts[0].upinS_Count_Tomorrow}</h3>
                            <p className='mb-0 text-nowrap'>Tomorrow</p>
                        </div>
                        <div style={{ cursor: "pointer" }} className={filterIndicator === "thisweek" ? ('active-indicator') : ("")}
                            onClick={() => {
                                setFilter(filterIndicator !== "thisweek" ? "upcomming" : "");
                                setFilterIndicator(filterIndicator === "thisweek" ? "" : "thisweek");
                                // setFilter("thisweek");
                                onCancelDateToggle("thisweek")
                            }}>
                            <h3 className='indication'> {dashboardCounts[0].upinS_Count_Week}</h3>
                            <p className='mb-0 text-nowrap'>This week</p>
                        </div>
                    </div>
                </div>
                <div style={{ cursor: "pointer" }} className={filter === "pendinginspections" ? (active) : (inActive)} onClick={() => { filter != "pendinginspections" ? setFilter("pendinginspections") : setFilter("ALL"); onCancelFilterToggle("pending"); setFilterIndicator(""); }}>
                    <h5 className='text-uppercase'>Pending <span className='fw-bold'>Inspections</span></h5>
                    <h1 className='indication2'>{dashboardCounts[0].pendinS_Count}</h1>
                </div>
                <div style={{ cursor: "pointer" }} className={filter === CLAIM_STATUS_CODE.RECEIVED_RESCHEDULE ? (active) : (inActive)} onClick={() => { filter != CLAIM_STATUS_CODE.RECEIVED_RESCHEDULE ? setFilter(CLAIM_STATUS_CODE.RECEIVED_RESCHEDULE) : setFilter("ALL"); setFilterIndicator(""); onCancelFilterToggle(CLAIM_STATUS_CODE.RECEIVED_RESCHEDULE); }} >
                    <h5 className='text-uppercase'>Rescheduling  <span className='fw-bold'>Requests</span></h5>
                    <h1 className='indication2'>{dashboardCounts[0].resreQ_Count}</h1>
                </div>
                <div style={{ cursor: "pointer" }} className={filter === CLAIM_STATUS_CODE.CANCELED ? (active) : (inActive)} onClick={() => { setFilterIndicator(""); filter != CLAIM_STATUS_CODE.CANCELED ? setFilter(CLAIM_STATUS_CODE.CANCELED) : setFilter("ALL"); onCancelFilterToggle(CLAIM_STATUS_CODE.CANCELED); }}>
                    <h5 className='text-uppercase fw-bold'>Cancelations</h5>
                    <h1 className='indication2'>{dashboardCounts[0].canC_Count}</h1>
                </div>

            </div ></>}
        {isErrorDashboardCounts && (<div>Error Loading Count</div>)}

    </>);
};

export default DashboardCount;
