// reducers/fileReducer.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../store';
import { fetchFiles } from '../../api-client.ts/fileapi';

export interface UploadedFile {
  contextID: string;
  fileID: number;
  sourceFileID: number;
  fileGUID: string;
  fileName: string;
  fileType: string;
  fileMime: string;
  entityType: string;
  entityID: number;
  direction: string;
  filePath: string;
  fileCDN: string;
  thumbCDN: string;
  isActive: boolean;
  isBase: boolean;
  userID: number;
  createdOn: string;
  deactivatedOn: string;
  comments: string;
  isInterior:boolean;
  isBasePhoto:boolean;
  basePhotoID:number;
}

export interface FetchFilesParams {
  FileType: string;
  UserID: number;
  EntityType: string;
  EntityID: number;
}

const initialState: { files: UploadedFile[]; loading: boolean; error: string | null } = {
  files: [],
  loading: false,
  error: null,
};



const fileSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFiles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFiles.fulfilled, (state, action) => {
        state.loading = false;
        state.files = action.payload ;
      })
      .addCase(fetchFiles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Failed to fetch files';
      });
  },
});

export default fileSlice.reducer;
