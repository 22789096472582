import { ReactNode } from 'react';
import { useCurrentUser } from '../hooks/user-current-user.hook';
import { validateRequiredPermissions } from '../utils/permission.manager';
import { PermissionName } from '../models/user-security/permission.name';
import { PermissionCheckType } from '../models/user-security/permission-check-type';

interface GuardedComponentProps {
    children: ReactNode;
    permission: PermissionName | PermissionName[];
    permissionCheckType?: PermissionCheckType;
}

export const GuardedComponent = ({
    children,
    permissionCheckType = PermissionCheckType.All,
    permission,
}: GuardedComponentProps) => {
    const { currentPermissions } = useCurrentUser();
    if (validateRequiredPermissions(permission, permissionCheckType, currentPermissions)) {
        return <> {children} </>;
    }
    return null;
};
