import axios, { AxiosError, AxiosResponse } from "axios";
import { getFromLocalStorage, setToLocalStorage } from "../utils/storage";
import { WINDOWS_DOORS_API_URL } from "../constants";



axios.defaults.baseURL = `${WINDOWS_DOORS_API_URL}/api/`;


const setAxiosAuthHeaders = () => {

    axios.defaults.headers['Accept'] = 'application/json';
    axios.defaults.headers['Content-Type'] = 'application/json';


    const token = getFromLocalStorage("AUTH_STATE");

    if (token) {
        axios.defaults.headers.Authorization = `Bearer ${token} `;
    }

};

const handleSuccessResponse = (response: AxiosResponse) => {

    return response;
};

const handleErrorResponse = (axiosErrorObject: AxiosError) => {
    if (axiosErrorObject.response?.status === 401) {
        setToLocalStorage({ key: 'AUTH_STATE', value: null });
        window.location.reload();
    }

    if (axiosErrorObject.response?.status === 423) {
        window.location.href = "/";
    }

    return Promise.reject(axiosErrorObject);
};

const registerAxiosIntercepts = () => {
    axios.interceptors.response.use(handleSuccessResponse, (error) =>
        handleErrorResponse(error),
    );
};

const resetAxiosAuthTokens = () => {
    delete axios.defaults.headers.Authorization;
};

export { setAxiosAuthHeaders, registerAxiosIntercepts, resetAxiosAuthTokens };
