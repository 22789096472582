import { capitalize, chain, filter, join, map } from 'lodash';
import * as Yup from 'yup';
import { FormikModelDialog, SubmitStatus } from '../../../components/formik-model-dialog.component';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { useLoadData } from '../../../hooks/use-load-data.hook';
import { Building } from '../../../models/claim/building';
import { MasterType } from '../../../models/masters/master-type';
import { InputFieldType } from '../../../models/enums/input-field-type';
import { createAttributeObject } from '../../../utils/building.utils';
import { TemplateModel } from '../../../models/template-model';
import { EditTemplateForm } from './template-form';
import { MasterValue } from '../../../models/masters/master-value';
import { WINDOWS_DOOR_OTHER_OPTION } from '../../../constants';

interface EditTemplateProps {
    data?: { building: Building; template: TemplateModel; copyFrom?: boolean };
    onClose?: (status: SubmitStatus, data: any) => any;
    isTemplate?: boolean;
    entityType: string;
}

export const EditTemplate = ({ data: { building, template, copyFrom }, onClose, isTemplate, entityType }: EditTemplateProps) => {
    let { isError, isLoading, result, reload } = useLoadData<MasterType[]>(`masters?type=${entityType}`);

    const title = template.templateID ? `Edit ${capitalize(entityType)} Template` : `Add New ${capitalize(entityType)} Template`;

    const initialValue = () => {
        if (result) {

            let attributes = { ...createAttributeObject(result, template.attributes) };
            console.log(attributes);

            console.log({
                templateName: template.templateName,
                direction: template.direction !== undefined ? template.direction : 'North',
                //  hasDamaged: template.hasDamaged,
                //  notes: template.notes,
                ...createAttributeObject(result, template.attributes),
            });

            return {
                templateName: template.templateName,
                direction: template.direction !== undefined ? template.direction : 'North',
                //  hasDamaged: template.hasDamaged,
                //  notes: template.notes,
                ...attributes,
            };
        }
    };

    const parseTemplateData = (data: TemplateModel) => {
        template.templateName = data.templateName;
        template.direction = data.direction;
        // template.notes = data.notes;
        template.buildingId = building.buildingID;
        template.templateEntityType = entityType;
        template.createdOn = new Date().toISOString();
        // template.hasDamaged = data.hasDamaged;
        const templateAttributes = chain(result)
            .map((item) => {
                console.log(item);
                console.log({
                    attributeName: item.name,
                    attributeValue: getAttributeValue(item, data),
                    attributeid: item.typeID,
                });


                return {
                    attributeName: item.name,
                    attributeValue: getAttributeValue(item, data),
                    attributeid: item.typeID,
                }
            })
            .filter((a) => !!a.attributeValue)
            .unionBy((a) => a.attributeName)
            .value();

        template.attributes = templateAttributes.map((e) => ({
            attributeName: e.attributeName,
            attributeValue: e.attributeValue,
            rowID: 0,
            templateID: template.templateID,
            attributeId: e.attributeid,
        }));

        return template;
    };
    ///data is u7ser defined item is master from db ;'v' is master from db
    const getAttributeValue = (item: MasterType, data: any) => {
         let customOption = '';
        if (item.inputType === InputFieldType.MultiSelect) {
            const selectedItems = item.values.filter((v: MasterValue) => {
                if (v.value.toLowerCase() !== WINDOWS_DOOR_OTHER_OPTION.toLowerCase()) {
                    return data[v.value];
                }
                else {
                    if (data[`${v.value}-${v.id}`] === true && data[`${v.value}-${v.id}`] !== "" && data[`${v.value}value-${v.id}`] !== undefined && data[`${v.value}value-${v.id}`] !== '') {
                        customOption = data[`${v.value}value-${v.id}`];

                        return true;
                    }
                }
            }
            );
            console.log(selectedItems);
            let option = join(map(selectedItems, (s) => s.value), ',');
            if (customOption !== '') {
                option = option + ":" + customOption;
            }
            console.log(option);

            return option;
        }

        else if (item.inputType === InputFieldType.Text || item.inputType === InputFieldType.Date) {
            return data[item.name];
        }
        return null;
    };

    const validationSchema = Yup.object().shape({
        templateName: Yup.string().required('Required'),
    });

    return (
        <FormikModelDialog
            width='60%'
            title={title}
            show={!!template}
            initialData={initialValue()}
            validationSchema={validationSchema}
            postRoute="TemplateAsset/SaveTemplateAsset"
            postDataFormatter={parseTemplateData}
            onClose={onClose}
        >
            <>
                <LoadingIndicator isLoading={isLoading} />
                {result && (
                    <EditTemplateForm
                        attributes={result}
                        building={building}
                        Template={{

                            templateDefaultID: template.
                                templateDefaultID,
                            buildingId: building.buildingID,
                            createdOn: '',
                            isActive: false,
                            templateEntityType: entityType,
                            templateID: template.templateID,
                            templateName: template.templateName,
                            UserGuid: template.UserGuid,
                            attributes: template.attributes,
                            direction: template.direction,
                            //  notes: template.notes,
                            // hasDamaged: template.hasDamaged,
                        }}
                    />
                )}
            </>
        </FormikModelDialog>
    );
};
