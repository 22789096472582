import { Building } from '../models/claim/building';
import { Claim } from '../models/claim/claim';
import { Breadcrumbs, CrumbItem } from './breadcrumbs.component';

interface ClaimSetupBreadCrumbProps {
    currentClaim?: Claim;
    currentBuilding?: Building;
    onClick?: () => void;
}

export const ClaimSetupBreadCrumb = ({
    currentClaim,
    currentBuilding,
    onClick,
}: ClaimSetupBreadCrumbProps) => {
    const crumbData = (): CrumbItem[] => {
        const crumbs: CrumbItem[] = [{ name: 'Home', route: '/' }];
        if (currentClaim) {
            crumbs.push({ name: `/ ${currentClaim.keystoneNumber}(${currentClaim.client})`, route: `/buildings/${currentClaim.claimGUID}` });
            if (currentBuilding) {
                /* crumbs.push({
                    name: `/ ${currentClaim.client}`,
                    route: `/buildings/${currentClaim.claimGUID}`,
                }); */
                crumbs.push({
                    name: `/ ${currentBuilding.buildingName}`,
                    onClick: (e) => {
                        onClick();
                    },
                });
            } else {
               /*  crumbs.push({ name: `/ ${currentClaim.client}` });
            */ }
        } else {
           /*  crumbs.push({ name: `/ Claims` });
       */  }

        return crumbs;
    };

    if (currentClaim?.claimNumber) {
        return <Breadcrumbs crumbs={crumbData()} />;
    } else {
        return null;
    }
};
