import axios, { AxiosRequestConfig, AxiosResponse, Canceler } from 'axios';
import { StorageKey, WINDOWS_DOORS_API_URL } from '../constants';
class AsyncRequest {
    static CancelToken = axios.CancelToken;

    static async requestApi(props: IAsyncReqProps): Promise<any> {
        try {
            const response = await this.method(props);
            console.log(response.data);
            return response.data;
        } catch (error: any) {
            if (axios.isCancel(error)) {
                console.log('Request Cancelled. Try Again Later');
            } else if (error.response) {
                const status = error.response.status;
                if (status === 400) {
                    console.log('Bad Request! Please check your request format');
                } else if (status === 401) {
                    console.log('Unauthorized! Please login to continue');
                } else if (status === 404) {
                    console.log('Not Found! The requested resource is not available');
                } else {
                    console.log('Server Error! Please try again later');
                }
            } else {
                console.log('Network Error! Please check your internet connection');
            }
            throw error;
        }
    }

    static config(parameters?: any, method?: HttpMethod): AxiosRequestConfig {
        const authState = JSON.parse(localStorage.getItem(StorageKey.AuthState));

        return method == HttpMethod.GET
            ? <AxiosRequestConfig>{
                  headers: {
                      'Content-Type': 'application/x-www-form-urlencoded',
                      Authorization: authState && `Bearer ${authState}`,
                  },
                  params: parameters,
                  timeout: 8000,
                  //baseURL: `${WINDOWS_DOORS_API_URL}/api/`,
              }
            : <AxiosRequestConfig>{
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: authState && `Bearer ${authState}`,
                  },
                  timeout: 8000,
                  //baseURL: `${WINDOWS_DOORS_API_URL}/api/`,
              };
    }

    static async method(props: IAsyncReqProps): Promise<AxiosResponse<any>> {
        const { method, url, body, params } = props;
        console.log(url);

        return method === HttpMethod.POST
            ? await axios.post(url, body, this.config())
            : await axios.get(url, this.config(params, HttpMethod.GET));
    }
}

export default AsyncRequest;

export enum HttpMethod {
    POST,
    GET,
}

export interface IAsyncReqProps {
    method: HttpMethod;
    url: string;
    params?: any;
    body?: any;
}

const errorDesc = {
    err400: 'Bad Request! Please check your request format',
    errcancel: 'Request Cancelled. Try Again Later',
    err401: 'Unauthorized! Please login to continue',
    err404: 'Not Found! The requested resource is not available',
    err500: 'Server Error! Please try again later',
};
