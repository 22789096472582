import React from 'react';

const FileVideoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.267" height="16.151" viewBox="0 0 13.267 16.151">
      <path id="Icon_awesome-file-video" data-name="Icon awesome-file-video" d="M11.767,3.847v.191H7.729V0H7.92a.757.757,0,0,1,.535.222l3.09,3.09a.757.757,0,0,1,.222.535ZM6.72,4.29V0H-.743A.757.757,0,0,0-1.5.757V15.394a.757.757,0,0,0,.757.757H11.01a.757.757,0,0,0,.757-.757V5.047H7.477A.759.759,0,0,1,6.72,4.29Zm3.509,4.122v3.532a.758.758,0,0,1-1.292.535L7.2,10.744v1.2a.757.757,0,0,1-.757.757H1.324a.757.757,0,0,1-.757-.757V8.411a.757.757,0,0,1,.757-.757H6.443a.757.757,0,0,1,.757.757v1.2L8.936,7.877A.758.758,0,0,1,10.229,8.412Z" transform="translate(1.5)" fill="#8f8f8f"/>
    </svg>
  );
}

export default FileVideoIcon;
