const CalenderIcon = () => {
    return (
        <svg
            className="me-2"
            width="14.25"
            height="15.833"
            viewBox="0 0 14.25 15.833"
        >
            <path
                id="Path_calendar-outline"
                data-name="Path / calendar-outline"
                d="M15.667,3.583h-.792V2H13.292V3.583H6.958V2H5.375V3.583H4.583A1.583,1.583,0,0,0,3,5.167V16.25a1.583,1.583,0,0,0,1.583,1.583H15.667A1.583,1.583,0,0,0,17.25,16.25V5.167a1.583,1.583,0,0,0-1.583-1.583m0,12.667H4.583V8.333H15.667V16.25m0-9.5H4.583V5.167H15.667V6.75m-5.542,3.958h3.958v3.958H10.125Z"
                fill="#606060"
                transform="translate(-3 -2)"
            />
        </svg>)
};
export default CalenderIcon;