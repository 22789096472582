import { Dialog } from '@progress/kendo-react-dialogs';
import { useEffect, useState } from 'react';
import { SubmitStatus } from '../../components/formik-model-dialog.component';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { usePostData } from '../../hooks/use-post-data.hook';
import { EntityImage } from '../../models/building-image/entity-image';
import { EntityImageData } from '../../models/building-image/entity-image-data';

import { Shape } from '../../models/building-image/image-draw.types';
import { Building } from '../../models/claim/building';
import { EntityType } from '../../models/claim/entity-type';
import { UnitDoor } from '../../models/claim/unit-door';
import { UnitWindow } from '../../models/claim/unit-window';
import { EditDoor } from '../building/door/edit-door.component';
import { EditWindow } from '../building/window/edit-window.component';

interface EditShapeDetailsModelDialogProps {
    currentImage: EntityImage;
    building: Building;
    shapeData: Shape;
    onClose: (shape: Shape) => any;
}

export const CreateShapeDetailsModelDialog = ({
    currentImage,
    building,
    shapeData,
    onClose,
}: EditShapeDetailsModelDialogProps) => {
    const [entityType, setEntityType] = useState<EntityType>(shapeData.tagData?.entityType);
    const [entity, setEntity] = useState<any>();
    const [doorData, setEditDoor] =
        useState<{ building: Building; editDoor: UnitDoor; shapeData?: Shape }>();
    const [windowData, setEditWindow] =
        useState<{ building: Building; window: UnitWindow; shapeData?: Shape }>();

    const {
        submitData,
        response: { isSubmitting, result: submitImageResult, error: submitError },
    } = usePostData<any>('image/cordinates', 'POST');

    useEffect(() => {
        if (entityType) {
            if (!shapeData.tagData) {
                entityType === EntityType.Window ? createNewWindow() : createNewDoor();
            } else {
                linkImageData(shapeData, shapeData.tagData.entityId);
            }
        }
    }, [entityType]);

    useEffect(() => {
        if (submitImageResult) {
            if (!shapeData.tagData) {
                const data: EntityImageData = {
                    iD: submitImageResult.id,
                    entityId: submitImageResult.entityID,
                    entityType: submitImageResult.entityType,
                    entityName: entity.doorName || entity.windowName,
                    point1: shapeData.point1,
                    point2: shapeData.point2,
                };
                onClose({ ...shapeData, colorTags: [], tagData: data });
            } else {
                onClose({ ...shapeData });
            }
        }
    }, [submitImageResult]);

    const handleEntityTypeSelect = (type: any) => {
        setEntityType(type);
    };

    const handleClose = () => {
        onClose(null);
    };

    const handleSaveDoorComplete = (status: SubmitStatus, data: UnitDoor) => {
        if (status === SubmitStatus.Completed) {
            setEntity(data);
            linkImageData(shapeData, data.doorID);
        } else {
            onClose(null);
        }
    };

    const handleSaveWindowComplete = (status: SubmitStatus, data: UnitWindow) => {
        if (status === SubmitStatus.Completed) {
            setEntity(data);
            linkImageData(shapeData, data.windowID);
        } else {
            onClose(null);
        }
    };

    const createNewDoor = () => {
        const selectedDoor: UnitDoor = {
            roomID: 0, // room.roomID,
            doorID: 0,
            doorName: '',
            direction: currentImage.direction,
            notes: '',
            images: 0,
            attributes: [],
            observations: [],
        };
        setEditDoor({ building: building, editDoor: selectedDoor });
    };

    const createNewWindow = () => {
        const selectedWindow: UnitWindow = {
            roomID: 0, //room.roomID,
            windowID: 0,
            windowName: '',
            direction: currentImage.direction,
            notes: '',
            images: 0,
            attributes: [],
            observations: [],
        };
        setEditWindow({ building: building, window: selectedWindow });
    };

    const linkImageData = (newImageData: Shape, entityId: number) => {
        submitData({
            data: {
                iD: newImageData.tagData ? newImageData.tagData.iD : 0,
                baseImageId: currentImage.imageID,
                entityId: entityId,
                entityType: entityType,
                point1: newImageData.point1,
                point2: newImageData.point2,
                shapeJsonData: JSON.stringify({
                    point1: newImageData.point1,
                    point2: newImageData.point2,
                }),
            },
        });
    };

    if (isSubmitting) {
        return (
            <Dialog>
                <LoadingIndicator isLoading={isSubmitting} />
            </Dialog>
        );
    }

    if (entityType) {
        return (
            <div>
                {doorData && <EditDoor data={doorData} onClose={handleSaveDoorComplete} />}
                {windowData && <EditWindow data={windowData} onClose={handleSaveWindowComplete} />}
            </div>
        );
    }

    return (
        <Dialog title="New Window/Door" onClose={handleClose}>
            <div>
                <div className="select">
                    <select
                        className="form-control"
                        onChange={(value) => handleEntityTypeSelect(value.target.value)}
                    >
                        <option key={'Please Select'} label={'Please select'} />
                        {[EntityType.Window, EntityType.Door].map((o) => {
                            return <option key={o} value={o} label={o} />;
                        })}
                    </select>
                </div>
                <LoadingIndicator isLoading={isSubmitting} />
            </div>
        </Dialog>
    );
};
