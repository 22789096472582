import { Route, Routes } from 'react-router-dom';
import { HomeOwnerRoutes } from './home-owner-routes';
import { InspectorRoutes } from './inspector-routes';
import { MainRoutes } from './main-routes';
import HomeOwnerAvailability from '../features/scheduler/home-owner-availability';
import Profile from '../features/scheduler/claim-profile';
import { User } from '../models/user-security/user';
import { UsersPage } from '../features/user-management/user/users.page';
import { RolePage } from '../features/user-management/role.page';
import { SchedulerHeaderComponent } from './scheduler.header.component';
import AgingReport from '../features/scheduler/report-aging';
import SLAList from '../features/scheduler/sla-list';


export const RouterView = () => {
    return (
        <Routes>
            <Route
                path="/*"
                element={<MainRoutes menuType="scheduler" />}
            />
            <Route
                path="/homeowner/*"
                element={<HomeOwnerRoutes />}
            />
            <Route
                path="/inspector/*"
                element={<InspectorRoutes />}
            />
            <Route

                path="/profile"
                element={<><SchedulerHeaderComponent showMenu={true} /><Profile /></>}
            />
            <Route
                path="/aging-report"
                element={
                    <><SchedulerHeaderComponent showMenu={true} /><AgingReport /></>
                }
            />
            <Route
                path="/sla"
                element={
                    <><SchedulerHeaderComponent showMenu={true} /><SLAList /></>
                }
            />
            <Route
                path="/users"
                element={
                    <><SchedulerHeaderComponent showMenu={true} /><UsersPage /></>
                }
            />
            <Route
                path="/roles"
                element={<><SchedulerHeaderComponent showMenu={true} /><RolePage /></>}
            />
        </Routes>
    );
};
