import { Dialog } from '@progress/kendo-react-dialogs';
import { Card, CardImage, CardSubtitle } from '@progress/kendo-react-layout';
import { ListBox } from '@progress/kendo-react-listbox';
import { useEffect, useState } from 'react';
import { ImageEditorComponent } from './image-editor.component';
import '../../style/image-list-model-dialog.component.scss';
import {
    DeletePostDataInfo,
    DeleteStatus,
    DeleteModelDialog,
} from '../../components/delete-model-dialog.component';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { FILE_STORAGE_URL } from '../../constants';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { EntityType } from '../../models/claim/entity-type';
import { KeyStoneFile } from '../../models/claim/keystone-file';
import { addOrUpdateArrayItem, deleteArrayItem } from '../../utils/array.util';
import { SubmitStatus } from '../../components/formik-model-dialog.component';
import { usePermission } from '../../hooks/use-permission.hook';
import { PermissionName } from '../../models/user-security/permission.name';

interface ImageMasterData {
    entityType: EntityType;
    entityId: number;
}

interface ImageListModelDialogProps {
    data: ImageMasterData;
    onCancel: any;
}

export const ImageListModelDialog = ({ data, onCancel }: ImageListModelDialogProps) => {
    const [imageToEdit, setImageToEdit] = useState<KeyStoneFile>();
    const [imageToDelete, setImageToDelete] = useState<DeletePostDataInfo>();
    const { isLoading, result, isError } = useLoadData<KeyStoneFile[]>(
        `file?EntityType=${data.entityType}&EntityId=${data.entityId}&FileType=image`,
    );
    const [images, setImages] = useState<KeyStoneFile[]>();
    const { hasPermission: hasEditPermission } = usePermission([PermissionName.EditWindow, PermissionName.EditDoor]);

    useEffect(() => {
        if (result) {
            setImages([...result]);
        }
    }, [result]);

    const onImageEditCancel = () => {
        setImageToEdit(null);
    };

    const onDeleteImageClick = (image: KeyStoneFile) => {
        const { fileID } = image;
        setImageToDelete({
            data: image,
            route: `file/${fileID}`,
        });
    };

    const onImageSaveCompleted = (status: SubmitStatus, image: KeyStoneFile) => {
        if (status === SubmitStatus.Completed) {
            const updatedImages = addOrUpdateArrayItem(images, image, 'fileID');
            setImages([...updatedImages]);
            setImageToEdit(null);
        }
    };

    const onDeleteComplete = (status: DeleteStatus, data: KeyStoneFile) => {
        if (status == DeleteStatus.Completed) {
            const updatedImages = deleteArrayItem(images, data, 'fileID');
            setImages([...updatedImages]);
        }
        setImageToEdit(null);
        setImageToDelete(null);
    };

    const onItemClick = (imageData: KeyStoneFile) => {
        if(hasEditPermission)
        setImageToEdit(imageData);
    };

    const onCancelPopup = () => {
        if (imageToEdit) {
            setImageToEdit(null);
        } else {
            onCancel();
        }
    };

    const windowTitle = `${data.entityType} Photos`;

    const MyItemRender = ({ dataItem }: any) => {
        return (
            <Card
                style={{
                    width: 180,
                    boxShadow: 'none',
                    flex: '0 0 15.33%',
                    margin: 25,
                    border: 'none',
                }}
            >
                <div onClick={() => onItemClick(dataItem)}>
                    <CardImage src={dataItem.thumbCDN} style={{ height: 150, width: 180 }} />
                </div>
                <div style={{ padding: 0 }}>
                    <CardSubtitle style={{ fontSize: 12, marginTop: 0 }}>
                        {dataItem.comments}
                    </CardSubtitle>
                </div>
            </Card>
        );
    };

    return (
        <>
            {data && (
                <Dialog title={windowTitle} className="image-list-dialog" onClose={onCancelPopup}>
                    <div>
                        <LoadingIndicator isLoading={isLoading} />
                        {isError && <div>Error loading data</div>}
                        {images &&
                            !imageToEdit &&
                            (images.length > 0 ? (
                                <ListBox
                                    data={images}
                                    item={MyItemRender}
                                    textField="path"
                                    selectedField="isSelected"
                                />
                            ) : (
                                'No images'
                            ))}
                        {imageToEdit && (
                            <ImageEditorComponent
                                image={imageToEdit}
                                onCancel={onImageEditCancel}
                                onDeleteImage={onDeleteImageClick}
                                onSubmitImage={onImageSaveCompleted}
                            />
                        )}
                        {imageToDelete && (
                            <DeleteModelDialog
                                title="Delete Image"
                                deleteData={imageToDelete}
                                onClose={onDeleteComplete}
                            >
                                <div>
                                    Are you sure you want to delete the image -
                                    {imageToDelete.data.fileName}?
                                </div>
                            </DeleteModelDialog>
                        )}
                    </div>
                </Dialog>
            )}
        </>
    );
};
