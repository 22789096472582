import React, { useEffect, useRef, useState } from 'react';
import { useLoadData } from '../hooks/use-load-data.hook';
import { Building } from '../models/claim/building';
import MultiSelectField from './multiselect-report.component'; // Import the custom component
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingIndicator } from './loading-indicator.component';
import axios from 'axios';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { StorageKey, WINDOWS_DOORS_API_URL } from '../constants';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { GetBuildingDetail } from '../redux/actions/buildingactions';
import "../style/multi-select-report.scss";
import { InspectionReportParams } from '../models/reports/inspection-report';
import SingleSelectDropdown from './single-select.component';
import { isEmpty } from 'lodash';
import { Grid, GridColumn, GridNoRecords, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { DeleteFiles, IDownloadFileResponse } from '../utils/downloadfileutil';
import DeleteIcon from '../images/delete';
import DownloadIcon from '../images/download-icon';
import { DataResult, process } from '@progress/kendo-data-query';
import EyeIcon from '../images/eye';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ZoomIcon from '../images/zoom';
const InspectionReport: React.FC = () => {
    const { state } = useLocation();
    const nav = useNavigate();
    const { result, isError } = useLoadData<Building[]>(`Building/Claims/${state.claimGUID}`);
    const dispatch = useDispatch<AppDispatch>();
    const [selectedBuilding, setSelectedBuilding] = useState<{ label: string, value: string }[]>([]);
    const [buildings, setBuildings] = useState<Building[]>([]);
    const [floorOptions, setFloorOptions] = useState<{ label: string, value: string, group: string }[]>([]);
    const [selectedFloorOptions, setSelectedFloorOptions] = useState<{ label: string, value: string }[]>([]);
    const [unitOptions, setUnitOptions] = useState<{ label: string, value: string, group: string }[]>([]);
    const [selectedUnitOptions, setSelectedUnitOptions] = useState<{ label: string, value: string }[]>([]);
    const [buildingOptions, setBuildingOptions] = useState<{ label: string, value: string }[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [report, setReport] = useState<{ isReport: boolean, src: string, loadingReport: boolean, iserr: boolean, isempty: boolean }>({ isReport: false, src: "", loadingReport: false, isempty: false, iserr: false });
    const [buildingDataArray, setBuildingDataArray] = useState<Building[]>([]);
    const [directions, setdirections] = useState<{ label: string, value: string }[]>([]);
    const [entityType, setEntityType] = useState<{ label: string, value: string }[]>([]);
    const [hasDamages, sethHasDamages] = useState<string>("");
    const [isPerils, setIsPerils] = useState<string>("");
    const [error, setError] = useState<string>('');
    const [downloadList, setDownloadList] = useState<IDownloadFileResponse[]>([]);
    const downloadListRef = useRef<IDownloadFileResponse[]>([]);
    const [confirmDelete, setConfirmDelete] = useState<{ isConfirm: boolean, filename: IDownloadFileResponse }>({ filename: undefined, isConfirm: false })
    const [deleting, setdeleting] = useState<{ deleting: boolean, isSuccess: boolean }>({ deleting: false, isSuccess: false });
    const [deletemsg, setdeletemsg] = useState<string>("Are you sure you want to delete this file? This action is irreversible.")
    const [isprocessing, setIsProcessing] = useState<boolean>(false);
    const refreshIntervalId = useRef<NodeJS.Timeout | null>(null);
    const [alreadyInProcess, setAlreadyInProcess] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [refreshData, setRefreshData] = useState<boolean>(false);



    const [gridData, setGridData] = useState<DataResult>({ data: [], total: 0 });
    const [dataState, setDataState] = useState({
        skip: 0,
        take: 8,
    });

    const handleDelete = async (id: number) => {
        setdeleting({ deleting: true, isSuccess: false });
        const isDeleted = await DeleteFiles(id);
        if (isDeleted) {

            let filteredfiles = downloadList.filter(e => e.logId !== id);
            setDownloadList(filteredfiles);
            setdeleting({ deleting: false, isSuccess: true });
            setConfirmDelete({ filename: undefined, isConfirm: false })
        }
        else {
            setdeleting({ deleting: false, isSuccess: false });
            setdeletemsg("Unable to delete.")
        }
    };


    useEffect(() => {
        let DataRes = process(downloadList, dataState);
        setGridData(DataRes);
    }, [downloadList, dataState]);

    const handleDataStateChange = (e: GridPageChangeEvent) => {
        let DataRes = process(downloadList, dataState);
        setGridData(DataRes);
        return setDataState({ skip: e.page.skip, take: e.page.take });
    };



    useEffect(() => {


        if (downloadListRef.current.some(e => e.processStatus === 0)) {

            if (refreshIntervalId !== null) {
                clearInterval(refreshIntervalId.current);
            }
            initializeTimer();
        }
        else {
            if (refreshIntervalId.current !== null) {
                clearInterval(refreshIntervalId.current);
            }
            GetFileDownLoadLogs();
        }


        return () => {
            if (refreshIntervalId.current) {
                clearInterval(refreshIntervalId.current);
            }

        };
    }, [selectedTab, refreshData]);

    const tabRef = useRef<number>(0);




    const initializeTimer = () => {
        const intervalId = setInterval(() => {
            GetFileDownLoadLogs();
        }, 10000); // Refresh every 10 seconds

        refreshIntervalId.current = intervalId;
    }


    const GetFileDownLoadLogs = async () => {
        let data: IDownloadFileResponse[] = [];
        setIsProcessing(true);
        const res = await axios.get(tabRef.current === 0 ?
            `${WINDOWS_DOORS_API_URL}/api/File/GetFileDownloadLogs?entityID=${state.claimID}&entityType=inspectionreport`
            : `${WINDOWS_DOORS_API_URL}/api/File/GetFileDownloadLogs?entityID=${state.claimID}&entityType=photoreport`).then((res) => {
                if (res.data) {
                    data = res.data;
                    downloadListRef.current = res.data;
                    setIsProcessing(false);
                    setDownloadList(res.data as IDownloadFileResponse[]);



                }
                else {
                    setIsProcessing(false);
                    downloadListRef.current = [];

                    data = res.data;

                }

            }).catch((reason) => {
                console.log(reason);
                setIsProcessing(false);
                downloadListRef.current = [];

                data = [];


            })
        if (data.length > 0 && data.some(e => e.processStatus === 0)) {
            setRefreshData(!refreshData);
        }
        else {

            clearInterval(refreshIntervalId.current);

        }
        return data as IDownloadFileResponse[];

    }


    const handleBuildingChange = async (selectedValues: { label: string, value: string }[]) => {
        setSelectedFloorOptions([]);
        setSelectedUnitOptions([]);
        setSelectedBuilding(selectedValues);
        setError('');


        // Fetch detailed building data for selected buildings
        const buildingDataArray: Building[] = [];
        for (const building of selectedValues) {
            const response = await dispatch(GetBuildingDetail(parseInt(building.value)));
            const buildingData = response.payload as Building;
            if (buildingData) {
                buildingDataArray.push(buildingData);
            }
        }

        // Update floors based on selected buildings
        const floorOptions: { label: string, value: string, group: string }[] = [];

        buildingDataArray.forEach(building => {

            building.floors?.forEach(floor => {
                // Group floors under each building
                floorOptions.push({
                    label: `${floor.floorName}(${floor.floorNumber})`,
                    value: floor.floorID?.toString(),
                    group: building.buildingName,
                });
            });
        });

        setFloorOptions(floorOptions);
        setBuildingDataArray(buildingDataArray);

    };

    const handleFloorChange = (selectedValues: { label: string, value: string }[]) => {
        setSelectedUnitOptions([]);
        setSelectedFloorOptions(selectedValues);

        // Update units based on selected floors
        const unitOptions: { label: string, value: string, group: string }[] = [];
        buildingDataArray.forEach(building => {
            building.floors?.forEach(floor => {
                if (selectedValues.some(selectedFloor => selectedFloor.value === floor.floorID?.toString())) {
                    floor.units?.forEach(unit => {
                        // Group units under each floor
                        unitOptions.push({
                            label: `${unit.unitName}(${unit.unitNumber})`,
                            value: unit.unitID?.toString(),
                            group: floor.floorName,
                        });
                    });
                }
            });
        });

        setUnitOptions(unitOptions);
    };
    const handleSelectTab = (e: TabStripSelectEventArguments) => {


        tabRef.current = e.selected;

        // setTableEntity(e.selected === 1 ? photoreport : inspectionReport);
        GetFileDownLoadLogs();
        setSelectedTab(e.selected);
    }
    if (isError) {
        return <div>Error Loading Data for Generating report</div>;
    } else {
        useEffect(() => {
            if (result) {
                setBuildings(result);

                let buildingOptions = result.map(e => ({
                    label: e.buildingName,
                    value: e.buildingID?.toString(),
                }));

                setBuildingOptions(buildingOptions);
                setLoading(false);
            }
        }, [result, report]);


        function handleDownload(fileCDN: any, fileName: any): void {
            fetch(fileCDN)
                .then((response) => response.blob())
                .then((blob) => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch((error) => {
                    alert('No File Found');
                    console.error('Error downloading file:', error);
                }
                );
        }
        function handlePreview(fileCDN: any, fileName: string): void {
            fetch(fileCDN)
                .then((response) => response.blob())
                .then((blob) => {
                    const blobUrl = window.URL.createObjectURL(blob);
                    const newWindow = window.open("", '_blank');

                    if (newWindow) {
                        const title = `<title>${fileName}</title>`;
                        const iframe = `<iframe src="${blobUrl}" title="${fileName}" style="width:100%; height:100%; border:none;"></iframe>`;
                        const doc = `
                            <!DOCTYPE html>
                            <html>
                                <head>
                                    ${title}
                                    <style>
                                        body { margin: 0; padding: 0; display: flex; flex-direction: column; height: 100vh; }
                                        header { padding: 10px; background: #f0f0f0; text-align: center; font-size: 20px; font-weight: bold; }
                                        iframe { flex: 1; }
                                    </style>
                                </head>
                                <body>
                                    <header>${fileName}</header>
                                    ${iframe}
                                </body>
                            </html>
                        `;

                        newWindow.document.write(doc);
                        newWindow.document.close();
                    }
                })
                .catch((error) => console.error('Error previewing file:', error));
        }





        return (<>
            <div className='container-fluid' >
                <h5 className='mt-3 appsubhead'>Generate Report</h5>
                <LoadingIndicator isLoading={loading} />
                {!loading && (

                    <div className="row justify-content-sapce-evenly border p-2 m-2">
                        <div className="col-4 mt-1" id='building'>
                            <label htmlFor="building">Building*</label>
                            <MultiSelectField

                                placeholder='Building'
                                optionValues={buildingOptions}
                                onChange={
                                    handleBuildingChange

                                }
                            />
                            <div className='text-danger'>{error}</div>
                        </div>

                        <div className="col-4 mt-2" id='floor'>
                            <label htmlFor="floor">Floor</label>
                            <MultiSelectField
                                isAll
                                placeholder='Floor'
                                groupBy
                                optionValues={floorOptions}
                                onChange={handleFloorChange}
                            />
                        </div>

                        <div className="col-4 mt-1" id='Unit'>
                            <label htmlFor="Unit">Unit</label>
                            <MultiSelectField
                                isAll
                                placeholder='Unit'
                                groupBy
                                optionValues={unitOptions}
                                onChange={selectedValues => {
                                    setSelectedUnitOptions(selectedValues);
                                    console.log('Selected Units:', selectedValues);
                                }}
                            />
                        </div>

                        <div className="col-4 mt-1" id='direction'>
                            <label htmlFor="direction">Direction</label>
                            <MultiSelectField
                                placeholder='Direction'
                                isAll
                                optionValues={[
                                    { label: 'North', value: 'North' },
                                    { label: 'East', value: 'East' },
                                    { label: 'West', value: 'West' },
                                    { label: 'South', value: 'South' },
                                    { label: 'North-East', value: 'North-East' },
                                    { label: 'South-West', value: 'South-West' },
                                ]}
                                onChange={(selectedValues) => {
                                    setdirections(selectedValues);
                                }}
                            />
                        </div>

                        <div className="col-4 mt-1" id='perilrelated'>
                            <label htmlFor="perilrelated">Peril Related</label>
                            <SingleSelectDropdown id='perilrelated' onchange={(value: string) => {
                                setIsPerils(value)
                            }} />
                        </div>


                        <div className="col-4 mt-1" id='hasdamage'>
                            <label htmlFor="hasdamage">Has Damage</label>
                            <SingleSelectDropdown id='hasdamage' onchange={(value: string) => {
                                sethHasDamages(value)
                            }} />
                        </div>
                        <div className="col-4 mt-1" id='FenestrationType'>
                            <label htmlFor="FenestrationType">Fenestration Type</label>
                            <MultiSelectField
                                isAll
                                placeholder='Fenestration Type'
                                optionValues={[
                                    { label: 'Window', value: '1' },
                                    { label: 'Door', value: '2' },
                                    { label: 'Railing', value: '3' }
                                ]}
                                onChange={selectedValues => {
                                    console.log(selectedValues);

                                    setEntityType(selectedValues)
                                }}
                            />
                        </div>

                        <div className='d-flex pt-3 pb-3'>
                            <button className="btn btn-outline-secondary btn-sm me-auto" onClick={() => { nav('/') }}>
                                Back
                            </button>

                            {/* Inspection Report Button */}
                            <button className='btn btn-outline-primary btn-sm me-2' type='submit'
                                onClick={async () => {
                                    if (!isEmpty(selectedBuilding)) {

                                        setSelectedTab(0);
                                        tabRef.current = 0;

                                        await GetFileDownLoadLogs().then(async (res) => {

                                            if (res.length > 0 && res.some(e => e.processStatus === 0)) {
                                                setAlreadyInProcess(true);
                                            }
                                            else {

                                                setIsProcessing(true);


                                                const authState = JSON.parse(localStorage.getItem(StorageKey.AuthState));
                                                const body: InspectionReportParams = {
                                                    buildings: selectedBuilding.map(e => e.value?.toString()).join(","),
                                                    claimNumber: buildings[0].claimGuid,
                                                    directions: directions.map(e => e.value).join(","),
                                                    fenestrationTypes: entityType.map(e => e.value).join(","),
                                                    floors: selectedFloorOptions.map(e => e.value).join(","),
                                                    hasDamaged: hasDamages,
                                                    isPeril: isPerils,
                                                    isBackGroundProcess: true,
                                                    hostValue: "",
                                                    baseURL: "",
                                                    units: selectedUnitOptions.map(e => e.value).join(","),
                                                }

                                                console.log(JSON.stringify(body));

                                                await axios.post(`${WINDOWS_DOORS_API_URL}/api/Reports/GetInspectionReport`, body, {
                                                    responseType: 'blob',

                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        Accept: '*/*',
                                                        Authorization: authState && `Bearer ${authState}`,
                                                    }
                                                }).then(onfulfilled => {
                                                    if (onfulfilled.data) {
                                                        GetFileDownLoadLogs().then(e => {
                                                            setTimeout(() => {
                                                                setIsProcessing(false);
                                                            }, 3000);


                                                        })

                                                    }
                                                    else {
                                                        setIsProcessing(false);
                                                    }
                                                }).catch(onrejected => {
                                                    setReport({ isReport: false, loadingReport: false, src: '', isempty: false, iserr: true })
                                                    console.log(onrejected);
                                                })
                                            }
                                        });

                                    }

                                    else {
                                        setError("One or more building needs to be selected");
                                    }

                                }}>Create Inspection Report</button>
                            {/* photo report button */}
                            <button className="btn btn-outline-primary btn-sm" style={{ marginLeft: '10px' }}
                                onClick={async () => {
                                    if (!isEmpty(selectedBuilding)) {
                                        setSelectedTab(1);
                                        tabRef.current = 1;
                                        //   setTableEntity(photoreport)


                                        await GetFileDownLoadLogs().then(async (res) => {

                                            if (res.length > 0 && res.some(e => e.processStatus === 0)) {
                                                setAlreadyInProcess(true);


                                            }
                                            else {

                                                setIsProcessing(true);
                                                const authState = JSON.parse(localStorage.getItem(StorageKey.AuthState));
                                                const body: InspectionReportParams = {
                                                    buildings: selectedBuilding.map(e => e.value?.toString()).join(","),
                                                    claimNumber: buildings[0].claimGuid,
                                                    directions: directions.map(e => e.value).join(","),
                                                    fenestrationTypes: entityType.map(e => e.value).join(","),
                                                    floors: selectedFloorOptions.map(e => e.value).join(","),
                                                    hasDamaged: hasDamages,
                                                    isPeril: isPerils,
                                                    isBackGroundProcess: true,
                                                    hostValue: "",
                                                    baseURL: "",
                                                    units: selectedUnitOptions.map(e => e.value).join(","),
                                                }
                                                console.log(JSON.stringify(body));

                                                await axios.post(`${WINDOWS_DOORS_API_URL}/api/Reports/GetPhotoReport`, body, {
                                                    responseType: 'blob',

                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        Accept: '*/*',
                                                        Authorization: authState && `Bearer ${authState}`,
                                                    }
                                                }).then(onfulfilled => {
                                                    console.log(onfulfilled.data)
                                                    if (onfulfilled.data) {
                                                        GetFileDownLoadLogs().then(e => {

                                                            setTimeout(() => {
                                                                setIsProcessing(false);
                                                            }, 3000);
                                                        });
                                                    } else {
                                                        setIsProcessing(false);
                                                    }
                                                }).catch(onrejected => {
                                                    setIsProcessing(false);
                                                    console.log(onrejected);
                                                });
                                            }

                                        });


                                    }



                                    else {
                                        setError("One or more buildings need to be selected!");
                                    }

                                }}>Create Photo Report</button>

                        </div>
                    </div>



                )}



                <h4 className='mt-4 mb-2 appsubhead'>Existing Report</h4>

                <div className="tabwhitespace inspectiontab reportpagetab">

                    <TabStrip

                        selected={selectedTab}
                        onSelect={handleSelectTab}
                    >

                        <TabStripTab
                            title="Inspection Report"
                            contentClassName="structureTab text-capitalize reportpagetab"
                            key="inspection_report"
                        >        {!isprocessing ?

                            <div style={{ overflow: "none" }}>

                                <div className="d-flex justify-content-end">
                                    <div>
                                        <button className="btn btn-outline-secondary btn-sm mb-2" onClick={() => {

                                            clearInterval(refreshIntervalId.current);


                                            GetFileDownLoadLogs();
                                        }}>
                                            Refresh
                                        </button>
                                    </div>
                                </div>

                                <Grid
                                    data={gridData.data}
                                    skip={dataState.skip}
                                    take={dataState.take}
                                    total={gridData.total}
                                    onPageChange={(e) => handleDataStateChange(e)}
                                    pageable={true}
                                    navigatable={false}
                                    expandField="expanded"
                                >
                                    <GridColumn field="fileName" title="File Name" />
                                    <GridColumn field="createdOn" title="Created On" />
                                    <GridColumn field="userName" title="User Name" />
                                    <GridColumn field="status" title="Status" />
                                    <GridNoRecords><div style={{ width: "100%", height: "300px" }}><center>
                                        No data found

                                    </center></div> </GridNoRecords>
                                    <GridColumn
                                        field="actions"
                                        title="Actions"
                                        width={200}

                                        cell={(props) => (
                                            <td style={{ textAlign: 'center' }}>
                                                <div>
                                                    <a className='mx-1' style={{ cursor: props.dataItem.processStatus === 1 ? 'pointer' : 'not-allowed', marginRight: '5px' }} title='Download Report' onClick={() => props.dataItem.processStatus === 1 ? handleDownload(props.dataItem.fileCDN, props.dataItem.fileName) : null}>
                                                        <DownloadIcon />
                                                    </a>
                                                    <a style={{ cursor: props.dataItem.processStatus === 1 ? 'pointer' : 'not-allowed', marginRight: '5px' }} title='Preview  Report' onClick={() => props.dataItem.processStatus === 1 ? handlePreview(props.dataItem.fileCDN, props.dataItem.fileName) : null} className="mx-3">
                                                        <ZoomIcon />
                                                    </a>
                                                    <a style={{ cursor: props.dataItem.processStatus === 1 || props.dataItem.processStatus === -1 ? 'pointer' : 'not-allowed', marginRight: '5px' }} title='Delete Report' onClick={() => props.dataItem.processStatus === 1 || props.dataItem.processStatus === -1 ? setConfirmDelete({ filename: props.dataItem, isConfirm: true }) : null} className="mx-3">
                                                        <DeleteIcon />
                                                    </a>

                                                </div>
                                            </td>
                                        )}
                                    />
                                </Grid>

                            </div> : <center> <LoadingIndicator isLoading /></center>


                            }
                        </TabStripTab>
                        <TabStripTab key={'Photo_tab'} title='Photo Report' contentClassName='structureTab text-capitalize'>
                            {!isprocessing ?

                                <div style={{ overflow: "none" }}>

                                    <div className="d-flex justify-content-end mb-2">

                                        <button className="btn btn-outline-secondary btn-sm" onClick={async () => {
                                            if (refreshIntervalId.current !== null) {
                                                clearInterval(refreshIntervalId.current);

                                            }
                                            setIsProcessing(true);
                                            await GetFileDownLoadLogs().then(() => {
                                                setIsProcessing(false);
                                            })
                                        }}>
                                            Refresh
                                        </button>

                                    </div>

                                    <Grid
                                        style={{ maxHeight: '500px', overflowY: 'hidden' }}
                                        data={gridData.data}
                                        skip={dataState.skip}
                                        take={dataState.take}
                                        total={gridData.total}
                                        onPageChange={(e) => handleDataStateChange(e)}
                                        pageable={true}
                                        navigatable={false}
                                        expandField="expanded"



                                    >
                                        <GridColumn field="fileName" title="File Name" />
                                        <GridColumn field="createdOn" title="Created On" />
                                        <GridColumn field="userName" title="User Name" />
                                        <GridColumn field="status" title="Status" />
                                        <GridNoRecords><div style={{ width: "100%", height: "500px" }}><center>
                                            No data found

                                        </center></div> </GridNoRecords>
                                        <GridColumn
                                            width={"200px"}
                                            field="actions"
                                            title="Actions"

                                            cell={(props) => (
                                                <td style={{ textAlign: 'center' }}>
                                                    <div>

                                                        <OverlayTrigger

                                                            overlay={(propss) => (
                                                                <Tooltip {...propss}>
                                                                    Download report
                                                                </Tooltip>
                                                            )}
                                                            placement="bottom"
                                                        >
                                                            <a className='mx-3' style={{ cursor: props.dataItem.processStatus === 1 ? 'pointer' : 'not-allowed', marginRight: '5px' }} onClick={() => props.dataItem.processStatus === 1 ? handleDownload(props.dataItem.fileCDN, props.dataItem.fileName) : null}>
                                                                <DownloadIcon />
                                                            </a>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger

                                                            overlay={(propss) => (
                                                                <Tooltip {...propss}>
                                                                    Preview report
                                                                </Tooltip>
                                                            )}
                                                            placement="bottom"
                                                        >
                                                            <a className='mx-3' style={{ cursor: props.dataItem.processStatus === 1 ? 'pointer' : 'not-allowed', marginRight: '5px' }} onClick={() => { props.dataItem.processStatus === 1 ? handlePreview(props.dataItem.fileCDN, props.dataItem.fileName) : null }}>
                                                                <ZoomIcon />
                                                            </a>
                                                        </OverlayTrigger>

                                                        <OverlayTrigger

                                                            overlay={(propss) => (
                                                                <Tooltip {...propss}>
                                                                    Delete report
                                                                </Tooltip>
                                                            )}
                                                            placement="bottom"
                                                        >
                                                            <a style={{ cursor: props.dataItem.processStatus === 1 || props.dataItem.processStatus === -1 ? 'pointer' : 'not-allowed', marginRight: '5px' }} onClick={() => { props.dataItem.processStatus === 1 || props.dataItem.processStatus === -1 ? setConfirmDelete({ filename: props.dataItem, isConfirm: true }) : null; }} className="mx-3">
                                                                <DeleteIcon />
                                                            </a>
                                                        </OverlayTrigger>



                                                    </div>
                                                </td>
                                            )}
                                        />
                                    </Grid>

                                </div> : <LoadingIndicator isLoading />

                            }</TabStripTab>
                    </TabStrip>
                </div>

            </div>
            {/* delete alert */}
            {
                confirmDelete.isConfirm && (<Dialog width={"30%"} onClose={() => {
                    setConfirmDelete({ filename: undefined, isConfirm: false })
                }} children={<>
                    <div style={{ minHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>  {!deleting.deleting ? <h5 style={{ textAlign: 'center', maxWidth: 350 }} > {deletemsg}</h5> : <LoadingIndicator isLoading={true} />}</div>
                    {<DialogActionsBar>
                        <div className='row'>
                            <div className='d-flex justify-content-between' >
                                <button className='btn btn-secondary btn-sm' onClick={() => {
                                    setConfirmDelete({ filename: undefined, isConfirm: false })
                                }}>Cancel</button>
                                {deletemsg === "Are you sure you want to delete this file? This action is irreversible." ? <button className='btn btn-primary btn-sm' onClick={() => handleDelete(confirmDelete.filename.logId)}>Delete</button>
                                    : null}
                            </div>
                        </div>

                    </DialogActionsBar>}

                </>} title={"Delete PDF File"} />)
            }
            {/* already in process alert */}

            {alreadyInProcess && (<Dialog onClose={() => {
                setAlreadyInProcess(false);
            }} children={<>
                A process is already running.please wait...
                {<DialogActionsBar>
                    <div className='row'>
                        <div className='d-flex justify-content-end'>
                            <button className='btn btn-secondary btn-sm' onClick={() => {
                                setAlreadyInProcess(false);
                            }}>Ok</button>


                        </div>
                    </div>

                </DialogActionsBar>}

            </>} title={"Processing"} />)
            }
        </>

        );
    }
};

export default InspectionReport;
