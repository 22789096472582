import React, { useState } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Checkbox } from '@progress/kendo-react-inputs';
import { ImgFile } from '../../utils/uploadfile.util';
import StarIcon from '../../images/star-image';
import DeleteIcon from '../../images/delete';

interface ImageListProps {
    images: ImgFile[];
    selectedImages: ImgFile[];
    onSelectImage: (selectedImages: ImgFile) => void;
    isChkBox?: boolean;
    isDelete?: boolean;
    onRemove: (unselectedImage: ImgFile) => void;
    onDeleteImage?: (image: ImgFile) => void;
}

const ImageList: React.FC<ImageListProps> = ({
    images,
    selectedImages,
    isChkBox,
    isDelete,
    onSelectImage,
    onRemove,
    onDeleteImage,
}) => {
    const handleSelectImage = (image: ImgFile) => {
        onSelectImage(image); // Pass the selected/unselected file
    };

    const handleRemove = (image: ImgFile) => {
        onRemove(image);
    };

    const handleDeleteImage = (image: ImgFile) => {
        if (onDeleteImage) {
            onDeleteImage(image);
        }
    };

    const handleClickImage = (image: ImgFile) => {
        if (isChkBox) {
            if (selectedImages.includes(image)) {
                handleRemove(image);
            } else {
                handleSelectImage(image);
            }
        }
    };

    return (
        <div
            style={{
                maxHeight: '300px',
                maxWidth: (window.outerWidth * 0.5).toString(),
                overflow: 'auto',
                margin: 0,
                padding: '0px',
            }}
        >
            <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                {images && images.length > 0 ? (
                    images.map((image, index) => (
                        <div key={index} className='image-thumb' style={{ position: 'relative', margin: '5px' }}>
                            {isChkBox && (
                                <div style={{ position: 'absolute', top: '5px', left: '5px' }}>
                                    <Checkbox
                                        checked={selectedImages.includes(image)}
                                        onChange={() => {
                                            if (selectedImages.includes(image)) {
                                                handleRemove(image);
                                            } else {
                                                handleSelectImage(image);
                                            }
                                        }}
                                    />
                                </div>
                            )}
                            {image.isbaseImg && (
                                <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
                                    <StarIcon />
                                </div>
                            )}
                            <OverlayTrigger
                                overlay={(props) => (
                                    <Tooltip {...props}>
                                        {image.comment}
                                    </Tooltip>
                                )}
                                placement="top"
                            >
                                <img
                                    src={URL.createObjectURL(image.file)}
                                    key={index}
                                    width={120}
                                    height={120}
                                    alt={image.file.name}
                                    style={{ objectFit: 'cover', borderRadius: '5px', cursor: isChkBox ? 'pointer' : 'default' }}
                                    onClick={() => handleClickImage(image)}
                                />
                            </OverlayTrigger>
                            {isDelete && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '5px',
                                        right: '5px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => handleDeleteImage(image)}
                                >
                                    <DeleteIcon />
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <div className='alertDesign' style={{ margin: '0 auto' }}>
                        <div className='images'>
                        </div>
                        <h5>There are no photos associated with this level.</h5>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageList;
