import React, { useEffect, useState } from 'react';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import '@progress/kendo-theme-default/dist/all.css';
import { text } from 'stream/consumers';
interface TimeDataItem {
    text: string;
    value: string;
}
interface ITimeSlotDropdown {
    dataitems: TimeDataItem[];
    onchange: Function;
    id: string;
    placeholder?: string;
    isDef?: TimeDataItem;
    isDisabled: boolean;
}

const TimeDropdown: React.FC<ITimeSlotDropdown> = ({ ...props }) => {
    const { onchange, id, dataitems, placeholder = "Select", isDef, isDisabled } = props;
    const [selectedItem, setSelectedItem] = useState<TimeDataItem | null>(isDef);
    const handleChange = (event: DropDownListChangeEvent) => {
        setSelectedItem(event.target.value as TimeDataItem);
        onchange(event.target.value.value as string);
    };

    return (
        <div>
            <DropDownList fillMode='solid'
                data={dataitems}
                id={id}
                key={id}
                name={id}
                disabled={isDisabled}
                textField="text"
                dataItemKey="value"
                defaultItem={isDef ? isDef : { text: "Select Option", value: "-1" }}
                onChange={handleChange}
                value={selectedItem}
                className='form-control dropDownList'
            />
            {/* {selectedItem && <div>Selected option: {selectedItem.text}</div>} */}
        </div>
    );
};

export default TimeDropdown;
