import { BrowserRouter as Router } from 'react-router-dom';
import { AppContextProvider } from './core/app.context';
import { FooterComponent } from './core/footer.component';
import { HeaderComponent } from './core/header.component';
import { RouterView } from './core/router.view.component';
import { AuthorizeComponent } from './core/authorize.component';
import '@progress/kendo-theme-default/dist/all.css';
import './App.scss';
import { Provider } from 'react-redux';
import appstore from './store';
import APIProvider from './api-client.ts/api-provider';
import { useEffect } from 'react';
import { registerAxiosIntercepts, setAxiosAuthHeaders } from './api-client.ts/axios';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';

const App = () => {
    useEffect(() => {
        registerAxiosIntercepts();
        setAxiosAuthHeaders();
    }, []);
    return (
        <AppContextProvider>
            <APIProvider>
                <Provider store={appstore}>
                    <Router>
                        <div className="wrapper bg-gray-50">
                            <RouterView />
                        </div>
                        <FooterComponent />
                        <ToastContainer />
                    </Router>
                </Provider>
            </APIProvider>
        </AppContextProvider>
    );
};

export default App;
