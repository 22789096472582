import { PermissionName } from '../models/user-security/permission.name';
import { every, includes, isArray, some } from 'lodash';
import { PermissionCheckType } from '../models/user-security/permission-check-type';

export const validateRequiredPermissions = (
    requiredPermission: PermissionName | PermissionName[],
    permissionCheckType: PermissionCheckType,
    currentUserPermissions: PermissionName[],
): boolean => {
    const permissions = isArray(requiredPermission) ? requiredPermission : [requiredPermission];
    switch (permissionCheckType) {
        case PermissionCheckType.Any:
            return some(permissions, (p) => includes(currentUserPermissions, p));
        case PermissionCheckType.All:
            console.log(every(permissions, (p) => includes(currentUserPermissions, p)));
            let isavailable:boolean=every(permissions, (p) => includes(currentUserPermissions, p));
            return isavailable;
        default:
            return false;
    }
};
