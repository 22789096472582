import { SubmitStatus } from '../../components/formik-model-dialog.component';
import { Shape } from '../../models/building-image/image-draw.types';
import { Building } from '../../models/claim/building';
import { EntityType } from '../../models/claim/entity-type';
import { UnitDoor } from '../../models/claim/unit-door';
import { UnitWindow } from '../../models/claim/unit-window';
import { EditDoor } from '../building/door/edit-door.component';
import { EditWindow } from '../building/window/edit-window.component';

interface EditShapeDetailsModelDialogProps {
    building: Building;
    entity: any;
    onClose: (status: SubmitStatus, data: any) => any;
}

export const EditWindowDoorWrapperComponent = ({
    building,
    entity,
    onClose,
}: EditShapeDetailsModelDialogProps) => {
    const getEntityType = () => {
        return entity.windowID ? EntityType.Window : EntityType.Door;
    };

    if (getEntityType() === EntityType.Window) {
        return <EditWindow data={{ building: building, window: entity }} onClose={onClose} />;
    }
    if (getEntityType() === EntityType.Door) {
        return <EditDoor data={{ building: building, editDoor: entity }} onClose={onClose} />;
    }
    return null;
};
