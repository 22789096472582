import type { AxiosError } from 'axios';
import axios from 'axios';
import { createQuery } from 'react-query-kit';
import { IInspector } from '../../models/scheduler/inspector';


type Params = {
    claimId: number;
}

type Response = {
    Status: string
}

export const useClaimStatus = createQuery<Response, Params, AxiosError>({
    queryKey: ['claimStatus'],
    fetcher: (params) =>
        axios.get('SchedulerAssignments/GetClaimStatus', { params }).then(response => response.data?.data),
    refetchOnWindowFocus: false
});