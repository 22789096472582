import * as React from 'react';

interface ISchedulerDetailsProps {
}

const SchedulerDetails: React.FunctionComponent<ISchedulerDetailsProps> = (props) => {
    return (<>

        <center>
            Scheduler Details
        </center>

    </>);
};

export default SchedulerDetails;
