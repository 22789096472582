import { Dialog } from '@progress/kendo-react-dialogs';
import { find, isEmpty, isNil, map, remove, result, some } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { usePostData } from '../../hooks/use-post-data.hook';
import { EntityImage } from '../../models/building-image/entity-image';
import { EntityImageData } from '../../models/building-image/entity-image-data';
import { Shape } from '../../models/building-image/image-draw.types';
import { EntityType } from '../../models/claim/entity-type';
import { addOrUpdateArrayItem } from '../../utils/array.util';
import { ImageDrawingComponent } from './image-drawing/image-drawing.component';

interface LinkImageModelDialogProps {
    buildingId: number;
    linkImageData: {
        entityType: EntityType;
        entityId: number;
        entityName: string;
        direction: string;
    };
    title: string | ReactElement<any>;
    onClose?: () => any;
}

export const LinkImageModelDialog = ({
    buildingId,
    linkImageData,
    title,
    onClose,
}: LinkImageModelDialogProps) => {
    const [buildingImage, setBuildingImage] = useState<EntityImage>();
    const [entityImages, setEntityImages] = useState<EntityImage[]>([]);
    const [imageData, setImageData] = useState<Shape[]>([]);

    const { isLoading: isLoadingImages, result: imageList } = useLoadData<EntityImage[]>(
        `image/building/${buildingId}`,
    );

    const { isLoading: isLoadingData, result: pointsData } = useLoadData<any[]>(
        `image/cordinates/${buildingImage?.imageID}`,
        !buildingImage || !isNil(buildingImage?.imageData),
    );

    const {
        submitData,
        response: { isSubmitting, result: submitResult, error: submitError },
    } = usePostData<any>('image/cordinates', 'POST');

    const {
        submitData: deleteData,
        response: { isSubmitting: isDeleting, result: deleteResult, error: deleteError },
    } = usePostData<any>('image/cordinates', 'DELETE');

    useEffect(() => {
        setEntityImages(imageList);
        if (imageList) {
            setBuildingImage(imageList.find((i) => i.direction === linkImageData?.direction));
        }
    }, [imageList]);

    useEffect(() => {
        if (entityImages) {
            setBuildingImage(entityImages.find((i) => i.direction === linkImageData?.direction));
        }
    }, [linkImageData]);

    useEffect(() => {
        if (buildingImage) {
            setImageData(convertToShapeObject(buildingImage.imageData));
        }
    }, [buildingImage]);

    useEffect(() => {
        if (pointsData && !isEmpty(pointsData)) {
            const imageData = map(pointsData, (p: any) => {
                const points = JSON.parse(p.shapeJsonData);
                return {
                    iD: p.id,
                    entityId: p.entityID,
                    entityType: p.entityType,
                    point1: points.point1,
                    point2: points.point2,
                };
            });
            const newImageData = { ...buildingImage, imageData: imageData };
            const entityImagesUpdated = addOrUpdateArrayItem(
                [...entityImages],
                newImageData,
                'imageID',
            );
            setEntityImages(entityImagesUpdated);
            setBuildingImage({ ...newImageData });
        }
    }, [pointsData]);

    useEffect(() => {
        if (!isSubmitting && submitResult && !submitError) {
            const entityImage = find(entityImages, (ei) => ei.imageID === buildingImage.imageID);
            remove(
                entityImage.imageData,
                (i) =>
                    i.entityId === linkImageData.entityId &&
                    i.entityType === linkImageData.entityType,
            );
            const shapeInfo = JSON.parse(submitResult.shapeJsonData);
            entityImage.imageData = addOrUpdateArrayItem(
                entityImage.imageData,
                {
                    iD: submitResult.id,
                    baseImageId: buildingImage.imageID,
                    entityId: linkImageData.entityId,
                    entityType: linkImageData.entityType,
                    point1: shapeInfo.point1,
                    point2: shapeInfo.point2,
                },
                'iD',
            );
            setEntityImages([...entityImages]);
            setBuildingImage({ ...entityImage });
        }
    }, [submitResult, isSubmitting]);

    useEffect(() => {
        if (!isDeleting && deleteResult && !deleteError) {
            const entityImage = find(entityImages, (ei) => ei.imageID === buildingImage.imageID);
            remove(
                entityImage.imageData,
                (i) =>
                    i.entityId === linkImageData.entityId &&
                    i.entityType === linkImageData.entityType,
            );
            setEntityImages([...entityImages]);
            setBuildingImage({ ...entityImage });
        }
    }, [deleteResult, isDeleting]);

    const handleDelete = async (imageData: Shape) => {
        const result = await deleteData({
            overrideRoute: `image/cordinates/${imageData.tagData.iD}`,
        });
        return !result.error;
    };

    const handleSubmit = async (newImageData: Shape) => {
        const result = await submitData({
            data: {
                iD: newImageData.tagData ? newImageData.tagData.iD : 0,
                baseImageId: buildingImage.imageID,
                entityId: linkImageData.entityId,
                entityType: linkImageData.entityType,
                point1: newImageData.point1,
                point2: newImageData.point2,
                shapeJsonData: JSON.stringify({
                    point1: newImageData.point1,
                    point2: newImageData.point2,
                }),
            },
        });

        const data: EntityImageData = {
            iD: result.id,
            entityId: linkImageData.entityId,
            entityType: linkImageData.entityType,
            point1: newImageData.point1,
            point2: newImageData.point2,
        };

        return {
            success: true,
            tagData: data,
        };
    };

    const handleClose = () => {
        onClose();
    };

    const convertToShapeObject = (imageData: EntityImageData[]): Shape[] => {
        return map(imageData, (i) => {
            return {
                point1: i.point1,
                point2: i.point2,
                isEditable:
                    i.entityId === linkImageData.entityId &&
                    i.entityType === linkImageData.entityType,
                isHighlighted:
                    i.entityId === linkImageData.entityId &&
                    i.entityType === linkImageData.entityType,
                tagData: { ...i },
            };
        });
    };

    const canDisableDraw = () => {
        return some(
            buildingImage?.imageData,
            (i) =>
                i.entityId === linkImageData.entityId && i.entityType === linkImageData.entityType,
        );
    };

    if (!linkImageData) {
        return null;
    }

    return (
        <Dialog title={title} onClose={handleClose}>
            <div className="linkWindow">
                {(submitError || deleteError) && <div>Error</div>}
                {!buildingImage && <div> No building image for the direction </div>}
                {buildingImage && (
                    <ImageDrawingComponent
                        disableDraw={canDisableDraw()}
                        imageUrl={buildingImage.imagePath}
                        initialShapes={imageData}
                        onShapeSave={handleSubmit}
                        onShapeDelete={handleDelete}
                    />
                )}
                <LoadingIndicator
                    isLoading={isSubmitting || isDeleting || isLoadingData || isLoadingImages}
                />
            </div>
        </Dialog>
    );
};
