import React, { useState } from 'react';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import '@progress/kendo-theme-default/dist/all.css';
import { text } from 'stream/consumers';
import { isEmpty } from 'lodash';
interface DataItem {
    text: string;
    value: string;
}
interface SingleDropdownReportProps {
    dataitems: DataItem[];
    onchange: Function;
    id: string;
    placeholder?: string;
    isDef?: DataItem;
    width?: string;
    height?: string;
    className?: string;
    isDisabled?: boolean;
}

const SingleDropdownReport: React.FC<SingleDropdownReportProps> = ({ ...props }) => {
    const { onchange, id, dataitems, placeholder = "Select", isDef } = props;
    const [selectedItem, setSelectedItem] = useState<DataItem | null>();
    const handleChange = (event: DropDownListChangeEvent) => {
        setSelectedItem(event.target.value as DataItem);
        onchange(event.target.value.value as string);
    };
    return (
        <div>
            <DropDownList fillMode='solid'
                adaptive={true}
                data={dataitems}
                style={{
                    width: props.width != "" ? props.width : "100px",
                    height: props.height != "" ? props.height : '40px'
                }}
                disabled={props.isDisabled}
                id={id}
                key={id}
                name={id}
                textField="text"
                dataItemKey="value"
                defaultItem={isDef ? isDef : { text: "Select Option", value: "-1" }}
                onChange={handleChange}
                value={selectedItem}
                className={!isEmpty(props.className) ? props.className : 'form-control dropDownList'}
            />
            {/* {selectedItem && <div>Selected option: {selectedItem.text}</div>} */}
        </div>
    );
};

export default SingleDropdownReport;
