import { Dialog } from '@progress/kendo-react-dialogs';
import { useEffect } from 'react';
import { GuardedComponent } from '../../components/guarded.componemt';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { usePostData } from '../../hooks/use-post-data.hook';
import { EntityImage } from '../../models/building-image/entity-image';
import { PermissionName } from '../../models/user-security/permission.name';

interface UploadImageProps {
    entityId: number;
    direction: string;
    onCompleteUpload: (image: EntityImage) => any;
}

export const UploadImageComponent = ({
    entityId,
    direction,
    onCompleteUpload,
}: UploadImageProps) => {
    const {
        submitData,
        response: { isSubmitting, result, error },
    } = usePostData<any>('image', 'POST', 'multipart/form-data');

    useEffect(() => {
        if (result) {
            onCompleteUpload({
                direction: direction,
                imageID: result.imageID,
                imagePath: result.imagePath,
                imageData: [],
            });
        }
    }, [result]);

    const handleSubmit = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('UploadedFile', file);
            formData.append('FileName', file.name);
            formData.append('BaseType', 'building');
            formData.append('BaseTypeID', entityId.toString());
            formData.append('direction', direction);
            submitData({ data: formData });
        }
    };

    if (isSubmitting) {
        return <LoadingIndicator isLoading={isSubmitting} />;
    }

    if (result) {
        return <label>Uploaded!</label>;
    }

    if (error) {
        return <label>An error occured while uploading the file</label>;
    }

    return (
        <>
            <div className="text-center pt-3 pb-3">
                <div className="row">
                    <div className="col-md-11">
                        <h5 className="mb-4">
                            <b>You have not added an image for {direction}</b>
                        </h5>
                    </div>
                    <GuardedComponent permission={PermissionName.EditBuilding}>
                        <div className="col-md-1">
                            <button onClick={() => onCompleteUpload(null)}>X</button>
                        </div>
                    </GuardedComponent>
                </div>

                <GuardedComponent permission={PermissionName.EditBuilding}>
                    <div className="upload-btn-wrapper">
                        <button
                            className="btn-upload"
                            onClick={() => {
                                document.getElementById('baseImage').click();
                            }}
                        >
                            Upload a file
                        </button>
                        <input id="baseImage" type="file" onChange={handleSubmit} />
                    </div>
                </GuardedComponent>
            </div>
        </>
    );
};
