
interface Props {
    color?: string;
    subColor?: string;
}

const ExclamationIcon = ({ color = "#ff5f4d", subColor = "#ff9e97" }: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16">
            <g id="Group_775" data-name="Group 775" transform="translate(-77)">
                <g id="Ellipse_81" data-name="Ellipse 81" transform="translate(77 1)" fill={subColor} stroke={color} stroke-width="1">
                    <circle cx="7.5" cy="7.5" r="7.5" stroke="none" />
                    <circle cx="7.5" cy="7.5" r="7" fill="none" />
                </g>
                <text id="_" data-name="!" transform="translate(82 13)" fill="#fff" font-size="13" font-family="Lato-Regular, Lato"><tspan x="0" y="0">!</tspan></text>
            </g>
        </svg>)
}
export default ExclamationIcon;
