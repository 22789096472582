import { useEffect, useRef, useState } from 'react';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import Logo from '../../images/logo-keystone';
import './login.style.scss';
import { useStorage } from '../../hooks/use-storage';
import { StorageKey, } from '../../constants';
import { useNavigate, useParams } from 'react-router-dom';

import { setAxiosAuthHeaders } from '../../api-client.ts/axios';
import { useHomeownerByClaimGuid } from '../../api/claim';
import { useSendEmailOtp, useVerifyOtp } from '../../api/inspector';
import OTPInput from 'react-otp-input';

const RESEND_OTP_INTERVAL = 5 * 60;

export const HomeOwnerLogin = () => {
    const [_, setAuthState] = useStorage(StorageKey.AuthState, null);
    const [, setUserType] = useStorage(StorageKey.UserType, null);

    const nav = useNavigate();

    const params = useParams()
    const guiID = Object.values(params)?.[0]?.replace('homeowner-login/', "")



    const [seconds, setSeconds] = useState(-1);

    const [formState, setFormState] = useState<'verify' | 'resend' | undefined>()
    const [otp, setOtp] = useState('')
    const [otpError, setOtpError] = useState('');

    const { data: homeownerInfo, isLoading: isLoadingHomeownerInfo } = useHomeownerByClaimGuid({ variables: { claimGUID: guiID } });

    const { mutate: sendEmailOtp, isPending: isPendingSendEmailOtp } = useSendEmailOtp({
        onSuccess: (response) => {
            if (response.success) {
                setOtpError("");
                setSeconds(RESEND_OTP_INTERVAL)
                setFormState('verify');
            }
            else setOtpError(response.message || "Failed to send One Time Code")
        },
        onError: () => setOtpError("Failed to send One Time Code")
    })

    const { mutate: verifyOtp, isPending: isPendingVerifyOtp } = useVerifyOtp({
        onSuccess: (response) => {
            if (response.isSuccess) {
                response.schUsers.claimGuid = guiID
                setOtpError("")
                setAuthState(response.token);
                setUserType("homeowner")
                setAxiosAuthHeaders()
                nav("/homeowner/landing-page", { state: response });
            } else {
                setOtpError('Failed to verify One Time Code');
            }
        },
        onError: () => setOtpError('Failed to verify One Time Code')
    })


    const onSendOtp = () => {
        if (!homeownerInfo?.homeowner_Email) {
            return
        }

        setOtpError("")
        sendEmailOtp({ email: homeownerInfo.homeowner_Email, userType: "Homeowner" })
    }

    useEffect(() => {
        if (homeownerInfo?.homeowner_Email)
            onSendOtp()
    }, [homeownerInfo?.homeowner_Email])

    const intervalRef = useRef<NodeJS.Timeout>()
    useEffect(() => {

        if (seconds === RESEND_OTP_INTERVAL) {
            intervalRef.current = setInterval(() => {
                setSeconds(prev => prev - 1);
            }, 1000);

        }
        else if (seconds === 0) {
            setFormState('resend')
            clearInterval(intervalRef.current);
        }
    }, [seconds, isLoadingHomeownerInfo, isPendingSendEmailOtp]);

    const handleSubmit = () => {


        if (formState === "resend") {
            onSendOtp();
            return
        }

        if (!otp) {
            setOtpError("Enter a valid OTP")
            return
        }

        verifyOtp({ OTP: otp, userGuid: homeownerInfo.userGUID })
    }

    const isLoading = isLoadingHomeownerInfo || isPendingSendEmailOtp || isPendingVerifyOtp

    const isBrokenUrl = guiID === "homeowner-login" || !guiID

    return (
        <>

            <div className="loginWrpae">

                <div className='logoM'>
                    <Logo />
                </div>
                <div className="mobileimg mobileStart" />
                <div className='login-box'>
                    {isBrokenUrl &&
                        (<div className="text-center mt-4 mb-3">
                            <h2>The page you are looking for does not exist
                            </h2>
                        </div>)}
                    {!isBrokenUrl && (<div className='login-boxinner'>

                        <div className="text-center mt-4 mb-3">

                            <h5>ENTER ONE TIME CODE</h5>
                        </div>
                        {isLoading && <LoadingIndicator showLabel={true} isLoading title={"Sending One time code.."} />}
                        <div className="otpinput">
                            <OTPInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderInput={(props) => <input {...props} />}
                            />
                        </div>

                        {otpError && <small className="errorMsg">{otpError}</small>}
                        <button
                            id="btnSignIn"
                            className="btn btn-normal btn-block w-100"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault()
                                handleSubmit()
                            }}
                            disabled={isLoading}

                        >
                            {formState === 'verify' ? "Verify One Time Code" : "Resend One Time Code"}
                        </button>


                        <p className="mt-2" style={{ fontSize: "12px", textAlign: "center" }}>(This One Time Code will be valid for the next 5 mins.)</p>
                        {formState === 'verify' &&
                            <div className='mt-3' style={{ fontSize: "12px", textAlign: "center" }}><center>{`Time Remaining: ${Math.floor(seconds / 60)}:${(seconds % 60).toString().padStart(2, '0')}`}</center></div>
                        }


                    </div>)
                    }


                </div>

            </div>
        </>);
};
