import { useFormikContext } from 'formik';
import { DynamicField } from '../../../components/form/dynamic-field.component';
import { InputTextArea } from '../../../components/form/input-textarea.component';
import { InputField } from '../../../components/form/input.feild.component';
import { SelectField } from '../../../components/form/select-field.component';
import {
    WINDOWS_DOOR_OTHER_OPTION,
    WINDOWS_DOOR_OTHER_OPTION_VALUE_PREFIX,
} from '../../../constants';
import { Building } from '../../../models/claim/building';
import { Unit } from '../../../models/claim/unit';
import { UnitWindow } from '../../../models/claim/unit-window';
import { InputFieldType } from '../../../models/enums/input-field-type';
import { MasterType } from '../../../models/masters/master-type';
import { MasterValue } from '../../../models/masters/master-value';
import { getDirectionOptions } from '../../../utils/building.utils';
import { CheckboxField } from '../../../components/form/checkbox-field.component';
import { useEffect } from 'react';
import { TemplateModel } from '../../../models/template-model';

interface EditTemplateFormProps {
    building: Building;
    Template: TemplateModel;
    attributes: MasterType[];



}

export const EditTemplateForm = ({ attributes, building, Template, }: EditTemplateFormProps) => {
    const { getFieldProps } = useFormikContext();
    useEffect(() => {
        console.log(Template);

    })

    const getAttributeOptions = (values: MasterValue[]) => {
        let result = values?.map<any>((o) => {
            return {
                value: o.value,
                label: o.value,
                inputType:
                    o.value && o.value.toLowerCase() === WINDOWS_DOOR_OTHER_OPTION.toLowerCase()
                        ? InputFieldType.Text
                        : InputFieldType.None,
                        id:o.id
            };
        });
        return result;
    };


    const preprocessAttributes = () => {
        return attributes.map((a) => {
            if (a.values) {
                const otherIndex = a.values.findIndex(
                    (v) =>
                        v.value &&
                        v.value.toLowerCase() === WINDOWS_DOOR_OTHER_OPTION.toLowerCase(),
                );
                if (otherIndex >= 0) {
                    a.values.push(a.values.splice(otherIndex, 1)[0]);
                }
            }
            return a;
        });
    };

    return (


        <div className="addwindowcls2">
            <div>
                <InputField label="Name*" name="templateName" />
            </div><div>
                <SelectField
                    label="Direction"
                    name="direction"
                    options={getDirectionOptions()}
                />
            </div>

            {preprocessAttributes().map<any>((item) => (
                <div key={item.id}>
                    <label className="label">{item.name}</label>
                    <DynamicField

                        optionvalue={
                            item.inputType === InputFieldType.Date &&
                                Template.attributes &&
                                Template.attributes.length > 0
                                ? Template.attributes.find(
                                    (e) => e.attributeName === item.name,
                                )?.attributeValue
                                : null
                        }
                        name={item.name}
                        type={item.inputType as InputFieldType}
                        optionValueName={item.name + WINDOWS_DOOR_OTHER_OPTION_VALUE_PREFIX}
                        optionValues={getAttributeOptions(item.values)}
                    />

                </div>
            ))}



        </div>


    );
};
