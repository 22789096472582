import React from 'react';

const EditIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.21" height="15.21" viewBox="0 0 15.21 15.21">
      <path fill="#f68b1f" d="M4.5,15.779v2.969H7.469l8.755-8.755L13.255,7.023ZM18.519,7.7a.788.788,0,0,0,0-1.116L16.667,4.728a.788.788,0,0,0-1.116,0L14.1,6.176l2.969,2.969Z"
        transform="matrix(0.998, -0.07, 0.07, 0.998, -4.803, -3.177)" />
    </svg>
  );
};

export default EditIcon;
