import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ReactNode } from 'react';

interface ImageDownloadialogProps {
    children: ReactNode;
    show: boolean;
    onClose?: () => any;
}
export const ImageDownloaddialog = ({ children, show, onClose }: ImageDownloadialogProps) => {
    return (
        <>
            (
            {show && (
                <Dialog title={'Download Photos'} onClose={() => onClose()} width={'70%'} style={{ maxHeight: 600 }} >
                    <div> {children}</div>

                </Dialog>
            )}
            )
        </>
    );
};
