import { useRef, useState } from 'react';
import * as Yup from 'yup';
import {
    DeleteModelDialog,
    DeletePostDataInfo,
    DeleteStatus,
} from '../../../components/delete-model-dialog.component';
import { InputField } from '../../../components/form/input.feild.component';
import { SelectField } from '../../../components/form/select-field.component';
import { FormikModelDialog, SubmitStatus } from '../../../components/formik-model-dialog.component';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { useLoadData } from '../../../hooks/use-load-data.hook';
import { Building } from '../../../models/claim/building';
import { Floor } from '../../../models/claim/floor';
import { Unit } from '../../../models/claim/unit';
import { EditDialogHeader } from '../edit-dialog-header.component';
import NumericField from '../../../components/form/numeric-input-field.component';
import { updateEntityDetails } from '../../../utils/updateutil';

interface EditUnitProps {
    data?: { building: Building; unit: Unit };
    onClose?: (status: SubmitStatus, data: any) => any;
    onDeleted?: (unit: Unit) => any;
}

export const EditUnit = ({ data: { building, unit }, onClose, onDeleted }: EditUnitProps) => {
    const [deleteUnitData, setDeleteUnit] = useState<DeletePostDataInfo>();
    const { isLoading, result } = useLoadData<Floor[]>(`floors/${building.buildingID}`);
    const isvisible = useRef<boolean>(false);
    const onDeleteFloorClick = () => {
        const { unitID } = unit;
        setDeleteUnit({
            data: { ...unit },
            route: `units/delete/${unitID}`,
        });
    };

    const onDeleteComplete = (status: DeleteStatus, unit: Unit) => {
        if (status == DeleteStatus.Completed && onDeleted) {
            onDeleted(unit);
        }
        setDeleteUnit(null);
    };

    const getOptions = () => {


        return result?.map<any>((f) => {
            return { value: f.floorID, label: f.floorName };
        });
    };

    const postDataFormatter = async (data: any): Promise<Unit> => {

        const room: Unit = {
            ...data,
            floorID: parseInt(data.floorID),
        };
        return room;
    };

    const validationSchema = Yup.object().shape({
        floorID: Yup.number().required('Required').moreThan(0, `Required`),
        unitName: Yup.string().required('Required'),
        // unitNumber: Yup.string().required('Required'),
    });

    const initialData: Unit = {
        //floorID: unit.floorID ? unit.floorID : getOptions() !== undefined && getOptions().length > 0 ? getOptions()[0].value : 0,
        floorID: unit?.floorID,
        unitID: unit.unitID,
        unitName: unit.unitName,
        unitNumber: '0'/* unit.unitNumber */,
    };
    const title = unit.unitID === 0 ? 'Add Unit' : 'Edit Unit';

    if (deleteUnitData) {
        return (
            <DeleteModelDialog

                title="Delete Unit"
                deleteData={deleteUnitData}
                onClose={onDeleteComplete}
            >
                <div>Are you sure you want to delete Unit - {deleteUnitData.data.unitName}?</div>
            </DeleteModelDialog>
        );
    }

    return (
        <>
            <FormikModelDialog
                isUpDateEntityDetails
                width='40%'
                title={<EditDialogHeader title={title} buildingName={building.buildingName} />}
                customButtons={
                    unit.unitID > 0 && (
                        <button className="btn btn-secondary" onClick={onDeleteFloorClick}>
                            Delete
                        </button>
                    )
                }
                initialData={initialData}
                validationSchema={validationSchema}
                show={!!unit}
                onClose={onClose}
                postRoute="units"
                postDataFormatter={postDataFormatter}
            >
                <>
                    <LoadingIndicator isLoading={isLoading} />
                    <SelectField label="Select Floor" name="floorID" options={getOptions()} />
                    <InputField label="Unit Name*" name="unitName" />
                    {isvisible.current && (<NumericField label="Unit Number" name="unitNumber" />)}

                </>
            </FormikModelDialog>
        </>
    );
};
