import { isEmpty, isNil } from 'lodash';
import { useState, useEffect } from 'react';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { useLoadData } from '../../../hooks/use-load-data.hook';
import { Room } from '../../../models/claim/room';
import { UnitWindow } from '../../../models/claim/unit-window';
import imageimg from '../../../images/file-image';
import FileVideoIcon from '../../../images/file-video';

interface CopyWindowComponentProps {
    roomId: number;
    onCopyEntity: (window: UnitWindow) => any;
}

export const CopyWindowComponent = ({ roomId, onCopyEntity }: CopyWindowComponentProps) => {
    const [windowsList, setWindowsList] = useState<UnitWindow[]>();
    const { isLoading, result, isError } = useLoadData<Room>(
        `rooms?roomid=${roomId}`,
        isNil(roomId),
    );

    useEffect(() => {
        if (result && result.windows) {
            setWindowsList(result.windows);
        }
    }, [result]);

    // const selectedOption = { label: window?.windowName, value: window?.windowID };

    // const options = map(result?.windows, (w) => {
    //     return {
    //         label: w.windowName,
    //         value: w.windowID,
    //     };
    // });

    // const handleWindowSelectionChange = (selected: any) => {
    //     const selectedItem: any = find(result.windows, (w) => w.windowID === selected?.value);
    //     setSelectedItem(selectedItem);
    // };

    if (isLoading) {
        return <LoadingIndicator isLoading={isLoading} />;
    }

    if (!windowsList || isEmpty(windowsList)) {
        return (
            <div className="text-center mt-4 mb-4 border-top pt-3">
                <h5>You have not added any windows for this room</h5>
            </div>
        );
    }

    return (
        <div>
            {windowsList &&
                windowsList.map((item) => (
                    <div className="structureList-grid" key={`window_${item.windowID}`}>
                        <div>
                            <h5 className="text-normal">{item.windowName}</h5>
                        </div>
                        <div>
                            <h6 className="text-normal">{item.direction}</h6>
                        </div>
                        <div className="options-list">
                            {item.attributes.map<any>((attribute) => (
                                <div>{attribute.attributeValue}</div>
                            ))}
                        </div>
                        <div>
                            <button
                                className="tag-buttons"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onCopyEntity(item);
                                }}
                            >
                               <FileVideoIcon></FileVideoIcon> {/* <img src={imageimg} /> */}
                            </button>
                        </div>
                    </div>
                ))}
        </div>
    );
};
