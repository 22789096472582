import React, { useState } from 'react';

interface IRadioProps {
    options: IOption[];
    onchange: (value: string) => void;
}

export interface IOption {
    label: string;
    value: string;
}

const RadioGroup: React.FC<IRadioProps> = ({ options, onchange }) => {
    const [selectedValue, setSelectedValue] = useState<string>(options[0].value);

    const handleTabChange = (value: string) => {
        setSelectedValue(value);
        onchange(value);
    };

    return (
        <div className="radioContainer">
            {options.map((option) => (
                <input
                    key={option.value}
                    type="radio"
                    id={`tab-${option.value}`}
                    name="tab"
                    value={option.value}
                    checked={selectedValue === option.value}
                    onChange={() => handleTabChange(option.value)}
                />
            ))}

            <div className="tabs-login">
                {options.map((option) => (
                    <label
                        key={option.value}
                        htmlFor={`tab-${option.value}`}
                        className={`tab-label ${selectedValue === option.value ? "active" : ""}`}
                    >
                        {option.label}
                    </label>
                ))}
                <span className="slidebrs" />
            </div>
        </div>
    );
};

export default RadioGroup;
