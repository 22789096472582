import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import appstore from './store';
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container as HTMLElement);
root.render(
   
            <App />
   
        ,
   
);
