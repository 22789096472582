import { Dialog } from '@progress/kendo-react-dialogs';
import { isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import {
    DeleteModelDialog,
    DeletePostDataInfo,
    DeleteStatus,
} from '../../components/delete-model-dialog.component';
import { SubmitStatus } from '../../components/formik-model-dialog.component';
import { GuardedComponent } from '../../components/guarded.componemt';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { ClaimObservation, Photo } from '../../models/claim/claim-observation';
import { ClaimObservationOption } from '../../models/claim/claim-observation-option';
import { EntityType } from '../../models/claim/entity-type';
import { UnitDoor } from '../../models/claim/unit-door';
import { UnitWindow } from '../../models/claim/unit-window';
import { ObservationType } from '../../models/masters/observation-type';
import { PermissionName } from '../../models/user-security/permission.name';
import { addOrUpdateArrayItem, deleteArrayItem } from '../../utils/array.util';
import { EditObservation } from '../building/observation/edit-observation.component';
import { ObservationListComponent } from '../building/observation/observation-list.component';
import { ImageListModelDialog } from '../image/image-list-model-dialog.component';
import imageimg from '../../images/file-image';
import { Building } from '../../models/claim/building';
import { EditWindowDoorWrapperComponent } from './edit-window-door-wrapper.component';
import { DeleteWindowDoorWrapperComponent } from './delete-window-door-wrapper.component';
import FileImage from '../../images/file-image';
import { ImgFile, deleteFile, editComments, fetchFile, uploadImages } from '../../utils/uploadfile.util';
import { Image } from 'react-grid-gallery';
import { fetchFiles } from '../../api-client.ts/fileapi';
import { UploadedFile } from '../../redux/reducers/filereducer';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import ImageUploader from '../../components/add-images.component';

interface ViewEntityDetailsModelDialogProps {
    buildingData: Building;
    entityData: { entityType: EntityType; entityId: number };
    onUpdate: (
        isDirty: boolean,
        enitity: { entityType: EntityType; entityId: number },
        observations: ClaimObservation[],
    ) => any;
    onEntityDelete: (enitity: { entityType: EntityType; entityId: number }) => any;
}

export const ViewEntityDetailsModelDialog = ({
    buildingData,
    entityData,
    onUpdate,
    onEntityDelete,
}: ViewEntityDetailsModelDialogProps) => {
    const [observations, setObservations] = useState<ClaimObservation[]>([]);
    const [newObservation, setNewObservation] = useState<{
        newObservation: ClaimObservation;
        copyFrom?: boolean;
        updateOption: ClaimObservationOption;
    }>();
    const [imageListData, setImageListData] = useState<{
        entityType: EntityType;
        entityId: number;
    }>();
    const [deleteObservation, setDeleteObservation] = useState<DeletePostDataInfo>();
    const [editEntity, setEditEntity] = useState<Boolean>(false);
    const [deleteEntity, setDeleteEntity] = useState<Boolean>(false);
    const { isLoading, result, isError, reload } = useLoadData<any>(
        `${entityData.entityType}s/details/${entityData.entityId}`,
    );
    const [imgpreview, showImgPreview] = useState<{
        imgpreview: boolean;
        src: File;
        comment?: string;
    }>({
        imgpreview: false,
        src: null,
        comment: '',
    });

    const [linkImageData, setLinkImageData] = useState<{
        entityType: EntityType;
        entityId: number;
        entityName: string;
        direction: string;
    }>();
    const [data, setEditWindow] = useState<{
        building: Building;
        window: UnitWindow;
        copyFrom: boolean;
    }>();
    const dispatch = useDispatch<AppDispatch>();

    const [photos, setPhoto] = useState<Image[]>();
    const [imgList, setImgList] = useState<ImgFile[]>([]);
    const uploadedfileList = useRef<UploadedFile[]>([]);
    const observationtype = useRef<{ entityType: ObservationType; entityID: number }>({
        entityType: entityData.entityType.toLowerCase() === 'window' ? ObservationType.Window : ObservationType.Door,
        entityID: entityData.entityId,
    });
    const handleAddFileToImgList = async (file: File) => {
        await uploadImages([file], {
            claimGuID: buildingData.claimGuid,
            EntityID: observationtype.current.entityID.toString(),
            EntityType: observationtype.current.entityType,
            IsInterior: false,
            Comments: imgpreview.comment.toString(),
        }).then((value: UploadedFile) => {
            loadImages();
            imgList.push({
                comment: imgpreview.comment!,
                file: file,
                isbaseImg: value.isBase,
                baseID: 0,
                entityID: value.entityID,
                entityType: value.entityType,
                fileID: value.fileID.toString(),
                userGuid: '',
            });
            //  alert('upload success');

            showImgPreview({
                imgpreview: false,
                src: null,
            });
        });
    };
    useEffect(() => {
        if (result) {
            setObservations(result.observations);
            loadImages();
        }
    }, [result]);
    const loadImages = async () => {
        try {
            //setPhoto([]);
            const action = await dispatch(
                fetchFiles({
                    FileType: 'image',
                    UserID: 0,
                    EntityType: observationtype.current.entityType,
                    EntityID: observationtype.current.entityID,
                }),
            );

            if (action.payload) {
                let uploadedFileArray = action.payload as UploadedFile[];

                uploadedfileList.current = uploadedFileArray;

                let files = await Promise.all(
                    uploadedFileArray.map(async (e) => {
                        return fetchFile(
                            e.fileCDN,
                            e.comments,
                            e.entityID.toString(),
                            observationtype.current.entityType,
                            e.isBasePhoto,
                            e.fileID.toString(),
                            e.basePhotoID
                        );
                    }),
                );

                setImgList(files);
            } else {
                setImgList([]);
            }
        } catch (error) {
            console.error('Error fetching files:', error);
            setImgList([]);
        }
    };
    const handleClose = () => {
        onUpdate(true, result, observations);
    };

    const openGallery = (data: { entityType: EntityType; entityId: number }) => {
        setImageListData(data);
    };

    const closGallery = () => {
        setImageListData(null);
    };

    const getEntityInfo = (): any => {
        if (!result) {
            return null;
        }
        if (!isNil(result.windowID)) {
            return getWindowInfo(result);
        } else if (!isNil(result.doorID)) {
            return getDoorInfo(result);
        }
    };
    const onShowObservationPhotos = (observation: ClaimObservation) => {
        setPhoto([
            ...observation.photos.map(
                (image, index): Image => ({
                    src: image.fileCDN,
                    height: 200,
                    width: 200,
                    key: image.observationID,
                    orientation: 1,
                    caption: image.comments,
                    /*   customOverlay: (
                        <div className="bg-dark text-light">
                            <div>{image.comments}</div>
                        </div>
                    ), */
                }),
            ),
        ]);
    };
    const getWindowInfo = (window: UnitWindow) => {
        return <div>Window Name: {window.windowName}</div>;
    };

    const getDoorInfo = (door: UnitDoor) => {
        return <div>Door Name: {door.doorName}</div>;
    };

    const onEditWindowClick = () => {
        if (result) {
            setEditEntity(true);
        }
    };

    const onDeleteWindowClick = () => {
        if (result) {
            setDeleteEntity(true);
        }
    };

    const getObservationType = () => {
        return entityData.entityType.toLocaleLowerCase() === EntityType.Window.toLocaleLowerCase()
            ? ObservationType.Window
            : ObservationType.Door;
    };


    const onAddObservationClick = () => {
        const observation = new ClaimObservation();
        observation.observationID = 0;
        observation.entityType = getObservationType();
        observation.entityID = entityData.entityId;
        setNewObservation({
            newObservation: observation,
            updateOption: null,
        });
    };

    const onEditObservationClick = (
        observation: ClaimObservation,
        updateOption: ClaimObservationOption,
    ) => {
        setNewObservation({
            newObservation: observation,
            updateOption: updateOption,
        });
    };
    const onDeleteObservation = (claimObservation: ClaimObservation) => {
        setDeleteObservation({
            data: claimObservation,
            route: `ClaimObservation/${claimObservation.observationID}`,
        });
    };

    const handleSaveObservationComplete = (status: SubmitStatus, data: ClaimObservation) => {
        if (status === SubmitStatus.Completed) {
            const updatedObsrvations = addOrUpdateArrayItem(observations, data, 'observationID');
            setObservations(updatedObsrvations);

        }
        setNewObservation(null);
    };

    const handleDeleteObservationComplete = (status: DeleteStatus, data: ClaimObservation) => {
        if (status == DeleteStatus.Completed) {
            const updatedObsrvations = deleteArrayItem(observations, data, 'observationID');
            setObservations(updatedObsrvations);
            reload();
        }
        setDeleteObservation(null);
    };

    const handleDeleteWindowComplete = (status: DeleteStatus, data: any) => {
        if (status == DeleteStatus.Completed) {
            onEntityDelete(data);
        }
        setDeleteEntity(false);
    };
    const onAddNewObservationClick = (window: UnitWindow, copyFrom: boolean) => {
        const observation = new ClaimObservation();
        observation.observationID = 0;
        observation.entityType = observationtype.current.entityType;
        observation.entityID = observationtype.current.entityID;
        setNewObservation({
            newObservation: observation,
            copyFrom: copyFrom,
            updateOption: null,
        });
    };
    const handleEditWindowComplete = (status: SubmitStatus, data: any) => {
        setEditEntity(false);
    };

    if (editEntity) {
        return (
            <EditWindowDoorWrapperComponent
                building={buildingData}
                entity={result}
                onClose={handleEditWindowComplete}
            />
        );
    }

    if (deleteEntity) {
        return (
            <DeleteWindowDoorWrapperComponent
                building={buildingData}
                entity={entityData}
                onClose={handleDeleteWindowComplete}
            />
        );
    }

    if (deleteObservation) {
        return (
            <DeleteModelDialog
                title="Delete Observation"
                deleteData={deleteObservation}
                onClose={handleDeleteObservationComplete}
            >
                <div>
                    Are you sure you want to delete Observation -{' '}
                    {deleteObservation.data.observation}?
                </div>
            </DeleteModelDialog>
        );
    }

    if (newObservation) {
        return (
            <EditObservation
                isphotoUploaded={(res: number) => {
                    if (res === 1) {
                        reload()
                    }
                }}
                observationType={getObservationType()}
                building={buildingData}
                data={newObservation}
                onClose={handleSaveObservationComplete}
                imgList={imgList}

            />
        );
    }

    if (imageListData) {
        return <ImageListModelDialog data={imageListData} onCancel={closGallery} />;
    }

    return (<>
        <Dialog title={`${entityData.entityType} Details`} onClose={handleClose}>
            <div>
                {result && (
                    <div>
                        <h5>
                            <b>{getEntityInfo()} </b>
                        </h5>
                        <div className="options-list d-flex">
                            {result.attributes?.map((attribute: any) => (
                                <label>{attribute.attributeValue} | </label>
                            ))}
                            <div className="d-flex mb-2 ms-2">
                                <button
                                    className="tag-buttons"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openGallery({
                                            entityType: entityData.entityType,
                                            entityId: entityData.entityId,
                                        });
                                    }}
                                >
                                    <FileImage></FileImage> {/*   <img src={imageimg} /> */}
                                    <span className="tagtop">{result?.images}</span>
                                </button>
                            </div>
                        </div>

                        <div className="d-flex mb-2">
                            {/* <div className="ms-auto">
                                <GuardedComponent permission={PermissionName.AddObservation}>
                                    <button
                                        title="Add Observation"
                                        className="btn btn-primary btn-sm"
                                        onClick={onAddObservationClick}
                                    >
                                        Add Observation
                                    </button>
                                </GuardedComponent>
                            </div> */}
                            <div className="ms-2">
                                <GuardedComponent permission={PermissionName.EditWindow}>
                                    <button
                                        title="Edit Window"
                                        className="btn btn-primary edit-window-btn btn-sm"
                                        onClick={onEditWindowClick}
                                    >
                                        Edit
                                    </button>
                                </GuardedComponent>
                            </div>
                            <div className="ms-2">
                                <GuardedComponent permission={PermissionName.DeleteWindow}>
                                    <button
                                        title="Delete Window"
                                        className="btn btn-secondary add-window-btn btn-sm"
                                        onClick={onDeleteWindowClick}
                                    >
                                        Delete
                                    </button>
                                </GuardedComponent>
                            </div>
                        </div>
                        <ObservationListComponent isimgclaim
                            handleCancel={() => { loadImages(); }}
                            handleDelete={async (img: ImgFile) => {
                                const isdeleted: boolean = await deleteFile(img);
                                if (isdeleted) {
                                    setPhoto([]);
                                    await loadImages().then(() => {
                                        // if (result.windows.length > 0) {
                                        //     result.windows.forEach((w: any) => {
                                        if (observations.length > 0) {
                                            observations.forEach((o: ClaimObservation) => {
                                                console.log(o);

                                                if (o.photos.length > 0) {
                                                    let photo = o.photos.find(
                                                        (p) =>
                                                            p.fileId ===
                                                            parseInt(
                                                                img.fileID,
                                                            ),
                                                    );
                                                    if (photo) {
                                                        let filteredPhotos =
                                                            o.photos.filter(
                                                                (p) =>
                                                                    p.fileId !==
                                                                    parseInt(
                                                                        img.fileID,
                                                                    ),
                                                            );
                                                        filteredPhotos.length >
                                                            0
                                                            ? (o.photos = [
                                                                ...filteredPhotos,
                                                            ])
                                                            : (o.photos = []);
                                                    }
                                                }
                                            });
                                        }
                                        //   });
                                        //  }
                                    });
                                }
                            }}
                            handleSave={async (file: ImgFile) => {
                                const isedited = await editComments({
                                    fileId: file.fileID!,
                                    comments: file.comment,
                                    isBaseImg: file.isbaseImg,
                                    baseID: file.baseID,
                                    entityID: file.entityID,
                                    entityType: file.entityType,
                                    fileID: file.fileID,
                                    userGuid: '',
                                });
                                if (isedited) {
                                    setPhoto([]);
                                    await loadImages().then((value) => {
                                        // if (result.windows.length > 0) {
                                        //     result.windows.forEach((w: any) => {
                                        if (observations.length > 0) {
                                            observations.forEach((o: ClaimObservation) => {
                                                if (o.photos.length > 0) {
                                                    let photo = o.photos.find(
                                                        (p: Photo) =>
                                                            p.fileId ===
                                                            parseInt(
                                                                file.fileID,
                                                            ),
                                                    );
                                                    if (photo) {
                                                        o.photos.find(
                                                            (p) =>
                                                                p.fileId ===
                                                                parseInt(
                                                                    file.fileID,
                                                                ),
                                                        ).comments =
                                                            file.comment;
                                                    }
                                                }
                                            });
                                        }
                                        //  });
                                        // }
                                    });
                                }
                            }}
                            addphoto={(e: React.ChangeEvent<HTMLInputElement>) => {
                                showImgPreview({
                                    imgpreview: true,
                                    src: e.target.files[0],
                                });
                                observationtype.current = {
                                    entityID: entityData.entityId,
                                    entityType: entityData.entityType.toLowerCase() === 'window' ? ObservationType.Window : ObservationType.Door,
                                };
                            }}
                            onAddNewObservationClick={() => {
                                observationtype.current = {
                                    entityID: entityData.entityId,
                                    entityType: entityData.entityType.toLowerCase() === 'window' ? ObservationType.Window : ObservationType.Door,
                                };
                                onAddNewObservationClick(result, false);
                            }
                            }
                            oncopyobservation={() =>
                                onAddNewObservationClick(result, true)
                            }
                            onShowObservationPhotos={onShowObservationPhotos}
                            imgList={imgList}
                            photos={photos}

                            observations={observations}
                            onEditObservation={onEditObservationClick}
                            onDeleteObservation={onDeleteObservation}
                            onOpenGallery={openGallery}
                        />
                    </div>
                )}
                {isError && <div>Error</div>}
                <LoadingIndicator isLoading={isLoading} />
            </div>
        </Dialog>
        <div className="me-3">
            <GuardedComponent permission={PermissionName.ViewObservation}>
                {imgpreview.imgpreview ? (
                    <ImageUploader
                        onAddImg={(comment: string) => {
                            imgpreview.comment = comment.toString();
                            handleAddFileToImgList(imgpreview.src);
                        }}
                        onclose={() => {
                            showImgPreview({
                                imgpreview: false,
                                src: null,
                            });
                        }}
                        showModal={imgpreview.imgpreview}
                        img={imgpreview.src}
                    />
                ) : null}

            </GuardedComponent>
        </div>,
    </>
    );
};
