import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import './master.style.scss';
import { ObservationMasterGridComponent } from './observation-master-grid.component';

export const ObservationMasterPage = (props: { masterType: string }) => {
    return (
        <div className="container-fluid">
            <h5 className="text-normal mt-4 mb-3 title-web">OBSERVATIONS</h5>
            <PanelBar className="masterList" expandMode="single">
                {['window', 'door', 'railing']?.map((item, index) => (
                    <PanelBarItem
                        title={item.toUpperCase()}
                        key={index}
                        expanded={index === 0}
                        selected={index === 0}
                    >
                        <ObservationMasterGridComponent observationType={item} />
                    </PanelBarItem>
                ))}
            </PanelBar>
        </div>
    );
};
