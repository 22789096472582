import { error } from 'console';
import { WINDOWS_DOORS_API_URL } from '../constants';
import { RequestClaimObservation } from '../models/api-request.models';
import { ClaimObservation } from '../models/claim/claim-observation';
import AsyncRequest, { HttpMethod } from '../utils/fetchutil';

export const GetObservationsByEntityTypeandID = async (requrstProps: RequestClaimObservation) => {
    const { entityID, entityType } = requrstProps;
    console.log(`${WINDOWS_DOORS_API_URL}/api/ClaimObservation/EntityByTypeAndID/${entityID}/${entityType}`);
    
    return await AsyncRequest.requestApi({
        method: HttpMethod.GET,
        url: `${WINDOWS_DOORS_API_URL}/api/ClaimObservation/EntityByTypeAndID/${entityID}/${entityType}`,
    }).then((response) => {
        return response as ClaimObservation[];
    });
};
