import { useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { usePostData } from '../../hooks/use-post-data.hook';
import { LoginReponse } from '../../models/login-response';
import Logo from '../../images/logo-keystone';
import './login.style.scss';
import { InputField } from '../../components/form/input.feild.component';
import { useStorage } from '../../hooks/use-storage';
import { StorageKey } from '../../constants';
import { setAxiosAuthHeaders } from '../../api-client.ts/axios';

export const LoginPage = () => {
    const [currentAuthState, setAuthState] = useStorage(StorageKey.AuthState, null);
    const [, setUserType] = useStorage(StorageKey.UserType, null);

    const {
        submitData,
        response: { isSubmitting, result, error },
    } = usePostData<LoginReponse>('login', 'POST');

    useEffect(() => {
        if (!isSubmitting && result && result.isSuccess) {
            setAuthState(result.token);
            setUserType("scheduler")
            setAxiosAuthHeaders()
        }
    }, [result, isSubmitting]);

    const handleSubmit = (values: any) => {
        submitData({
            data: values,
        });
    };

    const initialLoginData = {
        email: '',
        password: '',
    };
    const signupSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
        password: Yup.string().required('Password is required'),
    });

    return (
        <div className="loginWrap">
            <div className="logincontent">
                <h3 className="card-title text-center ">
                    <Logo></Logo>
                </h3>
                <div>
                    <div className="login-container global-container loginWrapper">
                        <div className="card login-form p-4">
                            {error && <div>Failed to login</div>}
                            <LoadingIndicator isLoading={isSubmitting} />
                            <Formik
                                initialValues={initialLoginData}
                                validationSchema={signupSchema}
                                onSubmit={handleSubmit}
                            >
                                {() => (
                                    <Form className="p-2">
                                        <InputField label="Email Address" name="email" />
                                        <InputField
                                            label="Password"
                                            name="password"
                                            type="password"
                                        />
                                        <button
                                            id="btnSignIn"
                                            className="btn btn-normal btn-block w-100"
                                            type="submit"
                                        >
                                            Sign In
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
