import type { AxiosError } from 'axios';
import axios from 'axios';
import { createQuery } from 'react-query-kit';

type Params = {
    SDate: string,
    FDate: string
}
export const useAgingParameters = createQuery<Scheduler[], Params, AxiosError>({
    queryKey: ['agingParameters'],
    fetcher: (params) =>
        axios.get('SchedulerAssignments/GetMetricsRptAgingList').then(response => response.data?.result || []),
    refetchOnWindowFocus: false
});
