import { Field } from 'formik';
import * as Yup from 'yup';
import { InputField } from '../../components/form/input.feild.component';
import { FormikModelDialog, SubmitStatus } from '../../components/formik-model-dialog.component';
import { MasterValue } from '../../models/masters/master-value';

interface EditMasterProps {
    data?: MasterValue;
    onClose?: (status: SubmitStatus, data: any) => any;
}

export const EditMaster = ({ data, onClose }: EditMasterProps) => {
    const title = data.id === 0 ? 'Add Option' : 'Edit Option';
    const validationSchema = Yup.object().shape({
        value: Yup.string().required('Required'),
    });

    return (
        <FormikModelDialog
            width='40%'
            title={title}
            initialData={data}
            validationSchema={validationSchema}
            show={!!data}
            onClose={onClose}
            postRoute="masters"
        >
            <>
                <InputField label="Value" name="value" />
            </>
        </FormikModelDialog>
    );
};
