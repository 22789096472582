import { useFormikContext } from 'formik';
import { InputField } from '../../../components/form/input.feild.component';
import { SelectField } from '../../../components/form/select-field.component';
import { Building } from '../../../models/claim/building';
import NumericField from '../../../components/form/numeric-input-field.component';
import { useRef } from 'react';

interface EditRoomFromProps {
    building: Building;
}

export const EditRoomFrom = ({ building }: EditRoomFromProps) => {
    const { getFieldProps } = useFormikContext();

    const getFloorOptions = () => {
        let floors = building?.floors?.sort((a, b) => a.floorID - b.floorID);
        return floors.map<any>((f) => {
            return { value: f.floorID, label: f.floorName };
        });
    };
    const isvisible = useRef<boolean>(false);
    const getUnitOptions = () => {
        // let fID = getFieldProps<number>('floorID').value !== undefined ? getFieldProps<number>('floorID') : { label: building?.floors[0].floorName, value: building?.floors[0].floorID };
        // const floorId = fID;
        const floorId = getFieldProps<number>('floorID');
        const floor = building?.floors?.find((f) => f.floorID == floorId.value);
        let units = floor?.units?.sort((a, b) => a.unitID - b.unitID)
        return units?.map<any>((u) => {
            return { value: u.unitID, label: u.unitName };

        });
    };

    return (
        <>
            <SelectField
                label="Select Floor"
                name="floorID"
                options={getFloorOptions()}
                unSelectValue={0}
            />
            <SelectField
                label="Select Unit"
                name="unitID"
                options={getUnitOptions()}
                unSelectValue={0}
            />
            <InputField label="Room Name*" name="roomName" />
            {(isvisible.current && <NumericField label="Room Number" name="roomNumber" />)}
        </>
    );
};
