import { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { usePostData } from '../../hooks/use-post-data.hook';
import { LoginReponse } from '../../models/login-response';
import Logo from '../../images/logo-keystone';
import './login.style.scss';
import { InputField } from '../../components/form/input.feild.component';
import { useStorage } from '../../hooks/use-storage';
import { StorageKey } from '../../constants';
import RadioGroup from './radio-group.component';
import MobileLogin from './login-mobile';
import EmailLogin from './login-email';
import { SendOtpPayload, SendOtpResponse, useSendEmailOtp, useSendMobileOtp } from '../../api/inspector';




const CHANNELS = [
    { label: 'Mobile', value: 'mobile' },
    { label: 'Email', value: 'email' },
];


type Channel = 'mobile' | 'email'




export const InspectorLogin = () => {
    const [channel, setChannel] = useState<Channel>("mobile");
    const [authType, setAuthType] = useState<'login' | 'verifyOtp'>('login')

    const [sendOtpPayload, setSendOtpPayload] = useState<SendOtpPayload>()

    const { mutate: sendMobileOtp, isPending: isPendingSendMobileOtp } = useSendMobileOtp()
    const { mutate: sendEmailOtp, isPending: isPendingSendEmailOtp } = useSendEmailOtp()

    const onSendOtp = (payload: SendOtpPayload, onSuccess: (response: SendOtpResponse) => void, onError: () => void) => {
        const sendOtpAction = channel === 'email' ? sendEmailOtp : sendMobileOtp

        sendOtpAction(payload, {
            onSuccess:
                (response, payload) => {
                    onSuccess(response);
                    setSendOtpPayload(payload)
                },
            onError
        })
    }



    const imageClass = authType === 'login' ? "mobileimg" : 'mobileimg mobileStart'

    return (
        <div className='loginWrpae'>
            <div className='logoM'>
                <Logo />
            </div>
            <div className={imageClass} />
            <div className='login-box'>
                <div className='login-boxinner'>
                    <RadioGroup
                        onchange={(channel) => {
                            setAuthType('login')
                            setChannel(channel as Channel);
                        }}
                        options={CHANNELS}
                    />
                    {channel === "mobile" ?
                        <MobileLogin onSendOtp={onSendOtp} isPendingSendOtp={isPendingSendMobileOtp} sendOtpPayload={sendOtpPayload} authType={authType} setAuthType={setAuthType} /> :
                        <EmailLogin onSendOtp={onSendOtp} isPendingSendOtp={isPendingSendEmailOtp} sendOtpPayload={sendOtpPayload} authType={authType} setAuthType={setAuthType} />}
                </div>
            </div>
        </div>
    );
};
