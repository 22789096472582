import React from 'react';
import { ErrorMessage, Field } from 'formik';

interface NumericFieldProps {
    label?: string;
    name: string;
    type?: string;
}

const NumericField: React.FC<NumericFieldProps> = ({ name, type, label }) => {
    return (
        <div>
            {label && <label className="label">{label}</label>}
            <Field name={name}>
                {({ field, form }: any) => (
                    <input
                        {...field}
                        type={type ? type : 'text'}
                        className="form-control mb-3"
                        onChange={(e) => {
                            const { value } = e.target;
                            // Custom logic to allow only digits greater than 0
                            const newValue = value.replace(/\D/g, ''); // Remove non-digit characters
                            if (newValue === '' || parseInt(newValue) <= 0) {
                                form.setFieldValue(name, ''); // Set empty value or reset to prevent non-numeric or zero values
                            } else {
                                form.setFieldValue(name, newValue); // Set the cleaned numeric value
                            }
                        }}
                    />
                )}
            </Field>
            <small className="errorMsg">
                <ErrorMessage name={name} />
            </small>
        </div>
    );
};

export default NumericField;
