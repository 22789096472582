interface IToastProps {
    heading: string;
    content: string;
}

const CustomToast: React.FC<IToastProps> = ({ ...props }) => {
    const { content, heading } = props;
    return <>
        <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: 11 }}>
            <div id="liveToast" className="toast hide" role="alert" aria-live="assertive" aria-atomic="true">

                <div className="toast-header">
                    <h5>{heading}</h5>
                    <div className="toast-body">
                        {content}
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default CustomToast;