import { useRef, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { SubmitStatus } from '../../../components/formik-model-dialog.component';
import { Room } from '../../../models/claim/room';
import { Unit } from '../../../models/claim/unit';
import { EditRoom } from './edit-room.component';
import { Building } from '../../../models/claim/building';
import { GuardedComponent } from '../../../components/guarded.componemt';
import { PermissionName } from '../../../models/user-security/permission.name';
import { ListBox, ListBoxItemClickEvent } from '@progress/kendo-react-listbox';
import iconEdit from '../../../images/edit';
import EditIcon from '../../../images/edit';
import { isatty } from 'tty';
import { compareStrings } from '../../../utils/array.util';

interface RoomComponentProps {
    building: Building;
    unit: Unit;
    selectRoom: any;
    onEditRoom: any;
    onRoomDeleted: (room: Room) => any;
    onRoomSelect: Function;
}

export const RoomComponent = ({
    building,
    unit,
    selectRoom,
    onEditRoom,
    onRoomDeleted,
    onRoomSelect
}: RoomComponentProps) => {
    const [editRoom, setEditRoomData] = useState<{ building: Building; room: Room }>();
    const [searchKey, setSearchKey] = useState<string>();
    const isAddRoom = useRef<boolean>(true);
    let sortedrooms = unit?.rooms?.sort((a, b) => compareStrings(a.roomName, b.roomName));
    const rooms = sortedrooms?.filter((f) => {
        return searchKey ? f.roomName.toLowerCase().search(searchKey.toLowerCase()) > -1 : true;
    });

    const onAddRoom = () => {
        if (unit && isAddRoom.current === true) {
            const room: Room = {
                floorID: unit?.floorID,
                unitID: unit?.unitID,
                roomID: 0,
            };
            setEditRoomData({
                building: building,
                room: room,
            });
        }
    };

    const onEditRoomClick = (room: Room) => {
        room.floorID = unit.floorID;
        setEditRoomData({
            building: building,
            room: room,
        });
    };

    const onSaveComplete = (submiStatus: SubmitStatus, data: Room) => {
        if (submiStatus === SubmitStatus.Completed || submiStatus === SubmitStatus.CompletedAddNew) {
            onEditRoom({ ...data });
        }

        setEditRoomData(null);
        if (submiStatus === SubmitStatus.CompletedAddNew && isAddRoom) {
            onAddRoom();
            isAddRoom.current = true;
        }
        else {
            if (submiStatus === SubmitStatus.Completed || submiStatus === SubmitStatus.Cancel) {
                isAddRoom.current = true;
            } else {
                isAddRoom.current = false;
            }
        }
    };

    const onItemClick = (event: ListBoxItemClickEvent) => {
        selectRoom(event.dataItem);
        onRoomSelect(event.dataItem);
    };

    const onSearch = (e: any) => {
        setSearchKey(e.target.value);
    };

    const handleRoomDeleted = (room: Room) => {
        setEditRoomData(null);
        onRoomDeleted(room);
    };

    const templatedItem = (props: any) => {
        let { dataItem, selected, ...others } = props;
        return (
            <li {...others}>
                <div className="d-flex w-100">
                    <span>
                        {props.dataItem.roomName} {/* ({props.dataItem.roomNumber}) */}
                    </span>
                    <GuardedComponent permission={PermissionName.AddRoom}>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onEditRoomClick(props.dataItem);
                            }}
                            className="action-btns k-grid-edit-command ms-auto"
                        >
                            <EditIcon></EditIcon>  {/* <img src={iconEdit} title="Edit" /> */}
                        </button>
                    </GuardedComponent>
                </div>
            </li>
        );
    };

    return (
        <div>
            <div className="d-flex mb-2">
                <h5>ROOMS</h5>
                <GuardedComponent permission={PermissionName.AddRoom}>
                    <button className="add-edit-btn ms-auto" onClick={onAddRoom}>
                        Add Room
                    </button>
                </GuardedComponent>
            </div>

            <div>
                <input
                    type="text"
                    className="form-control mb-2 searchBg gray-bg"
                    placeholder="Search"
                    value={searchKey}
                    onChange={onSearch}
                />
            </div>

            {unit?.rooms && (
                <ListBox
                    data={rooms}
                    item={templatedItem}
                    textField="roomName"
                    selectedField="isSelected"
                    onItemClick={(e: ListBoxItemClickEvent) => onItemClick(e)}
                />
            )}

            {editRoom && (
                <EditRoom
                    data={editRoom}
                    onClose={onSaveComplete}
                    onDeleted={handleRoomDeleted}
                    showAddAnother={editRoom.room?.roomID > 0 ? false : true}
                />
            )}
        </div>
    );
};
