interface DoorIconProps {
    color: string;
}
const DoorGray: React.FC<DoorIconProps> = (props: DoorIconProps) => {
    const { color } = props;
    return <> <svg
        xmlns="http://www.w3.org/2000/svg"

        height="35"
        viewBox="0 0 18.756 28.648"
    >
        <path
            id="Path_24"
            d="M-577.328,251h.168a.365.365,0,0,0,.095.049q4.508.816,9.017,1.63l2.851.516v.29q-.008,11.847-.011,23.693c0,.239-.073.311-.3.351q-3.975.7-7.947,1.424l-3.884.7v-2.134h-6.614V253.072h6.625Zm-.018,24.553V255.086h-4.63v20.467Zm1.294-10.238c0,.54.315.961.727.962a.863.863,0,0,0,.737-.957.859.859,0,0,0-.746-.948A.854.854,0,0,0-576.052,265.315Z"
            transform="translate(583.953 -251)"
            fill={color}
        />
    </svg>
    </>
};

export default DoorGray;
