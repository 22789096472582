export enum PermissionName {
    ViewMaster = 'ViewMaster',
    AddMaster = 'AddMaster',
    EditMaster = 'EditMaster',
    DeleteMaster = 'DeleteMaster',
    //CLAIM
    ViewClaim = 'ViewClaim',
    ViewClaimDetails = 'ViewClaimDetails',
    AddClaim = 'AddClaim',
    EditClaim = 'EditClaim',
    DeleteClaim = 'DeleteClaim',
    //BUILDING
    ViewBuilding = 'ViewBuilding',
    ViewBuildingImage = 'ViewBuildingImage',
    AddBuilding = 'AddBuilding',
    EditBuilding = 'EditBuilding',
    DeleteBuilding = 'DeleteBuilding',
    AddFloor = 'AddFloor',
    AddUnit = 'AddUnit',
    AddRoom = 'AddRoom',
    //WINDOW
    ViewWindow = 'ViewWindow',
    AddWindow = 'AddWindow',
    EditWindow = 'EditWindow',
    DeleteWindow = 'DeleteWindow',
    //DOOR
    ViewDoor = 'ViewDoor',
    AddDoor = 'AddDoor',
    EditDoor = 'EditDoor',
    DeleteDoor = 'DeleteDoor',
    //RAILING
    ViewRailing = 'ViewRailing',
    AddRailing = 'AddRailing',
    EditRailing = 'EditRailing',
    DeleteRailing = 'DeleteRailing',
    //OBSERVATION
    ViewObservation = 'ViewObservation',
    AddObservation = 'AddObservation',
    EditObservation = 'EditObservation',
    DeleteObservation = 'DeleteObservation',
    //ROLE
    ViewRole = 'ViewRole',
    AddRole = 'AddRole',
    EditRole = 'EditRole',
    DeleteRole = 'DeleteRole',
    //USER
    ViewUser = 'ViewUser',
    AddUser = 'AddUser',
    EditUser = 'EditUser',
    DeleteUser = 'DeleteUser',
    //HOME
    ViewQuickLink = 'ViewQuickLink',
    ViewMap = 'ViewMap',
    ViewHomeClaim = 'ViewHomeClaim',
    EditHome = 'EditHome',
}
