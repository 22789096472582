import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { LoadingIndicator } from './loading-indicator.component';
import { LOGIN_DEMO_KEY } from '../constants';
import { usePostData } from '../hooks/use-post-data.hook';
import { Form, Formik } from 'formik';
import { InputField } from './form/input.feild.component';
import { DatePickerField } from './form/datepicker-field.component';

export enum ShareType {
    Building = 'Building',
    Claim = 'Claim',
}

interface ShareLinkModelDialogProps {
    shareUrl: string;
    type: ShareType;
    typeId: number | string;
    onClose: () => any;
}

export const ShareLinkModelDialog = ({
    shareUrl,
    type,
    typeId,
    onClose,
}: ShareLinkModelDialogProps) => {
    const [showClipboardCopyConfirmation, setShowClipboardCopyConfirmation] = useState(false);
    const {
        submitData,
        response: { isSubmitting, result, error },
    } = usePostData<any>('login/create', 'POST');

    useEffect(() => {
        if (!error && !isSubmitting && result) {
            copyShareLink();
        }
    }, [result]);

    const getSharedUrl = () => {
        return `${shareUrl}?${LOGIN_DEMO_KEY}=${result.demoKeyGUID}`;
    };

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = (values: any) => {
        submitData({
            data: { ...values, type: type, typeID: typeId },
        });
    };

    const copyShareLink = () => {
        navigator.clipboard.writeText(getSharedUrl());
        setShowClipboardCopyConfirmation(true);
    };

    const initialLoginData = {
        expiresOn: '',
    };

    const shareSchema = Yup.object().shape({
        expiresOn: Yup.string().required('Date is required'),
    });

    if (showClipboardCopyConfirmation) {
        return (
            <Dialog title="LINK COPIED" onClose={handleClose}>
                Link
                <b>
                    <i>{getSharedUrl()}</i>
                </b>{' '}
                Copied to the clipboard. Please use the link to share with your customers.
                <DialogActionsBar>
                    <div className="d-flex margin-fix mt-3 p-3">
                        <button className="btn btn-primary ms-auto btn-sm" onClick={handleClose}>
                            OK
                        </button>
                    </div>
                </DialogActionsBar>
            </Dialog>
        );
    }

    return (
        <Dialog title="SHARE LINK" onClose={handleClose}>
            <div className="linkWindow">
                <LoadingIndicator isLoading={isSubmitting} />
            </div>
            {error && `Error creating share link`}


            <Formik
                initialValues={initialLoginData}
                validationSchema={shareSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form className="pt-4 datepicLabel">
                        <DatePickerField name="expiresOn" label="Expiry Date*" startDate={new Date()} />
                        <div className="d-flex mt-4 border-top pt-3">
                            <button className="btn btn-secondary btn-sm" onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                id="btnSignIn"
                                className="btn btn-primary btn-sm ms-auto"
                                type="submit"
                            >
                                Generate Link and Copy
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>


        </Dialog>
    );
};
