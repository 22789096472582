import Select from 'react-select';
import { ClaimObservation } from '../../../models/claim/claim-observation';
import { ObservationListComponent } from './observation-list.component';
import { Building } from '../../../models/claim/building';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { GetBuildingDetail } from '../../../redux/actions/buildingactions';
import { GetClaimObservationForEntity } from '../../../redux/actions/claimobservationaction';
import { Floor } from '../../../models/claim/floor';
import { Room } from '../../../models/claim/room';
import { Unit } from '../../../models/claim/unit';

interface CopyRoomObservationsComponentProps {
    onCopyObservation: (observation: ClaimObservation) => any;
    buildingList: Building[];
}

interface SelectOption {
    label: string;
    value: number;
}

export const CopyRoomObservationsComponent = ({
    onCopyObservation,
    buildingList,
}: CopyRoomObservationsComponentProps) => {
    const [building, setBuilding] = useState<SelectOption>(null);
    const [floors, setFloors] = useState<SelectOption[]>([]);
    const [floor, setFloor] = useState<SelectOption>(null);
    const [floorlist, setFloorList] = useState<Floor[]>([]);
    const [units, setUnits] = useState<SelectOption[]>([]);
    const [unit, setUnit] = useState<SelectOption>(null);
    const [rooms, setRooms] = useState<SelectOption[]>([]);
    const [room, setRoom] = useState<SelectOption>(null);
    const [roomList, setRoomList] = useState<Room[]>([]);
    const [observations, setObservations] = useState<ClaimObservation[]>([]);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (!isEmpty(buildingList)) {
            const buildingOptions: SelectOption[] = buildingList.map((b) => ({
                label: b.buildingName,
                value: b.buildingID,
            }));
            setBuilding(buildingOptions[0]);
        }
    }, [buildingList]);

    useEffect(() => {
        if (building) {
            dispatch(GetBuildingDetail(building.value)).then((value) => {
                const data = value.payload as Building;
                const floorOptions: SelectOption[] = data.floors.map((f) => ({
                    label: f.floorName,
                    value: f.floorID,
                }));
                setFloors(floorOptions);
                setFloorList(data.floors);
                setUnits([]);
                setRooms([]);
                setFloor(null);
                setUnit(null);
                setRoom(null);
            });
        }
    }, [building]);

    useEffect(() => {
        if (floor) {
            const selectedFloor = floorlist.find((f) => f.floorID === floor.value);
            setUnits(selectedFloor.units.map((u) => ({ label: u.unitName, value: u.unitID })));
            setRooms([]);
            setUnit(null);
            setRoom(null);
        }
    }, [floor]);

    useEffect(() => {
        if (unit) {
            const selectedUnit = floorlist.find((f) => f.floorID === floor.value)
                .units.find((u) => u.unitID === unit.value);
            setRoomList(selectedUnit.rooms);
            setRooms(selectedUnit.rooms.map((r) => ({ label: r.roomName, value: r.roomID })));
            setRoom(null);
        }
    }, [unit]);

    useEffect(() => {
        if (room) {
            dispatch(GetClaimObservationForEntity({ entityID: room.value, entityType: 'room' }))
                .then((value) => {
                    const data = value.payload as ClaimObservation[];
                    let modifiedData:ClaimObservation[]=JSON.parse(JSON.stringify(data));
                    setObservations(modifiedData);
                });
        }
    }, [room]);

    const handleBuildingSelectionChange = (selectedBuilding: SelectOption) => {
        const selectedBuildingData = buildingList.find(
            (b) => b.buildingID === selectedBuilding.value,
        );
        setBuilding(selectedBuilding);
        if (selectedBuildingData) {
            const floorOptions: SelectOption[] = selectedBuildingData.floors.map((f) => ({
                label: f.floorName,
                value: f.floorID,
            }));
            setFloors(floorOptions);
            setUnits([]);
            setRooms([]);
            setFloor(null);
            setUnit(null);
            setRoom(null);
        }
    };

    const handleFloorSelectionChange = (selectedFloor: SelectOption) => {
        setFloor(selectedFloor);
    };

    const handleUnitSelectionChange = (selectedUnit: SelectOption) => {
        setUnit(selectedUnit);
    };

    const handleRoomSelectionChange = (selectedRoom: SelectOption) => {
        setRoom(selectedRoom);
    };

    return (
        <div>
            <div>
                Buildings
                <Select
                    value={building}
                    options={buildingList.map((b) => ({
                        label: b.buildingName,
                        value: b.buildingID,
                    }))}
                    onChange={handleBuildingSelectionChange}
                />
            </div>
            <div>
                Floors
                <Select value={floor} options={floors} onChange={handleFloorSelectionChange} />
            </div>
            <div>
                Units
                <Select value={unit} options={units} onChange={handleUnitSelectionChange} />
            </div>
            <div>
                Rooms
                <Select value={room} options={rooms} onChange={handleRoomSelectionChange} />
            </div>

            <div className="copy-observation-list">
                <ObservationListComponent
                    observations={observations}
                    onCopyObservation={onCopyObservation}
                    ispopup
                    onShowObservationPhotos={()=>{}}
                />
            </div>
        </div>
    );
};
