import { configureStore } from '@reduxjs/toolkit';
import buildingreducer from './redux/reducers/building/buildingdetailreducer';
import claimobservationreducer from './redux/reducers/claimobservationreducer';
import buildinglistreducer from './redux/reducers/building/buildinglistreducer';
import filereducer from './redux/reducers/filereducer';
const appstore = configureStore({
    reducer: {
        buildingSlice: buildingreducer,
        claimObservationSlice: claimobservationreducer,
        buildingListSlice: buildinglistreducer,
        fileSlice: filereducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof appstore.getState>;
export type AppDispatch = typeof appstore.dispatch;
export default appstore;
