import { ErrorMessage, Field } from 'formik';

interface CheckboxFieldProps {
    label: string;
    name: string;

}

export const CheckboxField = ({ name, label }: CheckboxFieldProps): any => {
    return (
        <div>
            <label className="label">
                <Field id="txt_client" className="k-checkbox k-checkbox-md k-rounded-md" type="checkbox" name={name} /> {label}
            </label>
            <small className="errorMsg">
                <ErrorMessage name={name} />
            </small>
        </div>
    );
};
