import React, { useState } from 'react';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import '@progress/kendo-theme-default/dist/all.css';
interface DataItem {
    text: string;
    value: string;
}
interface SingleSelectProps {

    onchange: Function;
    id: string;
}

const SingleSelectDropdown: React.FC<SingleSelectProps> = ({ ...props }) => {
    const { onchange, id } = props;
    const [selectedItem, setSelectedItem] = useState<DataItem | null>({ text: "Both", value: "" });

    const data: DataItem[] = [
        { text: "Both", value: "" },
        { text: "Yes", value: '1' },
        { text: "No", value: '2' },

    ];

    const handleChange = (event: DropDownListChangeEvent) => {
        setSelectedItem(event.target.value as DataItem);
        console.log("Selected option:", event.target.value);
        onchange(event.target.value.value as string)
    };

    return (
        <div>
            <DropDownList

                data={data}
                id={id}
                key={id}
                name={id}
                textField="text"
                dataItemKey="value"
                onChange={handleChange}
                value={selectedItem}
            />
            {/* {selectedItem && <div>Selected option: {selectedItem.text}</div>} */}
        </div>
    );
};

export default SingleSelectDropdown;
