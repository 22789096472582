import { PermissionCheckType } from '../models/user-security/permission-check-type';
import { PermissionName } from '../models/user-security/permission.name';
import { validateRequiredPermissions } from '../utils/permission.manager';
import { useCurrentUser } from './user-current-user.hook';

export const usePermission = (
    permission: PermissionName | PermissionName[],
    permissionCheckType = PermissionCheckType.All,
) => {
    const { currentPermissions } = useCurrentUser();
    return {
        hasPermission: validateRequiredPermissions(
            permission,
            permissionCheckType,
            currentPermissions,
        ),
    };
};
