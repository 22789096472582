import { ErrorMessage, useField, useFormikContext } from 'formik';
import { ColorResult, SketchPicker } from 'react-color';

interface ColorPickerFieldProps {
    name: string;
    label: string;
}

export const ColorPickerField = ({ name, label }: ColorPickerFieldProps): any => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField<string>(name);

    const handleColorChangeComplete = (color: ColorResult) => {
        setFieldValue(name, color.hex);
    };

    return (
        <div>
            {label && <label className="label">{label}</label>}
            <SketchPicker color={field.value} onChangeComplete={handleColorChangeComplete} />
            <small className="errorMsg">
                <ErrorMessage name={name} />
            </small>
        </div>
    );
};
