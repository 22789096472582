import type { AxiosError } from 'axios';
import axios from 'axios';
import { createQuery } from 'react-query-kit';

type Params = {
    SDate: string,
    FDate: string
}
export const useSchedulerDashboardCounts = createQuery<ISchedulerCount[], Params, AxiosError>({
    queryKey: ['schedulerDashboardCounts'],
    fetcher: (params) =>
        axios.get('SchedulerAssignments/GetSchedulerDashboardCounts', { params }).then(response => response.data?.result || []),
    refetchOnWindowFocus: false
});
