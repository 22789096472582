import { useContext } from 'react';
import { AppContext } from '../core/app.context';

export const useCurrentUser = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useCount must be used within a CountProvider');
    }
    if (context.state.currentUser) return context.state.currentUser;
};
