import { ColorInfo, ImageConfig } from '../image-draw.config';
import { ShapeType, Shape } from '../../../../models/building-image/image-draw.types';
import { forEach, isNil } from 'lodash';
import { getDistance, getMaxSquareSize } from './geometry.util';

export const getShapeCreator = (shapeType: ShapeType) => {
    switch (shapeType) {
        case ShapeType.Rectangle:
            return drawRectangle;
        case ShapeType.Circle:
            return drawCircle;
        default:
            return drawRectangle;
    }
};

export const drawColorTags = (context: CanvasRenderingContext2D, shape: Shape) => {
    const { point1, point2, colorTags } = shape;
    const rightTop = { x: point2.x, y: point1.y };
    const calculatedSquareSize = getMaxSquareSize(
        getDistance(point1, rightTop) - ImageConfig.LINE_THICKNESS * 2,
        getDistance(rightTop, point2) - ImageConfig.LINE_THICKNESS * 2,
        colorTags.length,
    );
    if (isNil(calculatedSquareSize)) {
        return;
    }
    const margin = calculatedSquareSize * 0.2;
    const squareSize = calculatedSquareSize - margin;
    let x = point1.x + ImageConfig.LINE_THICKNESS;
    let y = point1.y + ImageConfig.LINE_THICKNESS;

    forEach(colorTags, (tag, i) => {
        if (i > 0) {
            x = x + calculatedSquareSize;
        }
        if (x + squareSize > point2.x - ImageConfig.LINE_THICKNESS) {
            x = point1.x + ImageConfig.LINE_THICKNESS;
            y = y + calculatedSquareSize;
        }

        context.beginPath();
        context.fillStyle = tag;
        context.lineWidth = 1;
        context.rect(x, y, squareSize, squareSize);
        context.fill();
    });
};

const setStrock = (context: CanvasRenderingContext2D, shape: Shape, colorInfo: ColorInfo) => {
    const { a: zoom } = context.getTransform();
    context.lineWidth = Math.ceil(ImageConfig.LINE_THICKNESS / zoom);
    if (shape.isGhost) {
        context.strokeStyle = colorInfo.ghostColor;
        context.setLineDash([Math.ceil(5 / zoom), Math.ceil(4 / zoom)]);
    } else if (shape.isSelected) {
        context.setLineDash([]);
        context.strokeStyle = colorInfo.selectionColor;
        context.lineWidth = Math.ceil((ImageConfig.LINE_THICKNESS + 1) / zoom);
    } else if (shape.isHighlighted) {
        context.setLineDash([]);
        context.strokeStyle = colorInfo.highlightColor;
    } else {
        context.setLineDash([]);
        context.strokeStyle = colorInfo.defaultColor;
    }
};

const drawRectangle = (context: CanvasRenderingContext2D, shape: Shape, colorInfo: ColorInfo) => {
    const { point1, point2 } = shape;
    setStrock(context, shape, colorInfo);
    context.rect(point1.x, point1.y, point2.x - point1.x, point2.y - point1.y);
    context.stroke();

    if (shape.colorTags) {
        drawColorTags(context, shape);
    }
};

const drawCircle = (context: CanvasRenderingContext2D, shape: Shape, colorInfo: ColorInfo) => {
    const { point1, point2 } = shape;
    setStrock(context, shape, colorInfo);
    const radious = Math.sqrt(
        (point2.x - point1.x) * (point2.x - point1.x) +
            (point2.y - point1.y) * (point2.y - point1.y),
    );
    context.arc(point1.x, point1.y, radious, 0, 2 * Math.PI);
    context.stroke();
};
