import { useState } from "react";
import { SLOT_TYPES } from "../../../constants";
import CloseIcon from "../../../images/close-icon";
import LocationIcon from "../../../images/location";
import { ScheduledSlot } from "../../../types/ScheduledSlot";

import { convertToTimezoneWithDateString } from "../../../utils";
import { Modal } from "./modal";
import { Map } from "./map";

interface Props {
    slot: ScheduledSlot;
    handleDelete: (slot: ScheduledSlot) => void;
    showClaimNumber?: boolean
}

const THEME = {
    [SLOT_TYPES.scheduled]: {
        backgroundColor: '#eeeeee',
        className: 'inner-available-grid-4col2',
        classNameWithLocation: 'inner-available-grid-4col2',
    },
    [SLOT_TYPES.availabilityReceived]: {
        backgroundColor: "#D5FFC4",
        textClassName: 'text-success',
        className: 'inner-available-grid',
        classNameWithLocation: 'inner-available-grid-4col',
    },
    [SLOT_TYPES.unavailable]: {
        backgroundColor: '#f8d7da',
        textClassName: 'text-danger',
        className: 'inner-available-grid',
        classNameWithLocation: 'inner-available-grid-4col',
    },

}


export function Slot({ slot, handleDelete, showClaimNumber = false }: Props) {


    const theme = THEME[slot.statusCode] || THEME[SLOT_TYPES.availabilityReceived];
    const showLocation = slot.lat && slot.long

    const [showLocationMap, setShowLocationMap] = useState(false)

    return (
        <>
            <div style={{ backgroundColor: theme.backgroundColor }} className="shadow-md rounded-3 p-3 m-1">
                <div className={showLocation ? theme.classNameWithLocation : theme.className}>
                    <div className='subgrid web-only'>{`${convertToTimezoneWithDateString(slot?.dateFrom).format("hh:mmA")}-${convertToTimezoneWithDateString(slot?.dateTo).format("hh:mmA")}`}</div>

                    <div className='subgrid mobile-only'>{`${convertToTimezoneWithDateString(slot?.dateFrom).format("MM/DD/YYYY hh:mmA")}-${convertToTimezoneWithDateString(slot?.dateTo).format("hh:mmA")}`}</div>

                    {slot.statusCode === SLOT_TYPES.scheduled && showClaimNumber && <div className="claimNumber" style={{ color: "#f58a1f", display: "inline-block" }}>{"#" + slot?.claimNo}</div>}
                    {showLocation ?
                        <div className="location_icon" onClick={() => setShowLocationMap(true)}>
                            <h5 className="mb-0">
                                <LocationIcon />
                            </h5>
                        </div> : null
                    }
                    <div className={theme.textClassName}><h5>{slot.slotStatus || slot.slotstatus}</h5></div>




                    {slot.statusCode !== SLOT_TYPES.scheduled ? <div className="itemClose" onClick={() => handleDelete(slot)} style={{ cursor: 'pointer' }}>
                        <CloseIcon />
                    </div> : null}
                </div>
            </div>
            {showLocationMap &&
                <Modal show={showLocationMap} onClose={() => setShowLocationMap(false)} title={slot.location} width='50%'>
                    <Map location={{ lat: slot.lat, long: slot.long }} />
                </Modal>
            }
        </>
    )

}