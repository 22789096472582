import type { AxiosError } from 'axios';
import axios from 'axios';
import { createQuery } from 'react-query-kit';

type Params = { claimGUID: string };

export const useHomeownerByClaimGuid = createQuery<IHomeOwnerLandingDetails, Params, AxiosError>({
    queryKey: ['homeownerByClaimGuid'],
    fetcher: (params) =>
        axios.get('SchedulerAssignments/GetHomeOwnerInfo', { params }).then(response => response.data?.result?.[0]),
    refetchOnWindowFocus: false
});


