import { createRef, useEffect, useState } from 'react';
import 'tui-image-editor/dist/tui-image-editor.css';
// @ts-ignore
import ImageEditor from '@toast-ui/react-image-editor';
import { KeyStoneFile } from '../../models/claim/keystone-file';
import { usePostData } from '../../hooks/use-post-data.hook';
import { SubmitStatus } from '../../components/formik-model-dialog.component';
import { LoadingIndicator } from '../../components/loading-indicator.component';

interface ImageEditorComponentProps {
    image: KeyStoneFile;
    onCancel: any;
    onDeleteImage: any;
    onSubmitImage: any;
}

export const ImageEditorComponent = ({
    image,
    onCancel,
    onDeleteImage,
    onSubmitImage,
}: ImageEditorComponentProps) => {
    const {
        submitData,
        response: { isSubmitting, result, error },
    } = usePostData<any>('file', 'POST', 'multipart/form-data');
    const [note, setNote] = useState<string>(image?.comments);
    const imageEditor = createRef();
    useEffect(() => {
        if (!isSubmitting && result && !error) {
            onSubmitImage(SubmitStatus.Completed, result);
        }
    }, [result, isSubmitting]);

    const myTheme = {
        'header.display': 'none',
    };

    const dataURLtoBlob = (dataurl: any): Blob => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };

    const handleSubmit = () => {
        const editor: any = imageEditor.current;
        const data = editor.imageEditorInst?.toDataURL();
        if (data) {
            const formData = new FormData();
            formData.append('UploadedFile', dataURLtoBlob(data));
            formData.append('EntityID', image.entityID.toString());
            formData.append('EntityType', image.entityType);
            formData.append('SourceFileID', image.fileGUID);
            formData.append('FileName', image.fileName);
            formData.append('FileType', image.fileType.toString());
            formData.append('Comments', note);
            formData.append('ContextID', '123456789-01234');
            submitData({ data: formData });
        }
    };

    const deleteImage = () => {
        onDeleteImage({ ...image });
    };

    const handleNoteChange = (e: any) => {
        setNote(e.target.value);
    };

    const props = {
        includeUI: {
            loadImage: {
                path: image.fileCDN,
                name: 'SampleImage',
            },
            menu: ['crop', 'flip', 'rotate', 'draw', 'shape', 'icon', 'text', 'mask', 'filter'],
            initMenu: 'shape',
            uiSize: {
                width: '100%',
                height: '700px',
            },
            menuBarPosition: 'bottom',
            theme: myTheme,
        },
        cssMaxWidth: 700,
        cssMaxHeight: 500,
        selectionStyle: {
            cornerSize: 20,
            rotatingPointOffset: 70,
        },
        usageStatistics: false,
    };

    if (!image) {
        return null;
    }

    return (
        <>
            <div>
                <div className="image-editer-wrap">
                    <div>
                        <ImageEditor {...props} ref={imageEditor} />
                    </div>
                    <div>
                        <label className="label">Note</label>
                        <textarea
                            className="form-control min-240"
                            value={note}
                            onChange={handleNoteChange}
                        />
                        <div className="d-flex mt-4">
                            <button className="btn btn-secondary btn-sm me-2" onClick={onCancel}>
                                Cancel
                            </button>
                            <button className="btn btn-secondary btn-sm" onClick={deleteImage}>
                                Delete
                            </button>
                            <button
                                className="btn btn-primary btn-sm ms-auto"
                                onClick={handleSubmit}
                            >
                                Save
                            </button>
                            <LoadingIndicator isLoading={isSubmitting} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
