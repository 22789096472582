const ZoomIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path fill="#f68b1f" id="Path_file-pdf-box" data-name="Path / file-pdf-box" d="M12,10.5h1v3H12v-3m-5,1H8v-1H7v1M20,6V18a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V6A2,2,0,0,1,6,4H18a2,2,0,0,1,2,2M9.5,10.5A1.5,1.5,0,0,0,8,9H5.5v6H7V13H8a1.5,1.5,0,0,0,1.5-1.5v-1m5,0A1.5,1.5,0,0,0,13,9H10.5v6H13a1.5,1.5,0,0,0,1.5-1.5v-3m4-1.5h-3v6H17V13h1.5V11.5H17v-1h1.5Z" transform="translate(-4 -4)" />
        </svg>

    );
};

export default ZoomIcon
