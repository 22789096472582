import { ErrorMessage, Field } from 'formik';

interface InputFieldProps {
    label?: string;
    name: string;
    type?: string;
    isDisabled?: boolean;
    maxLength?: number;
}

export const InputFieldScheduler = ({ name, type, label, isDisabled, maxLength }: InputFieldProps): any => {
    return (
        <div>
            {label && <label className="label">{label}</label>}
            <Field maxLength={maxLength} disabled={isDisabled} type={type ? type : 'input'} className="form-control  mb-3" name={name} />
            <small className="errorMsg">
                <ErrorMessage name={name} />
            </small>
        </div>
    );
};
