import { find, isEmpty, isNil, map } from 'lodash';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { useLoadData } from '../../../hooks/use-load-data.hook';
import { ClaimObservation } from '../../../models/claim/claim-observation';
import { Room } from '../../../models/claim/room';
import { UnitRailing } from '../../../models/claim/unit-railing';
import { ObservationListComponent } from './observation-list.component';

interface CopyRailingObservationsComponentProps {
    roomId: number;
    onCopyObservation: (observation: ClaimObservation) => any;
}

export const CopyRailingObservationsComponent = ({
    roomId,
    onCopyObservation,
}: CopyRailingObservationsComponentProps) => {
    const [railing, setSelectedItem] = useState<UnitRailing>();
    const { isLoading, result, isError } = useLoadData<Room>(
        `rooms?roomid=${roomId}`,
        isNil(roomId),
    );

    useEffect(() => {
        if (result && result.railings && !isEmpty(result.railings)) {
            setSelectedItem(result.railings[0]);
        }
    }, [result]);

    useEffect(() => {
        if (isNil(roomId)) {
            setSelectedItem(null);
        }
    }, [roomId]);

    const selectedOption = { label: railing?.railingName, value: railing?.railingID };

    const options = map(result?.railings, (w) => {
        return {
            label: w.railingName,
            value: w.railingID,
        };
    });

    const handleRailingSelectionChange = (selected: any) => {
        const selectedItem: any = find(result.railings, (w) => w.railingID === selected?.value);
        setSelectedItem(selectedItem);
    };

    if (isLoading) {
        return <LoadingIndicator isLoading={isLoading} />;
    }

    return (
        <div>
            Railing
            <Select value={selectedOption} options={options} onChange={handleRailingSelectionChange} />
            Observations
            <div className="copy-observation-list">
            <ObservationListComponent
                observations={railing?.observations}
                onCopyObservation={onCopyObservation}
                ispopup
                onShowObservationPhotos={()=>{}}
            />
            </div>
        </div>
    );
};
