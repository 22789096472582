import { createReducer, createSlice } from '@reduxjs/toolkit';
import { Building } from '../../models/claim/building';
import { GetBuildingDetail } from '../actions/buildingactions';
import { ClaimObservation } from '../../models/claim/claim-observation';
import { GetClaimObservationForEntity } from '../actions/claimobservationaction';

export interface ClaimObservationState {
    claimObservations?: ClaimObservation[];
    claimObservationLoading: boolean;
}

const initialBuildingState: ClaimObservationState = {
    claimObservationLoading: true,
    claimObservations: [],
};

const claimObservationSlice = createSlice({
    name: 'building',
    initialState: initialBuildingState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            GetClaimObservationForEntity.pending,
            (state: ClaimObservationState, action) => {
                state.claimObservationLoading = true;
                state.claimObservations = undefined;
            },
        );
        builder.addCase(
            GetClaimObservationForEntity.fulfilled,
            (state: ClaimObservationState, action) => {
                state.claimObservationLoading = false;

                state.claimObservations = action.payload;
            },
        );
        builder.addCase(
            GetClaimObservationForEntity.rejected,
            (state: ClaimObservationState, action) => {
                state.claimObservationLoading = false;
                state.claimObservations = undefined;
               // alert(action.error);
            },
        );
    },
});

export default claimObservationSlice.reducer;
