import React, { useState, useEffect } from 'react';
import { IClaimRequest } from '../../../models/scheduler/claim-request';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { formatDate, parseDate } from '@progress/kendo-intl';
import { convertToTimezoneWithDateString } from '../../../utils';
import noImage from '../../../images/no-result-found.png';
import { SLOT_TYPES } from '../../../constants';

interface IRequestStatusProps {
    isErrorRequestStatus: boolean;
    isLoadingRequestStatus: boolean;
    requestStatus: IClaimRequest[];
}


const BADGE_CLASS = {
    [SLOT_TYPES.availabilityReceived]: 'green-color',
    [SLOT_TYPES.scheduled]: 'green-color',
    [SLOT_TYPES.awaitingAvailability]: 'orange-color',
    [SLOT_TYPES.declined]: 'red-color',
    default: 'black-color'
}

const RequestStatus: React.FunctionComponent<IRequestStatusProps> = ({
    isErrorRequestStatus,
    isLoadingRequestStatus,
    requestStatus
}) => {




    return (
        <>
            {isLoadingRequestStatus && <LoadingIndicator isLoading={true} />}
            {isErrorRequestStatus && <center>Loading Error</center>}

            {!isLoadingRequestStatus && requestStatus && (
                <div className='card border-0 sw-shadow radius-15 overflow-hidden p-3'>
                    <div style={{ maxHeight: '393px', overflowY: 'auto' }}>
                        <div className="text-grid-header sticky-top">
                            <div>Name</div>
                            <div>Requested On</div>
                            <div>Status</div>
                        </div>
                        {(requestStatus?.length) > 0 ?

                            requestStatus?.map((e, index) => {

                                const badgeClass = BADGE_CLASS[e.statuscode] || BADGE_CLASS.default

                                return (
                                    <div className="text-grid-body" key={index}>
                                        <div>{e.name}</div>
                                        {/* <div>{formatDate(parseDate(e.requestedOn), "MM/dd/yyyy hh:mm a")}</div> */}
                                        <div>{convertToTimezoneWithDateString(e?.requestedOn).format('MM/DD/YYYY hh:mm A')}</div>
                                        <div>
                                            <span className={`statusbadge ${badgeClass}`} key={e.name}>
                                                {e.status}
                                            </span>
                                        </div>
                                    </div>
                                )
                            }) :
                            (<div className='no-resultFound'>
                                <div> <img src={noImage} /></div>
                                <div><p className='mt-3'>No requests have been made so far. Please request availability from the homeowner and inspectors</p></div>
                            </div>)}

                    </div>
                </div>
            )}
        </>
    );
};

export default RequestStatus;
