import { Route, Routes, useNavigate } from 'react-router-dom';
import { UnauthorizedPage } from '../features/login/unauthorized.page';
import { MasterPage } from '../features/master/master.page';
import { BuildingsPage } from '../features/building/buildings.page';
import { ObservationMasterPage } from '../features/master/observation-master.page';
import { ObservationMasterOptionsPage } from '../features/master/observation-master-options.page';
import { ObservationsPage } from '../features/reports/observation/observations.page';
import { RolePage } from '../features/user-management/role.page';
import { UsersPage } from '../features/user-management/user/users.page';
import { PermissionName } from '../models/user-security/permission.name';
import { BuildingPage } from '../features/building/building.page';
import { PermissionCheckType } from '../models/user-security/permission-check-type';
import { validateRequiredPermissions } from '../utils/permission.manager';
import { useCurrentUser } from '../hooks/user-current-user.hook';
import InspectionReport from '../components/inspection-report.component';
import SchedulerDashboard from '../features/scheduler/dashboard';
import SchedulerDetails from '../features/scheduler/scheduler-details';
import InspectorAvailability from '../features/scheduler/inspector-availability';
import { HeaderComponent } from './header.component';
import { AuthorizeComponent } from './authorize.component';
import { SchedulerHeaderComponent } from './scheduler.header.component';
interface IMenuType {
    menuType: string;
}
export function MainRoutes(props: IMenuType) {
    const { currentPermissions } = useCurrentUser();
    return (
        <AuthorizeComponent role='scheduler'>
            {props.menuType === "scheduler" ?
                <SchedulerHeaderComponent showMenu={true} /> :
                <HeaderComponent />}

            <Routes>
                <Route
                    path="/users"

                    element={
                        checkPermission(currentPermissions, PermissionName.ViewUser) ? (
                            <UsersPage />
                        ) : (
                            <UnauthorizedPage />
                        )
                    }

                />

                <Route
                    path="/roles"
                    element={
                        checkPermission(currentPermissions, PermissionName.ViewRole) ? (
                            <RolePage />
                        ) : (
                            <UnauthorizedPage />
                        )
                    }
                // requiredPrermission={PermissionName.ViewRole}
                />
                <Route
                    path="/inspectionReport"
                    element={
                        checkPermission(currentPermissions, PermissionName.ViewRole) ? (
                            <RolePage />
                        ) : (
                            <UnauthorizedPage />
                        )
                    }
                // requiredPrermission={PermissionName.ViewRole}
                />
                <Route
                    path="/buildings/:claimGuid"
                    element={
                        checkPermission(currentPermissions, PermissionName.ViewClaimDetails) ? (
                            <BuildingsPage />
                        ) : (
                            <UnauthorizedPage />
                        )
                    }
                // requiredPrermission={PermissionName.ViewClaimDetails}
                />
                <Route
                    path="/buildingdetails/:buildingID"
                    element={
                        checkPermission(currentPermissions, PermissionName.ViewBuildingImage) ? (
                            <BuildingPage />
                        ) : (
                            <UnauthorizedPage />
                        )
                    }
                // requiredPrermission={PermissionName.ViewBuildingImage}
                />
                <Route
                    path="/reports/observation/:claimGuid"
                    element={
                        checkPermission(currentPermissions, PermissionName.ViewClaimDetails) ? (
                            <ObservationsPage />
                        ) : (
                            <UnauthorizedPage />
                        )
                    }
                // requiredPrermission={PermissionName.ViewClaimDetails}
                />
                <Route
                    path="/window-master"
                    element={
                        checkPermission(currentPermissions, PermissionName.ViewMaster) ? (
                            <MasterPage masterType="window" />
                        ) : (
                            <UnauthorizedPage />
                        )
                    }
                /*                 masterType="window" */
                // requiredPrermission={PermissionName.ViewMaster}
                />
                <Route
                    path="/door-master"
                    element={
                        checkPermission(currentPermissions, PermissionName.ViewMaster) ? (
                            <MasterPage masterType="door" />
                        ) : (
                            <UnauthorizedPage />
                        )
                    }
                /* masterType="door" */
                // requiredPrermission={PermissionName.ViewMaster}
                />
                <Route
                    path="/railing-master"
                    element={
                        checkPermission(currentPermissions, PermissionName.ViewMaster) ? (
                            <MasterPage masterType="railing" />
                        ) : (
                            <UnauthorizedPage />
                        )
                    }
                // masterType="railing"/*  */
                // requiredPrermission={PermissionName.ViewMaster}
                />
                <Route
                    path="/observation-master"
                    element={
                        checkPermission(currentPermissions, PermissionName.ViewMaster) ? (
                            <ObservationMasterPage masterType="" />
                        ) : (
                            <UnauthorizedPage />
                        )
                    }
                // requiredPrermission={PermissionName.ViewMaster}
                />
                <Route
                    path="/observation-master-options/:observationID"
                    element={
                        checkPermission(currentPermissions, PermissionName.ViewMaster) ? (
                            <ObservationMasterOptionsPage />
                        ) : (
                            <UnauthorizedPage />
                        )
                    }
                // requiredPrermission={PermissionName.ViewMaster}
                />
                <Route
                    path="/"
                    element={
                        // checkPermission(currentPermissions, PermissionName.ViewClaim) ? (
                        <SchedulerDashboard />
                        // ) : (
                        // <UnauthorizedPage />
                        // )
                    }
                // requiredPrermission={PermissionName.ViewClaim}
                />
                <Route
                    path="/schedulerDetail"
                    element={
                        // checkPermission(currentPermissions, PermissionName.ViewClaim) ? (
                        <SchedulerDetails />
                        // ) : (
                        // <UnauthorizedPage />
                        // )
                    }
                // requiredPrermission={PermissionName.ViewClaim}
                />
                <Route
                    path="/inspectionReport"
                    element={
                        checkPermission(currentPermissions, PermissionName.ViewClaim) ? (
                            <InspectionReport />
                        ) : (
                            <UnauthorizedPage />
                        )
                    }
                ></Route>
                <Route
                    path="/inspectoravailability"
                    element={
                        // checkPermission(currentPermissions, PermissionName.ViewClaim) ? (
                        <InspectorAvailability />
                        // ) : (
                        // <UnauthorizedPage />
                        // )
                    }
                // requiredPrermission={PermissionName.ViewClaim}
                />
            </Routes>
        </AuthorizeComponent>
    );

    function checkPermission(
        currentPermissions: PermissionName[],
        requiredPermission: PermissionName | PermissionName[],
    ): boolean {
        let isPermitted: boolean;
        console.log(requiredPermission);

        isPermitted =
            requiredPermission &&
            validateRequiredPermissions(
                requiredPermission,
                PermissionCheckType.All,
                currentPermissions,
            );
        return isPermitted;
    }
};
