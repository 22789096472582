import { useParams } from 'react-router-dom';
import './master.style.scss';
import { ObservationMasterOptionsGridComponent } from './observation-master-options-grid.component';

export const ObservationMasterOptionsPage = (/* props: { match: any } */) => {
    const { observationID } = useParams();
    const masterTypeID = /* props.match.params. */ observationID;
    return (
        <div className="container-fluid">
            <h5 className="text-normal mt-4 mb-3 title-web">OBSERVATIONS OPTIONS</h5>
            <ObservationMasterOptionsGridComponent observationID={parseInt(masterTypeID)} />
        </div>
    );
};
