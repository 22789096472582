import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { MasterGridComponent } from './master-grid.component';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { MasterType } from '../../models/masters/master-type';
import './master.style.scss';
import { InputFieldType } from '../../models/enums/input-field-type';

export const MasterPage = (props: { masterType: string }) => {
    const { isLoading, result, isError } = useLoadData<MasterType[]>(
        `masters/type/${props.masterType}`,
    );

    if (isError) {
        return <div>Error</div>;
    }
    return isLoading ? (
        <div className="text-center w-100">Loading</div>
    ) : (
        <div className="container-fluid">
            <h5 className="text-normal mt-4 mb-3 title-web">{props.masterType.toUpperCase()}S</h5>
            <PanelBar className="masterList" expandMode="single">
                {result?.filter((obj) => { return (obj.inputType == InputFieldType.MultiSelect || obj.inputType == InputFieldType.SingleSelect)})
                .map((item, indx) => (
                    <PanelBarItem
                        title={item.name}
                        key={item.id}
                        expanded={indx === 0}
                        selected={indx === 0}
                    >
                        <MasterGridComponent
                            masterType={props.masterType}
                            masterTypeID={item.id}
                            attributeName={item.name}
                        />
                    </PanelBarItem>
                ))}
            </PanelBar>
        </div>
    );
};
