import { InputFieldType } from '../../models/enums/input-field-type';
import { ObservationType } from '../../models/masters/observation-type';
import { DatePickerField } from './datepicker-field.component';
import { InputField } from './input.feild.component';
import { MultiSelectField } from './multi-select-field.component';
import { SingleSelectField } from './single-select-field.component';

interface DynamicFieldProps {
    name: string;
    type?: InputFieldType;
    optionValueName?: string;
    optionValues?: { value: string; label: string; inputType: InputFieldType, id: number }[];
    observationName?: string;
    observationType?: ObservationType;
    optionvalue?: string;
    initialValue?: boolean;


}

export const DynamicField = ({
    observationType,
    name,
    optionvalue = undefined,
    type,
    optionValueName,
    optionValues,
    observationName,
}: DynamicFieldProps): any => {
    if (
        (type === InputFieldType.Text || type === InputFieldType.Number) &&
        observationName === 'Other'
    ) {
        let label = '';
        if (observationType) {
            label = 'Observation Type*';
        }
        return <InputField name={name} type="text" label={label} />;
    } else if (
        (type === InputFieldType.Text || type === InputFieldType.Number) &&
        observationName === undefined
    ) {
        let label = '';
        if (observationType) {
            label = 'Observation Type*';
        }
        return <InputField name={name} type="text" label={label} />;
    } else if (type === InputFieldType.Date) {
        return (
            <DatePickerField
                name={name}
                startDate={optionvalue ? new Date(optionvalue) : new Date()}
            />
        );
    } else if (type === InputFieldType.SingleSelect) {
        return (
            <SingleSelectField
                name={name}
                optionValues={optionValues}
                optionValueName={optionValueName}
            />
        );
    } else if (type === InputFieldType.MultiSelect) {
        return <div className='mt-1 mb-3 border p-2 border-' style={{ minHeight: "125px", maxHeight: '150px', overflowY: "scroll" }} > <MultiSelectField name={name} optionValues={optionValues} /></div>;
    } else {
        null;
    }
};
