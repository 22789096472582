import { ScheduledSlot } from "../../../types/ScheduledSlot";
import { Slot } from "./slot";

interface GroupedSlot {
    date: string;
    slots: ScheduledSlot[];
}
interface Props {
    groupedSlots: GroupedSlot[];
    handleDelete: (slot: ScheduledSlot) => void;
    showClaimNumber?: boolean;
}

export function AvailabilityList({ groupedSlots, handleDelete, showClaimNumber = false }: Props) {
    return (
        <div>
            {groupedSlots.map(groupedSlot =>
                <div>
                    <div className='web-only titleSlot'>{groupedSlot.date}</div>
                    <div className='responsivfix-3colm'>
                        {groupedSlot.slots.map(slot => <Slot slot={slot} showClaimNumber={showClaimNumber} handleDelete={handleDelete} />)}
                    </div>
                </div>
            )}
        </div>
    )
}