import { useFormikContext } from 'formik';
import { InputFieldType } from '../../models/enums/input-field-type';
import { DynamicField } from './dynamic-field.component';
import { SelectField } from './select-field.component';

interface SingleSelectFieldProps {
    name: string;
    label?: string;
    type?: InputFieldType;
    optionValueName?: string;
    optionValues?: { value: string; label: string; inputType: InputFieldType }[];
}

export const SingleSelectField = ({
    name,
    label,
    optionValueName,
    optionValues,
}: SingleSelectFieldProps): any => {
    const { getFieldProps } = useFormikContext();

    const inpitFieldType = (): InputFieldType => {
        const selectedValue = getFieldProps<string>(name);
        const optionValue = optionValues?.find((o) => o.value === selectedValue.value);
        return optionValue ? optionValue.inputType : InputFieldType.None;
    };

    return (
        <div>
            <SelectField name={name} label={label} options={optionValues} />
            <DynamicField
                name={optionValueName ? optionValueName : 'optionValue'}
                type={inpitFieldType()}
            />
        </div>
    );
};
