import { useLoadData } from '../../hooks/use-load-data.hook';
import { Grid, GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { GridCommandCell } from '../../components/grid-command-cell.component';
import { useEffect, useState } from 'react';
import { addOrUpdateArrayItem, deleteArrayItem } from '../../utils/array.util';
import {
    DeleteModelDialog,
    DeletePostDataInfo,
    DeleteStatus,
} from '../../components/delete-model-dialog.component';
import { SubmitStatus } from '../../components/formik-model-dialog.component';
import { ObservationOption } from '../../models/masters/observation-option';
import { EditObservationOptionMaster } from './edit--observation-options-master.component';
import { PermissionName } from '../../models/user-security/permission.name';

interface ObservationMasterOptionsGridComponentProps {
    observationID: number;
}

export const ObservationMasterOptionsGridComponent = ({
    observationID,
}: ObservationMasterOptionsGridComponentProps) => {

    const [editData, setEditData] = useState<ObservationOption>();
    const [deleteData, setDeleteData] = useState<DeletePostDataInfo>();
    const [gridData, setGridData] = useState<ObservationOption[]>();
    const { isLoading, result, isError } = useLoadData<ObservationOption[]>(
        `observation/options/${observationID}`,
    );
    useEffect(() => {
        if (result) {
            setGridData(result);
        }
    }, [result]);

    const onSaveComplete = (status: SubmitStatus, data: ObservationOption) => {
        if (status === SubmitStatus.Completed) {
            const newData = addOrUpdateArrayItem(gridData, data, 'observationOptionID');
            setGridData(newData);
        }
        setEditData(null);
    };

    const onDeleteComplete = (status: DeleteStatus, data: ObservationOption) => {
        if (status === DeleteStatus.Completed) {
            const newData = deleteArrayItem(gridData, data, 'observationOptionID');
            setGridData(newData);
        }
        setDeleteData(null);
    };

    const onEnterEdit = (data: ObservationOption) => {
        setEditData({ ...data });
    };

    const onAddNew = () => {
        const masterValue: ObservationOption = {
            observationOptionID: 0,
            observationID: Number(observationID),
            optionName: '',
        };
        setEditData(masterValue);
    };

    const onDelete = (data: ObservationOption) => {
        const { observationOptionID } = data;
        setDeleteData({
            data,
            route: `observation/option/${observationOptionID}`,
        });
    };

    const CommandCell = (props: GridCellProps) => (
        <GridCommandCell
            {...props}
            edit={onEnterEdit}
            remove={onDelete}
            add={onAddNew}
            idPropertyName="ID"
            viewPermissionName={PermissionName.ViewMaster}
            editPermissionName={PermissionName.EditMaster}
            deletePermissionName={PermissionName.DeleteMaster}
        />
    );

    if (isError) {
        return <div>Error</div>;
    }
    return isLoading ? (
        <div>Loading</div>
    ) : (
        <>
            <div className="d-flex observationoption">
                <button title="Add new" className="btn btn-outline-primary btn-sm" onClick={onAddNew}>
                    ADD NEW OBSERVATION Option
                </button>
            </div>
            <Grid data={gridData}>
                <GridColumn field="optionName" title="Option Name" />
                <GridColumn field="isMandatory" className="title-case" title="Mandatory" />
                <GridColumn cell={CommandCell} width="220px" />
            </Grid>
            {editData && <EditObservationOptionMaster data={editData} onClose={onSaveComplete} />}
            {deleteData && (
                <DeleteModelDialog
                    title="Delete Observation Option"
                    deleteData={deleteData}
                    onClose={onDeleteComplete}
                >
                    <div>Are you sure you want to delete - {deleteData.data.optionName}?</div>
                </DeleteModelDialog>
            )}
        </>
    );
};
