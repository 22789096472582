import * as React from 'react';
import { useLoadData } from '../../hooks/use-scheduler-load-data';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { formatDate, parseDate } from '@progress/kendo-intl';
import { Button } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { ISaveTimeSlot } from '../../models/scheduler/save-time-slot';
import { useEffect, useRef, useState } from 'react';
import { useRequestAvailability } from '../../api/homeowner';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import axios from 'axios';
import { addWeeks } from '@progress/kendo-date-math';
import { Claim } from '../../models/claim/claim';
import { convertToTimezoneWithDateString } from '../../utils';
import LeftSideArrowIcon from '../../images/arrow-left';
import { CLAIM_STATUS_CODE } from './utils';


interface IHomeOwnerLandingDetailsProps {
}

const HomeOwnerLandingDetails: React.FunctionComponent<IHomeOwnerLandingDetailsProps> = (props) => {

    const nav = useNavigate()
    const location = useLocation();
    const { state } = location;
    const { schUsers } = state || {};
    const [homeOwnerDetail, setHomeOwnerDetail] = useState<{ Claim: IHomeOwnerLandingDetails, schUsers: any }>(null);
    const path = `SchedulerAssignments/GetHomeOwnerLandingDetails?HomeownerEmail=${schUsers?.email}&&claimGuid=${schUsers?.claimGuid}`;
    const { isError, isLoading, reload, result } = useLoadData<IHomeOwnerLandingDetails[]>(path);
    const [loadData, setLoadData] = useState<boolean>(false);
    const [validateSlot, setValidateSlot] = useState<Boolean>(false);
    const [decline, setDecline] = useState<boolean>(false);
    const URL_HOME_OWNER_AVAILABILITY = "/homeowner/homeowner-availability";

    const [accept, setAccept] = useState<{
        isAccept: boolean;
        data: IHomeOwnerLandingList;
    }>({ data: undefined, isAccept: false });

    useEffect(() => {
        if (result) {

            setHomeOwnerDetail({
                Claim: { ...result[0] },
                schUsers: schUsers
            });
            console.log(schUsers)
            if (result[0]?.statusCode?.toLocaleLowerCase() !== CLAIM_STATUS_CODE.CANCELED?.toLocaleLowerCase() && result[0]?.homeOwnerSlots.length === 0 && schUsers) {
                let homeState = {
                    Claim: { ...result[0] }, schUsers: schUsers
                };
                nav(URL_HOME_OWNER_AVAILABILITY, { state: homeState });
            }
            //else {
            //     if (schUsers != undefined) {
            //         let homeState = {
            //             Claim: { ...result[0] }, schUsers: schUsers
            //         };
            //         nav(URL_HOME_OWNER_AVAILABILITY, { state: homeState });
            //     }
            // }
        }
    }, [result]);
    const handleDecline = () => {
        setDecline(true);
    }
    const omProvideNew = () => {
        nav(URL_HOME_OWNER_AVAILABILITY, { state: homeOwnerDetail });
    }
    const onDeclineClick = async () => {

        const claimInfo = homeOwnerDetail.Claim;
        let slotsvalueList = result[0]?.homeOwnerSlots.map((e) => e.slotID);
        const slotValues = slotsvalueList.join(',');
        setValidateSlot(false);
        if (claimInfo && slotValues?.length > 0) {
            setLoadData(true);
            const response = await axios.post(`SchedulerAssignments/HomeOwnerLandingSlotsDecline?homeownerId=${state?.schUsers?.userId}&claimId=${claimInfo.claimID}&claimNumber=${claimInfo.claimNo}&slotIds=${encodeURIComponent(slotValues)}`)
            setLoadData(false);
            if (response.data.result === -1) {
                nav(URL_HOME_OWNER_AVAILABILITY, { state: homeOwnerDetail });
            } else {
                console.log(response.status)
            }
        } else {
            setLoadData(false);
            setValidateSlot(true);
        }
    };
    const toggleDialogValidation = () => {
        setValidateSlot(false);
    };

    return (<>
        <div className="m-2 p-2" style={{ display: "none" }} >

            <div className="d-flex justify-content-between align-items-center mb-3 ">
                {/* <div style={{ fontWeight: "bold" }}>{"job offers".toUpperCase()}</div> */}

                <button className="btn btn-lg btn-light d-flex align-items-center sw-shadow"
                    onClick={() => {
                        nav('/homeowner/homeowner-availability', { state: homeOwnerDetail })
                    }}
                ><svg className='me-2' width="14.25" height="15.833" viewBox="0 0 14.25 15.833">
                        <path id="Path_calendar-outline" data-name="Path/calendar-outline" d="M15.667,3.583h-.792V2H13.292V3.583H6.958V2H5.375V3.583H4.583A1.583,1.583,0,0,0,3,5.167V16.25a1.583,1.583,0,0,0,1.583,1.583H15.667A1.583,1.583,0,0,0,17.25,16.25V5.167a1.583,1.583,0,0,0-1.583-1.583m0,12.667H4.583V8.333H15.667V16.25m0-9.5H4.583V5.167H15.667V6.75m-5.542,3.958h3.958v3.958H10.125Z" fill='#606060' transform="translate(-3 -2)" />
                    </svg>
                    My Availability</button>

            </div>
        </div>


        {isLoading && (<LoadingIndicator isLoading />)}
        {loadData && (<LoadingIndicator isLoading={loadData} />)}

        {result && result?.length > 0 ? (
            <>
                {validateSlot &&
                    <Dialog title={"Validation"} onClose={toggleDialogValidation} style={{ borderRadius: "10px" }} className="delete-dialog">
                        <div>
                            <div className="row">
                                <div className="d-flex justify-content-between align-items-center mt-2 mb-2'">
                                    <p style={{ fontSize: "16px", fontWeight: "bold" }}>There is no time slot found to decline!!</p>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                }
                <div className='m-lg-5 mt-lg-3 m-4'>
                    <div className="" >
                        <div className="d-flex justify-content-between m-3">
                            <div>
                                {result &&
                                    isEmpty(result[0].homeOwner_Name) ?
                                    (null) :
                                    (<><div>
                                        <h4>{result[0]?.homeOwner_Name}</h4>
                                        <p className="mb-0">{result[0]?.homeOwner_Address}</p>
                                        <p className="mb-0">{result[0]?.homeOwner_Phone}</p>
                                    </div></>)
                                }
                            </div>
                            <div className='text-end'>
                                {result && isEmpty(result[0]?.claimNo) ?
                                    (null) :
                                    (<><div className="col" style={{ fontWeight: 'bolder' }}><h4>{result[0]?.claimNo}</h4></div>
                                        <div className="col">Claim#</div></>)
                                }
                            </div>
                        </div>
                    </div>
                    {result[0]?.statusCode?.toLowerCase() !== CLAIM_STATUS_CODE.CANCELED?.toLowerCase() ?
                        (<div className="card border-0 mb-3 mb-lg-4 mt-3 sw-shadow radius-15" >
                            <div className="m-2 p-2">
                                <h5>
                                    INSTRUCTIONS
                                </h5>
                                <div className="col mt-2">
                                    <div className="d-flex justify-content-start">
                                        <p>
                                            Please select your preferred time slot for your inspection from the options provided below. If none of the available slots work for you, click the "Decline and Provide New" button to suggest alternative dates and times. Thank you!


                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>) : (
                            <div className="card gredientBg p-4 border-0 mb-3 mb-lg-4 mt-3 sw-shadow radius-15" >
                                <center><h4 style={{ fontWeight: "bolder" }}>CANCELED</h4></center>
                            </div>
                        )}
                    {result[0]?.statusCode?.toLowerCase() !== CLAIM_STATUS_CODE.CANCELED.toLowerCase() && result[0]?.homeOwnerSlots?.length > 0 ?
                        (<div className="row">
                            {result[0]?.homeOwnerSlots?.map(e => <>
                                <div className='col-md-6 col-lg-4  mb-3 mb-lg-4 '>
                                    <div className="gredientBg card p-4 border-0 sw-shadow radius-15" >
                                        <div className='d-flex justify-content-between align-items-center ' >
                                            <div>
                                                <span>Request Received</span>
                                                <h4 className='timeslot mt-2'>{convertToTimezoneWithDateString(e?.dateFrom).format('MM/DD/YYYY hh:mm A')} - {convertToTimezoneWithDateString(e?.dateTo).format('hh:mm A')}</h4>
                                                {/* <h4 className='timeslot mt-2'>{formatDate(parseDate(e.dateFrom), 'MM/dd/yyyy') + '  ' + e.timeSlot.toUpperCase()}</h4> */}
                                            </div>

                                            <div>
                                                {e?.isAccepted === 0 ?
                                                    (<button className="btn-primary btn-md  me-1 btn text-capitalize" onClick={() => {
                                                        setAccept({ data: e, isAccept: true })
                                                    }}>Accept</button>) :
                                                    (<button className="btn-secondary btn-md  me-1 btn text-capitalize" style={{ cursor: 'default' }} >Accepted</button>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            </>)}
                        </div >) : (null)
                    }
                    {result[0]?.statusCode?.toLowerCase() !== CLAIM_STATUS_CODE.CANCELED?.toLowerCase() && result[0]?.homeOwnerSlots?.length > 0 ?
                        (<div>
                            <div className="d-lg-flex justify-content-center">
                                <button onClick={() => { handleDecline(); }} className="btn btn-lg btn d-lg-block w-100 btn-light sw-shadow">Decline and Provide New
                                    &nbsp;<LeftSideArrowIcon />
                                </button>
                            </div>
                        </div>) :
                        result[0]?.statusCode?.toLowerCase() !== CLAIM_STATUS_CODE.CANCELED.toLowerCase() ? (
                            <div>
                                <div className="d-lg-flex justify-content-center">
                                    <button onClick={() => { omProvideNew(); }} className="btn btn-lg btn d-lg-block w-100 btn-light sw-shadow">Provide New
                                        &nbsp;<LeftSideArrowIcon />
                                    </button>
                                </div>
                            </div>) : (null)
                    }
                </div>

            </>) : (null)
            // <div className="row p-2 ms-2 tw-rounded-lg tw-rounded-md tw-p-5 tw-relative tw-bg-white" style={{ minWidth: "89vw" }} >
            //     (<h4>Availability Time slot not found</h4>)
            // </div>
        }
        {
            accept.isAccept && (<div ><Dialog onClose={() => { setAccept({ data: undefined, isAccept: false }); }} className='acceptpopup' >
                <center style={{ marginLeft: '-15px', marginRight: '-15px', marginBottom: '-16px' }} className='gredientBg90 content'>


                    <div className="gridHeaderTs">
                        <div>
                            <small>Location</small>
                            <div className='mt-2' style={{ fontWeight: 'bold' }}>{result[0].homeOwner_Address}</div>
                        </div>
                        <div >
                            <small>Claim#</small>
                            {<div className='mt-2' style={{ fontWeight: 'bold' }}>{result[0]?.claimNo}</div>}
                        </div>
                    </div>

                    <div className='mt-3'>

                        <p>Please confirm that you wish to select the below time slot(s) for the home inspection. Once approved, the inspection will be scheduled and you will receive a confirmation email.</p>
                        <div className='mt-4'>
                            Selected Time Slot
                            <div style={{ fontWeight: 'bold' }} className='mt-4'>
                                {convertToTimezoneWithDateString(accept?.data?.dateFrom).format('MM/DD/YYYY hh:mm A')} - {convertToTimezoneWithDateString(accept?.data?.dateTo).format('hh:mm A')}
                                {/* formatDate(new Date(accept?.data?.dateFrom), "MM/dd/yyyy") + " " + accept?.data?.timeSlot} */}
                            </div>
                        </div>
                    </div>


                </center>
                <DialogActionsBar>
                    <div className='row'>
                        <div className='d-flex justify-content-between' >
                            <button className='btn btn-sm btn-secondary' onClick={() => {
                                setAccept({ data: undefined, isAccept: false });
                            }}>Cancel</button>
                            <button className='btn btn-primary btn-sm' onClick={async () => {
                                const response = await axios.post(`SchedulerAssignments/HomeOwnerLandingSlotAccept?schedulerId=${state?.schUsers?.userId}&claimId=${result[0].claimID}&claimNumber=${accept.data.claimNo}&homeownerId=${state?.schUsers.userId}&slotId=${accept.data.slotID}`)
                                if (response.data.result === -1) {
                                    setAccept({ data: undefined, isAccept: false })
                                    reload();
                                }
                            }}>Accept</button>

                        </div>
                    </div>
                </DialogActionsBar>
            </Dialog>
            </div>)

        }
        {
            decline && (<Dialog title='Decline Request' width={"35%"} onClose={() => { setDecline(false) }} key={1}>
                <center>
                    <h5>Are you sure you want to decline all the request(s)?</h5>
                </center>
                <DialogActionsBar>
                    <div className='row'>
                        <div className='d-flex justify-content-between' >
                            <button className='btn btn-sm btn-secondary' onClick={() => {
                                setDecline(false)
                            }}>No</button>
                            <button className='btn btn-primary btn-sm' onClick={async () => {
                                onDeclineClick();
                            }}>Yes</button>
                        </div>
                    </div>

                </DialogActionsBar>
            </Dialog>)
        }
    </>);

};

export default HomeOwnerLandingDetails;
