
interface Props {
  size?: number;
  color?: string;
}

const CloseIcon = ({ size = 15.21, color = "#f68b1f" }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 15.21 15.21">
      <path fill={color} d="M15.21,1.414L13.796,0,7.605,6.19,1.414,0,0,1.414,6.19,7.605,0,13.796,1.414,15.21l6.191-6.191,6.191,6.191L15.21,13.796,9.019,7.605Z" />
    </svg>
  );
};

export default CloseIcon;
