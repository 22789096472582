import { chain, find, isNil, last, map, some } from 'lodash';
import { useEffect, useState } from 'react';
import { Observation } from '../../models/masters/observation';
import { observationTagColors } from './buildingview.page';

export interface ObservationChackBoxComponentProps {
    observations: Observation[];
    resetSelection: boolean;
    onObservationSelectionChange: (selectedNames: Observation[]) => any;
}

class ObservationSelected extends Observation {
    isSelected: boolean;
}

export const ObservationChackBoxComponent = ({
    observations,
    onObservationSelectionChange,
}: ObservationChackBoxComponentProps) => {
    const [selectAll, setSelectAll] = useState(true);
    const [observationSelectionDetails, setObservationSelectionDetails] =
        useState<ObservationSelected[]>();

    useEffect(() => {
        if (observations) {
            //Selection is setting based on the previous selection or select all
            const data = map(observations, (o) => {
                const prev = find(
                    observationSelectionDetails,
                    (s) =>
                        s.observationName === o.observationName &&
                        s.observationType.toLocaleLowerCase() ===
                            o.observationType.toLocaleLowerCase(),
                );
                return {
                    ...o,
                    isSelected: prev ? prev.isSelected : selectAll,
                };
            });
            data.push({
                observationName: 'OTHERS',
                isMandatory: false,
                note: '',
                observationID: 0,
                observationType: 'Other',
                color: last(observationTagColors),
                isSelected:
                    selectAll ||
                    !!find(observationSelectionDetails, (s) => s.observationName === 'OTHERS')
                        ?.isSelected,
            });
            setObservationSelectionDetails(data);
        }
    }, [observations]);

    useEffect(() => {
        if (observationSelectionDetails) {
            const names = chain(observationSelectionDetails)
                .filter((o) => o.isSelected)
                .value();
            onObservationSelectionChange(names);
        }
    }, [observationSelectionDetails]);

    const handleObservationSelectAllChange = (event: any) => {
        const value = event.target.checked;
        if (observationSelectionDetails) {
            observationSelectionDetails.forEach((o) => {
                o.isSelected = value;
            });
            setObservationSelectionDetails([...observationSelectionDetails]);
        }
        setSelectAll(value);
    };

    const handleObservationSelectionChange = (event: any) => {
        observationSelectionDetails.forEach((o) => {
            if (o.observationName === event.target.value) {
                o.isSelected = event.target.checked;
            }
        });

        const allSelected = some(observationSelectionDetails, (o) => !o.isSelected);
        setSelectAll(!allSelected);
        setObservationSelectionDetails([...observationSelectionDetails]);
    };

    const getObservationLabel = (observatioName: string) => {
        if (observatioName === 'OTHERS') {
            return 'Windows/Doors without observation';
        }
        return observatioName;
    };

    return (
        <>
            <div key="All" className="customChkbox">
                <label className="obserTitle">
                    <input
                        type="checkbox"
                        id="All"
                        name="CheckboxAll"
                        checked={selectAll}
                        onChange={handleObservationSelectAllChange}
                    />
                    <span className="checkmark"></span>
                    Observations
                </label>
            </div>
            {map(observationSelectionDetails, (o, i) => {
                return (
                    <div key={i} className="customChkbox">
                        <label>
                            <input
                                type="checkbox"
                                id={o.observationName}
                                name={o.observationName}
                                value={o.observationName}
                                checked={o.isSelected}
                                onChange={handleObservationSelectionChange}
                            />
                            <span
                                style={{
                                    borderColor: o.color,
                                }}
                                className="checkmark"
                            ></span>
                            {getObservationLabel(o.observationName)}
                        </label>
                    </div>
                );
            })}
        </>
    );
};
