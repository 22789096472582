import { filter, first, reduce } from 'lodash';
import { Point, Shape } from '../../../../models/building-image/image-draw.types';

export const checkPointInsideRectangle = (
    { x, y }: Point,
    { x: x1, y: y1 }: Point,
    { x: x2, y: y2 }: Point,
) => {
    return ((x > x1 && x < x2) || (x < x1 && x > x2)) && ((y < y1 && y > y2) || (y > y1 && y < y2));
};

export const checkPointInsideCircle = (point: Point, center: Point, radius: number) => {
    const distance = Math.sqrt(Math.pow(center.x - point.x, 2) + Math.pow(center.y - point.y, 2));
    return distance <= radius;
};

export const isPointEqual = (point1: Point, point2: Point) => {
    return point1.x === point2.x && point1.y === point2.y;
};

export const getDistance = (point1: Point, point2: Point) => {
    return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
};

export const getAdjustedRectanglePoints = (point1: Point, point2: Point) => {
    const minx = Math.min(point1.x, point2.x);
    const miny = Math.min(point1.y, point2.y);
    const maxx = Math.max(point1.x, point2.x);
    const maxy = Math.max(point1.y, point2.y);
    return {
        point1: { x: minx, y: miny },
        point2: { x: maxx, y: maxy },
    };
};

export const inverseTransform = (matrix: DOMMatrix, point: Point) => {
    const domPoint: DOMPoint = new DOMPoint(point.x, point.y);
    const invMatrix = matrix.inverse();
    const convertedPoint = domPoint.matrixTransform(invMatrix);
    return {
        x: convertedPoint.x,
        y: convertedPoint.y,
    };
};

export const applyTransform = (matrix: DOMMatrix, point: Point) => {
    const domPoint: DOMPoint = new DOMPoint(point.x, point.y);
    const convertedPoint = domPoint.matrixTransform(matrix);
    return {
        x: convertedPoint.x,
        y: convertedPoint.y,
    };
};

export const getResizeTranformation = (
    target: { width: number; height: number },
    source: { width: number; height: number },
) => {
    let scale = Math.min(target.width / source.width, target.height / source.height);
    scale = scale > 1 ? 1 : scale;
    const translateX =
        target.width - source.width * scale > 0 ? (target.width - source.width * scale) / 2 : 0;
    const translateY =
        target.height - source.height * scale > 0 ? (target.height - source.height * scale) / 2 : 0;
    return { zoom: scale, translate: { x: translateX, y: translateY } };
};

export const getMaxSquareSize = (width: number, height: number, numberOfSquares: number) => {
    const min = 1;
    const max = 40;
    for (let i = max; i >= min; i--) {
        const column = Math.floor(width / i);
        const row = Math.floor(height / i);
        if (column * row > numberOfSquares) {
            return i;
        }
    }
    return;
};

export const getImageBoundingPoint = (
    point: Point,
    imageWidth: number,
    imageHeight: number,
    lineThickness: number,
): Point => {
    const { x, y } = point;
    return {
        x:
            x < lineThickness
                ? lineThickness
                : x >= imageWidth - lineThickness
                ? imageWidth - lineThickness
                : x,
        y:
            y < lineThickness
                ? lineThickness
                : y >= imageHeight - lineThickness
                ? imageHeight - lineThickness
                : y,
    };
};

export const getSelectedShapeAtPosition = ({ x, y }: Point, shapes: Shape[]) => {
    const selectedShapes = filter(shapes, ({ isHidden, point1, point2 }) => {
        return !isHidden && checkPointInsideRectangle({ x, y }, point1, point2);
    });
    if (selectedShapes.length > 1) {
        const { shape } = reduce(
            selectedShapes,
            (prev: any, current) => {
                const { point1, point2 } = current;
                const point3 = { x: point2.x, y: point1.y };
                const point4 = { x: point1.x, y: point2.y };
                const area = getDistance(point1, point3) * getDistance(point3, point4);
                if (!prev.area || area < prev.area) {
                    return {
                        shape: current,
                        area: area,
                    };
                }
                return prev;
            },
            {},
        );
        return shape;
    }

    return first(selectedShapes);
};
