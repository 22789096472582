import { find, isEmpty, isNil, map } from 'lodash';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { useLoadData } from '../../../hooks/use-load-data.hook';
import { ClaimObservation } from '../../../models/claim/claim-observation';
import { Room } from '../../../models/claim/room';
import { UnitDoor } from '../../../models/claim/unit-door';
import { ObservationListComponent } from './observation-list.component';

interface CopyDoorObservationsComponentProps {
    roomId: number;
    onCopyObservation: (observation: ClaimObservation) => any;
}

export const CopyDoorObservationsComponent = ({
    roomId,
    onCopyObservation,
}: CopyDoorObservationsComponentProps) => {
    const [door, setSelectedItem] = useState<UnitDoor>();
    const { isLoading, result, isError } = useLoadData<Room>(
        `rooms?roomid=${roomId}`,
        isNil(roomId),
    );

    useEffect(() => {
        if (result && result.doors && !isEmpty(result.doors)) {
            setSelectedItem(result.doors[0]);
        }
    }, [result]);

    useEffect(() => {
        if (isNil(roomId)) {
            setSelectedItem(null);
        }
    }, [roomId]);

    const selectedOption = { label: door?.doorName, value: door?.doorID };

    const options = map(result?.doors, (w) => {
        return {
            label: w.doorName,
            value: w.doorID,
        };
    });

    const handleDoorSelectionChange = (selected: any) => {
        const selectedItem: any = find(result.doors, (w) => w.doorID === selected?.value);
        setSelectedItem(selectedItem);
    };

    if (isLoading) {
        return <LoadingIndicator isLoading={isLoading} />;
    }

    return (
        <div>
            Door
            <Select value={selectedOption} options={options} onChange={handleDoorSelectionChange} />
            Observations
            <div className="copy-observation-list">
            <ObservationListComponent
                observations={door?.observations}
                onCopyObservation={onCopyObservation}
                ispopup
                onShowObservationPhotos={()=>{}}
            />
            </div>
        </div>
    );
};
