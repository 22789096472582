import { ImgFile } from "./uploadfile.util";

export async function loadImageAsArrayBuffer(filePath:string):Promise<Blob> {
    try {
     
      const response = await fetch(filePath);
      const blob = await response.blob();

     return blob;
  }
  catch(err:any)
{
    console.log(err);
}  
}

// get file from cdn url
export async function fetchZipFile(url: string, comment: string, fileID?: string): Promise<ImgFile> {
  try {
      const response = await fetch(url);
      if (!response.ok) {
          throw new Error('Failed to fetch');
      }

      const blob = await response.blob();

      // Check if the blob is empty
      if (blob.size === 0) {
          const defaultText = 'no image found';
          const defaultBlob = new Blob([defaultText], { type: 'text/plain' });
          const defaultFileName = 'No Image Found';
          const defaultFile = new File([defaultBlob], defaultFileName, {
              type: defaultBlob.type,
          });
          return { comment: comment, file: defaultFile, fileID: fileID };
      }

      const fileName = url.substring(url.lastIndexOf('/') + 1);
      const file = new File([blob], fileName, { type: blob.type });

      return { comment: comment, file: file, fileID: fileID };
  } catch (error) {
      console.error('Error fetching file:', "Image is either  unavaialable or not loaded due to poor network ");
      const imagePath = require("../images/noimage.jpg");
      // Return default file in case of error
      const imageData = await loadImageAsArrayBuffer(imagePath);
      const file = new File([imageData], 'image.jpg', { type: 'image/jpeg' });

      console.log(file);
      return { comment: 'No Image', file: file, fileID: fileID };
  }
}