import { createRef, useEffect, useState } from 'react';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { StorageKey } from '../../constants';
import { useStorage } from '../../hooks/use-storage';
import { Building } from '../../models/claim/building';
import { Claim } from '../../models/claim/claim';
import { ClaimSetupBreadCrumb } from '../../components/claim-setup-breadcrumb';
import { ClaimInfoPopover } from '../building/claim-info-popover.component';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { UploadImageComponent } from './upload-image.component';
import { EntityImage } from '../../models/building-image/entity-image';
import { BuildingView } from '../building/building.page';
import { find } from 'lodash';
import { GuardedComponent } from '../../components/guarded.componemt';
import { PermissionName } from '../../models/user-security/permission.name';
import { Unit } from '../../models/claim/unit';
import { Room } from '../../models/claim/room';
import { Floor } from '../../models/claim/floor';
import { EditFloor } from '../building/floor/edit-floor.component';
import { EditUnit } from '../building/unit/edit-unit.component';
import { EditRoom } from '../building/room/edit-room.component';
import { SubmitStatus } from '../../components/formik-model-dialog.component';
import { UnitDoor } from '../../models/claim/unit-door';
import { EditDoor } from '../building/door/edit-door.component';
import { UnitWindow } from '../../models/claim/unit-window';
import { EditWindow } from '../building/window/edit-window.component';
import { EditClaim } from '../claim/edit-claim.component';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ObservationChackBoxComponent } from './observation-checkbox.component';
import { BuildingImageView } from './building-image-view.component';
import { Observation } from '../../models/masters/observation';
import { usePostData } from '../../hooks/use-post-data.hook';
import { ShareType } from '../../components/share-link-dialog.component';
import { ShareLinkButton } from '../../components/share-link-button.component';

interface BuildingViewPageProps {
    buildingID: number;
    setBuildingView: any;
}

const directions = [
    { value: 'North', label: 'North' },
    { value: 'NorthEast', label: 'North East' },
    { value: 'South', label: 'South' },
    { value: 'SouthEast', label: 'South East' },
    { value: 'East', label: 'East' },
    { value: 'SouthWest', label: 'South West' },
    { value: 'West', label: 'West' },
    { value: 'NorthWest', label: 'North West' },
];

export const observationTagColors = [
    'Red',
    'Yellow',
    'Orange',
    'Blue',
    'Purple',
    'Violet',
    'Brown',
    'Cyan',
    'Magenta',
    'Gray',
    'Black',
];

export const BuildingViewPage = ({ buildingID, setBuildingView }: BuildingViewPageProps) => {
    const [showPopover, setShowPopover] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [currentClaim, setCurrentClaim] = useStorage(StorageKey.CurrentUser, new Claim());
    const [editClaimData, setEditClaimData] = useState<Claim>();

    const [entityImages, setEntityImages] = useState<EntityImage[]>();
    const [selectedImageDirection, setSelectedImageDirection] = useState<string>(directions[0].value);
    const [selectedImage, setSelectedImage] = useState<EntityImage>();
    const [observationDetails, setObservationDetails] = useState<Observation[]>([]);
    const [selectedObservations, setSelectedObservations] = useState<Observation[]>([]);

    const [editFloorData, setEditFloorData] =
        useState<{ currentBuilding: Building; floor: Floor }>();
    const [editUnitData, setEditUnitData] = useState<{ building: Building; unit: Unit }>();
    const [editRoomData, setEditRoomData] = useState<{ building: Building; room: Room }>();
    const [doorData, setEditDoor] = useState<{ building: Building; editDoor: UnitDoor }>();
    const [windowData, setEditWindow] = useState<{ building: Building; window: UnitWindow }>();
    const uploadRef = createRef<HTMLInputElement>();

    const {
        isLoading,
        result: buildingData,
        isError,
    } = useLoadData<Building>(`building?buildingID=${buildingID}`);

    const { isLoading: isLoadingImages, result: imageList, reload } = useLoadData<EntityImage[]>(
        `image/building/${buildingID}`,
    );

    const {
        submitData,
        response: { isSubmitting, result, error },
    } = usePostData<any>('image', 'POST', 'multipart/form-data');

    useEffect(() => {
        if (imageList) {
            setObservationDetails([]);
            setEntityImages(imageList);
            const img = find(imageList, (img) => img.direction === selectedImageDirection);
            setSelectedImage(img);
            setShowUpload(!img);
        }
    }, [imageList]);

    useEffect(() => {
        if (selectedImageDirection) {
            const img = find(entityImages, (i) => i.direction === selectedImageDirection);
            setObservationDetails([]);
            setSelectedImage(img);
            setShowUpload(!img);
        }
    }, [selectedImageDirection]);

    useEffect(() => {
        if (result) {
            handleUploadComplete({
                direction: selectedImageDirection,
                imageID: result.imageID,
                imagePath: result.imagePath,
                imageData: [],
            });
        }
    }, [result]);

    const onObservationDetailsChanged = (observations: Observation[]) => {
        setObservationDetails(observations);
    };

    const onEditClaimClick = () => {
        setEditClaimData({ ...currentClaim });
    };

    const handleImageSelect = (direction: string) => {
        setSelectedImageDirection(direction);
    };

    const handleUploadComplete = (image: EntityImage) => {
        if (image) {
            console.log(entityImages);
            console.log(image);


            reload();
        }
        setShowUpload(false);
    };

    const onAddFloorClick = () => {
        const floor: Floor = {
            floorID: 0,
            buildingID: buildingID,
        };
        setEditFloorData({
            currentBuilding: { buildingID: buildingID, claimGuid: null },
            floor: floor,
        });
    };

    const onAddUnitClick = () => {
        const unit: Unit = {
            unitID: 0,
            floorID: 0,
            unitName: '',
            unitNumber: '',
        };
        setEditUnitData({
            building: { buildingID: buildingID, claimGuid: null },
            unit,
        });
    };

    const onAddRoomClick = () => {
        const room: Room = {
            unitID: 0,
            roomID: 0,
        };
        setEditRoomData({
            building: { buildingID: buildingID, claimGuid: null },
            room,
        });
    };

    const handleSaveFloorComplete = (status: SubmitStatus, data: Floor) => {
        setEditFloorData(null);
    };

    const handleSaveUnitComplete = (status: SubmitStatus, data: Unit) => {
        setEditUnitData(null);
    };

    const handleSaveRoomComplete = (status: SubmitStatus, data: Room) => {
        setEditRoomData(null);
    };

    const handleSaveDoorComplete = (status: SubmitStatus, data: UnitDoor) => {
        setEditDoor(null);
    };

    const handleSaveWindowComplete = (status: SubmitStatus, data: UnitWindow) => {
        setEditWindow(null);
    };

    const handleSaveClaimComplete = (status: SubmitStatus, data: Claim) => {
        if (status == SubmitStatus.Completed) {
            setCurrentClaim(data);
        }
        setEditClaimData(null);
    };

    const onAddDoor = () => {
        const selectedDoor: UnitDoor = {
            roomID: 0, // room.roomID,
            doorID: 0,
            doorName: '',
            direction: selectedImageDirection,
            notes: '',
            images: 0,
            attributes: [],
            observations: [],
        };
        setEditDoor({ building: buildingData, editDoor: selectedDoor });
    };

    const onAddWindowClick = () => {
        const selectedWindow: UnitWindow = {
            roomID: 0, //room.roomID,
            windowID: 0,
            windowName: '',
            direction: selectedImageDirection,
            notes: '',
            images: 0,
            attributes: [],
            observations: [],
        };
        setEditWindow({ building: buildingData, window: selectedWindow });
    };

    const onToggle = (show: boolean) => {
        setShowPopover(show);
    };

    const handleSubmit = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('UploadedFile', file);
            formData.append('FileName', file.name);
            formData.append('BaseType', 'building');
            formData.append('BaseTypeID', buildingID.toString());
            formData.append('direction', selectedImageDirection);
            submitData({ data: formData });
        }
    };

    const getToolBarButtons = () => {
        return (
            <>
                <GuardedComponent permission={PermissionName.EditBuilding}>
                    <div className="upload-btn-wrapper">
                        <button className="add-image" onClick={() => { document.getElementById('uploadLinkImg').click() }} />
                        <input id='uploadLinkImg' type="file" onChange={handleSubmit} hidden />
                    </div>
                </GuardedComponent>
                <GuardedComponent permission={PermissionName.EditBuilding}>
                    <div>
                        <OverlayTrigger
                            trigger="click"
                            key="buttons-popover"
                            placement="top"
                            onToggle={onToggle}
                            overlay={
                                <Popover className="add-info-pop" id="buttons-popover">
                                    <Popover.Body>
                                        <div>
                                            <div>
                                                <GuardedComponent
                                                    permission={PermissionName.AddFloor}
                                                >
                                                    <button
                                                        className="btn btn-primary "
                                                        onClick={() => onAddFloorClick()}
                                                    >
                                                        + &nbsp;&nbsp;Add Floor
                                                    </button>
                                                </GuardedComponent>
                                            </div>
                                            <div>
                                                <GuardedComponent
                                                    permission={PermissionName.AddUnit}
                                                >
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() => onAddUnitClick()}
                                                    >
                                                        + &nbsp;&nbsp;Add Unit
                                                    </button>
                                                </GuardedComponent>
                                            </div>
                                            <div>
                                                <GuardedComponent
                                                    permission={PermissionName.AddRoom}
                                                >
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() => onAddRoomClick()}
                                                    >
                                                        + &nbsp;&nbsp;Add Room
                                                    </button>
                                                </GuardedComponent>
                                            </div>

                                            <div>
                                                <GuardedComponent
                                                    permission={PermissionName.AddWindow}
                                                >
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={onAddWindowClick}
                                                    >
                                                        + &nbsp;&nbsp;Add Window
                                                    </button>
                                                </GuardedComponent>
                                            </div>
                                            <div>
                                                <GuardedComponent
                                                    permission={PermissionName.AddDoor}
                                                >
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={onAddDoor}
                                                    >
                                                        + &nbsp;&nbsp;Add Door
                                                    </button>
                                                </GuardedComponent>
                                            </div>
                                        </div>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <button className="add-menu" />
                        </OverlayTrigger>
                    </div>
                </GuardedComponent>
                {/* <GuardedComponent permission={PermissionName.EditBuilding}> 
                    <input type="file" onChange={handleSubmit} ref={uploadRef} className="invisible" />                    
                </GuardedComponent> */}
            </>
        );
    };

    return (
        <div className="container-fluid">
            <div className="">
                <div className="top-sec-align">
                    <div>
                        <ClaimSetupBreadCrumb
                            currentClaim={currentClaim}
                            currentBuilding={buildingData}
                        />
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <GuardedComponent permission={PermissionName.EditBuilding}>
                            <ShareLinkButton
                                title="Share Building"
                                shareUrl={window.location.href}
                                type={ShareType.Building}
                                typeId={Number(buildingID)}
                            />
                        </GuardedComponent>
                    </div>
                    <div className="ms-5">
                        <GuardedComponent permission={PermissionName.ViewBuilding}>
                            <button
                                title="Grid View"
                                className="icon-grid-view"
                                onClick={() => setBuildingView(BuildingView.Normal)}
                            ></button>
                        </GuardedComponent>

                        {currentClaim?.claimNumber && (
                            <ClaimInfoPopover
                                claim={currentClaim}
                                onEditClaimClick={onEditClaimClick}
                            />
                        )}
                    </div>
                </div>
                <LoadingIndicator isLoading={isLoadingImages || isLoading || isSubmitting} />
                {isError && <div>Error</div>}
                {buildingData && (
                    <div className="image-drawing">
                        <div>
                            <div className="image-row">
                                <div>
                                    <div className="mb-5">
                                        <h4>{buildingData.buildingName}</h4>

                                        <div className="button-group-image">
                                            <div className="select w175">
                                                <select
                                                    className="form-control"
                                                    value={selectedImageDirection}
                                                    onChange={(value) =>
                                                        handleImageSelect(value.target.value)
                                                    }
                                                >
                                                    {directions?.map((o) => {
                                                        return (
                                                            <option
                                                                key={o.value}
                                                                value={o.value}
                                                                label={o.label}
                                                            />
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-5">
                                        {!showUpload && (
                                            <ObservationChackBoxComponent
                                                observations={observationDetails}
                                                resetSelection={true}
                                                onObservationSelectionChange={
                                                    setSelectedObservations
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                                {showUpload ? (
                                    <UploadImageComponent
                                        entityId={buildingID}
                                        direction={selectedImageDirection}
                                        onCompleteUpload={handleUploadComplete}
                                    />
                                ) : (
                                    <div>
                                        {selectedImage && (
                                            <BuildingImageView
                                                buildingData={buildingData}
                                                selectedImage={selectedImage}
                                                selectedObservations={selectedObservations}
                                                customButtons={getToolBarButtons()}
                                                onObservationDetailsChanged={
                                                    onObservationDetailsChanged
                                                }
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {editFloorData && (
                    <EditFloor data={editFloorData} onClose={handleSaveFloorComplete} />
                )}
                {editUnitData && <EditUnit data={editUnitData} onClose={handleSaveUnitComplete} />}
                {editRoomData && <EditRoom data={editRoomData} onClose={handleSaveRoomComplete} />}
                {doorData && <EditDoor data={doorData} onClose={handleSaveDoorComplete} />}
                {windowData && <EditWindow data={windowData} onClose={handleSaveWindowComplete} />}
                {editClaimData && (
                    <EditClaim data={editClaimData} onClose={handleSaveClaimComplete} />
                )}
            </div>
        </div>
    );
};
