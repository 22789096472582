import * as React from 'react';
import { useLoadData } from '../../hooks/use-scheduler-load-data';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { parseDate, formatDate } from '@progress/kendo-intl';
import DatePicker from 'react-datepicker';
import { useEffect, useRef, useState } from 'react';
import CloseIcon from '../../images/close-icon';
import EditIcon from '../../images/edit';
import SingleSelectDropdown from '../../components/single-select.component';
import SingleDropdown from '../../components/single-select-dropdown';
import { Image } from 'react-bootstrap';
import AddAvailability from './components/add-inspector-availability';
import { useLocation, useNavigate } from 'react-router-dom';
import { ISaveTimeSlot } from '../../models/scheduler/save-time-slot';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ISlot } from './components/timeslot-component';
import axios from 'axios';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { convertToTimezone, convertToTimezoneWithDateString } from '../../utils';
import LocationIcon from '../../images/location';
import { ScheduledSlot } from '../../types/ScheduledSlot';
import { GroupedSlot } from '../../types/GroupedSlot';
import { AvailabilityList } from './components/availability-list';
import { SLOT_TYPES } from '../../constants';
import { useAvailabilities } from '../../api/inspector';

interface IInspectorAvailabilityProps { }


function toCurrentOffset(date: Date) {

    // initialize
    let result = dayjs(date)

    // remove date offset
    result = result.subtract(result.utcOffset(), 'minutes')

    result = result.add(convertToTimezone(dayjs().toDate()).utcOffset(), 'minutes')

    return result.toDate()
}


const addUserTimezoneOffset = (date: Date) => {
    let result = dayjs(date)

    // remove date offset
    result = result.subtract(result.utcOffset(), 'minutes')


    result = result.add(dayjs().utcOffset(), 'minutes')

    return result.toDate()
}

const DEFAULT_DATES = {
    start: (new Date()),
    end: (new Date(dayjs().add(3, "months").format("MM/DD/YYYY")))
}





const InspectorAvailability: React.FunctionComponent<IInspectorAvailabilityProps> = (props) => {
    const nav = useNavigate();
    const loc = useLocation();
    const { state } = loc;
    const { schUsers } = state;
    const { userGuid, userId, userName } = schUsers;
    // let userId = 30;
    // let userName = "pragupathi"
    // let userGuid = "";
    const [del, setDel] = useState<{ isDel: boolean, deleteData?: ScheduledSlot }>({ deleteData: undefined, isDel: false })

    const [fromDate, setFromDate] = useState<Date>(DEFAULT_DATES.start);
    const [toDate, setToDate] = useState<Date>(DEFAULT_DATES.end);


    const [startAndEndDates, setStartAndEndDates] = useState<{ startDate: string; endDate: string }>({
        startDate: convertToTimezone(DEFAULT_DATES.start).startOf("day").toISOString(),
        endDate: convertToTimezone(DEFAULT_DATES.end).endOf("day").toISOString(),
    })




    const [selectedStatusCode, setSelectedStatusCode] = useState<string>("-1");
    const [isAdd, setIsAdd] = useState<boolean>(false);
    const [dateValidation, setDateValidation] = useState(false);
    const unAvailableDaySlots = useRef<ISlot[]>([])
    const unAvailableTimeSlots = useRef<ISlot[]>([])
    const toggleDialogValidation = () => {
        setDateValidation(false);
    };



    const { data: availabilities, isLoading: isLoadingAvailabilities, isError: isErrorAvailabilities, refetch: reloadAvailabilities } = useAvailabilities({
        variables: {
            SDate: startAndEndDates.startDate,
            FDate: startAndEndDates.endDate,
            InspectorID: schUsers.userId
        }
    })


    const initialSlotData = React.useRef<ISaveTimeSlot>({
        claimNo: '', dateSlots: [],
        isAvailable: true,
        schID: 0,
        sDate: '',
        timeSlot: '',
        userGUID: userGuid,
        claimId: 0,
        userId: userId,
        inspectorId: userId,
    });


    const groupedSlots = React.useMemo(() => {
        let data = availabilities;

        if (selectedStatusCode !== "-1") {
            data = availabilities?.filter((e) => e.statusCode === selectedStatusCode);
        }

        if (data) {
            const groupedSlots: GroupedSlot[] = Object.values(
                data?.reduce<Record<string, GroupedSlot>>((acc, slot) => {
                    const date = convertToTimezoneWithDateString(slot.dateFrom).format('MM/DD/YYYY'); // Format date as DD/MM/YYYY
                    if (!acc[date]) {
                        acc[date] = { date, slots: [] };
                    }
                    acc[date].slots.push(slot);
                    return acc;
                }, {})
            ).map(group => {
                group?.slots.sort((a, b) => convertToTimezoneWithDateString(a.dateFrom).valueOf() - convertToTimezoneWithDateString(b.dateFrom).valueOf());// Sort slots by dateFrom
                return group;
            });
            return groupedSlots
        }
        return []

    }, [availabilities, selectedStatusCode])



    const handleDelete = (data: ScheduledSlot) => {
        setDel({ isDel: true, deleteData: data })
    }



    const onChangeStartAndEndDates = (start: Date, end: Date) => {



        let startDate = start


        const endDate = end


        if (startDate > endDate) {
            startDate = DEFAULT_DATES.start

            setDateValidation(true)
        }


        setFromDate(startDate);
        setToDate(endDate);



        setStartAndEndDates({
            startDate: convertToTimezone(startDate).startOf("day").toISOString(),
            endDate: convertToTimezone(endDate).endOf("day").toISOString(),
        })

        setDateValidation(false)

    }




    return (
        <>
            {isLoadingAvailabilities && <LoadingIndicator isLoading />}
            {isErrorAvailabilities && (
                <>
                    <div className='mt-5'><center><h4>Check Network connection!</h4></center></div>
                </>
            )}

            {!isLoadingAvailabilities && availabilities && (
                <div className="">
                    <div className="d-flex justify-content-between align-items-center ps-4 pe-4 ps-lg-5 ps-lg-3 pe-lg-5 pe-lg-3 pb-0 pt-3 pb-3">
                        {(state?.norecord === 0 ?
                            (<div>
                                <button
                                    className="backbtn"
                                    onClick={() => {
                                        nav('/inspector/landing-page', { state: state });
                                    }}
                                >
                                    Back
                                </button>
                            </div>) : null)}
                        <div style={{ fontWeight: 'bold' }}>
                            <div>{'My Availability'.toUpperCase()}</div>
                        </div>

                        <div>
                            <button
                                className="btn btn-primary btn-md text-capitalize fw-normal sw-shadow"
                                onClick={() => {
                                    let days = availabilities.map(e => e.dateFrom);
                                    let dayslots: ISlot[] = days.map(e => { return { label: formatDate(parseDate(e), "EE,MM/dd/yyyy"), value: formatDate(parseDate(e), "MM/dd/yyyy") } })
                                    unAvailableDaySlots.current = dayslots;
                                    let time: ISlot[] = availabilities.map(e => { return { label: e.timeSlot, value: e.timeSlot } })
                                    unAvailableTimeSlots.current = time;

                                    setIsAdd(true);
                                }}
                            >
                                <label className='webss-none text-white'>+</label>
                                <label className='mob-none text-white'>Add Availability</label>

                            </button>
                        </div>
                    </div>
                    {dateValidation && (
                        <Dialog
                            title={'Date Validation'}
                            onClose={toggleDialogValidation}
                            style={{ borderRadius: '10px' }}
                            className="delete-dialog"
                        >
                            <div>
                                <div className="row">
                                    <div className="d-flex justify-content-between align-items-center mt-2 mb-2'">
                                        <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                            From Date must be greater than to To Date
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    )}
                    <div
                        className="card p-4 p-4  p-lg-5 pt-lg-3 border-0 radius-page-15 sw-shadow "
                        style={{ minHeight: 'Calc(100vh - 170px)' }}
                    >
                        <><div className="date-pick-available">
                            <div>
                                <label className='webs-none'>From</label>
                                <DatePicker
                                    openToDate={fromDate}
                                    className="form-control calendarBg"
                                    value={formatDate(fromDate, 'MM/dd/yyyy')}
                                    placeholderText="To"
                                    maxDate={toDate}
                                    minDate={DEFAULT_DATES.start}
                                    onChange={([date]) => onChangeStartAndEndDates(date, toDate)}
                                    selectsMultiple
                                />
                            </div>
                            <div >
                                <span> to </span>
                            </div>
                            <div >
                                <label className='webs-none'>To</label>
                                <DatePicker
                                    // utcOffset={-20}
                                    openToDate={toDate}
                                    className="form-control calendarBg"
                                    minDate={new Date()}
                                    value={formatDate(toDate, 'MM/dd/yyyy')}
                                    placeholderText="To"
                                    dateFormat={'MM/dd/yyyy'}
                                    onChange={([date]) => onChangeStartAndEndDates(fromDate, date)}
                                    selectsMultiple
                                />
                            </div>
                            <div>
                                <SingleDropdown
                                    id="ddlAvailability"

                                    width={"130px"}
                                    height={"40px"}
                                    value={selectedStatusCode}
                                    dataitems={[
                                        { text: 'All', value: '-1' },
                                        { text: 'Available', value: SLOT_TYPES.availabilityReceived },
                                        { text: 'Unavailable', value: SLOT_TYPES.unavailable },
                                        { text: 'Scheduled', value: SLOT_TYPES.scheduled },
                                    ]}
                                    showDefaultItem={false}
                                    onchange={(value: string) => {
                                        setSelectedStatusCode(value);
                                    }}
                                />
                            </div>
                        </div>
                            {isAdd && (
                                <div>
                                    <AddAvailability
                                        onSuccess={() => {
                                            reloadAvailabilities();
                                        }}
                                        closeDialog={() => {
                                            setIsAdd(false);
                                        }}
                                        userType={"insepector"}
                                        data={initialSlotData.current}
                                        unAvailableDaySlots={unAvailableDaySlots.current}
                                        unAvailableTimeSlots={unAvailableTimeSlots.current}

                                    />
                                </div>
                            )}
                        </>
                        <AvailabilityList groupedSlots={groupedSlots} handleDelete={handleDelete} showClaimNumber />
                    </div>
                </div>
            )}
            {
                del.isDel && (<Dialog title='Delete Slot' className='inspector-delete' onClose={() => { setDel({ isDel: false, deleteData: undefined }) }} key={1}>
                    <center>
                        <h5>Are you sure you want to delete ?</h5>
                    </center>
                    <DialogActionsBar>
                        <div className='row'>
                            <div className='d-flex justify-content-between' >
                                <button className='btn btn-sm btn-secondary' onClick={() => {
                                    setDel({ isDel: false, deleteData: undefined })
                                }}>No</button>
                                <button className='btn btn-primary btn-sm' onClick={async () => {
                                    const response = await axios.post(`SchedulerAssignments/DeleteInspSlotsForCalendar?inspectorId=${userId}&slotId=${del.deleteData.slotId}&userId=${userId}&userName=${userName}&claimNumber=${del.deleteData.claimNo}`);
                                    if (response.data.result === -1) {
                                        setDel({ isDel: false, deleteData: undefined });
                                        reloadAvailabilities();
                                    }
                                }}>Yes</button>

                            </div>
                        </div>
                    </DialogActionsBar>

                </Dialog>)
            }

        </>
    );
};

export default InspectorAvailability;
