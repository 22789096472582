import React from 'react';

const EyeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.166" height="15.939" viewBox="0 0 21.166 15.939">
      <g id="Group_171" transform="translate(-1182.75 -251)">
        <path d="M1.5,12.97S4.985,6,11.083,6s9.583,6.97,9.583,6.97-3.485,6.97-9.583,6.97S1.5,12.97,1.5,12.97Z" transform="translate(1182.25 246)" fill="none" stroke="#f68b1f" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        <path d="M18.727,16.114A2.614,2.614,0,1,1,16.114,13.5,2.614,2.614,0,0,1,18.727,16.114Z" transform="translate(1177.22 242.856)" fill="none" stroke="#f68b1f" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      </g>
    </svg>
  );
};

export default EyeIcon;
