import axios from 'axios';
import { StorageKey, WINDOWS_DOORS_API_URL } from '../constants';
import FileUploadData from '../models/fileupload.model';
import { UploadedFile } from '../redux/reducers/filereducer';
import { loadImageAsArrayBuffer } from './fileutil';
import { ObservationType } from '../models/masters/observation-type';


export async function uploadImages(files: File[], fileUpload: FileUploadData) {
    const user = JSON.parse(localStorage.getItem(StorageKey.CurrentUser));
    console.log(user);

    try {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append(`UploadedFile`, files[i]); // File
        }
        formData.append('EntityID', fileUpload.EntityID.toString()); // long
        formData.append('UserID', '0'); // long
        formData.append('EntityType', fileUpload.EntityType.toString()); // string
        formData.append('SourceFileID', '123'); // long,
        formData.append('ContextID', 'sample_context'); // string,
        formData.append('Comments', fileUpload.Comments ? fileUpload.Comments : ''); // string,
        formData.append('Direction', 'sample_direction'); // string,
        formData.append('FileType', 'image'); // string,
        formData.append('IsInterior', fileUpload.IsInterior.toString()); //stringz
        formData.append('claimGuID', fileUpload.claimGuID.toString()); //string
        const authState = JSON.parse(localStorage.getItem(StorageKey.AuthState));

        const response = await axios.post(`${WINDOWS_DOORS_API_URL}/api/File/Upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: '*/*',
                Authorization: authState && `Bearer ${authState}`,
            },
        });

        console.log('Files uploaded successfully:', response.data);
        console.log(response.data);

        return response.data as UploadedFile;
    } catch (error: any) {
        console.error('Error uploading files:', error.response.data);
    }
}

// get file from cdn url
export async function fetchFile(
    url: string,
    comment: string,
    entityID?: string,
    entityType?: ObservationType,
    isbasemg?: boolean,
    fileID?: string,
    basePhotoID?: number,
    isInterior?: boolean,

): Promise<ImgFile> {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to fetch');
        }

        const blob = await response.blob();

        // Check if the blob is empty
        if (blob.size === 0) {
            const defaultText = 'no image found';
            const defaultBlob = new Blob([defaultText], { type: 'text/plain' });
            const defaultFileName = 'No Image Found';
            const defaultFile = new File([defaultBlob], defaultFileName, {
                type: defaultBlob.type,
            });
            return {
                comment: comment,
                file: defaultFile,
                entityID: parseInt(entityID),
                entityType: entityType,
                isbaseImg: isbasemg,
                fileID: fileID,
                baseID: basePhotoID,
                userGuid: '',
                isInterior: isInterior,
            };
        }

        const fileName = url.substring(url.lastIndexOf('/') + 1);
        const file = new File([blob], fileName, { type: blob.type });

        return {
            comment: comment,
            file: file,
            entityID: parseInt(entityID),
            entityType: entityType,
            isbaseImg: isbasemg,
            fileID: fileID,
            baseID: basePhotoID,
            userGuid: '',
            isInterior: isInterior

        };
    } catch (error) {
        console.error(
            'Error fetching file:',
            'Image is either  unavaialable or not loaded due to poor network ',
        );
        const imagePath = require('../images/noimage.jpg');
        // Return default file in case of error
        const imageData = await loadImageAsArrayBuffer(imagePath);
        const file = new File([imageData], 'image.jpg', { type: 'image/jpeg' });

        console.log(file);
        return {
            comment: 'No Image',
            file: file,
            fileID: fileID,
            baseID: basePhotoID,
            entityID: 0,
            entityType: '',
            userGuid: '',
            isInterior: isInterior
        };
    }
}
///delete file

export async function deleteFile(file: ImgFile) {
    const authState = JSON.parse(localStorage.getItem(StorageKey.AuthState));

    const deleteURL = `${WINDOWS_DOORS_API_URL}/api/File/DeleteFile/${file.fileID}`;
    try {
        const response = await axios.get(deleteURL, {
            headers: {
                'Content-Type': 'application/json',
                Accept: '*/*',
                Authorization: authState && `Bearer ${authState}`,
            },
        });
        return response.data as boolean;
    } catch (error: any) {
        console.error('Error uploading files:', error.response.data);
    }
}
///edit the comments
export async function editComments(editdata: EditComment) {
    const user = JSON.parse(localStorage.getItem(StorageKey.CurrentUser));
    console.log(user);

    try {
        const authState = JSON.parse(localStorage.getItem(StorageKey.AuthState));

        const body = {
            fileId: parseInt(editdata.fileID),
            comment: editdata.comments,
        };
        const response = await axios.post(
            `${WINDOWS_DOORS_API_URL}/api/File/UpdateComments`,
            body,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: '*/*',
                    Authorization: authState && `Bearer ${authState}`,
                },
            },
        );

        console.log('comment edited successfully:', response.data);
        console.log(response.data);

        return response.data as boolean;
    } catch (error: any) {
        console.error('Error editing comments:', error.response.data);
    }
}

///setbasePhoto 
export async function setbasePhoto(editdata: EditComment) {
    const user = JSON.parse(localStorage.getItem(StorageKey.CurrentUser));
    console.log(user);

    try {
        const authState = JSON.parse(localStorage.getItem(StorageKey.AuthState));

        const body = {
            baseID: editdata.baseID,
            fileID: parseInt(editdata.fileID),
            entityID: editdata.entityID,
            entityType: editdata.entityType,
            userGuid: '',
            isBasePhoto: editdata.isBaseImg,
            isInterior: editdata.isInterior ? editdata.isInterior : false
        };
        console.log(body);

        const response = await axios.post(`${WINDOWS_DOORS_API_URL}/api/File/SetBasePhoto`, body, {
            headers: {
                'Content-Type': 'application/json',
                Accept: '*/*',
                Authorization: authState && `Bearer ${authState}`,
            },
        });

        console.log('photo set as base successfully:', response.data);
        console.log(response.data);

        return response.data as boolean;
    } catch (error: any) {
        console.error('Error editing comments:', error.response.data);
    }
}

export interface ImgFile {
    fileID?: string;
    comment: string;
    file: File;
    isbaseImg?: boolean;
    baseID?: number;
    entityID?: number;
    entityType?: string;
    userGuid?: string;
    isInterior?: boolean;
}
///model to edit comment
export interface EditComment {
    fileId: string;
    comments: string;
    isBaseImg?: boolean;
    baseID?: number;
    fileID?: string;
    entityID?: number;
    entityType?: string;
    userGuid?: string;
    isInterior?: boolean;
}
