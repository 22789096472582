import type { AxiosError } from 'axios';
import axios from 'axios';
import { createMutation } from 'react-query-kit';


export type SendOtpPayload = { mobileNo?: string; countryCode?: string; userType: string, email?: string; };


export interface SendOtpResponse {
    success: boolean;
    token?: string;
    errorMessage?: string;
    message?: string;
    statusCode?: number;
    result?: string;
}

export const useSendMobileOtp = createMutation<SendOtpResponse, SendOtpPayload, AxiosError>({
    mutationFn: async payload =>
        axios({
            url: 'SchedulerAssignments/MobileVerificationAndSendOTP',
            method: 'POST',
            data: payload
        }).then(response => response.data),
});
