

import React from 'react';

const InspectionReportIcon: React.FC = () => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="orange">
            <path id="Path_chart-box-outline" data-name="Path / chart-box-outline" d="M9,17H7V10H9v7m4,0H11V7h2V17m4,0H15V13h2v4m2,2H5V5H19V19.1M19,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Z" transform="translate(-3 -3)" />
        </svg>

    );
};

interface StarBlankProps {
    width?: number;
    height?: number;
    fill0?: string;
    fill1?: string;
    onClick: () => Promise<void>;
}

const StarBlank: React.FC<StarBlankProps> = ({ width = 22, height = 22, fill0 = '#FFFFFF', fill1 = '#ABADB0', onClick }) => {
    return (
        <svg

            version="1.1"
            id="Layer_1"
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 22 22"
            style={{}}
            xmlSpace="preserve"
            width={width}
            height={height}
            onClick={onClick}
        >
            <title>Set as Default template</title>
            <style type="text/css">
                {`.st0{fill:${fill0};}
          .st1{fill:${fill1};}`}
            </style>
            <g>
                <path
                    className="st0"
                    d="M12,2l2.3,4.6c0.2,0.3,0.5,0.5,0.8,0.6l5,0.7c0.9,0.1,1.2,1.2,0.6,1.8L17,13.3c-0.3,0.2-0.4,0.6-0.3,1l0.9,5
            c0.2,0.9-0.8,1.6-1.6,1.1l-4.5-2.4c-0.3-0.2-0.7-0.2-1,0L6,20.4c-0.8,0.4-1.7-0.3-1.6-1.1l0.9-5c0.1-0.3-0.1-0.7-0.3-1L1.3,9.8
            C0.7,9.1,1,8.1,1.9,7.9l5-0.7c0.4-0.1,0.7-0.3,0.8-0.6L10,2C10.4,1.2,11.6,1.2,12,2z"
                />
                <path
                    className="st1"
                    d="M5.5,21.1c-0.3,0-0.6-0.1-0.9-0.3c-0.5-0.4-0.7-0.9-0.6-1.5l0.9-5c0-0.2,0-0.4-0.2-0.5L1,10.1
            c-0.4-0.4-0.6-1-0.4-1.6c0.2-0.6,0.7-1,1.3-1.1l5-0.7c0.2,0,0.4-0.1,0.4-0.3l2.3-4.6c0.3-0.5,0.8-0.9,1.4-0.9c0,0,0,0,0,0
            c0.6,0,1.1,0.3,1.4,0.9l2.3,4.6c0.1,0.2,0.2,0.3,0.4,0.3l5,0.7c0.6,0.1,1.1,0.5,1.3,1.1c0.2,0.6,0,1.2-0.4,1.6l-3.7,3.6
            c-0.1,0.1-0.2,0.3-0.2,0.5l0.9,5c0.1,0.6-0.1,1.2-0.6,1.5c-0.5,0.4-1.1,0.4-1.7,0.1l-4.5-2.4c-0.2-0.1-0.4-0.1-0.5,0l-4.5,2.4
            C6,21,5.7,21.1,5.5,21.1z M11,1.9c-0.2,0-0.4,0.1-0.5,0.3L8.2,6.8C8,7.3,7.6,7.6,7,7.7L2,8.4c-0.2,0-0.4,0.2-0.5,0.4
            C1.5,9,1.5,9.3,1.7,9.4L5.3,13c0.4,0.4,0.5,0.9,0.5,1.4l-0.9,5c0,0.2,0,0.4,0.2,0.6c0.2,0.1,0.4,0.1,0.6,0l4.5-2.4
            c0.5-0.2,1-0.2,1.5,0l4.5,2.4c0.2,0.1,0.4,0.1,0.6,0c0.2-0.1,0.3-0.3,0.2-0.6l-0.9-5c-0.1-0.5,0.1-1,0.5-1.4l3.7-3.6
            c0.2-0.2,0.2-0.4,0.1-0.6c-0.1-0.2-0.2-0.4-0.5-0.4l-5-0.7c-0.5-0.1-1-0.4-1.2-0.9l-2.3-4.6C11.4,2.1,11.2,1.9,11,1.9z"
                />
            </g>
        </svg>
    );
};

export { StarBlank, InspectionReportIcon };