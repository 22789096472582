import { Dialog } from '@progress/kendo-react-dialogs';
import { get } from 'lodash';
import { useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { ColorInfo } from './image-draw.config';

export interface ColorPickerComponentProps {
    currentColorInfo: ColorInfo;
    onClose?: (colorInfo: ColorInfo) => any;
}

export const ColorPickerComponent = ({ currentColorInfo, onClose }: ColorPickerComponentProps) => {
    const [selectedColorOption, setSelectedColorOption] = useState<{ prop: string; color: string }>(
        { prop: 'defaultColor', color: currentColorInfo.defaultColor },
    );
    const [showColorPicker, setShowColorPicker] = useState(false);

    const handleClose = () => {
        if (onClose) {
            onClose(null);
        }
        setShowColorPicker(false);
    };

    const colorOptions = [
        { label: 'Default Color', value: 'defaultColor', color: currentColorInfo?.defaultColor },
        { label: 'New Draw', value: 'ghostColor', color: currentColorInfo?.ghostColor },
        {
            label: 'Selection Color',
            value: 'selectionColor',
            color: currentColorInfo?.selectionColor,
        },
        {
            label: 'Higlight Color',
            value: 'highlightColor',
            color: currentColorInfo?.highlightColor,
        },
    ];

    const handleColorOptionSelect = (colorOption: string) => {
        setSelectedColorOption({ prop: colorOption, color: get(currentColorInfo, colorOption) });
    };

    const handleColorChangeComplete = (color: ColorResult) => {
        setSelectedColorOption({ prop: selectedColorOption.prop, color: color.hex });
    };

    const handleClickOK = () => {
        onClose({ ...currentColorInfo, [selectedColorOption.prop]: selectedColorOption.color });
        setShowColorPicker(false);
    };

    if (!showColorPicker) {
        return <button className="color-pic" onClick={() => setShowColorPicker(true)} />;
    }

    return (
        <Dialog title={'Color Picker'} onClose={handleClose} width={600} height={500}>
            <div className="button-group-image">
                <div className="select w175">
                    <select
                        className="form-control"
                        value={selectedColorOption.prop}
                        onChange={(value) => handleColorOptionSelect(value.target.value)}
                    >
                        {colorOptions?.map((o) => {
                            return <option key={o.value} value={o.value} label={o.label} />;
                        })}
                    </select>
                </div>
                <SketchPicker
                    color={selectedColorOption.color}
                    onChangeComplete={handleColorChangeComplete}
                />
                <button onClick={handleClickOK}>OK</button>
            </div>
        </Dialog>
    );
};
