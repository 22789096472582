import { GhostMode, Point, Shape } from '../../../../models/building-image/image-draw.types';
import {
    checkPointInsideCircle,
    checkPointInsideRectangle,
    getAdjustedRectanglePoints,
} from './geometry.util';
import { ImageConfig } from '../image-draw.config';

export const moveGhost = (delta: Point, ghost: Shape, imgWidth: number, imgHeight: number) => {
    const { point1, point2 } = ghost;
    const updatedShape = {
        ...ghost,
        point1: {
            x: point1.x + delta.x,
            y: point1.y + delta.y,
        },
        point2: {
            x: point2.x + delta.x,
            y: point2.y + delta.y,
        },
    };
    if (
        checkPointInsideRectangle(
            updatedShape.point1,
            { x: ImageConfig.LINE_THICKNESS, y: ImageConfig.LINE_THICKNESS },
            {
                x: imgWidth - ImageConfig.LINE_THICKNESS,
                y: imgHeight - ImageConfig.LINE_THICKNESS,
            },
        ) &&
        checkPointInsideRectangle(
            updatedShape.point2,
            { x: ImageConfig.LINE_THICKNESS, y: ImageConfig.LINE_THICKNESS },
            {
                x: imgWidth - ImageConfig.LINE_THICKNESS,
                y: imgHeight - ImageConfig.LINE_THICKNESS,
            },
        )
    ) {
        return updatedShape;
    }
};

export const resizeGhost = (point: Point, shape: Shape, currentGhostMode: GhostMode) => {
    const { point1, point2 } = shape;
    let newPoint1: Point;
    let newPoint2: Point;

    switch (currentGhostMode) {
        case GhostMode.TL:
            newPoint1 = { x: point.x, y: point.y };
            newPoint2 = { x: point2.x, y: point2.y };
            break;
        case GhostMode.TR:
            newPoint1 = { x: point1.x, y: point.y };
            newPoint2 = { x: point.x, y: point2.y };
            break;
        case GhostMode.BR:
            newPoint1 = { x: point1.x, y: point1.y };
            newPoint2 = { x: point.x, y: point.y };
            break;
        case GhostMode.BL:
            newPoint1 = { x: point.x, y: point1.y };
            newPoint2 = { x: point2.x, y: point.y };
            break;
        case GhostMode.Moving:
            break;
    }
    if (newPoint1 && newPoint2) {
        const { point1, point2 } = getAdjustedRectanglePoints(newPoint1, newPoint2);
        const updatedShape = {
            ...shape,
            point1: point1,
            point2: point2,
        };
        return updatedShape;
    }
};

export const getCurrentGhostMode = (
    point: Point,
    { point1, point2 }: Shape,
    scaleFactor: number,
) => {
    const radius = 10 / scaleFactor;
    if (checkPointInsideCircle(point, point1, radius)) {
        return GhostMode.TL;
    } else if (checkPointInsideCircle(point, { x: point2.x, y: point1.y }, radius)) {
        return GhostMode.TR;
    } else if (checkPointInsideCircle(point, { x: point1.x, y: point2.y }, radius)) {
        return GhostMode.BL;
    } else if (checkPointInsideCircle(point, point2, radius)) {
        return GhostMode.BR;
    } else if (checkPointInsideRectangle(point, point1, point2)) {
        return GhostMode.Moving;
    }
};
