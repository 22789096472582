import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { InputTextArea } from './form/input-textarea.component';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { setbasePhoto } from '../utils/uploadfile.util';

interface ImageUploaderProps {
    showModal: boolean;
    img: File;
    onclose: Function;
    onAddImg: Function;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ ...props }) => {
    const { img, onclose, showModal, onAddImg } = props;
    const [closeModal, setCloseModal] = useState<boolean>(showModal);
    const [imgSrc, setImgSrc] = useState<string>('');
    const [comment, setcomment] = useState<string>('');
    const [isbaseImg, setisbaseImg] = useState<boolean>();
    useEffect(() => {
        if (img) {
            convertImageToBase64(img)
                .then((base64String: string) => {
                    setImgSrc(base64String);
                    setCloseModal(showModal);
                })
                .catch((error) => {
                    console.error('Error converting image to base64:', error);
                });
        }
    }, [img]);

    function convertImageToBase64(imageFile: File) {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = function () {
                resolve(reader.result as string);
            };

            reader.onerror = function (error) {
                reject(error);
            };

            reader.readAsDataURL(imageFile);
        });
    }

    const handleAddImage = () => {
        onAddImg(comment);
        console.log('Image added');

        setCloseModal(false);
        setcomment('');
    };

    return (
        <div>
            {closeModal && (
                <Dialog title={'Image Preview'} onClose={() => onclose()}>
                    <div style={{ textAlign: 'center', border: '1px solid #eee', padding: '5px', backgroundColor: '#eee', borderRadius: '5px' }}>
                        <img
                            src={imgSrc}
                            alt="Preview"
                            style={{ width: '50%', height: '50%', justifyContent: 'center' }}
                        />
                    </div>
                    <div className="mt-3">

                        <Input
                            value={comment} placeholder='Comment'
                            onChange={(e) => {
                                setcomment(e.target.value as string);
                            }}
                            name="comment"
                            style={{ width: '100%', minHeight: 50 }}
                        />
                    </div>
                    {/* <div className="mt-4 m-1">
                        <label htmlFor="isBaseImg">Mark as Base Image</label>
                        <Checkbox
                            value={isbaseImg}
                            onChange={async(e) => {
                                setisbaseImg(e.target.value as boolean);
                                await setbasePhoto({
                                    comments:comment,
                                    fileId: selectedImage.fileID,
                                    baseID: selectedImage.baseID,
                                    entityID: selectedImage.entityID,
                                    entityType: selectedImage.entityType,
                                    isBaseImg: !isbaseImg,
                                    fileID: selectedImage.fileID,
                                    userGuid: '',
                                });
                            }}
                            name="isBaseImg"
                            style={{ width: '20px', height: '20px' }}
                        />
                    </div> */}
                    <DialogActionsBar>
                        <div className="d-flex m-2 mt-0 ">
                            <button
                                title="Cancel the image"
                                className="btn btn-secondary me-auto btn-sm"
                                onClick={() => onclose()}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-primary btn-sm "
                                title="Upload the image"
                                onClick={handleAddImage}
                            >
                                Submit
                            </button>
                        </div>

                    </DialogActionsBar>
                </Dialog>
            )}
        </div>
    );
};

export default ImageUploader;
