import { Field, useField, useFormikContext } from 'formik';
import { InputFieldType } from '../../models/enums/input-field-type';
import { CheckboxField } from './checkbox-field.component';
import React from 'react';
import { InputField } from './input.feild.component';
import { useEffect, useState } from 'react';
import { WINDOWS_DOORS_API_URL, WINDOWS_DOOR_OTHER_OPTION } from '../../constants';
import { log } from 'console';

interface MultiSelectFieldProps {
    name: string;
    optionValues?: MultiSelctOptionValue[];
    align?: MultiSelectAlign;
    isFullRow?: boolean; // Added the new prop here
}

export interface MultiSelctOptionValue {
    value: string;
    label: string;
    inputType?: InputFieldType;
    id?: number;
}

export const MultiSelectField = ({ optionValues, align = MultiSelectAlign.Column, isFullRow = false }: MultiSelectFieldProps): any => {
    const { values } = useFormikContext();

    return optionValues.map((option, index) => {

        return (
            <React.Fragment key={option.value + index} >

                {option.inputType && option.inputType === InputFieldType.None && (
                    <div className='checkboxOptionscls'>
                        <CheckboxField name={option.value} label={option.label} />
                    </div>
                )}
                {option.inputType && option.inputType !== InputFieldType.None && (
                    <CustomOtherField option={option} isFullRow={isFullRow} />
                )}

            </React.Fragment>
        );
    });
};

interface CustomOtherFieldProps {
    option: MultiSelctOptionValue;
    isFullRow?: boolean;
}

const CustomOtherField: React.FC<CustomOtherFieldProps> = ({ ...props }) => {
    const { option, isFullRow } = props;
    const [field, meta, helpers] = useField(`${option.value}value-${option.id}`);
    const [chkboxfield, chkBoxMeta, chkBoxHelpers] = useField(`${option.value}-${option.id}`);

    const [selected, setSelected] = useState<boolean>(chkboxfield.value && chkboxfield.value === true ? true : false);

    useEffect(() => {
        if (chkboxfield.name.includes(WINDOWS_DOOR_OTHER_OPTION)) {
            //console.log(chkboxfield.name);
        }
        setSelected(!!field.value);
    }, [field.value]);



    return <>

        <div className='checkboxOptionscls'>
            <input
                className="k-checkbox k-checkbox-md k-rounded-md"
                name={chkboxfield.name}  onClick={()=>{
                    console.log('clicked');
                    
                }}
                type="checkbox"
                checked={chkboxfield.value}
                onChange={(e: any) => {
                    console.log(option.value);
                    console.log(option.label);

                    setSelected(e.target.checked);
                    chkBoxHelpers.setValue(e.target.checked);
                    console.log(chkboxfield.value);
                }}
            />
            <label className="mx-1 switch">{option.label}</label>
        </div>



        {
            chkboxfield.value && chkboxfield.value === true && selected && (
                <div style={{ width: isFullRow ? '100%' : 'auto' }}>
                    <InputField name={field.name} label={undefined} />
                </div>

            )
        }
    </>
}

export enum MultiSelectAlign {
    Column, Row
}
