import { Point } from '../../../../models/building-image/image-draw.types';

export const getCanvasMousePosition = (
    { clientX, clientY }: any,
    canvas: HTMLCanvasElement,
): Point => {
    const rect = canvas.getBoundingClientRect();
    const x = clientX - rect.left;
    const y = clientY - rect.top;
    return { x, y };
};
