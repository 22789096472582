export const FooterComponent = () => {
    return (
        <div className="text-center">
            <small className="font-10">
                {' '}
                COPYRIGHT © {new Date().getFullYear()} ALL RIGHTS RESERVED. KEYSTONE ENGINEERING{' '}
            </small>
        </div>
    );
};
