import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";



interface Props {
    location: { lat: number; long: number }
}

const containerStyle = {
    width: '100%',
    height: '400px'
};
export function Map({ location }: Props) {

    const { isLoaded, } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBKDeDCyCAHKPru29FgkbODlVNwQGEzDPg",
    });

    return isLoaded ? (
        <div className='card border-0 sw-shadow radius-15 overflow-hidden'>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={{ lat: Number(location.lat), lng: Number(location.long) }}
                zoom={10}
                options={{ clickableIcons: false, zoomControlOptions: { position: google.maps.ControlPosition.BOTTOM_RIGHT }, zoomControl: true, }}
                mapTypeId={google.maps.MapTypeId.ROADMAP}
                id='map'
                onLoad={(map) => {
                    map.setOptions({
                        zoomControl: true,
                        zoomControlOptions: {
                            position: google.maps.ControlPosition.RIGHT_CENTER
                        }
                    });
                }}
            >
                {/* Mark location */}
                <Marker position={{ lat: Number(location.lat), lng: Number(location.long) }} />
            </GoogleMap>
        </div >
    ) : <></>
}