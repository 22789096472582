import { useState } from 'react';
import { ShareLinkModelDialog, ShareType } from './share-link-dialog.component';

interface ShareLinkButtonProps {
    title: string;
    shareUrl: string;
    type: ShareType;
    typeId: number | string;
}

export const ShareLinkButton = ({ title, shareUrl, type, typeId }: ShareLinkButtonProps) => {
    const [showShareComponent, setShowShareComponent] = useState(false);

    if (!typeId) {
        return null;
    }

    return (
        <>
            <button className='btn btn-primary w-auto text-nowrap me-2 btn-sm' title={title} onClick={() => setShowShareComponent(true)}>
                {title}
            </button>
            {showShareComponent && (
                <ShareLinkModelDialog
                    shareUrl={shareUrl}
                    type={type}
                    typeId={Number(typeId)}
                    onClose={() => setShowShareComponent(false)}
                />
            )}
        </>
    );
};
