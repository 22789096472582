import { WINDOWS_DOORS_API_URL } from '../constants';
import { Building } from '../models/claim/building';
import AsyncRequest, { HttpMethod } from '../utils/fetchutil';

export const GetBuildingDetailsByID = async (buildingID: number) => {
    return await AsyncRequest.requestApi({
        method: HttpMethod.GET,
        url: `${WINDOWS_DOORS_API_URL}/api/building?buildingID=${buildingID}`,
    }).then((data) => {
        return data as Building;
    });
};

export const GetBuildingList = async (claimsGuID: string) => {
    return await AsyncRequest.requestApi({
        method: HttpMethod.GET,
        url: `${WINDOWS_DOORS_API_URL}/api/Building/Claims/${claimsGuID}`,
    }).then((data) => {
        return data as Building[];
    });
};

