import { log } from 'util';
import { StorageKey, WINDOWS_DOORS_API_URL } from '../constants';
import { ApiResult } from '../models/api-result';

export const getData = async <T>(route: string, name?: string): Promise<ApiResult<T>> => {
    const authState = JSON.parse(localStorage.getItem(StorageKey.AuthState));
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...(authState && { Authorization: `Bearer ${authState}` }),
        },
    };
    console.log(`${WINDOWS_DOORS_API_URL}/api/${route}`, requestOptions);

    const result = await fetch(`${WINDOWS_DOORS_API_URL}/api/${route}`, requestOptions);

    return {
        name,
        status: result.status,
        data: await result.json(),
    };
};

export const postData = async <T>(
    route: string,
    method: string,
    contentType: string,
    data: any,
): Promise<ApiResult<T>> => {
    const authState = JSON.parse(localStorage.getItem(StorageKey.AuthState));
    const submitData = contentType === 'application/json' ? JSON.stringify(data) : data;
    const skipContentType = contentType === 'multipart/form-data';
    const requestOptions: RequestInit = {
        method: method,
        headers: {
            ...(!skipContentType && { 'Content-Type': contentType }),
            ...(authState && { Authorization: `Bearer ${authState}` }),
        },
        body: submitData,
    };
    if (!JSON.stringify(submitData).includes('email')) {
        console.log(submitData)
    };

    const result = await fetch(`${WINDOWS_DOORS_API_URL}/api/${route}`, requestOptions);
    //console.log(result);

    return {
        status: result.status,
        data: await result.json(),
    };
};
