import React, { useEffect, useState } from 'react';
import { MultiSelect, MultiSelectChangeEvent, ListItemProps } from '@progress/kendo-react-dropdowns';

interface MultiSelectFieldProps {
    optionValues?: MultiSelectOptionValue[];
    onChange?: (selectedValues: MultiSelectOptionValue[]) => void;
    placeholder: string;
    groupBy?: boolean;
    isAll?: boolean;
}

export interface MultiSelectOptionValue {
    label: string;
    value: string;
    group?: string;
    isAll?: boolean;
}

const MultiSelectField: React.FC<MultiSelectFieldProps> = ({ optionValues = [], onChange, placeholder, groupBy = false, isAll = false }) => {
    const [internalOptionValues, setInternalOptionValues] = useState<MultiSelectOptionValue[]>([]);
    const [selectedItems, setSelectedItems] = useState<MultiSelectOptionValue[]>([]);

    useEffect(() => {
        if (groupBy && optionValues.length > 0) {
            const groups = Array.from(new Set(optionValues.map(option => option.group)));
            const newOptionValues = groups.flatMap(group => {
                const groupItems = optionValues.filter(option => option.group === group);
                const allOption = { label: 'All', value: `${group}-all`, group, isAll: true };
                return [allOption, ...groupItems];
            });
            setInternalOptionValues(newOptionValues);
        } else if (isAll) {
            setInternalOptionValues([{ label: 'All', value: 'all', isAll: true }, ...optionValues]);
        } else {
            setInternalOptionValues(optionValues);
        }
    }, [optionValues, groupBy, isAll]);

    const handleMultiSelectChange = (event: MultiSelectChangeEvent) => {
        const newSelectedItems = event.value as MultiSelectOptionValue[];
        setSelectedItems(newSelectedItems);

        if (onChange) {
            onChange(newSelectedItems.filter(item => !item.isAll));
        }
    };

    const handleCheckboxChange = (item: MultiSelectOptionValue) => {
        let updatedSelectedItems: MultiSelectOptionValue[];

        if (item.isAll) {
            if (item.group) {
                const groupItems = internalOptionValues.filter(option => option.group === item.group && !option.isAll);
                const allGroupItemsSelected = groupItems.every(groupItem => selectedItems.includes(groupItem));
                updatedSelectedItems = allGroupItemsSelected
                    ? selectedItems.filter(selectedItem => !groupItems.includes(selectedItem))
                    : [...new Set([...selectedItems, ...groupItems])];
            } else {
                const allItemsSelected = internalOptionValues.every(option => selectedItems.includes(option));
                updatedSelectedItems = allItemsSelected || internalOptionValues.filter(option => !option.isAll).length === selectedItems.length
                    ? []
                    : [...internalOptionValues.filter(option => !option.isAll)];
            }
        } else {
            const isSelected = selectedItems.some(selectedItem => selectedItem.value === item.value);
            updatedSelectedItems = isSelected
                ? selectedItems.filter(selectedItem => selectedItem.value !== item.value)
                : [...new Set([...selectedItems, item])];
        }

        setSelectedItems(updatedSelectedItems.filter(option => !option.isAll));

        if (onChange) {
            onChange(updatedSelectedItems.filter(option => !option.isAll));
        }
    };

    const itemRender = (li: React.ReactElement<HTMLLIElement> | undefined, itemProps: ListItemProps) => {
        const { dataItem } = itemProps;
        const item = dataItem as MultiSelectOptionValue;
        const isChecked = selectedItems.some(selectedItem => selectedItem.value === item.value);

        const itemStyle: React.CSSProperties = {
            backgroundColor: isChecked ? 'orangered' : 'inherit',
            cursor: 'pointer',
            padding: '8px',
            display: 'flex',
            alignItems: 'center',
            color: isChecked ? 'white' : 'black'
        };

        return (
            <div
                onClick={(e) => { e.stopPropagation(); handleCheckboxChange(item); }}
                style={itemStyle}
            >
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={(e) => { e.stopPropagation(); handleCheckboxChange(item); }}
                    style={{ marginRight: '8px' }}
                />
                <label>{item.label}</label>
            </div>
        );
    };

    const groupHeaderRender = (group: any) => (
        <div style={{ backgroundColor: 'lightorange', color: 'white', padding: '8px' }}>
            {group.value}
        </div>
    );

    const renderTags = () => {
        const selectedItemsLength = selectedItems.length;
        let displayText = selectedItems.slice(0, 3).map(item => item.label).join(", ");
        if (selectedItemsLength > 3) {
            displayText += `, More...`;
        }
        return (
            <label>{displayText}</label>
        );
    };

    return (
        <MultiSelect
            data={internalOptionValues}
            textField="label"
            itemRender={itemRender}
            groupField={groupBy ? 'group' : undefined}
            // groupStickyHeaderItemRender={groupHeaderRender}

            onChange={handleMultiSelectChange}
            value={selectedItems}
            autoClose={false}
            tags={[{ text: `Select ${placeholder}(s)`, data: [] }]}
            tagRender={renderTags}
        />
    );
};

export default MultiSelectField;
