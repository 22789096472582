import { EntityImageData } from './entity-image-data';

export interface Point {
    x: number;
    y: number;
}

export interface Transform {
    zoom: number;
    translate: Point;
}

export enum ShapeType {
    Rectangle = 'Rectangle',
    Circle = 'Circle',
}

export interface Shape {
    point1: Point;
    point2: Point;
    shapeKey?: number;
    shapeType?: ShapeType;
    color?: string;
    thickness?: number;
    tagData?: EntityImageData;
    isHighlighted?: boolean;
    isGhost?: boolean;
    isSelected?: boolean;
    isEditable?: boolean;
    isHidden?: boolean;
    colorTags?: string[];
}

export enum Mode {
    View = 'View',
    Pan = 'Pan',
    ZoomIn = 'ZoomIn',
    ZoomOut = 'ZoomOut',
    Draw = 'Draw',
    Select = 'Select',
}

export enum GhostMode {
    Nil,
    Moving,
    TL,
    TR,
    BL,
    BR,
}
