import { isEmpty, isNil, find, map } from 'lodash';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { Building } from '../../../models/claim/building';
import { EntityType } from '../../../models/claim/entity-type';
import { CopyWindowComponent } from '../window/copy-window.component';

interface CopyEntityComponentProps {
    building: Building;
    entityType: EntityType;
    onCopyEntity: (entity: any) => any;
}

export const CopyEntityComponent = ({
    building,
    entityType,
    onCopyEntity,
}: CopyEntityComponentProps) => {
    const [floor, setSelectedFloor] = useState<{
        label: string;
        value: number;
    }>();
    const [unit, setSelectedUnit] = useState<{
        label: string;
        value: number;
    }>();
    const [room, setSelectedRoom] = useState<{
        label: string;
        value: number;
    }>();

    useEffect(() => {
        if (!isEmpty(building?.floors)) {
            const firstFloor = !isEmpty(building?.floors) ? building?.floors[0] : undefined;
            const firstUnit =
                !isNil(firstFloor) && !isEmpty(firstFloor?.units)
                    ? firstFloor?.units[0]
                    : undefined;
            const firstRoom =
                !isNil(firstUnit) && !isEmpty(firstUnit?.rooms) ? firstUnit?.rooms[0] : undefined;

            if (firstFloor)
                setSelectedFloor({ value: firstFloor.floorID, label: firstFloor.floorName });
            if (firstUnit) setSelectedUnit({ value: firstUnit.unitID, label: firstUnit.unitName });
            if (firstRoom) setSelectedRoom({ value: firstRoom.roomID, label: firstRoom.roomName });
        }
    }, []);

    const selectedFloor = find(building?.floors, (f) => f.floorID === floor?.value);
    const selectedUnit = find(selectedFloor?.units, (u) => u.unitID === unit?.value);
    //const selectedRoom = find(selectedUnit?.rooms, (r) => r.roomID === room?.value);

    const floorOptions = map(building?.floors, (f) => {
        return {
            label: f.floorName,
            value: f.floorID,
        };
    });

    const unitOptions = map(selectedFloor?.units, (u) => {
        return {
            label: u.unitName,
            value: u.unitID,
        };
    });

    const roomOptions = map(selectedUnit?.rooms, (r) => {
        return {
            label: r.roomName,
            value: r.roomID,
        };
    });

    const handleFloorSelectionChange = (floorSelected: any) => {
        if (floorSelected.value !== floor?.value) {
            setSelectedFloor(floorSelected);
            setSelectedUnit(null);
            setSelectedRoom(null);
        }
    };

    const handleUnitSelectionChange = (unitSelected: any) => {
        if (unitSelected.value !== unit?.value) {
            setSelectedUnit(unitSelected);
            setSelectedRoom(null);
        }
    };

    const handleRoomSelectionChange = (roomSelected: any) => {
        setSelectedRoom(roomSelected);
    };

    return (
        <div>
            Floor
            <Select value={floor} options={floorOptions} onChange={handleFloorSelectionChange} />
            Unit
            <Select value={unit} options={unitOptions} onChange={handleUnitSelectionChange} />
            Room
            <Select value={room} options={roomOptions} onChange={handleRoomSelectionChange} />
            {entityType === EntityType.Window && (
                <CopyWindowComponent roomId={room?.value} onCopyEntity={onCopyEntity} />
            )}
            {/* {entityType === EntityType.Door && (
                <CopyDoorComponent roomId={room?.value} onCopyEntity={onCopyEntity} />
            )}
            {entityType === EntityType.Railing && (
                <CopyRailingComponent roomId={room?.value} onCopyEntity={onCopyEntity} />
            )} */}
        </div>
    );
};
