import React, { useEffect, useMemo, useState } from 'react';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import '@progress/kendo-theme-default/dist/all.css';
import { text } from 'stream/consumers';
import { isEmpty } from 'lodash';
interface DataItem {
    text: string;
    value: string;
}
interface SingleDropdownProps {
    dataitems: DataItem[];
    onchange: Function;
    id: string;
    placeholder?: string;
    isDef?: DataItem;
    width?: string;
    height?: string;
    className?: string;
    showDefaultItem?: boolean;
    value: string;
    disabled?: boolean;
}

const SingleDropdown: React.FC<SingleDropdownProps> = ({ ...props }) => {
    const { onchange, id, dataitems, placeholder = "Select", showDefaultItem = true, value } = props;
    const handleChange = (event: DropDownListChangeEvent) => {
        onchange(event.target.value.value as string, event.target.value);
    };



    const options = useMemo(() => {
        if (showDefaultItem && !!dataitems.length) {
            return [{ text: "Select Option", value: "-1" }, ...dataitems]
        }
        return dataitems

    }, [showDefaultItem, dataitems])



    const selectedItem = useMemo(() => {
        return options.find(item => item.value === value) || {};
    }, [value, options])



    return (
        <div>
            <DropDownList fillMode='solid'
                adaptive={true}
                data={options}
                disabled={props.disabled}
                style={{
                    width: props.width != "" ? props.width : "100px",
                    height: props.height != "" ? props.height : '40px'
                }}
                id={id}
                key={id}
                name={id}
                textField="text"
                dataItemKey="value"
                adaptiveTitle={placeholder}
                onChange={handleChange}
                value={selectedItem}
                className={!isEmpty(props.className) ? props.className : 'form-control dropDownList'}
            />
            {/* {selectedItem && <div>Selected option: {selectedItem.text}</div>} */}
        </div>
    );
};

export default SingleDropdown;
