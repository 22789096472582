import { createReducer, createSlice } from '@reduxjs/toolkit';
import { Building } from '../../../models/claim/building';
import { GetBuildingDetail } from '../../actions/buildingactions';

export interface BuildingState {
    building: Building;
    buildingLoading: boolean;
}

const initialBuildingState: BuildingState = {
    buildingLoading: true,
    building: null,
};

const buildingSlice = createSlice({
    name: 'building',
    initialState: initialBuildingState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(GetBuildingDetail.pending, (state: BuildingState, action) => {
            state.buildingLoading = true;
            state.building = undefined;
        });
        builder.addCase(GetBuildingDetail.fulfilled, (state: BuildingState, action) => {
            state.buildingLoading = false;
            let data = { ...(action.payload as Building) };
            data.floors.map((e) => (e.isSelected = false));
            state.building = data;
        });
        builder.addCase(GetBuildingDetail.rejected, (state: BuildingState, action) => {
            state.buildingLoading = false;
            state.building = undefined;
           // alert('data fetch error');
           
        });
    },
});

export default buildingSlice.reducer;
