

import { useState, useEffect } from 'react';
import { FormikModelDialog, SubmitStatus } from '../../../components/formik-model-dialog.component';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { useLoadData } from '../../../hooks/use-load-data.hook';
import { ClaimObservation, Photo } from '../../../models/claim/claim-observation';
import { Observation } from '../../../models/masters/observation';
import { InputFieldType } from '../../../models/enums/input-field-type';
import { ObservationType } from '../../../models/masters/observation-type';
import { EditObservationOptionsFrom } from './edit-observation-option.form';
import { filter, forEach, join, map, reduce, split } from 'lodash';
import { SelectField } from '../../../components/form/select-field.component';
import { ClaimObservationOption } from '../../../models/claim/claim-observation-option';
import { Dialog } from '@progress/kendo-react-dialogs';
import { CopyObservationComponent } from './copy-observation.component';
import { Building } from '../../../models/claim/building';
import ImageList from '../../../components/form/image-list.component';
import { UploadedFile } from '../../../redux/reducers/filereducer';
import { ImgFile, fetchFile, uploadImages } from '../../../utils/uploadfile.util';
import ImageUploader from '../../../components/add-images.component';
import axios from 'axios';
import { WINDOWS_DOORS_API_URL } from '../../../constants';
import { MasterType } from '../../../models/masters/master-type';
import { MasterValue } from '../../../models/masters/master-value';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';

interface EditObservationProps {
    observationType: ObservationType;
    building?: Building;
    buildings?: Building[];
    data?: { newObservation: ClaimObservation; copyFrom?: boolean };
    onClose?: (status: SubmitStatus, data: any) => any;
    imgList?: ImgFile[];
    uploadedFiles?: UploadedFile[];
    isInterior?: boolean;
    isphotoUploaded?: (res: number) => void;
}

export const EditObservation = ({
    observationType,
    building,
    buildings,
    imgList,
    isInterior,
    data: { newObservation, copyFrom },
    uploadedFiles = [],
    isphotoUploaded,

    onClose,
}: EditObservationProps) => {
    const { isLoading, result, isError } = useLoadData<Observation[]>(
        `observation/list?type=${observationType}`, //entity type
    );
    const [mastertypes, setMasterTypes] = useState<MasterType[]>([]);
    const [initialValues, setInitialValues] = useState<any>({
        observation: '',
    });
    const [loading, setloading] = useState<boolean>(false);
    const [selectedObservation, setSelectedObservation] = useState<Observation>();
    const [showCopyObservationModel, setShowCopyObservationModel] = useState<boolean>(copyFrom);

    const [moveUpImages, setMoveUpImages] = useState<ImgFile[]>([]);
    const [selectedList1, setSelectedList1] = useState<ImgFile[]>([]);
    const [selectedList2, setSelectedList2] = useState<ImgFile[]>([]);
    const [generalList, setGeneralList] = useState<ImgFile[]>([]);
    const [isNewPhotoAdded, setIsNewPhotoAdded] = useState(false);
    const [imgpreview, showImgPreview] = useState<{
        imgpreview: boolean;
        src: File;
        comment?: string;
    }>({
        imgpreview: false,
        src: null,
        comment: '',
    });
    const [selectedObsTab, setSelectedObsTab] = useState<number>(0);
    const GetOptions = async () => {
        try {
            const response = await axios.get(`${WINDOWS_DOORS_API_URL}/api/masters?type=observation`);
            if (response.status === 200) {
                let mastertypes = response.data as MasterType[];
                setMasterTypes(mastertypes);
            }
        } catch (error) {
            setMasterTypes([]);
        }
    }

    useEffect(() => {
        GetOptions();
    }, [result])

    useEffect(() => {
        if (newObservation.photos && newObservation.photos.length > 0) {
            loadLinkedImages().then((files) => {
                // Filter imgList based on linked files
                const filteredList = imgList.filter(
                    (item1) => !files.some((item2) => item1.file.name === item2.file.name),
                );

                // Set moveUpImages with linked files
                setMoveUpImages(files);
                // setSelectedList1(files);

                // Set generalList initially if it's empty
                if (generalList.length === 0) {
                    setGeneralList(filteredList);
                }
                // Check if a new photo was added
                if (isNewPhotoAdded) {
                    // Add the newly added image to the selected list
                    const newlyAddedImage = files[files.length - 1]; // Assuming the newly added image is the last one
                    setSelectedList1([...selectedList1, newlyAddedImage]);
                    setIsNewPhotoAdded(false); // Reset the flag
                }
            });
        } else {
            // Set imgList as generalList initially if it's empty and there are no linked files
            if (generalList.length === 0) {
                setGeneralList(imgList);
            }
        }
    }, [newObservation.photos, imgList, isNewPhotoAdded]);

    const loadLinkedImages = async () => {
        let files: ImgFile[] = [];
        // Create an array to store promises for each fetchFile call
        const fetchPromises = newObservation.photos
            .filter((f) => f.fileCDN !== '')
            .map(async (element) => {
                const file = await fetchFile(
                    element.fileCDN,
                    element.comments,
                    newObservation.entityID.toString(),
                    newObservation.entityType,
                    element.isBasePhoto,
                    element.fileId.toString(),
                    element.basePhotoID,
                );
                files.push(file);
            });

        // Wait for all fetchFile calls to complete
        await Promise.all(fetchPromises);

        // Now files array is populated
        return files;
    };

    useEffect(() => {
        if (!isError && result) {
            initObservation(
                newObservation.observation,
                newObservation?.note,
                newObservation?.isStormRelated,

                newObservation?.options,
                newObservation.perils,
            );
        }
    }, [result, newObservation, isError]);

    const getPerils = (perils: string) => {
        const values = reduce(
            split(perils, ','),
            (prev, currnt) => {
                if (perils) {
                    if (currnt.includes('Other')) {

                        console.log({
                            ...prev,
                            [`${currnt.split(':')[0]}-undefined`]: true,
                            [`${currnt.split(':')[0]}value-undefined`]: currnt.split(':')[currnt.split(':').length - 1],
                        });

                        return {
                            ...prev,
                            [`${currnt.split(':')[0]}-undefined`]: true,
                            [`${currnt.split(':')[0]}value-undefined`]: currnt.split(':')[currnt.split(':').length - 1],
                        };
                    }
                }
                return {
                    ...prev,
                    [currnt]: true,
                };
            },
            {},
        );
        return {

            ...values,
        };

    }

    const getObservationOptions = () => {
        return result?.map<any>((o) => {
            return { value: o.observationName, label: o.observationName };
        });
    };

    const handleObservationSelectChange = (observation: string) => {
        if (result) {
            initObservation(observation);
        }
    };

    const initObservation = (
        observationName: string = observationType === ObservationType.Building || observationType === ObservationType.Floor || observationType === ObservationType.Unit || observationType === ObservationType.Room ? 'Other' : undefined,
        note?: string,
        isStormRelated?: boolean,
        options?: ClaimObservationOption[],
        perils?: string,
    ) => {
        console.log(options);


        const currentObservationMaster = result.find((r) => r.observationName === observationName);
        setInitialValues({
            observation: observationName,
            note: note ? note : '',
            isStormRelated: isStormRelated ? isStormRelated : false,
            ...getPerils(perils),
            ...buildInitialOptionValues(observationName, options ? options : []),
        });
        setSelectedObservation(currentObservationMaster);
    };

    const buildInitialOptionValues = (
        observationName: string,
        editOptions: ClaimObservationOption[],
    ) => {
        if (result && observationName) {
            const selectedMasterObservation = result.find(
                (r) => r.observationName === observationName,
            );
            console.log(selectedMasterObservation);

            if (!selectedMasterObservation) {
                return {};
            }
            if (selectedMasterObservation?.observationSelectType === InputFieldType.MultiSelect) {
                return selectedMasterObservation.options?.reduce((prevvalue, currentOption) => {
                    if (!currentOption || !currentOption.optionName) {
                        return prevvalue;
                    }

                    const editOptionValue = editOptions?.find(
                        (o) => o.observationOption === currentOption.optionName,
                    );

                    return {
                        ...prevvalue,
                        [currentOption.optionName]:
                            currentOption.optionSelectType === InputFieldType.None
                                ? !!editOptionValue
                                : editOptionValue?.optionValue
                                    ? editOptionValue.optionValue
                                    : '',
                    };
                }, {}) ?? {}; // Default to an empty object if options are undefined or reduce returns undefined
            }


            const option: ClaimObservationOption =
                editOptions?.length === 1
                    ? editOptions[0]
                    : {
                        observationOption: '',
                        optionValue: '',
                    };

            return {
                observationOption: option.observationOption ? option.observationOption : '',
                optionValue: option.optionValue ? option.optionValue : '',
            };
        }
    };

    const validate = (values: any) => {
        const errors: any = {};

        if (!values.observation) {
            errors.observation = 'Required';
        }
        if (
            (!values.observationOption && observationType === ObservationType.Building) ||
            observationType === ObservationType.Floor ||
            observationType === ObservationType.Unit ||
            observationType === ObservationType.Room
        ) {
            if (values.optionValue === '') {
                errors.optionValue = 'Required!';
            }
        } else {
            const selectedMasterObservation: Observation = result?.find(
                (r) => r.observationName === values.observation,
            );

            const mandatoryOptions = filter(
                selectedMasterObservation?.options,
                (o) => o.isMandatory,
            );
            forEach(mandatoryOptions, (option) => {
                if (!values[option.optionName]) {
                    errors[option.optionName] = 'Required';
                }
            });
        }

        return errors;
    };
    /// here data parameter represents data submitted from form by user for add it will be empty and sdubmit it will be filled
    const postDataFormatter = (data: any): ClaimObservation => {

        const mapOptionData = (): { observationOption: string; optionValue?: string }[] => {
            const masterObservationInfo = result?.find(
                (o) => o.observationName == data.observation,
            );

            if (!masterObservationInfo) {
                return []; // Return an empty array if masterObservationInfo is null or undefined
            }

            if (masterObservationInfo.observationSelectType === InputFieldType.MultiSelect) {
                return masterObservationInfo.options
                    .map((option) => {
                        if (option.optionSelectType === InputFieldType.None) {
                            if (data[option.optionName]) {
                                return {
                                    observationOption: option.optionName,
                                };
                            }
                        } else if (data[option.optionName]) {
                            return {
                                observationOption: option.optionName,
                                optionValue: data[option.optionName],
                            };
                        }
                    })
                    .filter(Boolean);
            } else if (
                masterObservationInfo.observationSelectType === InputFieldType.SingleSelect
            ) {
                const option = masterObservationInfo.options.find(
                    (o) => o.optionName === data.observationOption,
                );
                return [
                    {
                        observationOption: data.observationOption,
                        optionValue:
                            option?.optionSelectType !== InputFieldType.None
                                ? data.optionValue
                                : undefined,
                    },
                ];
            }
            else if (observationType === ObservationType.Building || observationType === ObservationType.Floor || observationType === ObservationType.Unit || observationType === ObservationType.Room) {
                console.log([{
                    observationOption: data.observation,
                    optionValue: data.observationOption
                }]);

                return [{
                    observationOption: data.observation,
                    optionValue: data.optionValue
                }];
            }
            else {
                return [
                    {
                        observationOption: data.observationOption,
                        optionValue: data.optionValue
                    },
                ];
            }
        };
        function getFileID(uploadedFiles: UploadedFile[], moveUpImages: ImgFile[], e: ImgFile) {
            if (uploadedFiles.length > 0) {
                const matchedFile = uploadedFiles.find(
                    (file) => e.file.name.split('.')[0] === file.fileGUID,
                );
                console.log(matchedFile ? matchedFile.fileID : 0);

                return matchedFile ? matchedFile.fileID : parseInt(e.fileID);
            } else {
                console.log(e.fileID ? parseInt(e.fileID) : 0);

                return e.fileID ? parseInt(e.fileID) : 0;
            }
        }

        const postPerils = (item: MasterType, data: any) => {
            let customOption = '';
            if (item.inputType === InputFieldType.MultiSelect) {
                const selectedItems = item.values.filter((v: MasterValue) => {
                    if (!v.value.toLowerCase().includes('other')) {
                        return data[v.value];
                    }
                    else {
                        if (data[`${v.value}-undefined`] === true && data[`${v.value}-undefined`] !== "" && data[`${v.value}value-undefined`] !== undefined && data[`${v.value}value-undefined`] !== '') {
                            customOption = data[`${v.value}value-undefined`];
                            return data[`${v.value}-undefined`];
                        }
                        else {
                            return false;
                        }
                    }
                }
                );
                console.log(selectedItems);
                let option = join(map(selectedItems, (s) => s.value), ',');
                if (customOption !== '') {
                    option = option + ":" + customOption;
                }
                console.log(option);

                return option;
            }


            return null;
        };

        const claimObservation: ClaimObservation = {
            ...newObservation,
            observation: data.observation,
            note: data.note,
            options: mapOptionData(),
            isStormRelated: data.isStormRelated,
            perils: data.isStormRelated ? postPerils(mastertypes[0], data) : undefined,

            // hasDamage: data.hasDamage,

            //  isInterior:data.isInterior,
            images: moveUpImages.length,
            photos:
                moveUpImages.length > 0
                    ? moveUpImages.map<Photo>((e) => {
                        return {
                            fileId: getFileID(uploadedFiles, moveUpImages, e),
                            observationID: newObservation.observationID,
                            fileCDN: '',
                            thumbCDN: '',
                            comments: e.comment,
                            basePhotoID: e.baseID,
                            isBasePhoto: e.isbaseImg,
                        };
                    })
                    : [],
        };

        return claimObservation;
    };

    const handleCopyObservation = (observation: ClaimObservation) => {
        setShowCopyObservationModel(false);
        initObservation(
            observation.observation,
            observation.note,
            observation.isStormRelated,
            observation.options,
        );
    };

    const handleCopyDialogClose = () => {
        onClose(SubmitStatus.Cancel, null);
    };

    if (showCopyObservationModel) {
        return (
            <Dialog width={"75%"} title={'Copy Observation'} onClose={handleCopyDialogClose}>
                <CopyObservationComponent
                    isInterior={isInterior}
                    building={building}
                    observationType={observationType}
                    onCopyObservation={handleCopyObservation}
                    buildinglist={buildings}
                />
            </Dialog>
        );
    }
    const handleMoveUp = (file: ImgFile) => {


        // Add the selected file to the selectedList1
        setSelectedList1([...selectedList1, file]);
    };

    const handleMoveDown = (file: ImgFile) => {
        // Move the selected file from moveUpImages back to generalList
        setGeneralList([...generalList, file]);

        // Remove the selected file from moveUpImages
        setMoveUpImages(moveUpImages.filter((f) => f !== file));

        // Remove the selected file from selectedList1
        setSelectedList1(selectedList1.filter((f) => f !== file));

        // If there are files in selectedList2, move the last one to selectedList1
        if (selectedList2.length > 0) {
            const lastFile = selectedList2[selectedList2.length - 1];
            setSelectedList2(selectedList2.slice(0, -1));
            setSelectedList1([...selectedList1, lastFile]);
        }
    };
    const handleSelectedObsTab = async (e: TabStripSelectEventArguments) => {
        setSelectedObsTab(e.selected);

    };
    return (
        <>
            {newObservation && (
                <FormikModelDialog width='80%'

                    title={
                        newObservation.observationID > 0 ? 'Edit Observation' : 'Add Observation'
                    }
                    initialData={initialValues}
                    validate={validate}
                    show={!!newObservation}
                    onClose={onClose}
                    postDataFormatter={postDataFormatter}
                    postRoute="ClaimObservation"
                >
                    <>
                        <LoadingIndicator isLoading={isLoading} />
                        {result && (
                            <>
                                {observationType === ObservationType.Window ||
                                    observationType === ObservationType.Door ||
                                    observationType === ObservationType.Railing ? (
                                    <SelectField
                                        label="Select Observation*"
                                        name="observation"
                                        options={getObservationOptions()}
                                        notifyChange={handleObservationSelectChange}
                                    />
                                ) : null}
                                {selectedObservation && (
                                    <EditObservationOptionsFrom
                                        selectedObservation={selectedObservation}
                                        observationType={
                                            observationType === ObservationType.Window ||
                                                observationType === ObservationType.Door ||
                                                observationType === ObservationType.Railing
                                                ? undefined
                                                : ObservationType.Building
                                        }
                                    />
                                )}
                                <div>
                                    <div className="d-flex justify-content-end">

                                        <input
                                            type="file"
                                            id="fileselect"
                                            hidden
                                            onChange={(e) => {
                                                e.preventDefault();
                                                showImgPreview({
                                                    imgpreview: true,
                                                    src: e.target.files[0],
                                                });
                                            }}
                                        />
                                    </div>
                                    {/* linked images list */}
                                    <div className='bg-white-head tabwhitespace observationTabs'>

                                        <TabStrip
                                            selected={selectedObsTab}
                                            onSelect={handleSelectedObsTab}

                                            style={{ b: 'lightgrey', padding: '5px' }}>
                                            <TabStripTab title="Observation Photos" contentClassName="structureTab text-capitalize">
                                                {!loading && (<ImageList
                                                    onRemove={(file: ImgFile) => { null; }}
                                                    isDelete
                                                    images={moveUpImages}
                                                    onSelectImage={() => { }}
                                                    selectedImages={selectedList1}
                                                    onDeleteImage={(file: ImgFile) => {
                                                        handleMoveDown(file);
                                                    }}
                                                />)}
                                                {loading && (<LoadingIndicator isLoading />)}

                                                <div className="d-flex justify-content-end buttonPosiionbottom">


                                                    <button
                                                        className="btn btn-outline-primary btn-sm"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            document.getElementById('fileselect').click();
                                                        }}
                                                    >
                                                        Add Photo
                                                    </button>
                                                </div>
                                            </TabStripTab>
                                            <TabStripTab title="Other Photos" contentClassName="structureTab text-capitalize">

                                                <ImageList
                                                    onRemove={(file: ImgFile) => {
                                                        setSelectedList1(selectedList1.filter(e => e.fileID !== file.fileID))
                                                    }}
                                                    images={generalList}
                                                    isChkBox
                                                    selectedImages={selectedList1}
                                                    onSelectImage={(files: ImgFile) => {
                                                        handleMoveUp(files);
                                                    }}
                                                />
                                                <div className="row mt-3 buttonPosiionbottom">
                                                    <div className="d-flex justify-content-end">
                                                        <button onClick={() => { setSelectedList1([]); }} className="btn btn-outline-secondary d-none">
                                                            Cancel
                                                        </button>
                                                        <div>
                                                            <label className='mx-2' style={{ fontStyle: 'italic', position: 'relative', top: '1px' }}>{selectedList1.length} Photos Selected </label>
                                                            {generalList?.length > 0 && (<button className="btn btn-outline-primary btn-sm" onClick={() => {
                                                                // Move the selected file from generalList to moveUpImages
                                                                setMoveUpImages([...moveUpImages, ...selectedList1]);

                                                                // // Remove the selected file from generalList

                                                                let remainingImages = generalList.filter(
                                                                    (image) => !selectedList1.some(selectedImage => selectedImage.file.name === image.file.name)
                                                                );
                                                                setGeneralList(remainingImages);
                                                                setSelectedList1([]);
                                                                setSelectedObsTab(0);
                                                            }}>
                                                                Add Selected Photos to Observation
                                                            </button>)}</div>
                                                    </div>
                                                </div>
                                            </TabStripTab>
                                        </TabStrip>


                                    </div>


                                    {/* add here */}

                                </div>


                                {imgpreview.imgpreview ? (
                                    <ImageUploader
                                        onAddImg={async (comment: string) => {
                                            imgpreview.comment = comment;
                                            await uploadImages([imgpreview.src], {
                                                claimGuID: building.claimGuid,
                                                EntityID: newObservation.entityID.toString(),
                                                EntityType: newObservation.entityType,
                                                IsInterior: newObservation.isInterior
                                                    ? newObservation.isInterior
                                                    : false,
                                                Comments: imgpreview.comment?.toString(),
                                            }).then(async (value: UploadedFile) => {
                                                let file = await fetchFile(
                                                    value.fileCDN,
                                                    value.comments,
                                                    value.entityID.toString(),
                                                    newObservation.entityType,
                                                    value.isBasePhoto,
                                                    value.fileID.toString(),
                                                    value.basePhotoID,
                                                );
                                                setMoveUpImages([...moveUpImages, file]);
                                                setSelectedList1([...selectedList1, file]);
                                                isphotoUploaded(1);
                                            });
                                        }}
                                        onclose={() => {
                                            showImgPreview({ imgpreview: false, src: null });
                                        }}
                                        showModal={imgpreview.imgpreview}
                                        img={imgpreview.src}
                                    />
                                ) : null}
                            </>
                        )}
                    </>
                </FormikModelDialog>
            )}
        </>
    );
};

