import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetObservationsByEntityTypeandID } from '../../api-client.ts/claimobservationapi';
import { RequestClaimObservation } from '../../models/api-request.models';

export const GetClaimObservationForEntity = createAsyncThunk(
    'loadclaimobservations',
    async (requestclaimobservation: RequestClaimObservation) => {
        return await GetObservationsByEntityTypeandID(requestclaimobservation);
    },
);
