import * as React from 'react';

import { Modal } from './modal';




import '@progress/kendo-date-math/tz/MST7MDT'
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useAddAvailabilitySlots, useAssignInspectorQB, useInspectors, useScheduleDeleteInspectorSlot, useScheduledSlots, } from '../../../api/inspector';
import { IInspector } from '../../../models/scheduler/inspector';
import { Calendar } from './calendar';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { Slot } from '../../../types/Slot';
import { useCurrentUser } from '../../../hooks/user-current-user.hook';
import { AddAvailabilityPayload } from '../../../api/homeowner';
import { Alert } from './delete-dialog';
import { SLOT_TYPES, TIME_ZONE } from '../../../constants';
import { AddAvailability } from './add-availability';
import { formatPhoneNumber } from '../utils';
import { convertToTimezone } from '../../../utils';

dayjs.extend(utc);
dayjs.extend(timezone);

interface UserItemProps {
    title: string;
    data: string
}

export function UserItem({ title, data }: UserItemProps) {
    return (
        <div className='d-flex flex-column'>
            <small>
                {title}
            </small>
            <p style={{ fontWeight: 'bold' }}>
                {data}
            </p>
        </div>
    );
}

function UserDetails({ inspector, disableRightArrow, disableLeftArrow, handleArrowClick }: { inspector: IInspector; disableRightArrow: boolean; disableLeftArrow: boolean; handleArrowClick: (type: 'add' | 'reduce') => void; }) {
    return (
        <div className="d-flex gap-5">
            <UserItem title='Inspector' data={inspector.inspector_Name} />
            <UserItem title='Address' data={inspector.address} />
            {inspector.inspector_Phone && <UserItem title='Phone' data={formatPhoneNumber(inspector.inspector_Phone)} />}

            <div className="fixedButton" style={{ marginRight: 20 }}>
                <button style={{ border: 'none', backgroundColor: 'transparent' }} disabled={disableLeftArrow} onClick={() => handleArrowClick('reduce')}><div className='arrow-back' style={{ width: "20px", height: "25px" }} /></button>
                <button style={{ border: 'none', backgroundColor: 'transparent' }} disabled={disableRightArrow} onClick={() => handleArrowClick('add')}><div className='arrow-next' style={{ width: "20px", height: "25px" }} /></button>
            </div>
        </div>
    )
}


function SelectedSlotToSchedule({ selectedSlot }: { selectedSlot: Slot }) {

    return <div style={{ display: 'flex', gap: 20, flex: 1, justifyContent: 'flex-end', marginRight: 30, alignItems: 'center' }}>
        <div>{dayjs.utc(selectedSlot.start).tz(TIME_ZONE).format('dddd, MMMM DD, YYYY')}</div>
        <div style={{ borderWidth: "0 0 1px", borderStyle: 'solid', borderColor: 'black', height: 22 }}>{dayjs.utc(selectedSlot.start).tz(TIME_ZONE).format('hh:ss A')}</div>
        <div style={{ borderWidth: "0 0 1px", borderStyle: 'solid', borderColor: 'black', height: 22 }}>{dayjs.utc(selectedSlot.end).tz(TIME_ZONE).format('hh:ss A')}</div>
    </div>
}






export function ScheduleInspector({ show, onClose, inspectorIndex, claim, onAddOrDeleteSchedule }: { show: boolean; onClose: ({ shouldCloseSlider }: { shouldCloseSlider?: boolean }) => void; inspectorIndex: number; claim: Scheduler; onAddOrDeleteSchedule: () => void }) {

    const { mutate: assignInspectorToQB } = useAssignInspectorQB()

    const { data: inspectors } = useInspectors({
        variables: { claimID: claim?.claimID },
        enabled: false
    })

    const [selectedInspectorIndex, setSelectedInspectorIndex] = React.useState(inspectorIndex)

    const selectedInspector = inspectors[selectedInspectorIndex]

    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
    const { userID, userName, } = useCurrentUser();



    const handleIndexChange = (type: 'add' | 'reduce') => {
        setSelectedInspectorIndex(prevIndex => {
            const indexToUpdate = type === 'add' ? prevIndex + 1 : prevIndex - 1
            getSlots(inspectors[indexToUpdate].userID)
            return indexToUpdate
        })

    }

    const [slots, setSlots] = React.useState<{ [key: string]: Slot }>({})
    const { mutate: fetchScheduledSlots } = useScheduledSlots({
        onSuccess: (response) => {
            setSlots(response.slots)
        }
    })


    const backupSlot = React.useRef<{ [key: string]: Slot }>({})


    const selectedStartAndEndDatesRef = React.useRef<{ start: string, end: string }>({ start: null, end: null })

    const getSlots = (inspectorId: number) => {
        if (selectedStartAndEndDatesRef.current.start && selectedStartAndEndDatesRef.current.end) {
            fetchScheduledSlots({ inspectorId, SDate: selectedStartAndEndDatesRef.current.start, EDate: selectedStartAndEndDatesRef.current.end, claimId: claim?.claimID })
        }
    }

    const { mutate: deleteScheduledSlot, isPending: isPendingDeleteScheduledSlot } = useScheduleDeleteInspectorSlot()



    const slotToDelete = React.useRef<Slot>()

    const handleDeleteSlot = (slot: Slot) => {
        if (slot.statusCode === SLOT_TYPES.temp) {
            const slotsToDelete = { ...slots }
            delete slotsToDelete[slot.id]
            if (backupSlot.current[slot.id]) {
                slotsToDelete[slot.id] = backupSlot.current[slot.id]
                delete backupSlot.current[slot.id]
            }
            setSlots(slotsToDelete)
            return
        }

        slotToDelete.current = slot
        setShowDeleteDialog(true)
    }

    const deleteSavedSlot = () => {
        setShowDeleteDialog(false);
        const slot = slotToDelete.current
        deleteScheduledSlot({ claimNumber: claim.claimNo, slotId: slot.slotId, inspectorId: selectedInspector.userID, userId: userID, userName, homeownerId: 0 }, {
            onSuccess: () => {
                const slotsToDelete = { ...slots }
                delete slotsToDelete[slot.id]
                setSlots(slotsToDelete)
                slotToDelete.current = null
                onAddOrDeleteSchedule?.()
            }
        })
    }

    const handleSelectSlot = ({ start, end }: { start: Date; end: Date; }) => {

        const isPrevDay = convertToTimezone(start).isBefore(convertToTimezone(new Date()), 'day')
        if (isPrevDay) return

        const slot = {
            id: dayjs(start).toISOString(),
            title: '',
            start,
            end,
            statusCode: SLOT_TYPES.temp
        }

        const prevSlots = { ...slots }
        if (prevSlots[slot.id] && prevSlots[slot.id].statusCode !== SLOT_TYPES.temp) {
            backupSlot.current[slot.id] = prevSlots[slot.id]
        }

        if (prevSlots[slot.id] && prevSlots[slot.id].statusCode === SLOT_TYPES.temp) {
            return
        }

        setSlots(prevSlot => ({ ...prevSlot, [slot.id]: slot }))
    }

    const selectedSlots = React.useMemo(() => Object.values(slots || {}).filter(slot => slot.statusCode === SLOT_TYPES.temp), [slots])

    const selectedSlot = selectedSlots?.[0]


    const { mutate: addAvailability, isPending: isPendingAddAvailability } = useAddAvailabilitySlots({
        onSuccess: () => {
            onClose({ shouldCloseSlider: true })
            onAddOrDeleteSchedule?.()
        }
    })



    const handleAddAvailability = (statusCode: string) => {




        if (statusCode === SLOT_TYPES.scheduled) {
            if (!!selectedInspector.inspectorQuickBaseId && !!claim.keyStoneNo) {
                const params = {
                    assignmentNumber: claim.keyStoneNo,
                    taskID: claim.taskID ? Number(claim.taskID) : 0,
                    inspectorQbaseId: selectedInspector.inspectorQuickBaseId,
                    inspectorName: selectedInspector.inspector_Name,
                    scheduledStartDateTime: convertToTimezone(selectedSlots[0].start).format(),
                    scheduledEndDateTime: convertToTimezone(selectedSlots[0].end).format()
                }

                assignInspectorToQB(params)
            }
        }

        const payload = selectedSlots?.map((slot): AddAvailabilityPayload => (
            { fromDateTime: slot.start.toISOString(), toDateTime: slot.end.toISOString(), claimID: claim.claimID, homeOwnerID: claim.homeowner_ID, inspectorID: selectedInspector.userID, slotStatusCode: statusCode, schedulerID: userID }
        ))
        addAvailability({ payload, params: { claimNumber: claim.claimNo } })

    }



    return (
        <Modal show={show} onClose={() => onClose({ shouldCloseSlider: false })} renderActions={() =>
            <div className='d-flex justify-content-between p-2 align-items-center'>
                <div>

                    {
                        selectedSlots?.length > 0 && <AddAvailability isLoading={isPendingAddAvailability} onAddAvailability={handleAddAvailability} />
                    }
                </div>

                {selectedSlots?.length === 1 && !!selectedSlot && <><SelectedSlotToSchedule selectedSlot={selectedSlot} />

                    <button className="btn-primary btn-sm me-1 btn" onClick={() =>
                        handleAddAvailability(SLOT_TYPES.scheduled)
                    } disabled={isPendingAddAvailability || !selectedSlot}>
                        {!isPendingAddAvailability && "Schedule"}
                        {isPendingAddAvailability && <LoadingIndicator isLoading={isPendingAddAvailability} showLabel={false} size='sm' />}
                    </button>

                </>}
            </div>
        }>
            <div>
                <LoadingIndicator isLoading={isPendingDeleteScheduledSlot} />
                <UserDetails inspector={selectedInspector} disableLeftArrow={selectedInspectorIndex === 0} disableRightArrow={selectedInspectorIndex === inspectors.length - 1} handleArrowClick={handleIndexChange} />
                <Calendar claimNumber={claim.claimNo} selectedSlot={selectedSlot} source='inspector' onChangeDate={(start, end) => {
                    selectedStartAndEndDatesRef.current = { start, end }
                    getSlots(selectedInspector.userID)
                }} slots={slots} onDelete={handleDeleteSlot} onSelectSlot={handleSelectSlot} claimStatus={claim.statusCode} />
            </div>

            {showDeleteDialog && <Alert onClose={() => setShowDeleteDialog(false)} onSubmit={deleteSavedSlot} />}
        </Modal>
    );
};