import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

type Props = {
    title?: string;
    onClose: () => void;
    subTitle?: string;
    onSubmit: () => void
}

export function Alert({ title = "Delete", subTitle = "Are you sure want to delete?", onClose, onSubmit }: Props) {
    return (
        <Dialog title={title} onClose={() => onClose?.()}>
            <div>{subTitle}</div>
            <DialogActionsBar>
                <div className="d-flex m-2 mt-0 ">
                    <button
                        title="Cancel the image"
                        className="btn btn-secondary me-auto btn-sm"
                        onClick={onClose}
                    >
                        No
                    </button>
                    <button
                        className="btn btn-primary btn-sm "
                        title="Upload the image"
                        onClick={onSubmit}
                    >
                        Yes
                    </button>
                </div>

            </DialogActionsBar>
        </Dialog>
    )
}