import { StorageKey } from '../../constants';
import { useStorage } from '../../hooks/use-storage';
import { Claim } from '../../models/claim/claim';
interface EditDialogHeaderProps {
    title: string;
    buildingName?: string;
}

export const EditDialogHeader = ({ title, buildingName }: EditDialogHeaderProps) => {
    const [currentClaim] = useStorage(StorageKey.CurrentUser, new Claim());
    return (
        <>
            <h5 className="w-100 modal-title">{title}</h5>
            <div className="dialoge-header-content">
                <div>
                    <small className="label d-block">KeyStone # </small>{' '}
                    <label>{currentClaim.keystoneNumber}</label>
                </div>
                <div>
                    <small className="label d-block">Project # </small>{' '}
                    <label>{currentClaim.claimNumber}</label>
                </div>
                <div>
                    <small className="label d-block">Project Name </small>{' '}
                    <label>{currentClaim.client}</label>
                </div>                

                {buildingName && (
                    <div>
                        {' '}
                        <div>
                            <small className="label d-block">Building Name </small>{' '}
                            <label> {buildingName}</label>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
