import * as React from 'react';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { IClaimProfile } from '../../../models/scheduler/claim-profile';

interface IHomeOwnerCardProps {
    claimDetails: IClaimProfile,
    isLoadingClaimDetails: boolean;
    isErrorClaimDetails: boolean;
}

const HomeOwnerCard: React.FunctionComponent<IHomeOwnerCardProps> = (props) => {
    const { claimDetails, isLoadingClaimDetails, isErrorClaimDetails } = props;
    return (
        <>
            {isLoadingClaimDetails && (<LoadingIndicator isLoading={true} />)}
            {isErrorClaimDetails && (<center>Loading Error</center>)}
            {!isLoadingClaimDetails && claimDetails && (<div className="card border-0 sw-shadow radius-15 p-4" >
                <div className="row">
                    <div className="flex-row justify-content-evenly">
                        <div className="row">
                            <div className="col">
                                <small className="text-secondary">Claim#</small>
                                <div >{claimDetails?.claimNo}</div>
                            </div>
                            <div className="col">
                                <small className="text-secondary">Keystone#</small>
                                <div >{claimDetails?.keyStoneNo}</div>
                            </div>
                            <div className="col">
                                <small className="text-secondary">Policy#</small>
                                <div >{claimDetails?.policyNo}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-2'>
                    <hr className="border-1 hr" />
                </div>
                <div className="row border-1">
                    <div className="flex-row justify-content-evenly">
                        <div className="row">
                            <div className="col">

                                <small className="text-secondary">Homeowner</small>
                                <div >{claimDetails?.homeOwner_Name}</div>
                            </div>
                            <div className="col">

                                <small className="text-secondary">Phone</small>

                                {claimDetails?.homeOwner_Phone && <div >{claimDetails?.homeOwner_Phone}</div>}
                            </div>
                            <div className="col">
                                {' '}
                                <small className="text-secondary">Email</small>
                                <div >{claimDetails?.homeOwner_Email}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)}
        </>
    );
};

export default HomeOwnerCard;
