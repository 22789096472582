export class Claim {
    claimID: number;
    claimGUID?: string;
    client: string;
    claimNumber: string;
    keystoneNumber: string;
    policyNumber: string;
    dateOfLoss: Date;
    buildingComplexName: string;
    buildingComplexAddress: string;
    latitude: number;
    longitude: number;
}
