
import { WINDOWS_DOORS_API_URL } from "../constants"

import AsyncRequest, { HttpMethod } from "./fetchutil";

export const updateEntityDetails: (assets: Asset) => Promise<boolean> = async (assets: Asset) => {
    let res: boolean = false;

    const body = JSON.stringify(assets);
    await AsyncRequest.requestApi({ method: HttpMethod.POST, url: `${WINDOWS_DOORS_API_URL}/api/Building`, body: body }).then(response => {
        if (response===0) {
            console.log(response);
            res = response === 0;
        }
        else {
            res = false;
        }
    }).
        catch(error => {
            console.log(error);

        });
    return res;

}

export interface Asset {
    BuildingID?: number;
    FloorID?: number;
    RoomID?: number;
    UnitID?: number;
}