import type { AxiosError } from 'axios';
import axios from 'axios';
import { createQuery } from 'react-query-kit';
import { ScheduledSlot } from '../../types/ScheduledSlot';


type Params = {
    claimId: number,
    inspectorId: number
}

export const useAvailabilityByClaims = createQuery<ScheduledSlot[], Params, AxiosError>({
    queryKey: ['inspectorAvailabilityByClaim'],
    fetcher: (params) =>
        axios.get('SchedulerAssignments/GetInspAvailabilityForClaim', { params }).then(response => response.data?.result || []),
    refetchOnWindowFocus: false
});
