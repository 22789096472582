import { useFormikContext } from 'formik';
import { DynamicField } from '../../../components/form/dynamic-field.component';
import { InputTextArea } from '../../../components/form/input-textarea.component';
import { InputField } from '../../../components/form/input.feild.component';
import { SelectField } from '../../../components/form/select-field.component';
import {
    WINDOWS_DOOR_OTHER_OPTION,
    WINDOWS_DOOR_OTHER_OPTION_VALUE_PREFIX,
} from '../../../constants';
import { Building } from '../../../models/claim/building';
import { Unit } from '../../../models/claim/unit';
import { UnitWindow } from '../../../models/claim/unit-window';
import { InputFieldType } from '../../../models/enums/input-field-type';
import { MasterType } from '../../../models/masters/master-type';
import { MasterValue } from '../../../models/masters/master-value';
import { getDirectionOptions } from '../../../utils/building.utils';
import { CheckboxField } from '../../../components/form/checkbox-field.component';
import { useEffect } from 'react';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { TemplateModel } from '../../../models/template-model';
import { replace } from 'lodash';

interface EditWindowFormProps {
    building: Building;
    window: UnitWindow;
    attributes: MasterType[];
    templates: TemplateModel[];
    selectedTemplate: TemplateModel;
    onTemplateChange: (event: DropDownListChangeEvent) => void;




}

export const EditWindowForm = ({ attributes, building, window, templates, selectedTemplate, onTemplateChange }: EditWindowFormProps) => {
    const { getFieldProps } = useFormikContext();
    useEffect(() => {
        console.log(window);

    })

    const getAttributeOptions = (values: MasterValue[]) => {
        return values?.map<any>((o) => {
            return {
                value: o.value,
                label: o.value == "Installation-Date" ? "Installation Date" : o.value,
                inputType:
                    o.value && o.value.toLowerCase() === WINDOWS_DOOR_OTHER_OPTION.toLowerCase()
                        ? InputFieldType.Text
                        : InputFieldType.None,
                id: o.id
            };
        });
    };

    const getFloorOptions = () => {
        return building?.floors.map<any>((f) => {
            return { value: f.floorID, label: f.floorName };
        });
    };

    const getUnitOptions = () => {
        const floorId = getFieldProps<number>('floorID');
        const floor = building?.floors.find((f) => f.floorID == floorId.value);
        return floor?.units?.map<any>((u) => {
            return { value: u.unitID, label: u.unitName };
        });
    };

    const getRoomOptions = () => {
        const floorId = getFieldProps<number>('floorID');
        const floor = building?.floors.find((f) => f.floorID == floorId.value);
        if (floor) {
            const unitId = getFieldProps<number>('unitID');
            const unit: Unit = floor?.units.find((u) => u.unitID == unitId.value);
            return unit?.rooms.map<any>((r) => {
                return { value: r.roomID, label: r.roomName };
            });
        }
    };

    const preprocessAttributes = () => {
        return attributes.map((a) => {
            if (a.values) {
                const otherIndex = a.values.findIndex(
                    (v) =>
                        v.value &&
                        v.value.toLowerCase() === WINDOWS_DOOR_OTHER_OPTION.toLowerCase(),
                );
                if (otherIndex >= 0) {
                    a.values.push(a.values.splice(otherIndex, 1)[0]);
                }
            }
            return a;
        });
    };

    return (
        <div>
            {window.roomID === 0 && (
                <div className="selectwindowcls border-bottom mb-3 pb-2">
                    <SelectField
                        label="Select Floor"
                        name="floorID"
                        options={getFloorOptions()}
                        unSelectValue={0}
                    />
                    <SelectField
                        label="Select Unit"
                        name="unitID"
                        options={getUnitOptions()}
                        unSelectValue={0}
                    />
                    <SelectField
                        label="Select Room"
                        name="roomID"
                        options={getRoomOptions()}
                        unSelectValue={0}
                    />
                </div>
            )}
            <div className="addwindowcls3">
                <div>
                    <label htmlFor="templates">Template List</label>
                    <DropDownList
                        className='border-bottom mb-3 pb-2'
                        id='templates'
                        data={templates}
                        value={selectedTemplate}

                        onChange={onTemplateChange}
                        textField="templateName"
                    />
                </div>
                <div>
                    <InputField label="Name*" name="windowName" />
                </div>
                <div>
                    <SelectField
                        label="Direction"
                        name="direction"
                        options={getDirectionOptions()}
                    />
                </div>
                {preprocessAttributes().map<any>((item) => (
                    <div key={item.id}>
                        <label className="label">
                            {item.name === "Depth" ?
                                ("Depth (in)")
                                : item.name === "Width" ? ("Width (in)") : item.name === "Height" ? ("Height (in)") : (replace(item.name, "-", " "))}</label>
                        <DynamicField
                            optionvalue={
                                item.inputType === InputFieldType.Date &&
                                    window.attributes &&
                                    window.attributes.length > 0
                                    ? window.attributes.find(
                                        (e) => e.attributeName === item.name,
                                    )?.attributeValue
                                    : null
                            }
                            name={item.name}
                            type={item.inputType as InputFieldType}
                            optionValueName={item.name + WINDOWS_DOOR_OTHER_OPTION_VALUE_PREFIX}
                            optionValues={getAttributeOptions(item.values)}
                        />
                    </div>
                ))}
                <div >
                    <InputTextArea label="Notes" name="windowNotes" />


                </div>
                <div className='mt-2'> <CheckboxField label="Has Damage" name="hasDamage" /></div>

            </div>
        </div>
    );
};
