import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { UserRole } from '../../models/user-security/user-role';
import { PermissionListComponent } from './permission-list.component';
import EditIcon from '../../images/edit';
import DeleteIcon from '../../images/delete';
import { useEffect, useState } from 'react';
import { SubmitStatus } from '../../components/formik-model-dialog.component';
import { EditRole } from './edit-role.component';
import { addOrUpdateArrayItem, deleteArrayItem } from '../../utils/array.util';
import {
    DeleteModelDialog,
    DeletePostDataInfo,
    DeleteStatus,
} from '../../components/delete-model-dialog.component';
import './user-management.scss';
import { PermissionName } from '../../models/user-security/permission.name';
import { GuardedComponent } from '../../components/guarded.componemt';

export const RolePage = () => {
    const { isLoading, result, isError } = useLoadData<UserRole[]>(`roles`);
    const [roles, setRoles] = useState<UserRole[]>();
    const [editRoleData, setEditRoleData] = useState<UserRole>();
    const [deleteRoleData, setDeleteRole] = useState<DeletePostDataInfo>();

    useEffect(() => {
        if (result) {
            setRoles(result);
        }
    }, [result]);

    const onAddClick = () => {
        setEditRoleData({
            roleId: 0,
            isActive: true,
        });
    };

    const onEditClick = (roleItem: UserRole) => {
        setEditRoleData(roleItem);
    };

    const onDeleteClick = (roleItem: UserRole) => {
        setDeleteRole({
            data: roleItem,
            route: 'roles/' + roleItem.roleId,
        });
    };

    const onSaveComplete = (status: SubmitStatus, data: UserRole) => {
        if (status == SubmitStatus.Completed) {
            const newData = addOrUpdateArrayItem(roles, data, 'roleId');
            setRoles(newData);
        }
        setEditRoleData(null);
    };

    const onDeleteComplete = (status: DeleteStatus, data: UserRole) => {
        if (status == DeleteStatus.Completed) {
            const newData = deleteArrayItem(roles, data, 'roleId');
            setRoles(newData);
        }
        setDeleteRole(null);
    };

    const titlebar = (roleItem: UserRole) => {
        return (
            <div className="roleHead">
                <div>{roleItem.roleName}</div>
                <div className="ms-auto">
                    <GuardedComponent permission={PermissionName.EditRole}>
                        <button
                            className="action-btns k-grid-edit-command"
                            onClick={(e) => {
                                onEditClick(roleItem);
                                e.stopPropagation();
                            }}
                        >
                            <EditIcon></EditIcon> {/* <img src={EditIcon} title="Edit" /> */}
                        </button>
                    </GuardedComponent>
                    <GuardedComponent permission={PermissionName.DeleteRole}>
                        <button
                            className="action-btns k-grid-edit-command"
                            onClick={(e) => {
                                onDeleteClick(roleItem);
                                e.stopPropagation();
                            }}
                        >
                            <DeleteIcon /> {/*  <img src={<DeleteIcon/>} title="Delete" /> */}
                        </button>
                    </GuardedComponent>
                </div>
            </div>
        );
    };

    if (isError) {
        return <div>Error occured while loading data</div>;
    }

    return (
        <div>
            <LoadingIndicator isLoading={isLoading} />
            <div className="container-fluid">
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="text-normal mt-4 mb-3 title-web">ROLES</h5>
                    <GuardedComponent permission={PermissionName.AddRole}>
                        <button className="btn btn-primary btn-sm " onClick={onAddClick}>
                            Add New Role
                        </button>
                    </GuardedComponent>
                </div>
                <PanelBar className="masterList" expandMode="multiple">
                    {roles?.map((item) => (
                        <PanelBarItem title={titlebar(item)} key={item.roleId}>
                            <PermissionListComponent permissions={item.permissions} />
                        </PanelBarItem>
                    ))}
                </PanelBar>
            </div>
            {editRoleData && <EditRole role={editRoleData} onClose={onSaveComplete} />}
            {deleteRoleData && (
                <DeleteModelDialog
                    title="Delete Role"
                    deleteData={deleteRoleData}
                    onClose={onDeleteComplete}
                >
                    <div>
                        Are you sure you want to delete role - {deleteRoleData.data.roleName}?
                    </div>
                </DeleteModelDialog>
            )}
        </div>
    );
};
