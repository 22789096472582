import React from 'react';


export interface Step {

    label: string;
    statusCode: string[]
}

interface StepperProps {
    options: Step[];
    activeStatusCode: string;
}


const CLASS_NAME = {
    notCompleted: '',
    active: 'active',
    completed: 'stepped',
}


type Status = 'notCompleted' | 'active' | 'completed';


const Stepper: React.FC<StepperProps> = ({ options, activeStatusCode }) => {

    if (!activeStatusCode) return null

    const activeStepIndex = options.findIndex(option => option.statusCode.includes(activeStatusCode))

    return (
        <div>
            <ul className="cus-Stepper">
                {options.map((option: Step, index: number) => {

                    let status = "notCompleted";
                    if (activeStepIndex === index)
                        status = 'active'
                    else if (activeStepIndex > index)
                        status = 'completed'

                    return (
                        <li key={index}
                            className={CLASS_NAME[status as Status]}
                        >
                            <span className="icon"></span>
                            <span>{option.label}</span>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
};

export default Stepper;
