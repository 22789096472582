const setToLocalStorage = ({ key, value }: { key: string; value: any }) => {
    localStorage.setItem(key, JSON.stringify(value));
};
const clearLocalStorage = (key: string) => {
    localStorage.removeItem(key);
};

const getFromLocalStorage = (key: string) => {
    let response = "";
    try {
        const value = localStorage.getItem(key);
        response = value ? JSON.parse(value) : "";
    } catch (error) {
        response = "";
    }

    return response;
};

export { setToLocalStorage, getFromLocalStorage, clearLocalStorage };
