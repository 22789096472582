import { Route, Routes, useNavigate } from "react-router-dom";
import InspectorLanding from "../features/scheduler/inspector-landing";
import { HeaderComponent } from "./header.component";
import { InspectorLogin } from "../features/login/login-inspector";
import { AuthorizeComponent } from "./authorize.component";
import InspectorAvailability from "../features/scheduler/inspector-availability";
import { InspectorAvailabilityByClaim } from "../features/scheduler/inspector-availability-by-claim";


export function InspectorRoutes() {

    return (
        <AuthorizeComponent role='inspector'>
            <HeaderComponent showMenu={false} disableLogoClick />
            <Routes>
                <Route
                    path="/inspector-login"
                    element={<InspectorLogin />}
                />
                <Route
                    path="/landing-page"
                    element={<InspectorLanding />}
                />
                <Route
                    path="/inspector-availability"
                    element={<InspectorAvailability />}
                />
                <Route
                    path="/inspector-availability-by-claims"
                    element={<InspectorAvailabilityByClaim />}
                />
            </Routes>
        </AuthorizeComponent>)
}