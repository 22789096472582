import axios from 'axios';
import { StorageKey, WINDOWS_DOORS_API_URL } from '../constants';
import { fetchFile } from './uploadfile.util';
import { loadImageAsArrayBuffer } from './fileutil';
import { boolean } from 'yup';
import { Building } from '../models/claim/building';

export async function DownloadFiles(prop: IDownloadFileProps) {
    try {
        const authState = JSON.parse(localStorage.getItem(StorageKey.AuthState));
        console.log(
            `url is ${`${WINDOWS_DOORS_API_URL}/api/File/DownloadFile`}and props are ${prop}`,
        );

        const response = await axios.post(`${WINDOWS_DOORS_API_URL}/api/File/DownloadFile`, prop, {
            headers: {
                'Content-Type': 'application/json',
                Accept: '*/*',
                Authorization: authState && `Bearer ${authState}`,
            },
        });
        console.log(response.data);
        let files = response.data.result as IDownloadFileResponse[];

        return files;
    } catch (error: any) {
        console.error('Error downloading Images:', error.response.data);
    }
}

export async function DeleteFiles(id: number): Promise<boolean> {
    try {
        const authState = JSON.parse(localStorage.getItem(StorageKey.AuthState));
        console.log(
            `${WINDOWS_DOORS_API_URL}/api/File/DeleteFileLog?logId=${id} and props are ${id}`,
        );

        const response = await axios.delete(
            `${WINDOWS_DOORS_API_URL}/api/File/DeleteFileLog?logId=${id}`,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: authState && `Bearer ${authState}`,
                },
            },
        );
        console.log(response.data);
        let files = response.data as boolean;

        return files;
    } catch (error: any) {
        console.error('Error downloading Images:', error.response.data);
        return false;
    }
}

export interface IDownloadFileProps {
    entityID: number;
    entityType: string;
    claimID: number;
    claimGUID: string;
    userID: number;
    building?: Building;

}

export interface IDownloadFileResponse {
    logId: number;
    fileName: string;
    filePath: string;
    fileCDN: string;
    entityType: string;
    entityID: number;
    processStatus: number;
    isActive: boolean;
    userID: number;
    createdOn: string;
    userName: string;
}
