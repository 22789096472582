import { chain, map } from 'lodash';
import { UserPermission } from '../../models/user-security/user-permission';

interface PermissionListComponentProps {
    permissions: UserPermission[];
}

export const PermissionListComponent = ({ permissions }: PermissionListComponentProps) => {
    const permissionGroups = chain(permissions)
        .map((p) => p.group)
        .uniq()
        .value();
    return (
        <div className="permissions-list">
            {map(permissionGroups, (groupName) => (
                <div className="permissions-group">
                    <strong>{groupName}</strong>
                    {map(
                        permissions,
                        (p, i) => p.group === groupName && <div>{p.permissionDescription}</div>,
                    )}
                </div>
            ))}
        </div>
    );
};
