import { every, map } from 'lodash';
import { useEffect, useState } from 'react';
import { getData } from '../utils/network.client';

interface RouteInfo {
    name: string;
    route: string;
}

export const useLoadDataMany = (route: RouteInfo[], routeId: string) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [result, setResult] = useState<{ name?: string; status?: number; data?: any }[]>();

    useEffect(() => {
        loadData();
    }, [routeId]);

    const loadData = async () => {
        try {
            setIsError(false);
            setIsLoading(true);
            const promises = map(route, (r) => getData<any>(r.route, r.name));
            const result = await Promise.all(promises);
            if (every(result, (r) => r.status === 200)) {
                setResult(result);
            } else {
                setIsError(true);
            }
        } catch (ex) {
            setIsError(true);
        }
        setIsLoading(false);
    };

    return { isLoading, result, isError, reload: loadData };
};
