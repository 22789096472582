import type { AxiosError } from 'axios';
import axios from 'axios';
import { createMutation } from 'react-query-kit';

type Response = {
    message: 'Slot created successfully';
    data: null;
};


type Payload = { schedulerId?: number; claimId?: number; claimNumber: string; schedulerName: string; homeOwnerName?: string; homeOwnerEmail: string; };

export const useRequestAvailability = createMutation<Response, Payload, AxiosError>({
    mutationFn: async payload =>
        axios({
            url: 'SchedulerAssignments/RequestHomeownerAvailability',
            method: 'POST',
            data: payload
        }).then(response => response.data),
});
