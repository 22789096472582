import { useEffect, useRef, useState } from 'react';
import { TabStrip, TabStripTab, TabStripSelectEventArguments } from '@progress/kendo-react-layout';
import { ClaimSetupBreadCrumb } from '../../components/claim-setup-breadcrumb';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import FileDialogButton from '../../components/add-image.button.component';
import { StorageKey } from '../../constants';
import ImageUploader from '../../components/add-images.component';
import { useStorage } from '../../hooks/use-storage';
import { Building } from '../../models/claim/building';
import { Claim } from '../../models/claim/claim';
import { Floor } from '../../models/claim/floor';
import { Room } from '../../models/claim/room';
import { Unit } from '../../models/claim/unit';
import { ClaimInfoPopover } from './claim-info-popover.component';
import { FloorComponent } from './floor/floor.component';
import { RoomComponent } from './room/room.component';
import { UnitComponent } from './unit/unit.component';
import { WindowsComponent } from './window/windows.component';
import { DoorsComponent } from './door/doors.component';
import { addOrUpdateArrayItem, deleteArrayItem } from '../../utils/array.util';
import { SubmitStatus } from '../../components/formik-model-dialog.component';
import { EditClaim } from '../claim/edit-claim.component';
import { BuildingView } from './building.page';
import { GuardedComponent } from '../../components/guarded.componemt';
import { PermissionName } from '../../models/user-security/permission.name';
import { find, result } from 'lodash';
import { RailingsComponent } from './railing/railings.component';
import { ObservationListComponent } from './observation/observation-list.component';
import { ObservationType } from '../../models/masters/observation-type';
import { ClaimObservation, Photo } from '../../models/claim/claim-observation';
import { ClaimObservationOption } from '../../models/claim/claim-observation-option';
import {
    DeleteModelDialog,
    DeletePostDataInfo,
    DeleteStatus,
} from '../../components/delete-model-dialog.component';
import { EditObservation } from './observation/edit-observation.component';
import { EntityType } from '../../models/claim/entity-type';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { GetBuildingDetail, GetBuildings } from '../../redux/actions/buildingactions';
import { GetClaimObservationForEntity } from '../../redux/actions/claimobservationaction';
import { RequestClaimObservation } from '../../models/api-request.models';
import {
    ImgFile,
    deleteFile,
    editComments,
    fetchFile,
    uploadImages,
} from '../../utils/uploadfile.util';
import { fetchFiles } from '../../api-client.ts/fileapi';
import { UploadedFile } from '../../redux/reducers/filereducer';
import { Gallery, Image } from 'react-grid-gallery';
import { LinkImageModelDialog } from '../building-image/link-image-model-dialog.component';
import DefaultTabComponent from '../../components/default-template.component';
interface BuildingDetailsPageProps {
    buildingID: number;
    setBuildingView: any;
}

export const BuildingDetailsPage = ({ buildingID, setBuildingView }: BuildingDetailsPageProps) => {
    const [imgList, setImgList] = useState<ImgFile[]>([]);

    const dispatch = useDispatch<AppDispatch>();
    const buildingSelector = useSelector((state: RootState) => state.buildingSlice);
    const [heading, setheading] = useState<{ id: string; name: string }>({ id: '', name: '' });
    const [currentClaim, setCurrentClaim] = useStorage(StorageKey.CurrentUser, new Claim());
    const [editClaimData, setEditClaimData] = useState<Claim>();
    const [buildingData, setBuildingData] = useState<Building>();
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [observations, setobservations] = useState<ClaimObservation[]>([]);
    const uploadedfileList = useRef<UploadedFile[]>([]);
    const [photos, setPhoto] = useState<Image[]>();

    const [newobservation, setnewobservation] = useState<{
        newObservation: ClaimObservation;
        copyFrom?: boolean;
        updateOption: ClaimObservationOption;
    }>();
    const [selectedObsTab, setSelectedObsTab] = useState<number>(0);
    const observationtype = useRef<{ entityType: ObservationType; entityID: number }>({
        entityType: ObservationType.Building,
        entityID: buildingID,
    });
    /* get image list for building observations */
    const [imageListData, setImageListData] = useState<{
        entityType: EntityType;
        entityId: number;
    }>();
    /* delete observation building hook */
    const [deleteObservation, setDeleteObservation] = useState<DeletePostDataInfo>();
    const [imgpreview, showImgPreview] = useState<{
        imgpreview: boolean;
        src: File;
        comment?: string;
    }>({
        imgpreview: false,
        src: null,
        comment: '',
    });
    /* image link usestate hook */
    const [linkImageData, setLinkImageData] = useState<{
        entityType: EntityType;
        entityId: number;
        entityName: string;
        direction: string;
    }>();
    const claimObservationObject = useRef<RequestClaimObservation>({
        entityID: buildingID,
        entityType: 'building',
    });
    const buildinglist = useRef<Building[]>([]);

    /* function to save new obs for building */
    const handleSaveObservationComplete = (status: SubmitStatus, data: ClaimObservation) => {
        if (status === SubmitStatus.Completed) {
            data.photos = data.photos.filter((e) => e.fileId !== 0);
            loadData();
            setPhoto([]);
        }

        setnewobservation(null);
    };

    const handleLinkWinodwComplete = () => {
        setLinkImageData(null);
    };

    const handleAddFileToImgList = async (file: File) => {
        const isInterior: boolean =
            observationtype.current.entityType === ObservationType.Building
                ? selectedObsTab === 1
                : false;

        await uploadImages([file], {
            claimGuID: buildingData.claimGuid,
            EntityID: observationtype.current.entityID.toString(),
            EntityType: observationtype.current.entityType,
            IsInterior: isInterior,
            Comments: imgpreview.comment.toString(),
        }).then((value: UploadedFile) => {
            imgList.push({
                comment: imgpreview.comment!,
                file: file,
                isbaseImg: value.isBase,
                baseID: 0,
                entityID: value.entityID,
                entityType: value.entityType,
                fileID: value.fileID.toString(),
                userGuid: '',
                isInterior: isInterior
            });
            showImgPreview({ imgpreview: false, src: null, comment: '' });

            loadImages();
        });
    };
    useEffect(() => {
        loadImages();
    }, [selectedObsTab, photos]);

    const loadImages = async () => {
        try {

            //setPhoto([]);
            const action = await dispatch(
                fetchFiles({
                    FileType: 'image',
                    UserID: 0,
                    EntityType: observationtype.current.entityType,
                    EntityID: observationtype.current.entityID,

                }),
            );

            if (action.payload) {
                let uploadedFileArray = action.payload as UploadedFile[];
                if (observationtype.current.entityType === ObservationType.Building) {
                    if (selectedObsTab === 1) {
                        let interiors = [];
                        interiors = uploadedFileArray.filter((e) => {
                            e.isInterior ? console.log(e) : null;

                            return e.isInterior;
                        });
                        console.log(interiors);
                        uploadedFileArray = [...interiors];
                    } else {
                        uploadedFileArray = uploadedFileArray.filter((e) => !e.isInterior);
                    }
                }
                uploadedfileList.current = uploadedFileArray;

                let files = await Promise.all(
                    uploadedFileArray.map(async (e) => {
                        return fetchFile(
                            e.fileCDN,
                            e.comments,
                            e.entityID.toString(),
                            observationtype.current.entityType,
                            e.isBasePhoto,
                            e.fileID.toString(),
                            e.basePhotoID,
                            e.isInterior,
                        );
                    }),
                );

                setImgList(files);

            } else {
                setImgList([]);

            }
        } catch (error) {
            console.error('Error fetching files:', error);
            setImgList([]);
        }
    };

    useEffect(() => {
        const LoadBuildingDetail = async () => await dispatch(GetBuildingDetail(buildingID));
        LoadBuildingDetail().then((res) => {
            if (res.payload) {
                let modifieddata = JSON.parse(JSON.stringify(res.payload as Building));
                refreshSelection(modifieddata);
                setBuildingData(modifieddata);
                setheading({
                    id: buildingID.toString(),
                    name: modifieddata.buildingName,
                });
            }
        });

        if (!selectedRoom) {
            loadData();
            loadbuildings();
        }
        generalAssetImages();
    }, [result, selectedObsTab]);
    const generalAssetImages = () => {
        setPhoto([]);
        setImgList([]);
        loadImages();
    };

    const loadbuildings = async () =>
        dispatch(GetBuildings(currentClaim.claimGUID)).then((value) => {
            if (value.payload) {
                let modifieddata = JSON.parse(JSON.stringify(value.payload as Building[]));
                buildinglist.current = modifieddata;
            }
        });
    async function loadData() {
        return await loadObs().then((obs) => {
            var modifiedData = JSON.parse(JSON.stringify(obs));
            if (observationtype.current.entityType === ObservationType.Building) {
                let res = populateResult(modifiedData);
                console.log(res);

                setobservations(res);
            } else {
                setobservations(modifiedData);
            }
        });
    }
    const loadObs = async () => {
        return await dispatch(
            GetClaimObservationForEntity({
                entityID: claimObservationObject.current.entityID,
                entityType: claimObservationObject.current.entityType,
            }),
        ).then((res) => {
            if (res.payload) {
                return res.payload as ClaimObservation[];
            }
            else {

                return [];
            }
        });
    };

    ///populatre the grid with observation list according to obsrvation type
    function populateResult(obsresult: ClaimObservation[]) {
        console.log(selectedObsTab, observationtype.current);

        if (observationtype.current.entityType === ObservationType.Building) {
            if (selectedObsTab === 1) {
                return obsresult.filter(
                    (e) =>
                        e.entityType.toLowerCase() === ObservationType.Building &&
                        e.isInterior === true,
                );
            } else {
                return obsresult.filter(
                    (e) =>
                        e.entityType.toLowerCase() === ObservationType.Building &&
                        e.isInterior === false,
                );
            }
        } else if (observationtype.current.entityType.toLowerCase() === ObservationType.Floor) {
            return obsresult.filter(
                (e) =>
                    e.entityType.toLowerCase() === ObservationType.Floor &&
                    e.entityID === parseInt(heading.id),
            );
        } else {
            return obsresult.filter(
                (e) =>
                    e.entityType.toLowerCase() === ObservationType.Unit &&
                    e.entityID === parseInt(heading.id),
            );
        }
    }
    let selectedFloor = buildingData?.floors?.find((f) => {
        return f.isSelected;
    });
    let selectedUnit = selectedFloor?.units?.find((u) => {
        return u.isSelected;
    });
    let selectedRoom = selectedUnit?.rooms?.find((r) => {
        return r.isSelected;
    });

    const currentFloor = buildingData?.floors?.filter((e) => e.isSelected)[0];

    const currentUnit = selectedFloor ? currentFloor.units.filter((e) => e.isSelected)[0] : null;
    const currentRoom = selectedUnit ? currentUnit.rooms.filter((e) => e.isSelected)[0] : null;
    console.log(selectedFloor);
    console.log(selectedUnit);

    const handleSelectTab = async (e: TabStripSelectEventArguments) => {
        setPhoto([]);
        setSelectedTab(e.selected);
    };
    const handleSelectedObsTab = async (e: TabStripSelectEventArguments) => {
        setSelectedObsTab(e.selected);
        setPhoto([]);
        setImgList([]);
        generalAssetImages();
    };
    const handleSelectFloor = (floor: Floor) => {
        const building = refreshSelection(buildingData, floor.floorID);
        setBuildingData({ ...building });
    };

    const handleSelectUnit = (unit: Unit) => {
        const building = refreshSelection(buildingData, unit.floorID, unit.unitID);
        setBuildingData({ ...building });
    };

    const handleSelectRoom = (room: Room) => {
        const building = refreshSelection(
            buildingData,
            selectedFloor.floorID,
            room.unitID,
            room.roomID,
        );
        setBuildingData({ ...building });
    };

    const handleFloorSaveComplete = (floor: Floor) => {
        const existingFloor = find(buildingData.floors, (f) => f.floorID === floor.floorID);
        if (existingFloor) {
            floor.units = existingFloor.units;
            floor.isSelected = existingFloor.isSelected;
        }
        const newData = addOrUpdateArrayItem(buildingData.floors, floor, 'floorID');

        setBuildingData({ ...buildingData, floors: newData });
    };

    const handleFloorDeleteComplete = (floor: Floor) => {
        const newData = deleteArrayItem(buildingData.floors, floor, 'floorID');
        setBuildingData({ ...buildingData, floors: newData });
    };

    const handleUnitDeleteComplete = (unit: Unit) => {
        const floor = buildingData.floors.find((b) => {
            return b.floorID === unit.floorID;
        });

        const units = deleteArrayItem(floor.units, unit, 'unitID');
        floor.units = units;
        setBuildingData({ ...buildingData });
    };

    const handleRoomDeleteComplete = (room: Room) => {
        const updated = buildingData?.floors?.some((floor) => {
            const unit = floor?.units?.find((unit) => {
                return unit.unitID === room.unitID;
            });
            if (unit) {
                unit.rooms = deleteArrayItem(unit.rooms, room, 'roomID');
                return true;
            }
        });
        if (updated) {
            setBuildingData({ ...buildingData });
        }
    };

    const handleUnitSaveComplete = (unit: Unit) => {

        const floor = buildingData.floors.find((b) => {
            return b.floorID === unit.floorID;
        });
        
        if (!floor.units.some((u) => u.isSelected)) {
            unit.isSelected = true;
        }

        const existingUnit = find(floor.units, (u) => u.unitID === unit.unitID);
        if (existingUnit) {
            unit.rooms = existingUnit.rooms;
            unit.isSelected = existingUnit.isSelected;
        }

        const units = addOrUpdateArrayItem(floor.units, unit, 'unitID');
        floor.units = units;
        setBuildingData({ ...buildingData });
    };

    const handleRoomSaveComplete = (room: Room) => {
        const updated = buildingData?.floors?.some((floor) => {
            const unit = floor?.units?.find((unit) => {
                return unit.unitID === room.unitID;
            });
            if (unit) {
                unit.rooms = addOrUpdateArrayItem(unit.rooms, room, 'roomID');
                if (!unit.rooms.some((r) => r.isSelected)) {
                    room.isSelected = true;
                }

                const existingRoom = find(unit.rooms, (r) => r.roomID === room.roomID);
                if (existingRoom) {
                    room.isSelected = existingRoom.isSelected;
                }


                return true;
            }
        });
        if (updated) {
            setBuildingData({ ...buildingData });
        }
    };

    const handleSaveClaimComplete = (status: SubmitStatus, data: Claim) => {
        if (status === SubmitStatus.Completed) {
            setCurrentClaim(data);
        }
        setEditClaimData(null);
    };

    const refreshSelection = (
        { ...building }: Building,
        floodId?: number,
        unitId?: number,
        roomId?: number,
    ): Building => {
        building?.floors?.forEach((floor, floorIndex) => {
            if (floodId && floor.floorID === floodId) {
                floor.isSelected = true;
                console.log(floor);
            } else {
                floor.isSelected = false;
                console.log(floor);
            }

            floor?.units?.forEach((unit, unitIndex) => {
                if (unitId && floor.isSelected && unit.unitID === unitId) {
                    unit.isSelected = true;
                } else {
                    unit.isSelected = false;
                }
                unit?.rooms?.forEach((room, roomIndex) => {
                    if (
                        /*  (!roomId && unit.isSelected && roomIndex === 0) || */
                        roomId &&
                        unit.isSelected &&
                        room.roomID === roomId
                    ) {
                        room.isSelected = true;
                    } else {
                        room.isSelected = false;
                    }
                });
            });
        });

        return building;
    };

    /* function to show picture of selected observation*/
    const onShowObservationPhotos = (observation: ClaimObservation) => {
        if (observation.photos) {
            setPhoto([
                ...observation.photos.map(
                    (image, index): Image => ({
                        src: image.fileCDN,
                        height: 200,
                        width: 200,
                        key: image.observationID,
                        orientation: 1,
                        alt: image.comments,
                        caption: image.comments,

                        /*  customOverlay: (
                            <div className="bg-dark text-light">
                                <div>{image.comments}</div>
                            </div>
                        ), */
                    }),
                ),
            ]);

        } else {
            setPhoto([]);
        }
    };

    /* function added to add observation to a building */
    const onAddNewObservationClick = (
        copyFrom: boolean,
        observationType: { entityType: ObservationType; entityID: number },
        unit?: Unit,
        floor?: Floor,
        building?: Building,
        room?: Room,
    ) => {
        console.log(copyFrom, observationType, unit, floor);

        const observation = new ClaimObservation();
        observation.observationID = 0;
        observation.entityType = observationType.entityType;
        observation.entityID = observationType.entityID;

        if (observationType.entityType === ObservationType.Building) {
            if (selectedObsTab === 0) {
                observation.isInterior = false;
            } else {
                observation.isInterior = true;
            }
        }
        setnewobservation({
            newObservation: observation,
            copyFrom: copyFrom,
            updateOption: null,
        });
    };

    /* edit observation for item */

    const onEditObservationClick = (
        observation: ClaimObservation,
        updateOption: ClaimObservationOption,
    ) => {
        setnewobservation({
            newObservation: observation,
            updateOption: updateOption,
        });
    };
    /* delete  observation for item */

    const onDeleteObservation = (claimObservation: ClaimObservation) => {
        setDeleteObservation({
            data: claimObservation,
            route: `ClaimObservation/${claimObservation.observationID}`,
        });
    };
    /* setstate to set image list of a building observation */
    const openGallery = (data: { entityType: EntityType; entityId: number }) => {
        setImageListData(data);
    };

    /* handle delete observation complete event =>building*/
    const handleDeleteObservationComplete = async (
        status: DeleteStatus,
        data: ClaimObservation,
    ) => {
        if (status === DeleteStatus.Completed) {
            await loadData();
        }
        setDeleteObservation(null);
        setPhoto([]);
    };
    const onEditClaimClick = () => {
        setEditClaimData({ ...currentClaim });
    };
    /*
   if (isError) {
        return <div>Error</div>;
    }
 */
    function showbuildingObservations() {
        if (
            observationtype.current.entityType === ObservationType.Building &&
            buildingData !== undefined
        ) {
            return (
                <div>
                    <div className="bg-white-head tabwhitespace">
                        <GuardedComponent permission={PermissionName.ViewObservation}>
                            <TabStrip
                                style={{ b: 'lightgrey', padding: '5px' }}
                                selected={selectedObsTab}
                                onSelect={handleSelectedObsTab}
                            >
                                <TabStripTab
                                    title="Exterior"
                                    contentClassName="structureTab text-capitalize"
                                    key="buildings_tab_exterior"
                                >
                                    <ObservationListComponent
                                        entityType={ObservationType.Building}
                                        handleCancel={() => { loadImages(); }}
                                        handleDelete={async (img: ImgFile) => {
                                            const isdeleted: boolean = await deleteFile(img);
                                            if (isdeleted) {
                                                setPhoto([]);
                                                loadData();
                                                loadImages();
                                            }
                                        }}
                                        handleSave={async (file: ImgFile) => {
                                            const isedited = await editComments({
                                                fileId: file.fileID!,
                                                comments: file.comment,
                                                isBaseImg: file.isbaseImg,
                                                baseID: file.baseID,
                                                entityID: file.entityID,
                                                entityType: file.entityType,
                                                fileID: file.fileID,
                                                userGuid: '',

                                            });
                                            if (isedited) {
                                                setPhoto([]);
                                                loadData();
                                                loadImages();
                                            }
                                        }}
                                        addphoto={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            e.preventDefault();
                                            showImgPreview({
                                                imgpreview: true,
                                                src: e.target.files[0],
                                            });
                                        }}
                                        onAddNewObservationClick={() =>
                                            onAddNewObservationClick(
                                                false,
                                                observationtype.current,
                                                observationtype.current.entityType ===
                                                    ObservationType.Unit
                                                    ? currentUnit
                                                    : null,
                                                observationtype.current.entityType ===
                                                    ObservationType.Floor
                                                    ? currentFloor
                                                    : null,
                                                observationtype.current.entityType ===
                                                    ObservationType.Building
                                                    ? buildingData
                                                    : null,
                                            )
                                        }
                                        oncopyobservation={() => {
                                            onAddNewObservationClick(
                                                true,
                                                observationtype.current,
                                                observationtype.current.entityType ===
                                                    ObservationType.Unit
                                                    ? currentUnit
                                                    : null,
                                                observationtype.current.entityType ===
                                                    ObservationType.Floor
                                                    ? currentFloor
                                                    : null,
                                                observationtype.current.entityType ===
                                                    ObservationType.Building
                                                    ? buildingData
                                                    : null,
                                            );
                                        }}
                                        observations={observations}
                                        imgList={imgList}
                                        onEditObservation={onEditObservationClick}
                                        onDeleteObservation={onDeleteObservation}
                                        onOpenGallery={openGallery}
                                        onShowObservationPhotos={onShowObservationPhotos}
                                        photos={photos}
                                    />
                                </TabStripTab>
                                <TabStripTab
                                    title="Interior"
                                    contentClassName="structureTab"
                                    key="buildings_tab_interior"
                                >
                                    <ObservationListComponent
                                        entityType={ObservationType.Building}
                                        handleCancel={() => { loadImages(); }}
                                        handleDelete={async (img: ImgFile) => {
                                            const isdeleted: boolean = await deleteFile(img);
                                            if (isdeleted) {
                                                setPhoto([]);
                                                loadData();
                                                loadImages();
                                            }
                                        }}
                                        handleSave={async (file: ImgFile) => {
                                            const isedited = await editComments({
                                                fileId: file.fileID!,
                                                comments: file.comment,
                                                isBaseImg: file.isbaseImg,
                                                baseID: file.baseID,
                                                entityID: file.entityID,
                                                entityType: file.entityType,
                                                fileID: file.fileID,
                                                userGuid: '',
                                                isInterior: true,
                                            });
                                            if (isedited) {
                                                setPhoto([]);
                                                loadData();
                                                loadImages();
                                            }
                                        }}
                                        addphoto={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            e.preventDefault();
                                            showImgPreview({
                                                imgpreview: true,
                                                src: e.target.files[0],
                                            });
                                        }}
                                        onAddNewObservationClick={() => {
                                            onAddNewObservationClick(
                                                false,
                                                observationtype.current,
                                                observationtype.current.entityType ===
                                                    ObservationType.Unit
                                                    ? currentUnit
                                                    : null,
                                                observationtype.current.entityType ===
                                                    ObservationType.Floor
                                                    ? currentFloor
                                                    : null,
                                                observationtype.current.entityType ===
                                                    ObservationType.Building
                                                    ? buildingData
                                                    : null,
                                            );
                                        }}
                                        oncopyobservation={() =>
                                            onAddNewObservationClick(
                                                true,
                                                observationtype.current,
                                                observationtype.current.entityType ===
                                                    ObservationType.Unit
                                                    ? currentUnit
                                                    : null,
                                                observationtype.current.entityType ===
                                                    ObservationType.Floor
                                                    ? currentFloor
                                                    : null,
                                                observationtype.current.entityType ===
                                                    ObservationType.Building
                                                    ? buildingData
                                                    : null,
                                            )
                                        }
                                        imgList={imgList}
                                        // onMoveUp={(imgList) => {}}
                                        observations={observations}
                                        onEditObservation={onEditObservationClick}
                                        onDeleteObservation={onDeleteObservation}
                                        onOpenGallery={openGallery}
                                        onShowObservationPhotos={onShowObservationPhotos}
                                        photos={photos}
                                    />
                                </TabStripTab>
                                <TabStripTab
                                    title="Asset Templates"
                                    contentClassName="structureTab"
                                    key="template_tab"
                                >
                                    <DefaultTabComponent building={buildingData} />


                                </TabStripTab>
                            </TabStrip>
                        </GuardedComponent>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className="container-fluid building-details" style={{ backgroundColor: 'white' }}>
            <div className="bg-white-head">
                <div className="top-sec-align mt-0">
                    <div className="grid">
                        <h5 className='title-web text-uppercase mb-0'>Building Details</h5>
                        <ClaimSetupBreadCrumb
                            currentClaim={currentClaim}
                            currentBuilding={buildingData}
                            onClick={() => {
                                observationtype.current = {
                                    entityID: buildingID,
                                    entityType: ObservationType.Building,
                                };

                                claimObservationObject.current = {
                                    entityID: buildingID,
                                    entityType: 'building',
                                };
                                selectedFloor = undefined;
                                selectedRoom = undefined;
                                selectedUnit = undefined;
                                refreshSelection(buildingData);
                                setSelectedTab(0);
                                setSelectedObsTab(0);
                                setobservations([]);
                                setPhoto([]);
                                setheading({
                                    id: buildingData.buildingID.toString(),
                                    name: buildingData.buildingName,
                                });

                                loadData();
                            }}
                        />
                    </div>
                    <div className="ms-auto ">
                        <GuardedComponent permission={PermissionName.ViewBuildingImage}>
                            <button
                                title="Image View"
                                className="icon-image-view"
                                onClick={() => setBuildingView(BuildingView.Image)}
                            ></button>
                        </GuardedComponent>
                        <ClaimInfoPopover
                            claim={currentClaim}
                            onEditClaimClick={onEditClaimClick}
                        />
                    </div>
                </div>
                <LoadingIndicator isLoading={buildingSelector.buildingLoading} />
                {buildingData && (
                    <div className="border p-3 rounded building-details-sec">
                        <div className="col3-grid">
                            {buildingData.floors && (
                                <FloorComponent
                                    onfloorSelect={(data: any) => {
                                        observationtype.current = {
                                            entityType: ObservationType.Floor,
                                            entityID: data.floorID,
                                        };
                                        setheading({ id: data.floorID, name: data.floorName });
                                        setobservations([]);
                                        claimObservationObject.current = {
                                            entityID: data.floorID,
                                            entityType: 'floor',
                                        };
                                        //setSelectedTab(1);
                                        setPhoto([]);

                                        loadData().then(async () => {
                                            generalAssetImages();
                                        });
                                    }}
                                    building={buildingData}
                                    selectFloor={handleSelectFloor}
                                    onEditFloor={handleFloorSaveComplete}
                                    onFloorDeleted={handleFloorDeleteComplete}
                                />
                            )}
                            {/* {selectedFloor && selectedFloor?.units && ( */}
                            <UnitComponent
                                onUnitSelect={(data: any) => {
                                    observationtype.current = {
                                        entityType: ObservationType.Unit,
                                        entityID: data.unitID,
                                    };
                                    setheading({ id: data.unitID, name: data.unitName });
                                    setobservations([]);
                                    setPhoto([]);
                                    claimObservationObject.current = {
                                        entityID: data.unitID,
                                        entityType: 'unit',
                                    };
                                    loadData();
                                    generalAssetImages();
                                }}
                                currentBuilding={buildingData}
                                currentFloor={selectedFloor}
                                selectUnit={handleSelectUnit}
                                onEditUnit={handleUnitSaveComplete}
                                onUnitDeleted={handleUnitDeleteComplete}
                            />
                            {/* )} */}
                            {/* {selectedUnit && selectedUnit && ( */}
                            <RoomComponent
                                onRoomSelect={(data: any) => {
                                    observationtype.current = {
                                        entityType: ObservationType.Room,
                                        entityID: data.roomID,
                                    };
                                    setheading({ id: data.roomID, name: data.roomName });
                                    setobservations([]);
                                    setPhoto([]);
                                    claimObservationObject.current = {
                                        entityID: data.roomID,
                                        entityType: 'room',
                                    };
                                    loadData();
                                    generalAssetImages();
                                }}
                                building={buildingData}
                                unit={selectedUnit}
                                selectRoom={handleSelectRoom}
                                onEditRoom={handleRoomSaveComplete}
                                onRoomDeleted={handleRoomDeleteComplete}
                            />
                            {/* )} */}
                        </div>
                    </div>
                )}
            </div>
            {/* here code added to add observation */}
            {showbuildingObservations()}

            {!selectedRoom && observationtype.current.entityType !== ObservationType.Building && (
                <>
                    {/*  <p className="mb-2 mt-2">{`${toCapitalize(observationtype.current.entityType)}:${toCapitalize(
                        heading.name,
                    )}`}</p>
 */}
                    <div className="bg-white-head tabfix">
                        {' '}
                        <ObservationListComponent
                            entityType={ObservationType.Building}
                            handleCancel={() => { loadImages(); }}
                            handleDelete={async (img: ImgFile) => {
                                const isdeleted: boolean = await deleteFile(img);
                                if (isdeleted) {
                                    setPhoto([]);
                                    loadData();
                                    loadImages();
                                }
                            }}
                            handleSave={async (file: ImgFile) => {
                                const isedited = await editComments({
                                    fileId: file.fileID!,
                                    comments: file.comment,
                                    isBaseImg: file.isbaseImg,
                                    baseID: file.baseID,
                                    entityID: file.entityID,
                                    entityType: file.entityType,
                                    fileID: file.fileID,
                                    userGuid: '',
                                });
                                if (isedited) {
                                    setPhoto([]);
                                    loadData();
                                    loadImages();
                                }
                            }}
                            addphoto={(e: React.ChangeEvent<HTMLInputElement>) => {
                                e.preventDefault();
                                showImgPreview({
                                    imgpreview: true,
                                    src: e.target.files[0],
                                });
                            }}
                            onAddNewObservationClick={() => {
                                onAddNewObservationClick(
                                    false,
                                    observationtype.current,
                                    observationtype.current.entityType === ObservationType.Unit
                                        ? currentUnit
                                        : null,
                                    observationtype.current.entityType === ObservationType.Floor
                                        ? currentFloor
                                        : null,
                                    observationtype.current.entityType === ObservationType.Building
                                        ? buildingData
                                        : null,
                                );
                            }}
                            oncopyobservation={() =>
                                onAddNewObservationClick(
                                    true,
                                    observationtype.current,
                                    observationtype.current.entityType === ObservationType.Unit
                                        ? currentUnit
                                        : null,
                                    observationtype.current.entityType === ObservationType.Floor
                                        ? currentFloor
                                        : null,
                                    observationtype.current.entityType === ObservationType.Building
                                        ? buildingData
                                        : null,
                                )
                            }
                            imgList={imgList}
                            /*  onMoveUp={(imgList) => {}} */
                            observations={observations}
                            onEditObservation={onEditObservationClick}
                            onDeleteObservation={onDeleteObservation}
                            onOpenGallery={openGallery}
                            onShowObservationPhotos={onShowObservationPhotos}
                            photos={photos}
                        />
                    </div>
                </>
            )}
            {selectedRoom && (
                <TabStrip
                    style={{ 'background-color': 'lightgrey', padding: '5px' }}
                    selected={selectedTab}
                    onSelect={handleSelectTab}
                >
                    <TabStripTab
                        title="Room Observations"
                        contentClassName="structureTab"
                        key="rooms_tab"
                    >
                        <>
                            <ObservationListComponent
                                entityType={ObservationType.Building}
                                handleCancel={() => { loadImages(); }}
                                handleDelete={async (img: ImgFile) => {
                                    const isdeleted: boolean = await deleteFile(img);
                                    if (isdeleted) {
                                        setPhoto([]);
                                        loadData();
                                        loadImages();
                                    }
                                }}
                                handleSave={async (file: ImgFile) => {
                                    const isedited = await editComments({
                                        fileId: file.fileID!,
                                        comments: file.comment,
                                        isBaseImg: file.isbaseImg,
                                        baseID: file.baseID,
                                        entityID: file.entityID,
                                        entityType: file.entityType,
                                        fileID: file.fileID,
                                        userGuid: '',
                                    });
                                    if (isedited) {
                                        setPhoto([]);
                                        loadData();
                                        loadImages();
                                    }
                                }}
                                oncopyobservation={() =>
                                    onAddNewObservationClick(
                                        true,
                                        observationtype.current,
                                        observationtype.current.entityType === ObservationType.Unit
                                            ? currentUnit
                                            : null,
                                        observationtype.current.entityType === ObservationType.Floor
                                            ? currentFloor
                                            : null,
                                        observationtype.current.entityType ===
                                            ObservationType.Building
                                            ? buildingData
                                            : null,
                                        observationtype.current.entityType === ObservationType.Room
                                            ? currentRoom
                                            : null,
                                    )
                                }
                                onAddNewObservationClick={() => {
                                    onAddNewObservationClick(
                                        false,
                                        observationtype.current,
                                        observationtype.current.entityType === ObservationType.Unit
                                            ? currentUnit
                                            : null,
                                        observationtype.current.entityType === ObservationType.Floor
                                            ? currentFloor
                                            : null,
                                        observationtype.current.entityType ===
                                            ObservationType.Building
                                            ? buildingData
                                            : null,
                                        observationtype.current.entityType === ObservationType.Room
                                            ? currentRoom
                                            : null,
                                    );
                                }}
                                addphoto={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    e.preventDefault();
                                    showImgPreview({
                                        imgpreview: true,
                                        src: e.target.files[0],
                                    });
                                }}
                                imgList={imgList}
                                observations={observations}
                                onEditObservation={onEditObservationClick}
                                onDeleteObservation={onDeleteObservation}
                                onOpenGallery={openGallery}
                                onShowObservationPhotos={onShowObservationPhotos}
                                photos={photos}
                            />
                        </>
                    </TabStripTab>
                    <TabStripTab title="Windows" contentClassName="structureTab" key="windows_tab">
                        {selectedRoom && (
                            <WindowsComponent building={buildingData} room={selectedRoom} />
                        )}
                    </TabStripTab>
                    <TabStripTab title="Doors" contentClassName="structureTab" key="doors_tab">
                        {selectedRoom && (
                            <DoorsComponent building={buildingData} room={selectedRoom} />
                        )}
                    </TabStripTab>
                    <TabStripTab
                        title="Railings"
                        contentClassName="structureTab"
                        key="railings_tab"
                    >
                        {selectedRoom && (
                            <RailingsComponent building={buildingData} room={selectedRoom} />
                        )}
                    </TabStripTab>
                </TabStrip>
            )}

            {editClaimData && <EditClaim data={editClaimData} onClose={handleSaveClaimComplete} />}
            {/* Add/Edit Observation modal for building */}
            {newobservation && (
                <EditObservation
                    isInterior={selectedObsTab === 1}
                    imgList={imgList}
                    isphotoUploaded={(res: number) => {
                        if (res === 1) {
                            loadImages();
                        }
                    }}
                    buildings={buildinglist.current}
                    building={buildingData}
                    observationType={observationtype.current.entityType}
                    data={newobservation}
                    uploadedFiles={uploadedfileList.current}
                    onClose={handleSaveObservationComplete}
                />
            )}
            {/* deletre dialog for building observation */}
            {deleteObservation && (
                <DeleteModelDialog
                    title="Delete Observation"
                    deleteData={deleteObservation}
                    onClose={handleDeleteObservationComplete}
                >
                    <div>
                        Are you sure you want to delete Observation -{' '}
                        {deleteObservation.data.observation}?
                    </div>
                </DeleteModelDialog>
            )}
            {buildingData && (
                <LinkImageModelDialog
                    buildingId={buildingData.buildingID}
                    linkImageData={linkImageData}
                    title={'Link Building'}
                    onClose={handleLinkWinodwComplete}
                />
            )}
            {imgpreview.imgpreview ? (
                <ImageUploader
                    onAddImg={(comment: string) => {
                        imgpreview.comment = comment;
                        handleAddFileToImgList(imgpreview.src);
                    }}
                    onclose={() => {
                        showImgPreview({ imgpreview: false, src: null });
                    }}
                    showModal={imgpreview.imgpreview}
                    img={imgpreview.src}
                />
            ) : null}
        </div>
    );
};
