import type { AxiosError } from 'axios';
import axios from 'axios';
import { createMutation } from 'react-query-kit';
import { UserResponse } from '../../models/user';


type Payload = { OTP: string; userGuid: string; }



export const useVerifyOtp = createMutation<UserResponse, Payload, AxiosError>({
    mutationFn: async payload =>
        axios({
            url: 'SchedulerAssignments/VerifyOTP',
            method: 'POST',
            params: payload
        }).then(response => response.data),
});
