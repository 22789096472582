import { ClaimObservationOption } from '../../../models/claim/claim-observation-option';
import { ObservationType } from '../../../models/masters/observation-type';

interface ObservationOptionsComponentProps {
    options: ClaimObservationOption[];
    observationType: string;
}

export const ObservationOptionsComponent = ({ options, observationType }: ObservationOptionsComponentProps) => {
    const getOption = (option: ClaimObservationOption) => {
        return (
            <>
                {observationType.toLowerCase() === 'window' || observationType.toLowerCase() === 'door' || observationType.toLowerCase() === 'railing' ? (`${option.observationOption}${option?.optionValue !== undefined ? option?.optionValue : ''}`) :
                    option.optionValue}



            </>
        );
    };

    return <>{options?.map((option) => getOption(option))}</>;
};
