import type { AxiosError } from 'axios';
import axios from 'axios';
import { createQuery } from 'react-query-kit';
import { ScheduledSlot } from '../../types/ScheduledSlot';

type Params = {
    SDate: string,
    FDate: string,
    InspectorID: number
}
export const useAvailabilities = createQuery<ScheduledSlot[], Params, AxiosError>({
    queryKey: ['availabilities'],
    fetcher: (params) =>
        axios.get('SchedulerAssignments/GetInspectorAvailability', { params }).then(response => response.data?.result || []),
    refetchOnWindowFocus: false
});
