import { DeleteFiles, DownloadFiles, IDownloadFileProps, IDownloadFileResponse } from '../utils/downloadfileutil';
import DownloadIcon from '../images/download-icon';
import DeleteIcon from '../images/delete';
import { useEffect, useState } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { LoadingIndicator } from './loading-indicator.component';
import { ObservationType } from '../models/masters/observation-type';
import { Grid, GridColumn, GridDataStateChangeEvent, GridNoRecords, GridPageChangeEvent } from '@progress/kendo-react-grid';

import { DataResult, process } from '@progress/kendo-data-query';
import { Building } from '../models/claim/building';

interface Props {
    downloadFiles: IDownloadFileResponse[];
    downloadFileProps: IDownloadFileProps;
    onRefresh: (buildingData: Building, isrefresh?: boolean) => Promise<boolean>;
}

const FileTable: React.FC<Props> = ({ downloadFiles, downloadFileProps, onRefresh }) => {
    const [isprocessing,] = useState<boolean>(false);
    const [downloadList, setDownloadList] = useState<IDownloadFileResponse[]>(downloadFiles);
    const [confirmDelete, setConfirmDelete] = useState<{ isConfirm: boolean, filename: IDownloadFileResponse }>({ filename: undefined, isConfirm: false });
    const [deleting, setdeleting] = useState<{ deleting: boolean, isSuccess: boolean }>({ deleting: false, isSuccess: false });
    const [deletemsg, setdeletemsg] = useState<string>("Are you sure want to delete the file?");
    const [gridData, setGridData] = useState<DataResult>({ data: [], total: 0 });
    const [alreadyInProcess, setAlreadyInProcess] = useState<boolean>(false);
    const [dataState, setDataState] = useState({
        skip: 0,
        take: 10,
    });

    useEffect(() => {
        console.log(downloadList);

        const DataRes = process(downloadList, dataState);
        setGridData(DataRes);

        const hasProcessingFiles = downloadList.some(file => file.processStatus === 0);

        if (hasProcessingFiles) {
            const autoRefreshId = window.setInterval(() => {
                onRefresh(downloadFileProps.building);
            }, 10000); // Refresh every 10 seconds

            return () => {
                clearInterval(autoRefreshId);
            };
        }
    }, [downloadList, dataState, downloadFileProps.building, onRefresh]);

    const handleDownload = (fileCDN: string, fileName: string) => {
        fetch(fileCDN)
            .then((response) => response.blob())
            .then((blob) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => console.error('Error downloading file:', error));
    };

    const handleDelete = async (id: number) => {
        setdeleting({ deleting: true, isSuccess: false });
        const isDeleted = await DeleteFiles(id);
        if (isDeleted) {
            let filteredfiles = downloadList.filter(e => e.logId !== id);
            setDownloadList(filteredfiles);
            setdeleting({ deleting: false, isSuccess: true });
            setConfirmDelete({ filename: undefined, isConfirm: false });
        } else {
            setdeleting({ deleting: false, isSuccess: false });
            setdeletemsg("Unable to delete.");
        }
    };

    const handleDataStateChange = (e: GridPageChangeEvent) => {
        const newDataState = { skip: e.page.skip, take: e.page.take };
        setDataState(newDataState);
        setGridData(process(downloadList, newDataState));
    };

    return (
        <>
            {!isprocessing ? (
                <div style={{ overflow: "none" }}>
                    <Grid
                        data={gridData.data}
                        skip={dataState.skip}
                        take={dataState.take}
                        total={gridData.total}
                        onPageChange={(e) => handleDataStateChange(e)}
                        pageable={true}
                        navigatable={false}
                        expandField="expanded"
                    >
                        <GridColumn field="fileName" title="File Name" width={"240px"} />
                        <GridColumn field="createdOn" title="Created On" width={"240px"} />
                        <GridColumn field="userName" title="User Name" />
                        <GridColumn field="status" title="Status" />
                        <GridNoRecords>
                            <div style={{ width: "100%", height: "500px" }}>
                                <center>No data found</center>
                            </div>
                        </GridNoRecords>
                        <GridColumn
                            field="actions"
                            title="Actions"
                            cell={(props) => (
                                <td style={{ textAlign: 'center' }}>
                                    <div>
                                        <a
                                            style={{ cursor: props.dataItem.processStatus === 1 ? 'pointer' : 'not-allowed', marginRight: '5px' }}
                                            onClick={() => props.dataItem.processStatus === 1 ? handleDownload(props.dataItem.fileCDN, props.dataItem.fileName) : null}
                                        >
                                            <DownloadIcon />
                                        </a>
                                        <a
                                            style={{ cursor: props.dataItem.processStatus === 1 || props.dataItem.processStatus === -1 || props.dataItem.processStatus === -2 ? 'pointer' : 'not-allowed', marginRight: '5px' }}
                                            onClick={() => props.dataItem.processStatus === 1 || props.dataItem.processStatus === -1 || props.dataItem.processStatus === -2 ? setConfirmDelete({ filename: props.dataItem, isConfirm: true }) : null}
                                            className="mx-1"
                                        >
                                            <DeleteIcon />
                                        </a>
                                    </div>
                                </td>
                            )}
                        />
                    </Grid>
                </div>
            ) : (
                <LoadingIndicator isLoading />
            )}

            <div className='d-flex justify-content-between' style={{ marginTop: '15px' }}>
                <button className='btn btn-secondary btn-sm' style={{ marginRight: '25px' }} onClick={async () => {
                    const response = await onRefresh(downloadFileProps.building);
                }}>Refresh</button>
                <button className='btn btn-primary btn-sm' onClick={async () => {
                    if (downloadList.length > 0 && downloadList.filter(e => e.processStatus === 0).length > 0) {
                        setAlreadyInProcess(true);
                    }
                    else {
                        const response = await DownloadFiles({
                            claimID: downloadFileProps.claimID,
                            entityID: downloadFileProps.entityID,
                            entityType: 'photodownload',
                            userID: 0,
                            claimGUID: downloadFileProps.claimGUID,
                        });
                        if (response === undefined) {
                            const res = await onRefresh(downloadFileProps.building, true);
                            if (res) {
                                // Handle the response if needed
                            }
                        } else {
                            const res = await onRefresh(downloadFileProps.building, true);
                        }
                    }
                }}>Create Photo Archive</button>
            </div>

            {confirmDelete.isConfirm && (
                <Dialog title="Delete Zip File" onClose={() => {
                    setConfirmDelete({ filename: undefined, isConfirm: false });
                }}>
                    {!deleting.deleting ? <div>{deletemsg}</div> : <LoadingIndicator isLoading={true} />}
                    <DialogActionsBar>
                        <div className='row'>
                            <div className='d-flex justify-content-between'>
                                <button className='btn btn-secondary btn-sm' onClick={() => {
                                    setConfirmDelete({ filename: undefined, isConfirm: false });
                                }}>Cancel</button>
                                {deletemsg === "Are you sure want to delete the file?" &&
                                    <button className='btn btn-primary btn-sm' onClick={() => handleDelete(confirmDelete.filename.logId)}>Delete</button>}
                            </div>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            )}

            {/* already in process alert */}

            {alreadyInProcess && (<Dialog onClose={() => {
                setAlreadyInProcess(false);
            }} children={<>
                A process is already running.please wait....
                {<DialogActionsBar>
                    <div className='row'>
                        <div className='d-flex justify-content-end'>
                            <button className='btn btn-secondary btn-sm' onClick={() => {
                                setAlreadyInProcess(false);
                            }}>Ok</button>
                        </div>
                    </div>
                </DialogActionsBar>}
            </>} title={"Processing"} />)
            }
        </>
    );
};

export default FileTable;
