import { Shape } from '../../../models/building-image/image-draw.types';
import DeleteGreyIcon from '../../../images/delete-gray';
import editIc from '../../../images/edit';
import EyeIcon from '../../../images/eye';
import { GuardedComponent } from '../../../components/guarded.componemt';
import { PermissionName } from '../../../models/user-security/permission.name';
import EditIcon from '../../../images/edit';

export interface ShapeInfoComponentProps {
    shape: Shape;
    onViewDetails: any;
    oEditSelectedClick: any;
    onDeleteClick: any;
}

export const ShapeInfoComponent = ({
    shape,
    onViewDetails,
    oEditSelectedClick,
    onDeleteClick,
}: ShapeInfoComponentProps) => {
    if (!shape.tagData) {
        return null;
    }

    return (
        <>
            {shape.tagData?.entityName && (
                <div className="shape-info" style={{ fontSize: 'small' }}>
                    <label>
                        {shape.tagData.entityType}: {shape.tagData.entityName}
                    </label>
                </div>
            )}
            <div className="pop-tool contol-icons">
                {onViewDetails && (
                    <button onClick={() => onViewDetails(shape.tagData)}>
                     <EyeIcon></EyeIcon>   {/* <img src={Eye} /> */}
                    </button>
                )}
                <GuardedComponent permission={PermissionName.EditBuilding}>
                    <button className="me-2 ms-2" onClick={() => oEditSelectedClick(shape.tagData)}>
                      <EditIcon></EditIcon>  {/* <img src={EditIcon} /> */}
                    </button>
                </GuardedComponent>
                <GuardedComponent permission={PermissionName.EditBuilding}>
                    <button onClick={() => onDeleteClick(shape)}>
                    <DeleteGreyIcon></DeleteGreyIcon>    {/* <img src={Dele} /> */}
                    </button>
                </GuardedComponent>
            </div>
        </>
    );
};
