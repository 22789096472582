import { Field } from 'formik';
import * as Yup from 'yup';
import { CheckboxField } from '../../components/form/checkbox-field.component';
import { ColorPickerField } from '../../components/form/color-picker-field.component';
import { InputField } from '../../components/form/input.feild.component';
import { SelectField } from '../../components/form/select-field.component';
import { FormikModelDialog, SubmitStatus } from '../../components/formik-model-dialog.component';
import { Observation } from '../../models/masters/observation';

interface EditObservationMasterProps {
    data?: Observation;
    onClose?: (status: SubmitStatus, data: Observation) => any;
}

export const EditObservationMaster = ({ data, onClose }: EditObservationMasterProps) => {
    const title = data.observationID === 0 ? 'Add Observation' : 'Edit Observation';
    const validationSchema = Yup.object().shape({
        observationName: Yup.string().required('Observation name is required'),
        observationSelectType: Yup.string().required('Please select a type'),
        color: Yup.string().required('Please select a color'),
    });
    const typeOptions: { value: string; label: string }[] = [
        { value: 'Text', label: 'Text' },
        { value: 'SingleSelect', label: 'SingleSelect' },
        { value: 'MultiSelect', label: 'MultiSelect' },
    ];
    return (
        <FormikModelDialog
            title={title}
            initialData={data}
            validationSchema={validationSchema}
            show={!!data}
            onClose={onClose}
            postRoute="observation"
        >
            <>
                <InputField label="Name" name="observationName" />
                <SelectField label="Type" name="observationSelectType" options={typeOptions} />
                <CheckboxField label="Mandatory" name="isMandatory" />
                <ColorPickerField label="Color" name="color" />
            </>
        </FormikModelDialog>
    );
};
