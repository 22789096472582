import React from 'react';
import { useField } from 'formik';


interface ToggleSwitchProps {
    name: string;
    label?: string;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ label, ...props }) => {
    const [field, , helpers] = useField(props);

    return (
        <label className="toggle-switch">
            <input
                type="checkbox"
                {...field}
                {...props}
                checked={field.value}
                onChange={(e) => {
                    helpers.setValue(e.target.checked);
                }}
            />
            <span className="slider"></span>
            {label && <span className="mx-1">{label}</span>}
        </label>
    );
};

export default ToggleSwitch;
