import { useEffect, useRef, useState } from "react";
import { SendOtpPayload, SendOtpResponse, useVerifyOtp } from "../../api/inspector"
import { maskMobile, maskEmail } from "../../utils"
import OtpInput from 'react-otp-input';
import { LoadingIndicator } from "../../components/loading-indicator.component";
import { setAxiosAuthHeaders } from "../../api-client.ts/axios";
import { useStorage } from "../../hooks/use-storage";
import { StorageKey } from "../../constants";
import { useNavigate } from "react-router-dom";



interface Props {
    channel: 'mobile' | 'email';
    userGuid: string;
    sendOtpPayload: SendOtpPayload;
    resendOtp: (payload: SendOtpPayload, onSuccess: (response: SendOtpResponse) => void, onError?: () => void) => void;
    isPendingResendOtp: boolean

}


const RESEND_OTP_INTERVAL = 5 * 60;

export function InspectorVerifyOtp({ channel, userGuid, sendOtpPayload, resendOtp, isPendingResendOtp }: Props) {
    const [formState, setFormState] = useState<'verify' | 'resend'>('verify')
    const [seconds, setSeconds] = useState(RESEND_OTP_INTERVAL)
    const [otp, setOtp] = useState('');
    const [error, setError] = useState('')
    const nav = useNavigate();

    const [_, setAuthState] = useStorage(StorageKey.AuthState, null);
    const [, setUserType] = useStorage(StorageKey.UserType, null);


    const intervalRef = useRef<NodeJS.Timeout>()
    useEffect(() => {
        if (seconds === RESEND_OTP_INTERVAL) {
            intervalRef.current = setInterval(() => {
                setSeconds(prev => prev - 1);
            }, 1000);

        }
        else if (seconds === 0) {
            setFormState('resend')
            clearInterval(intervalRef.current);
        }
    }, [seconds]);

    const { mutate: verifyOtp, isPending: isPendingVerifyOtp } = useVerifyOtp({
        onSuccess: (response) => {
            if (response.isSuccess) {
                setAuthState(response.token);
                setUserType("inspector")
                setAxiosAuthHeaders()

                nav('/inspector/landing-page', { state: { ...response } })
            } else {
                setError('Invalid One Time Code');
            }
        },
        onError: () => {
            setError('Failed to verify One Time Code');
        }

    })

    return (
        <div >
            <LoadingIndicator isLoading={isPendingResendOtp || isPendingVerifyOtp} />
            <div className="text-center mt-4 mb-3">
                <h5>ENTER ONE TIME CODE</h5>
                <label>Send your one time code to your {channel === "mobile" ? "mobile number" : "email"}</label>
                {channel === "mobile" ? <h5>{sendOtpPayload.countryCode} {maskMobile(sendOtpPayload.mobileNo)}</h5> :
                    <h5>{maskEmail(sendOtpPayload.email)}</h5>
                }
            </div>

            <div className="otpinput">
                <OtpInput

                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderInput={(props) => <input {...props} />}
                />
            </div>
            {error && <span className="text-danger">{error}</span>}
            <button
                id="btnSignIn"
                className="btn btn-normal btn-block w-100 mt-2 text-uppercase"
                type="button"
                onClick={() => {
                    if (!otp) {
                        setError("Please enter a valid One Time Code")
                        return
                    }
                    verifyOtp({ OTP: otp, userGuid })
                }}
                disabled={isPendingVerifyOtp}
            >
                Verify  One Time Code
            </button>

            {formState === 'verify' &&
                <div style={{ fontSize: "14px", textAlign: "center", marginTop: 10 }}>
                    <center>{`Time Remaining: ${Math.floor(seconds / 60)}:${(seconds % 60).toString().padStart(2, '0')}`}</center>
                </div>
            }
            {formState === 'resend' && <label className="resendBac">Didn't receive the code? <button onClick={() => {
                resendOtp(sendOtpPayload, () => {
                    setFormState('verify');
                    setSeconds(RESEND_OTP_INTERVAL)
                })
            }}>Resend it</button></label>}

        </div>
    )
}