import * as React from 'react';

import { LoadingIndicator } from "../../components/loading-indicator.component";
import { useLoadData } from "../../hooks/use-scheduler-load-data";
import { Grid, GridCellProps, GridColumn, GridColumnMenuFilter, GridColumnMenuProps, GridColumnMenuSort, GridDataStateChangeEvent, GridNoRecords, GridPageChangeEvent } from "@progress/kendo-react-grid";
import { DataResult, State, process } from "@progress/kendo-data-query";
import { take } from "lodash";
import { useEffect, useState } from "react";
import { GridCommandCell } from '../../components/grid-command-cell.component';
import EditIcon from '../../images/edit';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { EditSLA } from './components/edit-sla.component';
import { SubmitStatus } from '../../components/formik-model-dialog.component';

const SLAList: React.FunctionComponent<ISLACondition> = (props) => {
    const initialDataState: State = {
        take: 50,
        skip: 0
    };

    const url = `SchedulerAssignments/GetSLA`;
    const { isLoading, isError, reload, result } = useLoadData<ISLACondition[]>(url);
    const [gridData, setGridData] = useState<DataResult>({ data: [], total: 0 });
    const [dataState, setDataState] = useState<any>({ take: 50, skip: 0 });
    const [resultData, setResultData] = useState<DataResult>();
    const [editSLAData, setEditSLAData] = useState<ISLACondition>();

    useEffect(() => {
        if (result !== undefined) {
            const DataRes = process(result, dataState)
            setGridData(DataRes);
        }
    }, [result, dataState]);

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        const newDataState = process(result, dataState);
        setResultData(newDataState);
        setDataState(event.dataState);
    };
    const handleDataStateChange = (e: GridPageChangeEvent) => {
        const newDataState = { skip: e.page.skip, take: e.page.take };
        setDataState(newDataState);
    };

    const ColumnMenu = (props: GridColumnMenuProps) => {
        return (
            <div>
                <GridColumnMenuFilter {...props} expanded={true} />
            </div>
        );
    };
    const columnProps = (field: string) => {
        return {
            field: field,
            columnMenu: ColumnMenu,
            headerClassName: isColumnActive(field, dataState) ? "active" : "",
        };
    };
    const isColumnActive = (field: string, dataState: State) => {
        return (
            GridColumnMenuFilter.active(field, dataState.filter) ||
            GridColumnMenuSort.active(field, dataState.sort)
        );
    };
    const onEdit = (data: ISLACondition) => {
        setEditSLAData(data);
    };
    const CommandCell = (props: GridCellProps) => (
        <GridCommandCell
            {...props}
            edit={onEdit}
            idPropertyName="claimID"

        />
    );

    const EditColumnCell = (props: any) => {
        return (
            <td
                {...props.tdProps}
                colSpan={1}
                style={{
                    color: "#aaa",
                }}
            >
                <div>
                    <button
                        className="action-btns k-grid-edit-command"
                        onClick={() => {
                            console.log(props?.dataItem)
                            onEdit(props?.dataItem)
                        }}
                    >
                        <EditIcon></EditIcon>{' '}
                    </button>
                </div>
            </td>
        );
    };
    const onSaveComplete = (status: SubmitStatus, data: ISLACondition) => {
        if (status === SubmitStatus.Completed) {
            // const newData = addOrUpdateArrayItem(result, data, 'claimID');
            reload();
            //setResultData(processWithGroups(result, dataState));
        }
        setEditSLAData(null);
    };
    return (<div className='m-lg-6 mt-lg-3 m-4'>
        <div className="top-sec-align">
            <h5 className="text-normal title-web mb-0 text-uppercase">Service Level Agreement</h5>
        </div>
        {editSLAData && <EditSLA data={editSLAData} onClose={onSaveComplete} />}
        <div className='card p-4 border-0 sw-shadow radius-15 border-0 '>
            {isError && (<div>Error</div>)}
            {isLoading && (<LoadingIndicator isLoading />)}
            {!isLoading && <>
                <Grid
                    data={gridData.data}
                    pageable={{ pageSizes: [50, 100, 200, 500] }}
                    skip={dataState.skip}
                    take={dataState.take}
                    total={gridData.total}
                    groupable={false}
                    sortable={true}
                    onDataStateChange={dataStateChange}
                    {...dataState}
                    onPageChange={(e) => handleDataStateChange(e)}
                    resizable={true}
                    columnVirtualization={false}
                >

                    <GridColumn
                        {...columnProps("description")}
                        field="description"
                        columnMenu={ColumnMenu}
                        title="Description" />
                    <GridColumn field="sCondition"
                        {...columnProps("sCondition")}
                        columnMenu={ColumnMenu}
                        title="Condition" />
                    <GridColumn
                        {...columnProps("action")}
                        field="action"
                        title="Trigger"
                        columnMenu={ColumnMenu}
                    />
                    <GridColumn field="userType"
                        columnMenu={ColumnMenu}
                        {...columnProps("userType")}
                        title="Applicable Users" />
                    <GridColumn title={"Edit"} width={"100px"}
                        cells={{ data: EditColumnCell }}
                        groupable={true} />
                    <GridNoRecords>
                        <div className='mt-2' style={{ width: "100%", height: "500px" }}>
                            <center><h5>No data found</h5></center>
                        </div>
                    </GridNoRecords>
                </Grid>
            </>
            }
        </div >
    </div >
    );

}
export default SLAList;