import type { AxiosError } from 'axios';
import axios from 'axios';
import { createMutation } from 'react-query-kit';

type Response = {
    slotFrom: string;
    slotTo: string;
    inspectorID: number;
    inspector: string;
    homeownerId: number;
    iSlotID: number;
    hSlotID: number;
    assignCnt: number;
};


type Params = { claimId?: number; };

export const useCheckAutoSuggestedSlot = createMutation<Response, Params, AxiosError>({
    mutationFn: async params =>
        axios({
            url: 'SchedulerAssignments/GetSlotAutoSuggest',
            method: 'GET',
            params: params
        }).then(response => response.data?.result?.[0]),
});
