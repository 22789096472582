import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { FetchFilesParams, UploadedFile } from '../redux/reducers/filereducer';
import AsyncRequest, { HttpMethod } from '../utils/fetchutil';
import { WINDOWS_DOORS_API_URL } from '../constants';

export const fetchFiles = createAsyncThunk('files/fetchFiles', async (params: FetchFilesParams) => {
    const response = await AsyncRequest.requestApi({
        url: `${WINDOWS_DOORS_API_URL}/api/File`,
        method: HttpMethod.GET,
        params:  params ,
    });
    return response as UploadedFile[];
});
