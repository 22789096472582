import type { AxiosError } from 'axios';
import axios from 'axios';
import { createMutation } from 'react-query-kit';
import { SendOtpPayload, SendOtpResponse } from './use-send-mobile-otp';




export const useSendEmailOtp = createMutation<SendOtpResponse, SendOtpPayload, AxiosError>({
    mutationFn: async payload =>
        axios({
            url: 'SchedulerAssignments/EmailVerificationAndSendOTP',
            method: 'POST',
            data: payload
        }).then(response => response.data),
});