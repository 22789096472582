import * as Yup from 'yup';
import { CheckboxField } from '../../components/form/checkbox-field.component';
import { InputField } from '../../components/form/input.feild.component';
import { SelectField } from '../../components/form/select-field.component';
import { FormikModelDialog, SubmitStatus } from '../../components/formik-model-dialog.component';
import { ObservationOption } from '../../models/masters/observation-option';

interface EditObservationOptionMasterProps {
    data?: ObservationOption;
    onClose?: (status: SubmitStatus, data: ObservationOption) => any;
}

export const EditObservationOptionMaster = ({
    data,
    onClose,
}: EditObservationOptionMasterProps) => {
    const title =
        data.observationOptionID === 0 ? 'Add Observation Option' : 'Edit Observation Option';
    const validationSchema = Yup.object().shape({
        optionName: Yup.string().required('Required'),
        optionSelectType: Yup.string().required('Required'),
    });

    const typeOptions: { value: string; label: string }[] = [
        { value: 'None', label: 'None' },
        { value: 'Number', label: 'Number' },
        { value: 'Text', label: 'Text' },
    ];

    return (
        <FormikModelDialog
            width='40%'
            title={title}
            initialData={data}
            validationSchema={validationSchema}
            show={!!data}
            onClose={onClose}
            postRoute="observation/option"
        >
            <>
                <InputField label="Name" name="optionName" />
                <SelectField label="Type" name="optionSelectType" options={typeOptions} />
                <CheckboxField label="Mandatory" name="isMandatory" />
            </>
        </FormikModelDialog>
    );
};
