import type { AxiosError } from 'axios';
import axios from 'axios';
import { createQuery } from 'react-query-kit';
import { IInspector } from '../../models/scheduler/inspector';


type Params = {
    claimID: number
}

export const useInspectors = createQuery<IInspector[], Params, AxiosError>({
    queryKey: ['inspectors'],
    fetcher: (params) =>
        axios.get('SchedulerAssignments/GetInspectorsForInspectionRequest', { params }).then(response => response.data?.result || []),
    refetchOnWindowFocus: false
});
