import { result, find, reduce, split, some } from 'lodash';
import { WINDOWS_DOOR_OTHER_OPTION, WINDOWS_DOOR_OTHER_OPTION_VALUE_PREFIX } from '../constants';
import { Attribute } from '../models/claim/attribute';
import { InputFieldType } from '../models/enums/input-field-type';
import { MasterType } from '../models/masters/master-type';

export const getDirectionOptions = () => {
    return [
        { value: 'North', label: 'North' },
        { value: 'East', label: 'East' },
        { value: 'South', label: 'South' },
        { value: 'West', label: 'West' },
        { value: 'NorthEast', label: 'North East' },
        { value: 'SouthEast', label: 'South East' },
        { value: 'SouthWest', label: 'South West' },
        { value: 'NorthWest', label: 'North West' },
    ];
};

export const createAttributeObject = (
    result: MasterType[],///master data from db to filter against user data
    attributesData: Attribute[],//user data empty for insert and filled for edit 
    isTemplate?: boolean,


) => {
    if (result) {
        return result.reduce((prevvalue, currentType) => {
            const attribute = find(attributesData, (a) => a.attributeName === currentType.name);
            if (currentType.inputType === InputFieldType.Text && !attribute && isTemplate) {
                return {
                    ...prevvalue,
                    [currentType.name]: ''
                }
            }

            if (!attribute) {
                return {
                    ...prevvalue,
                };
            }

            if (
                currentType.inputType === InputFieldType.Date ||
                currentType.inputType === InputFieldType.Text
            ) {
                return {
                    ...prevvalue,
                    [currentType.name]: attribute.attributeValue,
                };
            } else if (currentType.inputType === InputFieldType.MultiSelect) {
                const values = reduce(
                    split(attribute.attributeValue, ','),
                    (prev, currnt) => {
                        if (attribute) {
                            if (currnt.includes(':')) {

                                console.log({
                                    ...prev,
                                    [`${currnt.split(':')[0]}-${result.find(e => e.name.toLowerCase() === attribute.attributeName.toLowerCase()).values.find(e => e.value === WINDOWS_DOOR_OTHER_OPTION).id}`]: true,
                                    [`${currnt.split(':')[0]}value-${result.find(e => e.name.toLowerCase() === attribute.attributeName.toLowerCase()).values.find(e => e.value === WINDOWS_DOOR_OTHER_OPTION).id}`]: currnt.split(':')[currnt.split(':').length - 1],
                                });

                                return {
                                    ...prev,
                                    [`${currnt.split(':')[0]}-${result.find(e => e.name.toLowerCase() === attribute.attributeName.toLowerCase()).values.find(e => e.value === WINDOWS_DOOR_OTHER_OPTION).id}`]: true,
                                    [`${currnt.split(':')[0]}value-${result.find(e => e.name.toLowerCase() === attribute.attributeName.toLowerCase()).values.find(e => e.value === WINDOWS_DOOR_OTHER_OPTION).id}`]: currnt.split(':')[currnt.split(':').length - 1],
                                };
                            }
                        }
                        return {
                            ...prev,
                            [currnt]: true,
                        };
                    },
                    {},
                );
                return {
                    ...prevvalue,
                    ...values,
                };
            } else {
                const isOtherValue = !some(
                    currentType.values,
                    (v) => v.value === attribute.attributeValue,
                );

                return {
                    ...prevvalue,
                    [currentType.name]: !isOtherValue
                        ? attribute.attributeValue
                        : WINDOWS_DOOR_OTHER_OPTION,
                    ...(isOtherValue && {
                        [currentType.name + WINDOWS_DOOR_OTHER_OPTION_VALUE_PREFIX]:
                            attribute.attributeValue,
                    }),
                };
            }
        }, {});
    }
};
