import { useState } from 'react';
import { useCurrentUser } from '../../hooks/user-current-user.hook';
import { PermissionCheckType } from '../../models/user-security/permission-check-type';
import { PermissionName } from '../../models/user-security/permission.name';
import { validateRequiredPermissions } from '../../utils/permission.manager';
import { BuildingViewPage } from '../building-image/buildingview.page';
import { BuildingDetailsPage } from './buildingdetails.page';
import { useParams } from 'react-router-dom';

interface BuildingPageProps {
    match: {
        params: {
            buildingID: number;

        };
    };
}

export enum BuildingView {
    Normal = 'Normal',
    Image = 'Image',
}

export const BuildingPage: React.FC = (/* {
    match: {
        params: { buildingID },
    },
}: BuildingPageProps */) => {
    const { buildingID} = useParams();
    const [buildingView, setBuildingView] = useState(BuildingView.Normal);
    const { currentPermissions } = useCurrentUser();

    if (
        buildingView === BuildingView.Normal &&
        validateRequiredPermissions(
            PermissionName.ViewBuilding,
            PermissionCheckType.All,
            currentPermissions,
        )
    ) {
        return (
            <BuildingDetailsPage
                buildingID={parseInt(buildingID)}/* here buildingid is parsed to int to upgrade to v6 */
                setBuildingView={setBuildingView}
            />
        );
    } else {
        return (
            <BuildingViewPage buildingID={parseInt(buildingID)} setBuildingView={setBuildingView} />
        );
    }
};
