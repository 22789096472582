import * as Yup from 'yup';
import { FormikModelDialog, SubmitStatus } from '../../../components/formik-model-dialog.component';
import { useState } from 'react';
import { InputFieldScheduler } from './Inputfield-scheduler';

interface EditSLAProps {
    data?: ISLACondition;
    onClose?: (status: SubmitStatus, data: any) => any;
}

export const EditSLA = ({ data, onClose }: EditSLAProps) => {
    const title = 'Edit';
    const condDataImtem = [{ text: '<', value: "<" }, { text: '>', value: ">" }, { text: '=', value: "=" }]
    const userTypeDataImtem = [{ text: 'System', value: "System" }, { text: 'Inspector', value: "Inspector" }, { text: 'Homeowner', value: "Homeowner" }]
    const periodDataImtem = [{ text: 'Days', value: "Days" }, { text: 'Hours', value: "Hours" }]
    const POST_URL = `SchedulerAssignments/UpdateSLA`;
    //const POST_URL = `SchedulerAssignments/UpdateSLA?key=${data.sKey}&val=${data?.sValue}`;
    const [intervelValue, setIntervalValue] = useState<number>(parseInt(data?.sValue))
    const validationSchema = Yup.object().shape({
        sValue: Yup
            .number()// Validates for numerical value
            .positive("Must be a positive value") // Validates against negative values
            .required("Please enter a value.") // Sets it as a compulsory field
            .min(1, "Trigger value must be greater than or equal to 1!"), // Sets a minimum value});

    });


    const initialData: ISLACondition = {
        action: data?.action ? data?.action : "",
        description: data?.description ? data?.description : '',
        period: data?.period,
        userType: data?.userType,
        sKey: data?.sKey,
        sValue: data?.sValue,
        sCondition: data?.sCondition
    };


    return (
        <FormikModelDialog
            width="20%"
            title={title}
            initialData={initialData}
            validationSchema={validationSchema}
            show={!!data}
            onClose={onClose}
            postRoute={POST_URL}
        >
            <>
                <div className="row labelfix">
                    <div className="col-12">
                        <InputFieldScheduler label="Description" isDisabled={true} name="description" />
                    </div>

                    <div className="col-12">
                        <InputFieldScheduler label="Condition" isDisabled={true} name="sCondition" />
                        {/* <label>Condition</label>
                        <SingleDropdown
                            dataitems={condDataImtem}
                            disabled={true}
                            id={'vCondition'}
                            height={"35px"}
                            value={data?.sCondition}
                            className="form-control dropDownList"
                            isDef={{ text: ">", value: ">" }}
                            onchange={(value: string) => {
                                console.log(value)
                            }} /> */}
                    </div>
                    <div className="col-12 d-flex">
                        <div className='d-flex col-12' >
                            <div className="col-6">
                                <InputFieldScheduler maxLength={3} label="Trigger*" name="sValue" />
                            </div>
                            <div className="col-6 ms-1">
                                <InputFieldScheduler label="Period" isDisabled={true} name="period" />
                                {/* <label>Period</label>
                                <SingleDropdown
                                    dataitems={periodDataImtem}
                                    id={'userType'}
                                    disabled={true}
                                    height={"35px"}
                                    value={data?.period?.trim()}
                                    className="form-control dropDownList"
                                    isDef={{ text: 'Days', value: 'Days' }}
                                    onchange={(value: string) => {
                                        console.log(value)
                                    }} /> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <InputFieldScheduler label="Applicable User" isDisabled={true} name="userType" />
                        {/* <label>Applicable User</label>
                        <SingleDropdown
                            disabled={true}
                            dataitems={userTypeDataImtem}
                            id={'userType'}
                            height={"35px"}
                            value={data?.userType?.trim()}
                            className="form-control dropDownList"
                            isDef={{ text: 'System', value: 'System' }}
                            onchange={(value: string) => {
                                console.log(value)
                            }} /> */}
                    </div>

                </div>
            </>
        </FormikModelDialog >
    );
};
