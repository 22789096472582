import React from 'react';

const FileImg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.968" height="15.749" viewBox="0 0 14.968 15.749">
      <path d="M16.386,5.67l-2.2-.2-.2-2.356A.917.917,0,0,0,13,2.253L3.075,3.2a.941.941,0,0,0-.822,1.034l.9,10.425a.917.917,0,0,0,.985.861l.524-.049-.05.587a.938.938,0,0,0,.825,1.03l9.931.911a.921.921,0,0,0,.982-.865L17.214,6.7A.945.945,0,0,0,16.386,5.67ZM4.107,11.7a.233.233,0,0,1-.247-.214l-.6-7.032a.234.234,0,0,1,.2-.257l9.326-.883a.233.233,0,0,1,.247.214l.16,1.856L6.459,4.76a.921.921,0,0,0-.982.865l-.5,5.988Zm12.1-4.768-.585,7.032a.231.231,0,0,1-.244.218l-.621-.056-8.708-.8a.236.236,0,0,1-.207-.257l.127-1.544.458-5.489a.231.231,0,0,1,.244-.218l6.614.608.992.091L16,6.676A.226.226,0,0,1,16.206,6.929Z" transform="translate(-2.25 -2.249)" fill="#8f8f8f"/>
    </svg>
  );
};

export default FileImg;
