import { Link } from 'react-router-dom';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { GuardedComponent } from '../components/guarded.componemt';
import { PermissionName } from '../models/user-security/permission.name';

export const SchedulerMenuComponent = () => {
    return (
        <>
            <Navbar expand="lg" >
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav >
                        <Link to="/" className="nav-link text-dark">
                            Home
                        </Link>

                        <NavDropdown title="Admin" id="basic-nav-dropdown" style={{ marginRight: "10px" }}>
                            <Link to="/aging-report" className="nav-link text-dark-3">
                                Aging Report
                            </Link>
                            <Link to="/sla" className="nav-link text-dark-3">
                                SLA
                            </Link>
                            <GuardedComponent permission={PermissionName.ViewUser}>
                                <Link to="/users" className="nav-link text-dark-3">
                                    Users
                                </Link>
                            </GuardedComponent>
                            <GuardedComponent permission={PermissionName.ViewRole}>
                                <Link to="/roles" className="nav-link text-dark">
                                    Roles
                                </Link>
                            </GuardedComponent>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>

            </Navbar >
        </>
    );
};
