import { useLocation, useNavigate } from "react-router-dom";
import { useAvailabilityByClaims } from "../../api/inspector/use-availability-by-claim";
import { ManageAvailability } from "./components/manage-availability";
import { LoadingIndicator } from '../../components/loading-indicator.component';

import React, { useEffect, useRef, useState } from "react";
import { IInspectorAvailability } from "../../models/scheduler/inspector-availability";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import axios from "axios";
import { ISaveTimeSlot } from "../../models/scheduler/save-time-slot";
import { formatDate, parseDate } from "@progress/kendo-intl";
import { ISlot } from "./components/timeslot-component";
import CloseIcon from "../../images/close-icon";
import { convertToTimezoneWithDateString } from "../../utils";
import LocationIcon from "../../images/location";
import { isEmpty } from "lodash";
import { GroupedSlot } from "../../types/GroupedSlot";
import { ScheduledSlot } from "../../types/ScheduledSlot";
import { AvailabilityList } from "./components/availability-list";



export function InspectorAvailabilityByClaim() {

    const { state: params } = useLocation();
    const nav = useNavigate();


    const { inspectorId, claimId, state, location, scopeOfWork } = params


    const { schUsers } = state;


    const { userName, userGuid, userId, } = schUsers;

    const { data: availabilities, isLoading: isLoadingAvailabilities, isError, refetch: reloadInspectorAvailability } = useAvailabilityByClaims({
        variables: {
            claimId, inspectorId
        }
    })






    const [unAvailableDaySlots, setUnAvailableDaySlots] = useState<ISlot[]>([])
    const [unAvailableTimeSlots, setUnAvailableTimeSlots] = useState<ISlot[]>([])



    useEffect(() => {
        let days = availabilities?.map(e => e.dateFrom);
        let dayslots: ISlot[] = days?.map(e => { return { label: formatDate(parseDate(e), "EE,MM/dd/yyyy"), value: formatDate(parseDate(e), "MM/dd/yyyy") } })
        setUnAvailableDaySlots(dayslots);
        let time: ISlot[] = availabilities?.map(e => { return { label: e.timeSlot, value: e.timeSlot } })
        setUnAvailableTimeSlots(time);
    }, [availabilities])



    const slotToDelete = useRef<ScheduledSlot>()

    const [showDeleteDialog, setShowDeleteDialog] = useState(false)


    const handleDelete = (slot: ScheduledSlot) => {
        slotToDelete.current = slot
        setShowDeleteDialog(true)
    }



    const initialSlotData = React.useRef<ISaveTimeSlot>({
        claimNo: '', dateSlots: [],
        isAvailable: true,
        schID: 0,
        sDate: '',
        timeSlot: '',
        userGUID: userGuid,
        claimId: 0,
        userId: userId,
        inspectorId: userId,
    });



    const copyInitialSlot = React.useRef<ISaveTimeSlot>({
        claimNo: '', dateSlots: [],
        isAvailable: true,
        schID: 0,
        sDate: '',
        timeSlot: '',
        userGUID: userGuid,
        claimId: 0,
        userId: userId,
        inspectorId: userId,
    });









    const groupedSlots = React.useMemo(() => {

        if (availabilities) {
            const groupedSlots: GroupedSlot[] = Object.values(
                availabilities?.reduce<Record<string, GroupedSlot>>((acc, slot) => {
                    const date = convertToTimezoneWithDateString(slot.dateFrom).format('MM/DD/YYYY'); // Format date as DD/MM/YYYY
                    if (!acc[date]) {
                        acc[date] = { date, slots: [] };
                    }
                    acc[date].slots.push(slot);
                    return acc;
                }, {})
            ).map(group => {
                group?.slots.sort((a, b) => convertToTimezoneWithDateString(a.dateFrom).valueOf() - convertToTimezoneWithDateString(b.dateFrom).valueOf());// Sort slots by dateFrom
                return group;
            });
            return groupedSlots
        }
        return []

    }, [availabilities])




    return (
        <div className="wrapper-inspector-avail">

            {isLoadingAvailabilities && <LoadingIndicator isLoading />}
            {isError && (
                <div>Error Loading Availability Details</div>
            )}
            <div className="">
                <div className="d-flex justify-content-between align-items-center pt-3 pb-3">
                    <div className="locationData" >
                        <small>Location </small>
                        <div className='mt-2' style={{ fontWeight: 'bold' }}>{location}</div>
                    </div>
                    <div>
                        <small>Scope Of Work</small>
                        {<div className='mt-2' style={{ fontWeight: 'bold' }}> {scopeOfWork}</div>}

                    </div>
                    <button
                        className="backbtn fixedtopbtn"
                        onClick={() => {
                            nav('/inspector/landing-page', { state: state });
                        }}
                    >
                        Back
                    </button>


                </div>
                <ManageAvailability
                    data={initialSlotData.current}
                    unAvailableDaySlots={unAvailableDaySlots}
                    unAvailableTimeSlots={unAvailableTimeSlots}
                    onSuccess={() => reloadInspectorAvailability()}
                    claimId={claimId}
                />

            </div>


            <center>
                <h5 style={{ fontWeight: 'bold' }}>Selected Time Slots</h5>
            </center>

            <AvailabilityList groupedSlots={groupedSlots} handleDelete={handleDelete} showClaimNumber />
            {showDeleteDialog && (<Dialog title='Delete Slot' width={"30%"} onClose={() => { setShowDeleteDialog(false) }} key={1}>
                <center>
                    <h5>Are you sure you want to delete ?</h5>
                </center>
                <DialogActionsBar>
                    <div className='row'>
                        <div className='d-flex justify-content-between' >
                            <button className='btn btn-sm btn-secondary' onClick={() => {
                                setShowDeleteDialog(false)
                            }}>No</button>
                            <button className='btn btn-primary btn-sm' onClick={async () => {
                                const response = await axios.post(`SchedulerAssignments/DeleteInspSlotsForCalendar?inspectorId=${inspectorId}&slotId=${slotToDelete.current.slotId}&userId=${inspectorId}&userName=${userName}&claimNumber=${slotToDelete.current.claimNo}`);
                                if (response.data.result === -1) {
                                    setShowDeleteDialog(false)
                                    reloadInspectorAvailability();
                                }
                            }}>Yes</button>

                        </div>
                    </div>
                </DialogActionsBar>

            </Dialog>)
            }
        </div>
    )
}