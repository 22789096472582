import DeleteIcon from '../images/delete';
import iconEdit from '../images/edit';
import iconEye from '../images/eye';
import iconReport from '../images/report';
import { GuardedComponent } from './guarded.componemt';
import { PermissionName } from '../models/user-security/permission.name';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import EditIcon from '../images/edit';
import EyeIcon from '../images/eye';
import ReportIcon from '../images/report';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
//import InspectionReport from './inspection-report.component';
import { Dialog } from '@progress/kendo-react-dialogs';
import FileImg from '../images/file-image';
import axios from 'axios';
import InspectionReport from './inspection-report.component';
import { InspectionReportIcon } from '../images/report-icon.component';

export const GridCommandCell = (props: {
    view?: any;
    report?: any;
    editField?: any;
    add?: any;
    update?: any;
    discard?: any;
    cancel?: any;
    edit?: any;
    remove?: any;
    dataItem?: any;
    idPropertyName: string;
    viewPermissionName?: PermissionName;
    editPermissionName?: PermissionName;
    deletePermissionName?: PermissionName;
}) => {
    const { dataItem, viewPermissionName, editPermissionName, deletePermissionName } = props;
    //const [report, setreport] = useState<boolean>(false);
    const nav = useNavigate();
    return (
        <>
            <td className="k-command-cell text-center">
                <div className='d-flex'>
                    {props.view && (
                        <GuardedComponent permission={viewPermissionName}>
                            <OverlayTrigger
                                delay={{ hide: 450, show: 300 }}
                                overlay={(props) => (
                                    <Tooltip {...props}>
                                        View
                                    </Tooltip>
                                )}
                                placement="bottom"
                            >
                                <button
                                    className="action-btns k-grid-edit-command"
                                    onClick={() => props.view(dataItem)}
                                >
                                    <EyeIcon /> {/* <img src={iconEye} alt="view" title="View" /> */}
                                </button>
                            </OverlayTrigger>
                        </GuardedComponent>
                    )}
                    {/*  {props.report && (
                <button
                    className="action-btns k-grid-edit-command"
                    onClick={() => props.report(dataItem)}
                >
                   <ReportIcon/> <img className="report-cls" alt="report" src={iconReport} title="Report" />
                </button>
            )} */}
                    {props.report && (
                        <GuardedComponent permission={viewPermissionName}>
                            {' '}
                            <OverlayTrigger

                                overlay={(props) => (
                                    <Tooltip {...props}>
                                        Inspection Report
                                    </Tooltip>
                                )}
                                placement="bottom"
                            >
                                <button
                                    className='action-btns k-grid-edit-command'
                                    onClick={async () => {
                                        nav("/inspectionReport", { state: dataItem });

                                    }}
                                >
                                    <InspectionReportIcon />
                                </button>
                            </OverlayTrigger>
                        </GuardedComponent>
                    )}
                    {props.edit && (
                        <GuardedComponent permission={editPermissionName}>
                            <OverlayTrigger

                                overlay={(props) => (
                                    <Tooltip {...props}>
                                        Edit
                                    </Tooltip>
                                )}
                                placement="bottom"
                            >
                                <button
                                    className="action-btns k-grid-edit-command"
                                    onClick={() => props.edit(dataItem)}
                                >
                                    <EditIcon></EditIcon>{' '}
                                    {/* <img src={iconEdit} alt="edit" title="Edit" /> */}
                                </button>
                            </OverlayTrigger>
                        </GuardedComponent>
                    )}
                    {props.remove && (
                        <GuardedComponent permission={deletePermissionName}>
                            <OverlayTrigger

                                overlay={(props) => (
                                    <Tooltip {...props}>
                                        Delete
                                    </Tooltip>
                                )}
                                placement="bottom"
                            >
                                <button
                                    className="action-btns k-grid-remove-command"
                                    onClick={() => props.remove(dataItem)}
                                >
                                    <DeleteIcon></DeleteIcon>{' '}
                                    {/* <img src={iconDelete} alt='delete' title="Delete" /> */}
                                </button>

                            </OverlayTrigger>
                        </GuardedComponent>
                    )}
                </div>
            </td>

        </>
    );
};
