export const WINDOWS_DOORS_API_URL =
    process.env.REACT_APP_BASE_API_URL; /* 'https://localhost:5001' */
export const FILE_STORAGE_URL = process.env.REACT_APP_FILE_STORAGE_URL;
export const YOUR_GOOGLE_MAPS_API_KEY = 'AIzaSyBKDeDCyCAHKPru29FgkbODlVNwQGEzDPg';
export const WINDOWS_DOOR_OTHER_OPTION = 'Custom-Other'


export const WINDOWS_DOOR_OTHER_OPTION_VALUE_PREFIX = '_other_value';
export const LOGIN_DEMO_KEY = 'demokey';


export const STORAGE_KEY: {
    [key: string]: {
        CurrentUser: string,
        AuthState: string,
    }
} = {
    scheduler: {
        CurrentUser: 'CURRENT_USER',
        AuthState: 'AUTH_STATE',
    },
    inspector: {
        CurrentUser: 'CURRENT_INSPECTOR',
        AuthState: 'INSPECTOR_AUTH_STATE',
    },
    homeowner: {
        CurrentUser: 'CURRENT_HOME_OWNER',
        AuthState: 'HOMEOWNER_AUTH_STATE',
    }
}


export enum StorageKey {
    CurrentUser = 'CURRENT_USER',
    AuthState = 'AUTH_STATE',
    UserType = 'USER_TYPE'
}

export enum InspectorStorageKey {
    CurrentInspector = 'CURRENT_INSPECTOR',
    InspectorAuthState = 'INSPECTOR_AUTH_STATE',
}
export enum HomeOwnerStorageKey {
    CurrentHomeOwner = 'CURRENT_HOME_OWNER',
    HomeOwnerAuthState = 'HOMEOWNER_AUTH_STATE',
}


export const SLOT_TYPES = {
    availabilityReceived: 'AVALBL',
    awaitingAvailability: "AWTAVL",
    rescheduled: 'RESCHD',
    readyToSchedule: 'RDYSCH',
    declined: 'DCLIND',
    scheduled: 'SCHEDL',
    unavailable: 'UNAVAIL',
    received: 'RECVED',
    cancelled: 'CANCEL',
    temp: 'TEMP'
}

export const TIME_ZONE = 'MST7MDT';
