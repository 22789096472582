export enum ObservationType {
    Window = 'window',
    Door = 'door',
    Railing = 'railing',
    /* building enum added here to add obs to building feature */
    Building="building",
    /* added for observation tab to unit and floor  */
    Floor="floor",
    Unit="unit",
    Room='room'
}
