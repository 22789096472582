import React from 'react';

const Logo = () => {
    return (
        <div>
            {' '}
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xlinkHref="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                height={60}
                viewBox="0 0 366.1 90.3"
                style={{ background: 'new 0 0 366.1 90.3;' }}
                xmlSpace="preserve"
            >
                <style>
                    {`
        .st0 {
          fill: #F68B1F;
        }
      `}
                </style>

                {/* .st1{enable-background:new    ;} */}
                <style>
                    {`
        .st1 {
          enable-background: new;
        }
      `}
                </style>

                {/* .st2{clip-path:url(#SVGID_2_);} */}
                <style>
                    {`
        .st2 {
          clip-path: url(#SVGID_2_);
        }
      `}
                </style>

                {/* .st3{fill:#114656;} */}
                <style>
                    {`
        .st3 {
          fill: #114656;
        }
      `}
                </style>
                <path className="st0" d="M22.3,90.3h68l-34-34L22.3,90.3z" />
                <path className="st0" d="M0,0v38.3L38.3,0H0z" />
                <path className="st0" d="M60.6,0L0,60.6v29.7l45.1-45.1L90.3,0H60.6z" />
                <g className="st1">
                    <path
                        className="st0"
                        d="M116.6,34.2l-3.9,4.3v12.3h-3V15.5h3v19.3l17.1-19.3h3.9l-15.1,16.9l16.5,18.5h-3.9L116.6,34.2z"
                    />
                </g>
                <g className="st1">
                    <path
                        className="st0"
                        d="M140.7,15.5h22.4v2.8h-19.4v13.1h19v2.8h-19v14h19.4V51h-22.4V15.5z"
                    />
                </g>
                <g className="st1">
                    <path
                        className="st0"
                        d="M184.4,35.7l-14-20.2h3.7L186,32.9l11.9-17.4h3.7l-14,20.2v15.1h-3.1L184.4,35.7L184.4,35.7z"
                    />
                </g>
                <g className="st1">
                    <path
                        className="st0"
                        d="M206.3,43.6c2.3,2.6,6,5.1,11,5.1c7.3,0,9.3-4,9.3-7c0-5-4.8-6.4-9.7-7.7c-5.6-1.5-11.5-3.1-11.5-9.6
         c0-5.8,5.2-9.4,11.6-9.4c5.2,0,9.2,1.9,12,5l-2,2.2c-2.5-3.1-6.1-4.5-10.1-4.5c-4.7,0-8.3,2.7-8.3,6.5c0,4.2,4.4,5.4,9.2,6.7
         c5.7,1.6,12,3.4,12,10.6c0,4.5-3,10-12.6,10c-5.8,0-10.2-2.3-12.9-5.6L206.3,43.6z"
                    />
                </g>
                <g className="st1">
                    <path
                        className="st0"
                        d="M247.7,18.2h-11.6v-2.8h26.2v2.8h-11.6v32.6h-3.1V18.2H247.7z"
                    />
                </g>
                <g className="st1">
                    <path
                        className="st0"
                        d="M282.8,14.9c10.3,0,17.2,7.9,17.2,18.3c0,10.3-6.8,18.3-17.2,18.3c-10.4,0-17.2-7.9-17.2-18.3
         C265.6,22.8,272.4,14.9,282.8,14.9z M282.8,17.7c-8.6,0-14,6.6-14,15.5s5.4,15.5,14,15.5c8.5,0,14-6.6,14-15.5
         C296.8,24.2,291.3,17.7,282.8,17.7z"
                    />
                </g>
                <g className="st1">
                    <path
                        className="st0"
                        d="M309.2,20.3v30.5h-3V15.5h3.1l22.2,30.1V15.5h3v35.3h-3L309.2,20.3z"
                    />
                </g>
                <g className="st1">
                    <path
                        className="st0"
                        d="M341.7,15.5h22.4v2.8h-19.4v13.1h19v2.8h-19v14h19.4V51h-22.4V15.5z"
                    />
                </g>
                <g transform="translate(0 0)">
                    <g>
                        <g>
                            <defs>
                                <rect id="SVGID_1_" x="4" width="366.1" height="90.3" />
                            </defs>
                            <clipPath id="SVGID_2_">
                                <use href="#SVGID_1_" style={{ overflow: 'visible' }} />
                            </clipPath>
                            <g className="st2">
                                <g className="st1">
                                    <path
                                        className="st3"
                                        d="M109.7,63.6h7.3v1.2H111v3.5h5.8v1.2H111v3.8h5.9v1.2h-7.3V63.6z"
                                    />
                                </g>
                                <g className="st1">
                                    <path
                                        className="st3"
                                        d="M127.9,69.9l-3.5,4.7h-1.7l4.4-5.7l-4-5.4h1.7L128,68l3.3-4.5h1.7l-4,5.4l4.3,5.7h-1.7L127.9,69.9z"
                                    />
                                </g>
                                <g className="st1">
                                    <path
                                        className="st3"
                                        d="M139.3,63.6h4.5c2.2,0,3.4,1.6,3.4,3.3c0,1.8-1.2,3.3-3.4,3.3h-3.1v4.4h-1.3V63.6z M143.5,64.9h-2.9v4.3
                         h2.9c1.3,0,2.2-0.8,2.2-2.1C145.7,65.8,144.8,64.9,143.5,64.9z"
                                    />
                                    <path
                                        className="st3"
                                        d="M153.6,63.6h7.3v1.2h-5.9v3.5h5.8v1.2h-5.8v3.8h5.9v1.2h-7.3V63.6z"
                                    />
                                </g>
                                <g className="st1">
                                    <path
                                        className="st3"
                                        d="M171.3,70.2h-2.2v4.4h-1.3v-11h4.5c2.1,0,3.4,1.2,3.4,3.3c0,2-1.3,3.1-2.9,3.2l3,4.6h-1.7L171.3,70.2z
                          M172,64.9H169v4.3h2.9c1.3,0,2.2-0.8,2.2-2.1C174.1,65.8,173.3,64.9,172,64.9z"
                                    />
                                </g>
                                <g className="st1">
                                    <path
                                        className="st3"
                                        d="M185,64.9h-3.5v-1.2h8.4v1.2h-3.5v9.9H185L185,64.9L185,64.9z"
                                    />
                                </g>
                                <g className="st1">
                                    <path
                                        className="st3"
                                        d="M196.5,72.1c0.7,0.8,2,1.6,3.5,1.6c2,0,2.6-1,2.6-1.9c0-1.3-1.3-1.7-2.8-2.1c-1.8-0.4-3.7-0.9-3.7-3.1
                         c0-1.9,1.7-3.1,3.8-3.1c1.7,0,3,0.5,3.9,1.6l-0.8,1c-0.8-0.9-2-1.3-3.2-1.3c-1.3,0-2.3,0.7-2.3,1.8c0,1.1,1.2,1.5,2.7,1.9
                         c1.8,0.5,3.8,1,3.8,3.3c0,1.7-1.1,3.2-4,3.2c-2,0-3.3-0.7-4.3-1.8L196.5,72.1z"
                                    />
                                </g>
                                <g className="st1">
                                    <path
                                        className="st3"
                                        d="M223,73h-0.9v-3.5h-3.2v-0.8h3.2v-3.4h0.9v3.4h3.2v0.8H223V73z"
                                    />
                                </g>
                                <g className="st1">
                                    <path
                                        className="st3"
                                        d="M241.5,63.6h7.3v1.2h-5.9v3.5h5.8v1.2h-5.8v3.8h5.9v1.2h-7.3V63.6z"
                                    />
                                </g>
                                <g className="st1">
                                    <path
                                        className="st3"
                                        d="M256.9,65.8v8.9h-1.3V63.6h1.5l6.3,8.7v-8.7h1.3v11.1h-1.3L256.9,65.8z"
                                    />
                                    <path
                                        className="st3"
                                        d="M277.2,63.4c2,0,3.3,0.8,4.3,2.1l-1.1,0.7c-0.7-0.9-1.9-1.6-3.2-1.6c-2.4,0-4.3,1.9-4.3,4.5
                         c0,2.6,1.9,4.6,4.3,4.6c1.3,0,2.4-0.6,3-1.2v-2.2h-3.8V69h5.2v4c-1,1.1-2.5,1.9-4.4,1.9c-3.1,0-5.7-2.3-5.7-5.7
                         S274,63.4,277.2,63.4z"
                                    />
                                    <path className="st3" d="M288.4,63.6h1.3v11.1h-1.3V63.6z" />
                                </g>
                                <g className="st1">
                                    <path
                                        className="st3"
                                        d="M298.4,65.8v8.9h-1.3V63.6h1.5l6.3,8.7v-8.7h1.3v11.1h-1.3L298.4,65.8z"
                                    />
                                    <path
                                        className="st3"
                                        d="M313.4,63.6h7.3v1.2h-5.9v3.5h5.8v1.2h-5.8v3.8h5.9v1.2h-7.3V63.6z"
                                    />
                                    <path
                                        className="st3"
                                        d="M327.5,63.6h7.3v1.2h-5.8v3.5h5.8v1.2h-5.8v3.8h5.9v1.2h-7.3V63.6H327.5z"
                                    />
                                    <path
                                        className="st3"
                                        d="M345.2,70.2H343v4.4h-1.3v-11h4.5c2.1,0,3.4,1.2,3.4,3.3c0,2-1.3,3.1-2.9,3.2l3,4.6H348L345.2,70.2z
                          M345.8,64.9h-2.9v4.3h2.9c1.3,0,2.2-0.8,2.2-2.1C348,65.8,347.2,64.9,345.8,64.9z"
                                    />
                                </g>
                                <g className="st1">
                                    <path
                                        className="st3"
                                        d="M356.5,72.1c0.7,0.8,2,1.6,3.5,1.6c2,0,2.6-1,2.6-1.9c0-1.3-1.3-1.7-2.8-2.1c-1.8-0.4-3.7-0.9-3.7-3.1
                         c0-1.9,1.7-3.1,3.8-3.1c1.7,0,3,0.5,3.9,1.6l-0.8,1c-0.8-0.9-2-1.3-3.2-1.3c-1.3,0-2.3,0.7-2.3,1.8c0,1.1,1.2,1.5,2.7,1.9
                         c1.8,0.5,3.8,1,3.8,3.3c0,1.7-1.1,3.2-4,3.2c-2,0-3.3-0.7-4.3-1.8L356.5,72.1z"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default Logo;
