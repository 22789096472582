import { GhostMode, Mode, Point, Shape } from '../../../../models/building-image/image-draw.types';
import { getCurrentGhostMode } from './ghost.util';

export const getImageCursor = (
    mode: Mode,
    point: Point,
    inprogress: boolean,
    ghostShape: Shape,
    scaleFactor: number,
) => {
    switch (mode) {
        case Mode.Pan:
            return inprogress ? 'grabbing' : 'grab';
        case Mode.ZoomIn:
            return 'zoom-in';
        case Mode.ZoomOut:
            return 'zoom-out';
        case Mode.Select:
        case Mode.Draw:
            if (ghostShape) {
                const editMode = getCurrentGhostMode(point, ghostShape, scaleFactor);
                if (editMode === GhostMode.TL || editMode === GhostMode.BR) {
                    return 'nwse-resize';
                } else if (editMode === GhostMode.TR || editMode === GhostMode.BL) {
                    return 'nesw-resize';
                } else if (editMode === GhostMode.Moving) {
                    return 'move';
                }
            }
            break;
    }
    return 'default';
};
