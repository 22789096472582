import React from 'react';

const Window = ({ color = "#959595", ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg"
        height="30"
        viewBox="0 0 20.512 26.773" {...props}>
        <g transform="translate(-502.487 -721)">
            <g transform="translate(502.487 721)">
                <path
                    d="M-286.1,326.659a.562.562,0,0,1-.262-.529c0-5.559-.026-11.119.018-16.677a9.236,9.236,0,0,1,1.979-5.593,9.827,9.827,0,0,1,5.2-3.538,10.8,10.8,0,0,1,4.682-.31,9.965,9.965,0,0,1,4.733,1.994,10.011,10.011,0,0,1,3.068,3.934,10.231,10.231,0,0,1,.827,4.193q-.021,7.96,0,15.919c0,.247-.018.472-.261.607Zm19.477-.771v-.349q0-7.829,0-15.658a8.541,8.541,0,0,0-2.892-6.61,9.6,9.6,0,0,0-8.422-2.417,9.037,9.037,0,0,0-4.517,2.151,8.548,8.548,0,0,0-3.056,6.568c-.044,5.341-.013,10.683-.013,16.024v.291Z"
                    transform="translate(286.372 -299.887)"
                    fill={color}
                />
                <path
                    d="M-259.744,341.971h-7.869c-.564,0-.64-.073-.64-.625,0-4.635.005-8.66,0-13.3a9.921,9.921,0,0,1,2.447-6.752,8.19,8.19,0,0,1,4.77-2.446,8.267,8.267,0,0,1,6.632,1.787,9.638,9.638,0,0,1,3.064,5.937,10.69,10.69,0,0,1,.108,1.561c.008,4.609,0,8.607,0,13.217,0,.537-.079.616-.621.616Zm7.715-13.689h-7.29l0,5.718h7.29Zm-15.415,12.875h7.289v-6.329h-7.289Zm8.112,0h7.295v-6.327h-7.295Zm-.821-7.156,0-5.726h-7.287l0,5.726Zm-5.194-12.036c-1.273,1.395-1.992.853-2.1,5.48h6.737C-262.276,325.805-263.8,323.589-265.348,321.967Zm11.217,0c-1.55,1.629-3.075,3.845-4.635,5.484h6.7A7.747,7.747,0,0,0-254.131,321.967Zm-5.188-2.425,0,7.307c1.547-1.627,3.062-3.832,4.59-5.439A7.405,7.405,0,0,0-259.319,319.542Zm-5.431,1.868c1.538,1.617,3.053,3.818,4.6,5.447l0-7.314A7.984,7.984,0,0,0-264.75,321.41Z"
                    transform="translate(270.018 -316.914)"
                    fill={color}
                />
            </g>
            <path
                d="M19.391,26.31h0L0,26.113V8.051A10.077,10.077,0,0,1,1.4,4.026,8.114,8.114,0,0,1,4.184,1.258,9.847,9.847,0,0,1,9.272,0c.3,0,.606.01.917.029a10.951,10.951,0,0,1,5.483,1.687,8.489,8.489,0,0,1,2.8,3.136,10.306,10.306,0,0,1,1.162,4.38l-.245,17.077Zm-10-23.541A6.816,6.816,0,0,0,3.174,5.947,8.041,8.041,0,0,0,2.069,9.126V23.385l15.308.156.194-13.482A8.131,8.131,0,0,0,16.652,6.6C15.806,5,13.984,3.033,10.11,2.791,9.867,2.776,9.624,2.769,9.389,2.769Z"
                transform="translate(503.048 721.365)"
                fill={color}
            />
            <rect width="16" height="3" transform="translate(505 732.474)" fill={color} />
            <rect width="16" height="3" transform="translate(505 738.474)" fill={color} />
            <path d="M3981.031-817.5l5.512,6,.492-2.51-5.119-5.709Z" transform="translate(-3474.538 1544.311)" fill={color} />
            <path d="M3984.313-820.359v22.22h2.559v-22.22Z" transform="translate(-3472.996 1543.992)" fill={color} />
            <path d="M3990.2-819.422l-5.758,6.792h2.412l4.823-5.291Z" transform="translate(-3472.579 1544.482)" fill={color} />
        </g>
    </svg>
);

const Railing = ({ color = "#959595", ...props }) => (
    <svg id="Group_180" data-name="Group 180" width="29" height="29.905" viewBox="0 0 29 29.905">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_855" data-name="Rectangle 855" width="29" height="29.905" fill={color} />
            </clipPath>
        </defs>
        <g id="Group_179" data-name="Group 179" clip-path="url(#clip-path)">
            <path id="Path_138" data-name="Path 138" d="M25.816,0a3.188,3.188,0,0,0-3.184,3.184v.607H6.368V3.184A3.184,3.184,0,1,0,0,3.184V29.905H6.368V26.228H22.632v3.678H29V3.184A3.188,3.188,0,0,0,25.816,0M24.643,3.184a1.172,1.172,0,1,1,2.345,0v.607H24.643Zm-2.012,4.85H6.368V5.8H22.632Zm-8.71,16.182H10.983V10.046h2.939Zm2.012-14.17H18.25v14.17H15.933ZM3.184,2.012A1.174,1.174,0,0,1,4.357,3.184v.607H2.012V3.184A1.174,1.174,0,0,1,3.184,2.012M2.012,5.8H4.357V27.894H2.012Zm4.357,4.243h2.6v14.17h-2.6Zm13.893,0h2.37v14.17h-2.37ZM24.643,5.8h2.345V27.894H24.643Z" fill={color} />
        </g>
    </svg>
);

export { Window, Railing };
