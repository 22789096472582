import { Link } from 'react-router-dom';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { GuardedComponent } from '../components/guarded.componemt';
import { PermissionName } from '../models/user-security/permission.name';
import { PermissionCheckType } from '../models/user-security/permission-check-type';

export const MenuComponent = () => {
    return (
        <>
            <Navbar expand="lg" >

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav >
                        <GuardedComponent permission={PermissionName.ViewHomeClaim}>
                            <Link to="/" className="nav-link text-dark">
                                Home
                            </Link>
                        </GuardedComponent>
                        {/* <GuardedComponent permission={PermissionName.ViewClaim}>
                                <Link to="/claims" className="nav-link text-dark">
                                    Claims
                                </Link>
                            </GuardedComponent> */}
                        <GuardedComponent permission={PermissionName.ViewMaster}>
                            <NavDropdown title="Master" id="basic-nav-dropdown">
                                <Link to="/window-master" className="nav-link text-dark">
                                    Window
                                </Link>
                                <Link to="/door-master" className="nav-link text-dark">
                                    Door
                                </Link>
                                <Link to="/railing-master" className="nav-link text-dark">
                                    Railing
                                </Link>
                                <Link to="/observation-master" className="nav-link text-dark">
                                    Observation
                                </Link>
                            </NavDropdown>
                        </GuardedComponent>
                        <GuardedComponent
                            permission={[PermissionName.ViewUser, PermissionName.ViewRole]}
                            permissionCheckType={PermissionCheckType.Any}
                        >
                            <NavDropdown title="Admin" id="basic-nav-dropdown">
                                <GuardedComponent permission={PermissionName.ViewUser}>
                                    <Link to="users" className="nav-link text-dark">
                                        Users
                                    </Link>
                                </GuardedComponent>
                                <GuardedComponent permission={PermissionName.ViewRole}>
                                    <Link to="roles" className="nav-link text-dark">
                                        Roles
                                    </Link>
                                </GuardedComponent>
                            </NavDropdown>
                        </GuardedComponent>
                    </Nav>
                </Navbar.Collapse>

            </Navbar>
        </>
    );
};
