import React from 'react';

const ImageLink = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.9" height="22.532" viewBox="0 0 24.9 22.532">
      <g id="Group_299" data-name="Group 299" transform="translate(501 -2683)">
        <path id="Path_image-edit-outline" data-name="Path / image-edit-outline" d="M9.842,13.692,8.208,11.725,5.917,14.667h5.517l2.117-2.042-1.417-1.883-2.292,2.95m-.175,3.392v-.708l.042-.042H4.667V4.667H16.333V9.925L18,8.317V4.667A1.666,1.666,0,0,0,16.333,3H4.667A1.672,1.672,0,0,0,3,4.667V16.333A1.666,1.666,0,0,0,4.667,18h5Z" transform="translate(-504 2680)" fill="#686666"/>
        <path id="Path_link" data-name="Path / link" d="M3.541,11.056A2.517,2.517,0,0,1,6.056,8.541H9.3V7H6.056a4.056,4.056,0,0,0,0,8.113H9.3V13.571H6.056a2.517,2.517,0,0,1-2.515-2.515m3.326.811h6.49V10.245H6.868v1.623M14.169,7H10.924V8.541h3.245a2.515,2.515,0,0,1,0,5.03H10.924v1.541h3.245a4.056,4.056,0,1,0,0-8.113Z" transform="translate(-499.737 2695.844) rotate(-43)" fill="#686666"/>
      </g>
    </svg>
  );
}

export default ImageLink;
