import { formatDate } from '@progress/kendo-intl';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { useState, useEffect, useRef } from 'react';

interface ITimeSlotProps {
    timeFormat: string;
    from: dayjs.Dayjs;
    to: dayjs.Dayjs;
    factor: number;
    diffIn: "second" | "minute" | "hour" | "hours" | "day" | "month" | "year" | "date" | "milliseconds" | "seconds" | "minutes"
    manipulateType: dayjs.ManipulateType;
    formatType: "days" | "time";
    onTimeSelected: (slots: ISlot[]) => void
    previousSelectedSlots?: ISlot[];
    unavailableSlots?: ISlot[]
}

export interface ISlot {
    label: string;
    value: string;
}

const TimeSlot: React.FunctionComponent<ITimeSlotProps> = (props) => {
    const { from, timeFormat, to, factor, diffIn, manipulateType, formatType, onTimeSelected, previousSelectedSlots, unavailableSlots = [] } = props;

    const [timeSlots, setTimeSlots] = useState<ISlot[]>([]);
    const selectedSlotsRef = useRef<ISlot[]>([]);
    const [selectedTimeSlots, setSelectedTimeSlots] = useState<ISlot[]>(previousSelectedSlots);



    useEffect(() => {

        let slots: ISlot[] = [];
        const timediff = to.diff(from, diffIn);
        const interval = Math.floor(timediff);

        if (formatType === "time") {
            // for (let i = 0; i <= interval - factor; i += factor) {
            //     slots.push({
            //         value: `${formatDate(dayjs().add(i, manipulateType).toDate(), timeFormat)}-${formatDate(dayjs().add(i + factor, manipulateType).toDate(), timeFormat)}`,
            //         label: `${formatDate(dayjs().add(i, manipulateType).toDate(), timeFormat)}-${formatDate(dayjs().add(i + factor, manipulateType).toDate(), timeFormat)}`
            //     });
            // }
            const formatTime = (hour: any) => {
                const period = hour < 12 ? ':00 AM' : ':00 PM';
                const formattedHour = hour % 12 === 0 ? 12 : hour % 12; // Convert to 12-hour format
                return `${String(formattedHour).padStart(2, '0')}${period}`;
            };

            const formatTimeValue = (hour: any) => {
                const period = ':00:00.000Z' //hour < 12 ? ':00 AM' : ':00 PM';
                //const formattedHour = hour % 12 === 0 ? 12 : hour % 12; // Convert to 12-hour format
                return `${String(hour).padStart(3, 'T0')}${period}`;
            };
            const startHour = 8; // 8 AM
            const endHour = 18; // 6 PM


            for (let hour = startHour; hour < endHour; hour++) {
                const startTime = formatTime(hour);
                const endTime = formatTime(hour + 1);
                const startTimeValue = formatTimeValue(hour);
                const endTimeValue = formatTimeValue(hour + 1);
                slots.push({ label: `${startTime}-${endTime}`, value: `${startTimeValue}-${endTimeValue}` });
            }

            // for (let hour = startHour; hour < endHour; hour++) {
            //     const startTime = hour % 12 === 0 ? '12 PM' : `${hour % 12} ${hour < 12 ? 'AM' : 'PM'}`;
            //     const endTime = (hour + 1) % 12 === 0 ? '12 PM' : `${(hour + 1) % 12} ${hour + 1 < 12 ? 'AM' : 'PM'}`;
            //     //slots.push(`${startTime} - ${endTime}`);
            //     slots.push({ label: `${startTime.toLocaleUpperCase()}-${endTime.toLocaleUpperCase()}`, value: `${startTime}-${endTime}` });
            // }
        }

        if (formatType === "days") {
            for (let i = 0; i <= interval - factor; i += factor) {
                slots.push({
                    label: `${formatDate(from.add(i, manipulateType).toDate(), timeFormat)}`,
                    value: `${formatDate(from.add(i, manipulateType).toDate(), "yyyy-MM-dd")}`
                });
            }
        }
        setTimeSlots(slots);
        setSelectedTimeSlots(previousSelectedSlots);
        selectedSlotsRef.current = previousSelectedSlots;

    }, [from, to, timeFormat, factor, diffIn, manipulateType, formatType]);

    const toggleSlotSelection = (slot: ISlot) => {

        if (selectedSlotsRef.current.some(e => e.label === slot.label)) {
            selectedSlotsRef.current = selectedSlotsRef.current.filter(s => s.label !== slot.label);

        } else {
            selectedSlotsRef.current = [...selectedSlotsRef.current, slot];
        }
        setSelectedTimeSlots([...selectedSlotsRef.current]);
        onTimeSelected(selectedSlotsRef.current);
    };

    return (
        <div className='slotGrid' style={{ textTransform: formatType === "days" ? "capitalize" : "uppercase" }} >
            {!isEmpty(timeSlots) && timeSlots.map((slot, i) => (
                // <div style={unavailableSlots.some(e => e.value.trim() === slot.value.trim()) ? { pointerEvents: "none", backgroundColor: "#D5FFC4" } : null} key={i} onClick={() => toggleSlotSelection(slot)}
                //     className={selectedTimeSlots.some(e => e.label === slot.label) ? "slot-date active" : "slot-date"}>
                //     <div>{slot.label.toLowerCase()}</div>
                // </div>
                <div key={i} onClick={() => toggleSlotSelection(slot)}
                    style={formatType === "days" && unavailableSlots.some(e => e.value.trim() === slot.value.trim()) ? { backgroundColor: "#D5FFC4" } : null}
                    className={selectedTimeSlots.some(e => e.label === slot.label) ? "slot-date active" : "slot-date"}>
                    <div>{slot.label.toLowerCase()}</div>
                </div>

            ))}

            {isEmpty(timeSlots) && <LoadingIndicator isLoading />}
        </div>
    );
};

export default TimeSlot;
