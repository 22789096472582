
import React from 'react';
const ClockedIcon = () => {
    return (<svg width="38.323" height="24" viewBox="0 0 38.323 24">
        <g
            id="Group_748"
            data-name="Group 748"
            transform="translate(15639.868 14282)"
        >
            <g
                id="Group_743"
                data-name="Group 743"
                transform="translate(-16030.938 -14674)"
            >
                <g
                    id="MDI_clock-time-three-outline"
                    data-name="MDI / clock-time-three-outline"
                    transform="translate(405.393 392)"
                    opacity="0.6"
                >
                    <g
                        id="Boundary"
                        stroke="rgba(0,0,0,0)"
                        stroke-width="1"
                        opacity="0"
                    >
                        <rect
                            width="24"
                            height="24"
                            stroke="none"
                        />
                        <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            fill="none"
                        />
                    </g>
                    <path
                        id="Path_clock-time-three-outline"
                        data-name="Path / clock-time-three-outline"
                        d="M12,20a8,8,0,1,0-8-8,8.024,8.024,0,0,0,8,8M12,2A10,10,0,1,1,2,12,10.029,10.029,0,0,1,12,2m5,9.5V13H11V7h1.5v4.5Z"
                    />
                </g>
                <path
                    id="Path_335"
                    data-name="Path 335"
                    d="M-15549-14507.333l7.517,7.517-7.517,7.517"
                    transform="translate(-15149 -14095.8) rotate(180)"
                    fill="none"
                    stroke="#707070"
                    stroke-width="2"
                />
            </g>
        </g>
    </svg>);
};
export default ClockedIcon